<ng-container *ngIf="headerTitle">
  <span *ngIf="headerTitle.fromAirportCode && headerTitle.toAirportCode" class="container"
    [ngClass]="{ 'small': small }">
    <span [matTooltip]="headerTitle.fromAirportCity">{{headerTitle.fromAirportCode}}</span>
    <span>-</span>
    <span [matTooltip]="headerTitle.toAirportCity">{{headerTitle.toAirportCode}}</span>
    <span>&nbsp;|&nbsp;</span>
    <span>{{headerTitle.totalNumberOfFlights}} flight{{headerTitle.totalNumberOfFlights === 1 ? '' : 's'}}</span>
  </span>

  <span *ngIf="headerTitle.title" class="container" [ngClass]="{ 'small': small }">{{headerTitle.title}}</span>

  <span *ngIf="headerTitle.titleHTML" class="container" [innerHTML]="headerTitle.titleHTML"
    [ngClass]="{ 'small': small }"></span>
</ng-container>
