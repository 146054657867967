import { Component, ChangeDetectionStrategy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { Airport } from 'src/app/models/airport';
import { startWith, map, filter } from 'rxjs/operators';
import { AirportData } from '../../../data/airport-data';
import { Router, Route, ActivatedRoute, Params, RouterEvent, RoutesRecognized } from '@angular/router';
import { formatDate } from '@angular/common';
import { AutoUnsubscribe } from 'src/app/common/auto-unsubscribe.base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss']
})
export class FindComponent extends AutoUnsubscribe implements OnInit {

  public toAirports: Observable<Airport[]>;

  public fromAirports: Observable<Airport[]>;

  public findForm: FormGroup;

  constructor(private router: Router, @Inject(LOCALE_ID) private locale: string, private activatedRoute: ActivatedRoute) {
    super();

    this.findForm = new FormGroup({
      from: new FormControl(),
      to: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      cabin: new FormControl('economy'),
      quantity: new FormControl(1),
      engines: new FormArray([])
    });
  }

  ngOnInit() {
    this.subscriptions.push(this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof RoutesRecognized),
        filter((routerEvent: RoutesRecognized) => routerEvent.state.root.firstChild !== null),
        map((routerEvent: RoutesRecognized) => routerEvent.state.root.firstChild.params)
      )
      .subscribe((routeParams: Params) => {
        this.subscriptions.push(this.activatedRoute.queryParams
          .subscribe((queryParams: Params) => {
            const routeNameArr: string[] = routeParams.routeName ? routeParams.routeName.toUpperCase().split('-') : [];

            this.findForm.get('from').setValue(routeNameArr[0]);
            this.findForm.get('to').setValue(routeNameArr[1]);
            this.findForm.get('startDate').setValue(queryParams.startDate ? new Date(queryParams.startDate) : null);
            this.findForm.get('endDate').setValue(queryParams.endDate ? new Date(queryParams.endDate) : null);
          }));
      }));

    this.fromAirports = this.findForm.get('from').valueChanges
      .pipe(
        startWith(''),
        map((search: string) => this.filterAirports(search, this.findForm.get('to').value))
      );

    this.toAirports = this.findForm.get('to').valueChanges
      .pipe(
        startWith(''),
        map((search: string) => this.filterAirports(search, this.findForm.get('from').value))
      );
  }

  find() {
    const routeName: string = `${this.findForm.get('from').value}-${this.findForm.get('to').value}`.toUpperCase();

    this.router.navigate([`route/${routeName}`], {
      queryParams: {
        startDate: this.findForm.get('startDate').value ? formatDate(this.findForm.get('startDate').value, 'yyyy-MM-ddT00:00:00', this.locale) : '',
        endDate: this.findForm.get('endDate').value ? formatDate(this.findForm.get('endDate').value, 'yyyy-MM-ddT00:00:00', this.locale) : ''
      }
    });
  }

  private filterAirports(search: string, airportCodeToIgnore: string): Airport[] {
    const airportsArr: Airport[] = [];

    if (search) {
      const searchTerm = search.toLowerCase();

      for (const airportCode in AirportData) {
        if (AirportData.hasOwnProperty(airportCode)) {
          const airportDetails: any = AirportData[airportCode];

          if ((!airportCodeToIgnore || airportCode.toLowerCase() !== airportCodeToIgnore.toLowerCase()) &&
            (airportCode.toLowerCase().includes(searchTerm) ||
              (airportDetails.name && airportDetails.name.toLowerCase().includes(searchTerm)) ||
              (airportDetails.city && airportDetails.city.toLowerCase().includes(searchTerm)) ||
              (airportDetails.country && airportDetails.country.toLowerCase().includes(searchTerm)))) {
            airportsArr.push({
              code: airportCode,
              name: airportDetails.name,
              country: airportDetails.country,
              city: airportDetails.city
            });
          }
        }
      }
    }

    return airportsArr;
  }

}
