import { Component, ChangeDetectionStrategy, ViewChild, ViewContainerRef, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { SidenavService } from '../sidenav.service';
import { SidenavContent } from 'src/app/models/sidenav-content';
import { AutoUnsubscribe } from 'src/app/common/auto-unsubscribe.base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent extends AutoUnsubscribe {

  @ViewChild('contentAnchor', { read: ViewContainerRef })
  public contentAnchor: ViewContainerRef;

  @ViewChild('contentContainer', { read: ViewContainerRef })
  public contentContainer: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.subscriptions.push(this.sidenavService.getLeftNavContent()
      .subscribe((content: SidenavContent) => {
        this.setContent(content);
      }));
  }

  private setContent(content: SidenavContent) {
    if (this.contentAnchor) {
      this.contentAnchor.clear();

      if (content.componentType) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(content.componentType);
        const componentRef = this.contentAnchor.createComponent(componentFactory);

        if (content.componentInput) {
          for (const input in content.componentInput) {
            if (content.componentInput.hasOwnProperty(input)) {
              componentRef.instance[input] = content.componentInput[input];
            }
          }
        }

        this.changeDetectorRef.detectChanges();
      }
    }
  }

}
