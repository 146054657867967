import { Airport } from '../models/airport';

export const AirportData: { [key: string]: Airport } = {
  '0V4': { name: 'Brookneal/Campbell County Airport', city: 'Brookneal', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '19S': { name: 'Sublette Municipal Airport', city: 'Sublette', country: 'United States', timezone: null, offset: -5 },
  '23M': { name: 'Clarke County Airport', city: 'Quitman', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  '2A5': { name: 'Causey Airport', city: 'Liberty', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '2H0': { name: 'Shelby County Airport', city: 'Shelbyville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  '2K7': { name: 'Neodesha Municipal Airport', city: 'Neodesha', country: 'United States', timezone: null, offset: -5 },
  '3AU': { name: 'Augusta Municipal Airport', city: 'Augusta', country: 'United States', timezone: null, offset: -5 },
  '4A7': { name: 'Atlanta South Regional Airport/Tara Field', city: 'Hampton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '4U9': { name: 'Dell Flight Strip', city: 'Dell', country: 'United States', timezone: 'America/Denver', offset: -7 },
  '52A': { name: 'Madison Municipal Airport', city: 'Madison', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '57C': { name: 'East Troy Municipal Airport', city: 'East Troy', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  '5B2': { name: 'Saratoga County Airport', city: 'Ballston Spa', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '6J4': { name: 'Saluda County Airport', city: 'Saluda', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '6S0': { name: 'Big Timber Airport', city: 'Big Timber', country: 'United States', timezone: 'America/Denver', offset: -7 },
  '7W6': { name: 'Hyde County Airport', city: 'Engelhard', country: 'United States', timezone: 'America/New_York', offset: -5 },
  '87K': { name: 'El Dorado Springs Memorial Airport', city: 'El dorado springs', country: 'United States', timezone: null, offset: -5 },
  A39: { name: 'Ak-Chin Regional Airport', city: 'Phoenix', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  A50: { name: 'Colorado Springs East Airport', city: 'Ellicott', country: 'United States', timezone: 'America/Denver', offset: -7 },
  AAA: { name: 'Anaa Airport', city: 'Anaa', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  AAC: { name: 'El Arish International Airport', city: 'El Arish', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  AAE: { name: 'Annaba Airport', city: 'Annaba', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  AAF: { name: 'Apalachicola Regional Airport', city: 'Apalachicola', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AAH: { name: 'Aachen-Merzbrück Airport', city: 'Aachen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  AAK: { name: 'Buariki Airport', city: 'Buariki', country: 'Kiribati', timezone: null, offset: 10 },
  AAL: { name: 'Aalborg Airport', city: 'Aalborg', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  AAM: { name: 'Malamala Airport', city: 'Malamala', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  AAN: { name: 'Al Ain International Airport', city: 'Al Ain', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  AAO: { name: 'Anaco Airport', city: 'Anaco', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  AAP: { name: 'Andrau Airpark', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AAQ: { name: 'Anapa Vityazevo Airport', city: 'Anapa', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  AAR: { name: 'Aarhus Airport', city: 'Aarhus', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  AAV: { name: 'Allah Valley Airport', city: 'Surallah', country: 'Philippines', timezone: null, offset: 0 },
  AAX: { name: 'Romeu Zema Airport', city: 'Araxa', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  AAY: { name: 'Al Ghaidah International Airport', city: 'Al Ghaidah Intl', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  AAZ: { name: 'Quezaltenango Airport', city: 'Quezaltenango', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  ABA: { name: 'Abakan Airport', city: 'Abakan', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  ABD: { name: 'Abadan Airport', city: 'Abadan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ABE: { name: 'Lehigh Valley International Airport', city: 'Allentown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ABF: { name: 'Abaiang Airport', city: 'Abaiang Atoll', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  ABH: { name: 'Alpha Airport', city: 'Alpha', country: 'Australia', timezone: null, offset: 0 },
  ABI: { name: 'Abilene Regional Airport', city: 'Abilene', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ABJ: { name: 'Port Bouet Airport', city: 'Abidjan', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  ABK: { name: 'Kabri Dehar Airport', city: 'Kabri Dehar', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  ABL: { name: 'Ambler Airport', city: 'Ambler', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ABM: { name: 'Northern Peninsula Airport', city: 'Amberley', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  ABN: { name: 'Albina Airport', city: 'Albina', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  ABQ: { name: 'Albuquerque International Sunport Airport', city: 'Albuquerque', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ABR: { name: 'Aberdeen Regional Airport', city: 'Aberdeen', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ABS: { name: 'Abu Simbel Airport', city: 'Abu Simbel', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  ABT: { name: 'Al Baha Airport', city: 'El-baha', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  ABV: { name: 'Nnamdi Azikiwe International Airport', city: 'Abuja', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  ABX: { name: 'Albury Airport', city: 'Albury', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  ABY: { name: 'Southwest Georgia Regional Airport', city: 'Albany', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ABZ: { name: 'Aberdeen Dyce Airport', city: 'Aberdeen', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ACA: { name: 'General Juan N Alvarez International Airport', city: 'Acapulco', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  ACC: { name: 'Kotoka International Airport', city: 'Accra', country: 'Ghana', timezone: 'Africa/Accra', offset: 0 },
  ACD: { name: 'Alcides Fernández Airport', city: 'Acandi', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  ACE: { name: 'Lanzarote Airport', city: 'Arrecife', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  ACH: { name: 'St Gallen Altenrhein Airport', city: 'Altenrhein', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  ACI: { name: 'Alderney Airport', city: 'Alderney', country: 'Guernsey', timezone: 'Europe/Guernsey', offset: 0 },
  ACJ: { name: 'Anuradhapura Air Force Base', city: 'Anuradhapura', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  ACK: { name: 'Nantucket Memorial Airport', city: 'Nantucket', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ACP: { name: 'Sahand Airport', city: 'Maragheh', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ACR: { name: 'Araracuara Airport', city: 'Araracuara', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  ACS: { name: 'Achinsk Airport', city: 'Achinsk', country: 'Russia', timezone: null, offset: 8 },
  ACT: { name: 'Waco Regional Airport', city: 'Waco', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ACV: { name: 'Arcata Airport', city: 'Arcata CA', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ACX: { name: 'Xingyi Airport', city: 'Xingyi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ACY: { name: 'Atlantic City International Airport', city: 'Atlantic City', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ACZ: { name: 'Zabol Airport', city: 'Zabol', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ADA: { name: 'Adana Airport', city: 'Adana', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ADB: { name: 'Adnan Menderes International Airport', city: 'Izmir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ADD: { name: 'Addis Ababa Bole International Airport', city: 'Addis Ababa', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  ADE: { name: 'Aden International Airport', city: 'Aden', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  ADF: { name: 'Adıyaman Airport', city: 'Adiyaman', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ADH: { name: 'Aldan Airport', city: 'Aldan', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  ADI: { name: 'Arandis Airport', city: 'Arandis', country: 'Namibia', timezone: null, offset: 0 },
  ADJ: { name: 'Amman-Marka International Airport', city: 'Amman', country: 'Jordan', timezone: 'Asia/Amman', offset: 2 },
  ADK: { name: 'Adak Airport', city: 'Adak Island', country: 'United States', timezone: 'America/Adak', offset: -10 },
  ADL: { name: 'Adelaide International Airport', city: 'Adelaide', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  ADM: { name: 'Ardmore Municipal Airport', city: 'Ardmore', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ADQ: { name: 'Kodiak Airport', city: 'Kodiak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ADS: { name: 'Addison Airport', city: 'Addison', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ADU: { name: 'Ardabil Airport', city: 'Ardabil', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ADW: { name: 'Andrews Air Force Base', city: 'Camp Springs', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ADX: { name: 'RAF Leuchars', city: 'Leuchars', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ADY: { name: 'Alldays Airport', city: 'Alldays', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  ADZ: { name: 'Gustavo Rojas Pinilla International Airport', city: 'San Andres Island', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  AEA: { name: 'Abemama Atoll Airport', city: 'Abemama', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  AEH: { name: 'Abeche Airport', city: 'Abeche', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  AEP: { name: 'Jorge Newbery Airpark', city: 'Buenos Aires', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  AER: { name: 'Sochi International Airport', city: 'Sochi', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  AES: { name: 'Ålesund Airport', city: 'Alesund', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  AET: { name: 'Allakaket Airport', city: 'Allakaket', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AEX: { name: 'Alexandria International Airport', city: 'Alexandria', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AEY: { name: 'Akureyri Airport', city: 'Akureyri', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  AFA: { name: 'Suboficial Ay Santiago Germano Airport', city: 'San Rafael', country: 'Argentina', timezone: 'America/Mendoza', offset: -3 },
  AFE: { name: 'Kake Airport', city: 'Kake', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AFL: { name: 'Piloto Osvaldo Marques Dias Airport', city: 'Alta Floresta', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  AFS: { name: 'Sugraly Airport', city: 'Zarafshan', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  AFT: { name: 'Afutara Aerodrome', city: 'Afutara', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  AFW: { name: 'Fort Worth Alliance Airport', city: 'Fort Worth', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AFY: { name: 'Afyon Airport', city: 'Afyon', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  AFZ: { name: 'Sabzevar National Airport', city: 'Sabzevar', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  AGA: { name: 'Al Massira Airport', city: 'Agadir', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  AGB: { name: 'Augsburg Airport', city: 'Augsburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  AGC: { name: 'Allegheny County Airport', city: 'Pittsburgh', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AGE: { name: 'Wangerooge Airport', city: 'Wangerooge', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  AGF: { name: 'Agen-La Garenne Airport', city: 'Agen', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  AGH: { name: 'Ängelholm-Helsingborg Airport', city: 'Ängelholm', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  AGI: { name: 'Wageningen Airstrip', city: 'Wageningen', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  AGJ: { name: 'Aguni Airport', city: 'Aguni', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  AGM: { name: 'Tasiilaq Heliport', city: 'Angmagssalik', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  AGN: { name: 'Angoon Seaplane Base', city: 'Angoon', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AGP: { name: 'Málaga Airport', city: 'Malaga', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  AGQ: { name: 'Agrinion Air Base', city: 'Agrinion', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  AGR: { name: 'Agra Airport', city: 'Agra', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  AGS: { name: 'Augusta Regional At Bush Field', city: 'Bush Field', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AGT: { name: 'Guarani International Airport', city: 'Ciudad del Este', country: 'Paraguay', timezone: 'America/Asuncion', offset: -4 },
  AGU: { name: 'Jesús Terán Paredo International Airport', city: 'Aguascalientes', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  AGV: { name: 'Oswaldo Guevara Mujica Airport', city: 'Acarigua', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  AGX: { name: 'Agatti Airport', city: 'Agatti Island', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  AGZ: { name: 'Aggeneys Airport', city: 'Aggeneys', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  AHB: { name: 'Abha Regional Airport', city: 'Abha', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  AHE: { name: 'Ahe Airport', city: 'Ahe', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  AHN: { name: 'Athens Ben Epps Airport', city: 'Athens', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AHO: { name: 'Alghero-Fertilia Airport', city: 'Alghero', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  AHQ: { name: 'Wahoo Municipal Airport', city: 'Wahoo', country: 'United States', timezone: null, offset: -5 },
  AHS: { name: 'Ahuas Airport', city: 'Ahuas', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  AHU: { name: 'Cherif Al Idrissi Airport', city: 'Al Hociema', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  AIA: { name: 'Alliance Municipal Airport', city: 'Alliance', country: 'United States', timezone: 'America/Denver', offset: -7 },
  AIK: { name: 'Aiken Municipal Airport', city: 'Aiken', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AIN: { name: 'Wainwright Airport', city: 'Wainwright', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AIR: { name: 'Aripuanã Airport', city: 'Aripuana', country: 'Brazil', timezone: null, offset: -4 },
  AIS: { name: 'Arorae Island Airport', city: 'Arorae', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  AIT: { name: 'Aitutaki Airport', city: 'Aitutaki', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  AIU: { name: 'Enua Airport', city: 'Atiu Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  AIZ: { name: 'Lee C Fine Memorial Airport', city: 'Kaiser Lake Ozark', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AJA: { name: 'Ajaccio-Napoléon Bonaparte Airport', city: 'Ajaccio', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  AJF: { name: 'Al-Jawf Domestic Airport', city: 'Al-Jawf', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  AJI: { name: 'Ağrı Airport', city: 'Agri', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  AJL: { name: 'Lengpui Airport', city: 'Aizwal', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  AJN: { name: 'Ouani Airport', city: 'Anjouan', country: 'Comoros', timezone: 'Indian/Comoro', offset: 3 },
  AJR: { name: 'Arvidsjaur Airport', city: 'Arvidsjaur', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  AJU: { name: 'Santa Maria Airport', city: 'Aracaju', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  AJY: { name: 'Mano Dayak International Airport', city: 'Agadez', country: 'Niger', timezone: 'Africa/Niamey', offset: 1 },
  AKA: { name: 'Ankang Wulipu Airport', city: 'Ankang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  AKB: { name: 'Atka Airport', city: 'Atka', country: 'United States', timezone: 'America/Adak', offset: -10 },
  AKC: { name: 'Akron Fulton International Airport', city: 'Akron', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AKD: { name: 'Akola Airport', city: 'Akola', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  AKF: { name: 'Kufra Airport', city: 'Kufra', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  AKI: { name: 'Akiak Airport', city: 'Akiak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AKJ: { name: 'Asahikawa Airport', city: 'Asahikawa', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  AKK: { name: 'Akhiok Airport', city: 'Akhiok', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AKL: { name: 'Auckland International Airport', city: 'Auckland', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  AKN: { name: 'King Salmon Airport', city: 'King Salmon', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AKO: { name: 'Colorado Plains Regional Airport', city: 'Akron', country: 'United States', timezone: 'America/Denver', offset: -7 },
  AKP: { name: 'Anaktuvuk Pass Airport', city: 'Anaktuvuk Pass', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AKR: { name: 'Akure Airport', city: 'Akure', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  AKS: { name: 'Auki Airport', city: 'Auki', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  AKT: { name: 'RAF Akrotiri', city: 'Akrotiri', country: 'Cyprus', timezone: 'Europe/London', offset: 0 },
  AKU: { name: 'Aksu Airport', city: 'Aksu', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  AKV: { name: 'Akulivik Airport', city: 'Akulivik', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  AKX: { name: 'Aktobe Airport', city: 'Aktyubinsk', country: 'Kazakhstan', timezone: 'Asia/Oral', offset: 5 },
  AKY: { name: 'Sittwe Airport', city: 'Sittwe', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  ALA: { name: 'Almaty Airport', city: 'Alma-ata', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  ALB: { name: 'Albany International Airport', city: 'Albany', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ALC: { name: 'Alicante International Airport', city: 'Alicante', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  ALF: { name: 'Alta Airport', city: 'Alta', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  ALG: { name: 'Houari Boumediene Airport', city: 'Algier', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  ALH: { name: 'Albany Airport', city: 'Albany', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  ALI: { name: 'Alice International Airport', city: 'Alice', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ALJ: { name: 'Alexander Bay Airport', city: 'Alexander Bay', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  ALL: { name: 'Villanova D\'Albenga International Airport', city: 'Albenga', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  ALM: { name: 'Alamogordo White Sands Regional Airport', city: 'Alamogordo', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ALN: { name: 'St Louis Regional Airport', city: 'Alton/St Louis', country: 'United States', timezone: null, offset: 0 },
  ALO: { name: 'Waterloo Regional Airport', city: 'Waterloo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ALP: { name: 'Aleppo International Airport', city: 'Aleppo', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  ALR: { name: 'Alexandra Airport', city: 'Alexandra', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  ALS: { name: 'San Luis Valley Regional Bergman Field', city: 'Alamosa', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ALU: { name: 'Alula Airport', city: 'Alula', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  ALW: { name: 'Walla Walla Regional Airport', city: 'Walla Walla', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ALX: { name: 'Thomas C Russell Field', city: 'Alexander City', country: 'United States', timezone: null, offset: -5 },
  ALY: { name: 'El Nouzha Airport', city: 'Alexandria', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  AMA: { name: 'Rick Husband Amarillo International Airport', city: 'Amarillo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AMB: { name: 'Ambilobe Airport', city: 'Ambilobe', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  AMC: { name: 'Am Timan Airport', city: 'Am Timan', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  AMD: { name: 'Sardar Vallabhbhai Patel International Airport', city: 'Ahmedabad', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  AMH: { name: 'Arba Minch Airport', city: 'Arba Minch', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  AMI: { name: 'Selaparang Airport', city: 'Mataram', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  AMM: { name: 'Queen Alia International Airport', city: 'Amman', country: 'Jordan', timezone: 'Asia/Amman', offset: 2 },
  AMQ: { name: 'Pattimura Airport, Ambon', city: 'Ambon', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  AMS: { name: 'Amsterdam Airport Schiphol', city: 'Amsterdam', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  AMV: { name: 'Amderma Airport', city: 'Amderma', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  AMZ: { name: 'Ardmore Airport', city: 'Ardmore', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  ANB: { name: 'Anniston Metropolitan Airport', city: 'Anniston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ANC: { name: 'Ted Stevens Anchorage International Airport', city: 'Anchorage', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AND: { name: 'Anderson Regional Airport', city: 'Andersen', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ANE: { name: 'Angers-Loire Airport', city: 'Angers/Marcé', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  ANF: { name: 'Cerro Moreno Airport', city: 'Antofagasta', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ANG: { name: 'Angoulême-Brie-Champniers Airport', city: 'Angouleme', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  ANI: { name: 'Aniak Airport', city: 'Aniak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ANK: { name: 'Etimesgut Air Base', city: 'Ankara', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ANM: { name: 'Antsirabato Airport', city: 'Antalaha', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  ANN: { name: 'Annette Island Airport', city: 'Annette Island', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ANP: { name: 'Lee Airport', city: 'Annapolis', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ANQ: { name: 'Tri State Steuben County Airport', city: 'Angola', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ANR: { name: 'Antwerp International Airport (Deurne)', city: 'Antwerp', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  ANS: { name: 'Andahuaylas Airport', city: 'Andahuaylas', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  ANU: { name: 'V.C. Bird International Airport', city: 'Antigua', country: 'Antigua and Barbuda', timezone: 'America/Antigua', offset: -4 },
  ANV: { name: 'Anvik Airport', city: 'Anvik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ANX: { name: 'Andøya Airport', city: 'Andoya', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  AOC: { name: 'Altenburg-Nobitz Airport', city: 'Altenburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  AOE: { name: 'Anadolu Airport', city: 'Eskissehir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  AOG: { name: 'Anshan Air Base', city: 'Anshan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  AOH: { name: 'Lima Allen County Airport', city: 'Lima', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AOI: { name: 'Ancona Falconara Airport', city: 'Ancona', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  AOJ: { name: 'Aomori Airport', city: 'Aomori', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  AOK: { name: 'Karpathos Airport', city: 'Karpathos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  AOL: { name: 'Paso De Los Libres Airport', city: 'Paso De Los Libres', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  AOO: { name: 'Altoona Blair County Airport', city: 'Altoona', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AOR: { name: 'Sultan Abdul Halim Airport', city: 'Alor Setar', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  AOT: { name: 'Aosta Airport', city: 'Aosta', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  AOU: { name: 'Attopeu Airport', city: 'Attopeu', country: 'Laos', timezone: null, offset: 7 },
  APA: { name: 'Centennial Airport', city: 'Denver', country: 'United States', timezone: 'America/Denver', offset: -7 },
  APC: { name: 'Napa County Airport', city: 'Napa', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  APF: { name: 'Naples Municipal Airport', city: 'Naples', country: 'United States', timezone: 'America/New_York', offset: -5 },
  APG: { name: 'Phillips Army Air Field', city: 'Aberdeen', country: 'United States', timezone: 'America/New_York', offset: -5 },
  API: { name: 'Gomez Nino Apiay Air Base', city: 'Apiay', country: 'Colombia', timezone: null, offset: 0 },
  APK: { name: 'Apataki Airport', city: 'Apataki', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  APL: { name: 'Nampula Airport', city: 'Nampula', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  APN: { name: 'Alpena County Regional Airport', city: 'Alpena', country: 'United States', timezone: 'America/New_York', offset: -5 },
  APO: { name: 'Antonio Roldan Betancourt Airport', city: 'Carepa', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  APQ: { name: 'Arapiraca Airport', city: 'Arapiraca', country: 'Brazil', timezone: null, offset: -3 },
  APW: { name: 'Faleolo International Airport', city: 'Faleolo', country: 'Samoa', timezone: 'Pacific/Apia', offset: 13 },
  APZ: { name: 'Zapala Airport', city: 'ZAPALA', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  AQA: { name: 'Araraquara Airport', city: 'Araracuara', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  AQB: { name: 'Santa Cruz del Quiche Airport', city: 'Santa Cruz des Quiche', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  AQC: { name: 'Klawock Seaplane Base', city: 'Klawock', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AQG: { name: 'Anqing Tianzhushan Airport', city: 'Anqing', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  AQI: { name: 'Al Qaisumah/Hafr Al Batin Airport', city: 'Hafr Al-batin', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  AQJ: { name: 'Aqaba King Hussein International Airport', city: 'Aqaba', country: 'Jordan', timezone: 'Asia/Amman', offset: 2 },
  AQP: { name: 'Rodríguez Ballón International Airport', city: 'Arequipa', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  ARA: { name: 'Acadiana Regional Airport', city: 'Louisiana', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ARB: { name: 'Ann Arbor Municipal Airport', city: 'Ann Arbor', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ARC: { name: 'Arctic Village Airport', city: 'Arctic Village', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ARD: { name: 'Mali Airport', city: 'Alor Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  ARE: { name: 'Antonio Nery Juarbe Pol Airport', city: 'Arecibo', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  ARH: { name: 'Talagi Airport', city: 'Arkhangelsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  ARI: { name: 'Chacalluta Airport', city: 'Arica', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ARK: { name: 'Arusha Airport', city: 'Arusha', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  ARM: { name: 'Armidale Airport', city: 'Armidale', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  ARN: { name: 'Stockholm-Arlanda Airport', city: 'Stockholm', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  ARR: { name: 'D. Casimiro Szlapelis Airport', city: 'Alto Rio Senguer', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  ART: { name: 'Watertown International Airport', city: 'Watertown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ARU: { name: 'Araçatuba Airport', city: 'Aracatuba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  ARV: { name: 'Lakeland-Noble F. Lee Memorial field', city: 'Minocqua - Woodruff', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ARW: { name: 'Arad International Airport', city: 'Arad', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  ARY: { name: 'Ararat Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  ASA: { name: 'Assab International Airport', city: 'Assab', country: 'Eritrea', timezone: 'Africa/Asmera', offset: 3 },
  ASB: { name: 'Ashgabat Airport', city: 'Ashkhabad', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 5 },
  ASD: { name: 'Andros Town Airport', city: 'Andros Town', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  ASE: { name: 'Aspen-Pitkin Co/Sardy Field', city: 'Aspen', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ASF: { name: 'Astrakhan Airport', city: 'Astrakhan', country: 'Russia', timezone: 'Europe/Samara', offset: 4 },
  ASH: { name: 'Boire Field', city: 'Nashua', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ASI: { name: 'RAF Ascension Island', city: 'Wide Awake', country: 'Saint Helena', timezone: 'Atlantic/St_Helena', offset: 0 },
  ASJ: { name: 'Amami Airport', city: 'Amami', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  ASK: { name: 'Yamoussoukro Airport', city: 'Yamoussoukro', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  ASM: { name: 'Asmara International Airport', city: 'Asmara', country: 'Eritrea', timezone: 'Africa/Asmera', offset: 3 },
  ASN: { name: 'Talladega Municipal Airport', city: 'Talladega', country: 'United States', timezone: null, offset: -5 },
  ASO: { name: 'Asosa Airport', city: 'Asosa', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  ASP: { name: 'Alice Springs Airport', city: 'Alice Springs', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  ASR: { name: 'Kayseri Erkilet Airport', city: 'Kayseri', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  AST: { name: 'Astoria Regional Airport', city: 'Astoria', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ASU: { name: 'Silvio Pettirossi International Airport', city: 'Asuncion', country: 'Paraguay', timezone: 'America/Asuncion', offset: -4 },
  ASV: { name: 'Amboseli Airport', city: 'Amboseli National Park', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  ASW: { name: 'Aswan International Airport', city: 'Aswan', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  ATA: { name: 'Comandante FAP German Arias Graziani Airport', city: 'Anta', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  ATB: { name: 'Atbara Airport', city: 'Atbara', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  ATC: { name: 'Arthur\'s Town Airport', city: 'Arthur\'s Town', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  ATD: { name: 'Uru Harbour Airport', city: 'Atoifi', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  ATF: { name: 'Chachoán Airport', city: 'Ambato', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  ATG: { name: 'Minhas Air Base', city: 'Kamra', country: 'Pakistan', timezone: null, offset: 0 },
  ATH: { name: 'Eleftherios Venizelos International Airport', city: 'Athens', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  ATI: { name: 'Artigas International Airport', city: 'Artigas ', country: 'Uruguay', timezone: null, offset: -3 },
  ATJ: { name: 'Antsirabe Airport', city: 'Antsirabe', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  ATK: { name: 'Atqasuk Edward Burnell Sr Memorial Airport', city: 'Atqasuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ATL: { name: 'Hartsfield Jackson Atlanta International Airport', city: 'Atlanta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ATM: { name: 'Altamira Airport', city: 'Altamira', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  ATQ: { name: 'Sri Guru Ram Dass Jee International Airport', city: 'Amritsar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  ATR: { name: 'Atar International Airport', city: 'Atar', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  ATW: { name: 'Appleton International Airport', city: 'Appleton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ATY: { name: 'Watertown Regional Airport', city: 'Watertown', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ATZ: { name: 'Assiut International Airport', city: 'Asyut', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  AUA: { name: 'Queen Beatrix International Airport', city: 'Oranjestad', country: 'Aruba', timezone: 'America/Aruba', offset: -4 },
  AUC: { name: 'Santiago Perez Airport', city: 'Arauca', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  AUF: { name: 'Auxerre-Branches Airport', city: 'Auxerre', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  AUG: { name: 'Augusta State Airport', city: 'Augusta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AUH: { name: 'Abu Dhabi International Airport', city: 'Abu Dhabi', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  AUK: { name: 'Alakanuk Airport', city: 'Alakanuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  AUO: { name: 'Auburn Opelika Robert G. Pitts Airport', city: 'Auburn', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AUQ: { name: 'Hiva Oa-Atuona Airport', city: 'Hiva-oa', country: 'French Polynesia', timezone: 'Pacific/Marquesas', offset: -9 },
  AUR: { name: 'Aurillac Airport', city: 'Aurillac', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  AUS: { name: 'Austin Bergstrom International Airport', city: 'Austin', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AUU: { name: 'Aurukun Airport', city: 'Aurukun', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  AUW: { name: 'Wausau Downtown Airport', city: 'Wausau', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  AUX: { name: 'Araguaína Airport', city: 'Araguaina', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  AUY: { name: 'Aneityum Airport', city: 'Anelghowhat', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  AVA: { name: 'Anshun Huangguoshu Airport', city: 'Anshun', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  AVB: { name: 'Aviano Air Base', city: 'Aviano', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  AVI: { name: 'Maximo Gomez Airport', city: 'Ciego De Avila', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  AVK: { name: 'Arvaikheer Airport', city: 'Arvaikheer', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  AVL: { name: 'Asheville Regional Airport', city: 'Asheville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AVN: { name: 'Avignon-Caumont Airport', city: 'Avignon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  AVO: { name: 'Avon Park Executive Airport', city: 'Avon Park', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AVP: { name: 'Wilkes Barre Scranton International Airport', city: 'Scranton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AVV: { name: 'Avalon Airport', city: 'Avalon', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  AVW: { name: 'Marana Regional Airport', city: 'Tucson', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  AVX: { name: 'Catalina Airport', city: 'Catalina Island', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  AWA: { name: 'Awassa Airport', city: 'Awasa', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  AWD: { name: 'Aniwa Airport', city: 'Aniwa', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  AWK: { name: 'Wake Island Airfield', city: 'Wake island', country: 'Wake Island', timezone: 'Pacific/Johnston', offset: -10 },
  AWO: { name: 'Arlington Municipal Airport', city: 'Arlington', country: 'United States', timezone: null, offset: -8 },
  AWZ: { name: 'Ahwaz Airport', city: 'Ahwaz', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  AXA: { name: 'Wallblake Airport', city: 'The Valley', country: 'Anguilla', timezone: 'America/Anguilla', offset: -4 },
  AXD: { name: 'Dimokritos Airport', city: 'Alexandroupolis', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  AXJ: { name: 'Amakusa Airport', city: 'Amakusa', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  AXK: { name: 'Ataq Airport', city: 'Ataq', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  AXM: { name: 'El Eden Airport', city: 'Armenia', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  AXN: { name: 'Chandler Field', city: 'Alexandria', country: 'United States', timezone: null, offset: 0 },
  AXP: { name: 'Spring Point Airport', city: 'Spring Point', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  AXR: { name: 'Arutua Airport', city: 'Arutua', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  AXT: { name: 'Akita Airport', city: 'Akita', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  AXU: { name: 'Axum Airport', city: 'Axum', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  AYK: { name: 'Arkalyk North Airport', city: 'Arkalyk', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  AYP: { name: 'Coronel FAP Alfredo Mendivil Duarte Airport', city: 'Ayacucho', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  AYQ: { name: 'Ayers Rock Connellan Airport', city: 'Uluru', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  AYT: { name: 'Antalya International Airport', city: 'Antalya', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  AZ3: { name: 'Sharana Airstrip', city: 'Sharona', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  AZA: { name: 'Phoenix-Mesa-Gateway Airport', city: 'Mesa', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  AZD: { name: 'Shahid Sadooghi Airport', city: 'Yazd', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  AZI: { name: 'Bateen Airport', city: 'Abu Dhabi', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  AZN: { name: 'Andizhan Airport', city: 'Andizhan', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  AZO: { name: 'Kalamazoo Battle Creek International Airport', city: 'Kalamazoo', country: 'United States', timezone: 'America/New_York', offset: -5 },
  AZR: { name: 'Touat Cheikh Sidi Mohamed Belkebir Airport', city: 'Adrar', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  AZS: { name: 'Samaná El Catey International Airport', city: 'Samana', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  BAB: { name: 'Beale Air Force Base', city: 'Marysville', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BAD: { name: 'Barksdale Air Force Base', city: 'Shreveport', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BAF: { name: 'Barnes Municipal Airport', city: 'Westfield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BAG: { name: 'Loakan Airport', city: 'Baguio', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  BAH: { name: 'Bahrain International Airport', city: 'Bahrain', country: 'Bahrain', timezone: 'Asia/Bahrain', offset: 3 },
  BAL: { name: 'Batman Airport', city: 'Batman', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BAQ: { name: 'Ernesto Cortissoz International Airport', city: 'Barranquilla', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  BAS: { name: 'Ballalae Airport', city: 'Ballalae', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  BAT: { name: 'Chafei Amsei Airport', city: 'Barretos', country: 'Brazil', timezone: null, offset: 0 },
  BAU: { name: 'Bauru Airport', city: 'Bauru', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BAV: { name: 'Baotou Airport', city: 'Baotou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  BAX: { name: 'Barnaul Airport', city: 'Barnaul', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  BAY: { name: 'Tautii Magheraus Airport', city: 'Baia Mare', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  BAZ: { name: 'Barcelos Airport', city: 'Barcelos', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  BBA: { name: 'Balmaceda Airport', city: 'Balmaceda', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  BBD: { name: 'Curtis Field', city: 'Brady', country: 'United States', timezone: null, offset: 0 },
  BBG: { name: 'Butaritari Atoll Airport', city: 'Butaritari', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  BBH: { name: 'Barth Airport', city: 'Barth', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BBI: { name: 'Biju Patnaik Airport', city: 'Bhubaneswar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BBJ: { name: 'Bitburg Airport', city: 'Birburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BBK: { name: 'Kasane Airport', city: 'Kasane', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  BBM: { name: 'Battambang Airport', city: 'Battambang', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  BBN: { name: 'Bario Airport', city: 'Bario', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  BBO: { name: 'Berbera Airport', city: 'Berbera', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  BBP: { name: 'Bembridge Airport', city: 'Bembridge', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BBQ: { name: 'Codrington Airport', city: 'Codrington', country: 'Antigua and Barbuda', timezone: 'America/Antigua', offset: -4 },
  BBR: { name: 'Baillif Airport', city: 'Basse Terre', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  BBS: { name: 'Blackbushe Airport', city: 'Blackbushe', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BBT: { name: 'Berbérati Airport', city: 'Berberati', country: 'Central African Republic', timezone: 'Africa/Bangui', offset: 1 },
  BBU: { name: 'Băneasa International Airport', city: 'Bucharest', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  BBX: { name: 'Wings Field', city: 'Philadelphia', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BCA: { name: 'Gustavo Rizo Airport', city: 'Baracoa Playa', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  BCD: { name: 'Bacolod-Silay City International Airport', city: 'Bacolod', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  BCE: { name: 'Bryce Canyon Airport', city: 'Bryce Canyon', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BCI: { name: 'Barcaldine Airport', city: 'Barcaldine', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BCL: { name: 'Barra del Colorado Airport', city: 'Pococi', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  BCM: { name: 'Bacău Airport', city: 'Bacau', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  BCN: { name: 'Barcelona International Airport', city: 'Barcelona', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  BCO: { name: 'Baco Airport', city: 'Baco', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  BCT: { name: 'Boca Raton Airport', city: 'Boca Raton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BCV: { name: 'Birchwood Airport', city: 'Belmopan', country: 'Belize', timezone: 'America/Anchorage', offset: -9 },
  BDA: { name: 'L.F. Wade International International Airport', city: 'Bermuda', country: 'Bermuda', timezone: 'Atlantic/Bermuda', offset: -4 },
  BDB: { name: 'Bundaberg Airport', city: 'Bundaberg', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BDD: { name: 'Badu Island Airport', city: 'Badu Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BDE: { name: 'Baudette International Airport', city: 'Baudette', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BDH: { name: 'Bandar Lengeh Airport', city: 'Bandar Lengeh', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  BDI: { name: 'Bird Island Airport', city: 'Bird Island', country: 'Seychelles', timezone: null, offset: 4 },
  BDJ: { name: 'Syamsudin Noor Airport', city: 'Banjarmasin', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  BDL: { name: 'Bradley International Airport', city: 'Windsor Locks', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BDM: { name: 'Bandırma Airport', city: 'Bandirma', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BDN: { name: 'Talhar Airport', city: 'Talhar', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  BDO: { name: 'Husein Sastranegara International Airport', city: 'Bandung', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  BDP: { name: 'Bhadrapur Airport', city: 'Chandragarhi', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BDQ: { name: 'Vadodara Airport', city: 'Baroda', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BDR: { name: 'Igor I Sikorsky Memorial Airport', city: 'Stratford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BDS: { name: 'Brindisi – Salento Airport', city: 'Brindisi', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  BDT: { name: 'Gbadolite Airport', city: 'Gbadolite', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  BDU: { name: 'Bardufoss Airport', city: 'Bardufoss', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BEB: { name: 'Benbecula Airport', city: 'Benbecula', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BEC: { name: 'Beech Factory Airport', city: 'Wichita', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BED: { name: 'Laurence G Hanscom Field', city: 'Bedford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BEF: { name: 'Bluefields Airport', city: 'Bluefields', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  BEG: { name: 'Belgrade Nikola Tesla Airport', city: 'Belgrade', country: 'Serbia', timezone: 'Europe/Belgrade', offset: 1 },
  BEI: { name: 'Beica Airport', city: 'Beica', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  BEJ: { name: 'Barau(Kalimaru) Airport', city: 'Tanjung Redep-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  BEL: { name: 'Val de Cans/Júlio Cezar Ribeiro International Airport', city: 'Belem', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  BEM: { name: 'Beni Mellal Airport', city: 'Beni Mellal', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  BEN: { name: 'Benina International Airport', city: 'Benghazi', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  BEO: { name: 'Lake Macquarie Airport', city: 'Lake Macquarie', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  BEP: { name: 'Bellary Airport', city: 'Bellary', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BEQ: { name: 'RAF Honington', city: 'Honington', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BES: { name: 'Brest Bretagne Airport', city: 'Brest', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BET: { name: 'Bethel Airport', city: 'Bethel', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BEU: { name: 'Bedourie Airport', city: 'Bedourie', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BEV: { name: 'Beersheba (Teyman) Airport', city: 'Beer-sheba', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  BEW: { name: 'Beira Airport', city: 'Beira', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  BEY: { name: 'Beirut Rafic Hariri International Airport', city: 'Beirut', country: 'Lebanon', timezone: 'Asia/Beirut', offset: 2 },
  BEZ: { name: 'Beru Airport', city: 'Beru Island', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  BFD: { name: 'Bradford Regional Airport', city: 'Bradford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BFF: { name: 'Western Neb. Rgnl/William B. Heilig Airport', city: 'Scottsbluff', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BFH: { name: 'Bacacheri Airport', city: 'Curitiba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BFI: { name: 'Boeing Field King County International Airport', city: 'Seattle', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BFJ: { name: 'Bijie Feixiong Airport', city: 'Bijie', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  BFL: { name: 'Meadows Field', city: 'Bakersfield', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BFM: { name: 'Mobile Downtown Airport', city: 'Mobile', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BFN: { name: 'Bram Fischer International Airport', city: 'Bloemfontein', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  BFO: { name: 'Buffalo Range Airport', city: 'Chiredzi', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  BFP: { name: 'Beaver County Airport', city: 'Beaver Falls', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BFS: { name: 'Belfast International Airport', city: 'Belfast', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BFT: { name: 'Beaufort County Airport', city: 'Beaufort', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BFU: { name: 'Bengbu Airport', city: 'Bengbu', country: 'China', timezone: null, offset: 0 },
  BFV: { name: 'Buri Ram Airport', city: 'Buri Ram', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  BFX: { name: 'Bafoussam Airport', city: 'Bafoussam', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  BGA: { name: 'Palonegro Airport', city: 'Bucaramanga', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  BGC: { name: 'Bragança Airport', city: 'Braganca', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  BGE: { name: 'Decatur County Industrial Air Park', city: 'Bainbridge', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BGF: { name: 'Bangui M\'Poko International Airport', city: 'Bangui', country: 'Central African Republic', timezone: 'Africa/Bangui', offset: 1 },
  BGI: { name: 'Sir Grantley Adams International Airport', city: 'Bridgetown', country: 'Barbados', timezone: 'America/Barbados', offset: -4 },
  BGM: { name: 'Greater Binghamton/Edwin A Link field', city: 'Binghamton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BGO: { name: 'Bergen Airport Flesland', city: 'Bergen', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BGR: { name: 'Bangor International Airport', city: 'Bangor', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BGW: { name: 'Baghdad International Airport', city: 'Baghdad', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  BGX: { name: 'Comandante Gustavo Kraemer Airport', city: 'Bage', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BGY: { name: 'Il Caravaggio International Airport', city: 'Bergamo', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  BHB: { name: 'Hancock County-Bar Harbor Airport', city: 'Bar Harbor', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BHD: { name: 'George Best Belfast City Airport', city: 'Belfast', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BHE: { name: 'Woodbourne Airport', city: 'Woodbourne', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  BHG: { name: 'Brus Laguna Airport', city: 'Brus Laguna', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  BHH: { name: 'Bisha Airport', city: 'Bisha', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  BHI: { name: 'Comandante Espora Airport', city: 'Bahia Blanca', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  BHJ: { name: 'Bhuj Airport', city: 'Bhuj', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BHK: { name: 'Bukhara Airport', city: 'Bukhara', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  BHM: { name: 'Birmingham-Shuttlesworth International Airport', city: 'Birmingham', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BHN: { name: 'Beihan Airport', city: 'Beihan', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  BHO: { name: 'Raja Bhoj International Airport', city: 'Bhopal', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BHP: { name: 'Bhojpur Airport', city: 'Bhojpur', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BHQ: { name: 'Broken Hill Airport', city: 'Broken Hill', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  BHR: { name: 'Bharatpur Airport', city: 'Bharatpur', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BHS: { name: 'Bathurst Airport', city: 'Bathurst', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  BHU: { name: 'Bhavnagar Airport', city: 'Bhaunagar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BHV: { name: 'Bahawalpur Airport', city: 'Bahawalpur', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  BHX: { name: 'Birmingham International Airport', city: 'Birmingham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BHY: { name: 'Beihai Airport', city: 'Beihai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  BIA: { name: 'Bastia-Poretta Airport', city: 'Bastia', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BID: { name: 'Block Island State Airport', city: 'Block Island', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BIF: { name: 'Biggs Army Air Field (Fort Bliss)', city: 'El Paso', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BIG: { name: 'Allen Army Airfield', city: 'Delta Junction', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BIH: { name: 'Eastern Sierra Regional Airport', city: 'Bishop', country: 'United States', timezone: null, offset: 0 },
  BIK: { name: 'Frans Kaisiepo Airport', city: 'Biak', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  BIL: { name: 'Billings Logan International Airport', city: 'Billings', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BIM: { name: 'South Bimini Airport', city: 'Alice Town', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  BIN: { name: 'Bamiyan Airport', city: 'Bamyan', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  BIO: { name: 'Bilbao Airport', city: 'Bilbao', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  BIQ: { name: 'Biarritz-Anglet-Bayonne Airport', city: 'Biarritz-bayonne', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BIR: { name: 'Biratnagar Airport', city: 'Biratnagar', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BIS: { name: 'Bismarck Municipal Airport', city: 'Bismarck', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BIU: { name: 'Bildudalur Airport', city: 'Bildudalur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  BIV: { name: 'Tulip City Airport', city: 'Holland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BIX: { name: 'Keesler Air Force Base', city: 'Biloxi', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BIY: { name: 'Bisho Airport', city: 'Bisho', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  BJA: { name: 'Soummam Airport', city: 'Bejaja', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  BJB: { name: 'Bojnord Airport', city: 'Bojnourd', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  BJC: { name: 'Rocky Mountain Metropolitan Airport', city: 'Broomfield-CO', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BJF: { name: 'Båtsfjord Airport', city: 'Batsfjord', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BJH: { name: 'Bajhang Airport', city: 'Bajhang', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BJI: { name: 'Bemidji Regional Airport', city: 'Bemidji', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BJL: { name: 'Banjul International Airport', city: 'Banjul', country: 'Gambia', timezone: 'Africa/Banjul', offset: 0 },
  BJM: { name: 'Bujumbura International Airport', city: 'Bujumbura', country: 'Burundi', timezone: 'Africa/Bujumbura', offset: 2 },
  BJO: { name: 'Bermejo Airport', city: 'Bermejo', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  BJP: { name: 'Estadual Arthur Siqueira Airport', city: 'Braganca Paulista', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BJR: { name: 'Bahir Dar Airport', city: 'Bahar Dar', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  BJU: { name: 'Bajura Airport', city: 'Bajura', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BJV: { name: 'Milas Bodrum International Airport', city: 'Bodrum', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BJX: { name: 'Del Bajío International Airport', city: 'Del Bajio', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  BJY: { name: 'Batajnica Air Base', city: 'Batajnica', country: 'Serbia', timezone: null, offset: 0 },
  BJZ: { name: 'Badajoz Airport', city: 'Badajoz', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  BKA: { name: 'Bykovo Airport', city: 'Moscow', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  BKC: { name: 'Buckland Airport', city: 'Buckland', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BKD: { name: 'Stephens County Airport', city: 'Breckenridge', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BKE: { name: 'Baker City Municipal Airport', city: 'Baker City', country: 'United States', timezone: null, offset: 0 },
  BKF: { name: 'Buckley Air Force Base', city: 'Buckley', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BKH: { name: 'Barking Sands Airport', city: 'Barking Sands', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  BKI: { name: 'Kota Kinabalu International Airport', city: 'Kota Kinabalu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  BKK: { name: 'Suvarnabhumi Airport', city: 'Bangkok', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  BKL: { name: 'Burke Lakefront Airport', city: 'Cleveland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BKM: { name: 'Bakalalan Airport', city: 'Bakalalan', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  BKO: { name: 'Senou Airport', city: 'Bamako', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  BKQ: { name: 'Blackall Airport', city: 'Blackall', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BKS: { name: 'Fatmawati Soekarno Airport', city: 'Bengkulu', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  BKW: { name: 'Raleigh County Memorial Airport', city: 'Beckley', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BKY: { name: 'Bukavu Kavumu Airport', city: 'Bukavu/kavumu', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  BKZ: { name: 'Bukoba Airport', city: 'Bukoba', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  BLA: { name: 'General Jose Antonio Anzoategui International Airport', city: 'Barcelona', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  BLB: { name: 'Panama Pacific International Airport', city: 'Howard', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  BLE: { name: 'Borlange Airport', city: 'Borlange', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  BLF: { name: 'Mercer County Airport', city: 'Bluefield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BLG: { name: 'Belaga Airport', city: 'Belaga', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  BLH: { name: 'Blythe Airport', city: 'Blythe', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BLI: { name: 'Bellingham International Airport', city: 'Bellingham', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BLJ: { name: 'Batna Airport', city: 'Batna', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  BLK: { name: 'Blackpool International Airport', city: 'Blackpool', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BLL: { name: 'Billund Airport', city: 'Billund', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  BLN: { name: 'Benalla Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  BLQ: { name: 'Bologna Guglielmo Marconi Airport', city: 'Bologna', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  BLR: { name: 'Kempegowda International Airport', city: 'Bangalore', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BLT: { name: 'Blackwater Airport', city: 'Blackwater', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BLV: { name: 'Scott AFB/Midamerica Airport', city: 'Belleville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BLZ: { name: 'Chileka International Airport', city: 'Blantyre', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  BMA: { name: 'Stockholm-Bromma Airport', city: 'Stockholm', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  BMB: { name: 'Bumbar Airport', city: 'Bumba', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  BMC: { name: 'Brigham City Airport', city: 'Brigham City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BMD: { name: 'Belo sur Tsiribihina Airport', city: 'Belo sur Tsiribihina', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  BME: { name: 'Broome International Airport', city: 'Broome', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  BMG: { name: 'Monroe County Airport', city: 'Bloomington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BMI: { name: 'Central Illinois Regional Airport at Bloomington-Normal', city: 'Bloomington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BMK: { name: 'Borkum Airport', city: 'Borkum', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BMM: { name: 'Bitam Airport', city: 'Bitam', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  BMO: { name: 'Banmaw Airport', city: 'Banmaw', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  BMP: { name: 'Brampton Island Airport', city: 'Brampton Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BMQ: { name: 'Burnet Municipal Kate Craddock Field', city: 'Bamburi', country: 'Kenya', timezone: 'America/Chicago', offset: -6 },
  BMT: { name: 'Beaumont Municipal Airport', city: 'Beaumont', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BMU: { name: 'Muhammad Salahuddin Airport', city: 'Bima', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  BMV: { name: 'Buon Ma Thuot Airport', city: 'Buonmethuot', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  BMW: { name: 'Bordj Badji Mokhtar Airport', city: 'Bordj Badji Mokhtar', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  BMX: { name: 'Big Mountain Airport', city: 'Big Mountain', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BMY: { name: 'Île Art - Waala Airport', city: 'Waala', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  BNA: { name: 'Nashville International Airport', city: 'Nashville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BNB: { name: 'Boende Airport', city: 'Boende', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  BNC: { name: 'Beni Airport', city: 'Beni', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  BND: { name: 'Bandar Abbas International Airport', city: 'Bandar Abbas', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  BNE: { name: 'Brisbane International Airport', city: 'Brisbane', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BNG: { name: 'Banning Municipal Airport', city: 'Banning', country: 'United States', timezone: null, offset: -8 },
  BNI: { name: 'Benin Airport', city: 'Benin', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  BNK: { name: 'Ballina Byron Gateway Airport', city: 'Ballina Byron Bay', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  BNN: { name: 'Brønnøysund Airport', city: 'Bronnoysund', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BNP: { name: 'Bannu Airport', city: 'Bannu', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  BNS: { name: 'Barinas Airport', city: 'Barinas', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  BNU: { name: 'Blumenau Airport', city: 'BLUMENAU', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BNX: { name: 'Banja Luka International Airport', city: 'Banja Luka', country: 'Bosnia and Herzegovina', timezone: 'Europe/Sarajevo', offset: 1 },
  BOA: { name: 'Boma Airport', city: 'Boma', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  BOB: { name: 'Bora Bora Airport', city: 'Bora Bora', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  BOC: { name: 'Bocas Del Toro International Airport', city: 'Bocas Del Toro', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  BOD: { name: 'Bordeaux-Mérignac Airport', city: 'Bordeaux', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BOG: { name: 'El Dorado International Airport', city: 'Bogota', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  BOH: { name: 'Bournemouth Airport', city: 'Bournemouth', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BOI: { name: 'Boise Air Terminal/Gowen field', city: 'Boise', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BOJ: { name: 'Burgas Airport', city: 'Bourgas', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 2 },
  BOM: { name: 'Chhatrapati Shivaji International Airport', city: 'Mumbai', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BON: { name: 'Flamingo International Airport', city: 'Kralendijk', country: 'Netherlands Antilles', timezone: 'America/Curacao', offset: -4 },
  BOO: { name: 'Bodø Airport', city: 'Bodo', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BOR: { name: 'Fontaine Airport', city: 'Belfort', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BOS: { name: 'General Edward Lawrence Logan International Airport', city: 'Boston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BOU: { name: 'Bourges Airport', city: 'Bourges', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BOW: { name: 'Bartow Municipal Airport', city: 'Bartow', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BOX: { name: 'Borroloola Airport', city: 'Borroloola', country: 'Australia', timezone: null, offset: 9 },
  BOY: { name: 'Bobo Dioulasso Airport', city: 'Bobo-dioulasso', country: 'Burkina Faso', timezone: 'Africa/Ouagadougou', offset: 0 },
  BPC: { name: 'Bamenda Airport', city: 'Bamenda', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  BPE: { name: 'Qinhuangdao Beidaihe Airport', city: 'Bagan', country: 'Burma', timezone: 'Asia/Shanghai', offset: 8 },
  BPH: { name: 'Bislig Airport', city: null, country: 'Philippines', timezone: null, offset: 0 },
  BPI: { name: 'Miley Memorial Field', city: 'Big Piney', country: 'United States', timezone: null, offset: 0 },
  BPL: { name: 'Alashankou Bole (Bortala) airport', city: 'Bole', country: 'China', timezone: null, offset: 8 },
  BPM: { name: 'Begumpet Airport', city: 'Hyderabad', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  BPN: { name: 'Sultan Aji Muhamad Sulaiman Airport', city: 'Balikpapan', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  BPR: { name: 'Borongan Airport', city: 'Borongan', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  BPS: { name: 'Porto Seguro Airport', city: 'Porto Seguro', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  BPT: { name: 'Southeast Texas Regional Airport', city: 'Beaumont', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BPX: { name: 'Qamdo Bangda Airport', city: 'Bangda', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  BPY: { name: 'Besalampy Airport', city: 'Besalampy', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  BQB: { name: 'Busselton Regional Airport', city: 'Brusselton', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  BQE: { name: 'Bubaque Airport', city: 'Bubaque', country: 'Guinea-Bissau', timezone: null, offset: 0 },
  BQH: { name: 'London Biggin Hill Airport', city: 'Biggin Hill', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BQK: { name: 'Brunswick Golden Isles Airport', city: 'Brunswick', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BQL: { name: 'Boulia Airport', city: 'Boulia', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BQN: { name: 'Rafael Hernandez Airport', city: 'Aguadilla', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  BQS: { name: 'Ignatyevo Airport', city: 'Blagoveschensk', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  BQT: { name: 'Brest Airport', city: 'Brest', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  BQU: { name: 'J F Mitchell Airport', city: 'Bequia', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: -4 },
  BRA: { name: 'Barreiras Airport', city: 'Barreiras', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  BRC: { name: 'San Carlos De Bariloche Airport', city: 'San Carlos De Bariloche', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  BRD: { name: 'Brainerd Lakes Regional Airport', city: 'Brainerd', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BRE: { name: 'Bremen Airport', city: 'Bremen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BRI: { name: 'Bari Karol Wojtyła Airport', city: 'Bari', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  BRK: { name: 'Bourke Airport', city: 'Bourke', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  BRL: { name: 'Southeast Iowa Regional Airport', city: 'Burlington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BRM: { name: 'Barquisimeto International Airport', city: 'Barquisimeto', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  BRN: { name: 'Bern Belp Airport', city: 'Bern', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  BRO: { name: 'Brownsville South Padre Island International Airport', city: 'Brownsville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BRQ: { name: 'Brno-Tuřany Airport', city: 'Brno', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  BRR: { name: 'Barra Airport', city: 'Barra', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BRS: { name: 'Bristol Airport', city: 'Bristol', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BRT: { name: 'Bathurst Island Airport', city: 'Bathurst Island', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  BRU: { name: 'Brussels Airport', city: 'Brussels', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  BRV: { name: 'Bremerhaven Airport', city: 'Bremerhaven', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BRW: { name: 'Wiley Post Will Rogers Memorial Airport', city: 'Barrow', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BRX: { name: 'Maria Montez International Airport', city: 'Barahona', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  BSA: { name: 'Bosaso Airport', city: 'Bosaso', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  BSB: { name: 'Presidente Juscelino Kubistschek International Airport', city: 'Brasilia', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  BSC: { name: 'José Celestino Mutis Airport', city: 'Bahia Solano', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  BSF: { name: 'Bradshaw Army Airfield', city: 'Bradshaw Field', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  BSG: { name: 'Bata Airport', city: 'Bata', country: 'Equatorial Guinea', timezone: 'Africa/Malabo', offset: 1 },
  BSJ: { name: 'Bairnsdale Airport', city: 'Bairnsdale', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  BSK: { name: 'Biskra Airport', city: 'Biskra', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  BSL: { name: 'EuroAirport Basel-Mulhouse-Freiburg Airport', city: 'Mulhouse', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BSO: { name: 'Basco Airport', city: 'Basco', country: 'Philippines', timezone: null, offset: 8 },
  BSR: { name: 'Basrah International Airport', city: 'Basrah', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  BST: { name: 'Bost Airport', city: 'Lashkar Gah', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  BSU: { name: 'Basankusu Airport', city: 'Basankusu', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  BSX: { name: 'Pathein Airport', city: 'Pathein', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  BTE: { name: 'Sherbro International Airport', city: 'Bonthe', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  BTH: { name: 'Hang Nadim International Airport', city: 'Batam', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  BTI: { name: 'Barter Island LRRS Airport', city: 'Barter Island', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BTJ: { name: 'Sultan Iskandar Muda International Airport', city: 'Banda Aceh', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  BTK: { name: 'Bratsk Airport', city: 'Bratsk', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  BTL: { name: 'W K Kellogg Airport', city: 'Battle Creek', country: 'United States', timezone: null, offset: 0 },
  BTM: { name: 'Bert Mooney Airport', city: 'Butte', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BTR: { name: 'Baton Rouge Metropolitan, Ryan Field', city: 'Baton Rouge', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BTS: { name: 'M. R. Štefánik Airport', city: 'Bratislava', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  BTT: { name: 'Bettles Airport', city: 'Bettles', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  BTU: { name: 'Bintulu Airport', city: 'Bintulu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  BTV: { name: 'Burlington International Airport', city: 'Burlington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BTZ: { name: 'Bursa Airport', city: 'Bursa', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BUA: { name: 'Buka Airport', city: 'Buka Island', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  BUC: { name: 'Burketown Airport', city: 'Burketown', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BUD: { name: 'Budapest Ferenc Liszt International Airport', city: 'Budapest', country: 'Hungary', timezone: 'Europe/Budapest', offset: 1 },
  BUF: { name: 'Buffalo Niagara International Airport', city: 'Buffalo', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BUG: { name: 'Benguela Airport', city: 'Benguela', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  BUI: { name: 'Bokondini Airport', city: 'Bokondini-Papua Island', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  BUN: { name: 'Gerardo Tobar López Airport', city: 'Buenaventura', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  BUO: { name: 'Burao Airport', city: 'Burao', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  BUQ: { name: 'Joshua Mqabuko Nkomo International Airport', city: 'Bulawayo', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  BUR: { name: 'Bob Hope Airport', city: 'Burbank', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BUS: { name: 'Batumi International Airport', city: 'Batumi', country: 'Georgia', timezone: 'Asia/Tbilisi', offset: 4 },
  BUT: { name: 'Bathpalathang Airport', city: 'Jakar', country: 'Bhutan', timezone: null, offset: 6 },
  BUU: { name: 'Burlington Municipal Airport', city: 'Burlington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BUW: { name: 'Betoambari Airport', city: 'Bau-Bau', country: 'Indonesia', timezone: null, offset: 8 },
  BUX: { name: 'Bunia Airport', city: 'Bunia', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  BUY: { name: 'Bunbury Airport', city: 'Bunbury', country: 'Australia', timezone: null, offset: 8 },
  BUZ: { name: 'Bushehr Airport', city: 'Bushehr', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  BVA: { name: 'Paris Beauvais Tillé Airport', city: 'Beauvais', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BVB: { name: 'Atlas Brasil Cantanhede Airport', city: 'Boa Vista', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  BVC: { name: 'Rabil Airport', city: 'Boa Vista', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  BVE: { name: 'Toul Rosières Air Base', city: 'Brive', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BVG: { name: 'Berlevåg Airport', city: 'Berlevag', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  BVH: { name: 'Brigadeiro Camarão Airport', city: 'Vilhena', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  BVI: { name: 'Birdsville Airport', city: 'Birdsville', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  BVS: { name: 'Breves Airport', city: 'Breves', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  BVY: { name: 'Beverly Municipal Airport', city: 'Beverly', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BWA: { name: 'Gautam Buddha Airport', city: 'Bhairawa', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  BWB: { name: 'Barrow Island Airport', city: 'Barrow Island', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  BWE: { name: 'Braunschweig Wolfsburg Airport', city: 'Braunschweig', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BWF: { name: 'Barrow Walney Island Airport', city: 'Barrow Island', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  BWG: { name: 'Bowling Green Warren County Regional Airport', city: 'Bowling Green', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BWI: { name: 'Baltimore/Washington International Thurgood Marshall Airport', city: 'Baltimore', country: 'United States', timezone: 'America/New_York', offset: -5 },
  BWK: { name: 'Bol Airport', city: 'Brac', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  BWN: { name: 'Brunei International Airport', city: 'Bandar Seri Begawan', country: 'Brunei', timezone: 'Asia/Brunei', offset: 8 },
  BWO: { name: 'Balakovo Airport', city: 'Balakovo', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  BWQ: { name: 'Brewarrina Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  BWT: { name: 'Wynyard Airport', city: 'Burnie', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  BWU: { name: 'Sydney Bankstown Airport', city: 'Sydney', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  BWW: { name: 'Las Brujas Airport', city: 'Cayo Santa Maria', country: 'Cuba', timezone: null, offset: -5 },
  BXB: { name: 'Babo Airport', city: 'Babo', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  BXE: { name: 'Bakel Airport', city: 'Bakel', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  BXH: { name: 'Balkhash Airport', city: 'Balkhash', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  BXK: { name: 'Buckeye Municipal Airport', city: 'Buckeye', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  BXN: { name: 'Imsık Airport', city: 'Bodrum', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BXO: { name: 'Buochs Airport', city: 'Buochs', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  BXP: { name: 'Biała Podlaska Airfield', city: 'Biała Podlaska', country: 'Poland', timezone: null, offset: 0 },
  BXR: { name: 'Bam Airport', city: 'Bam', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  BXU: { name: 'Bancasi Airport', city: 'Butuan', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  BYC: { name: 'Yacuiba Airport', city: 'Yacuiba', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  BYH: { name: 'Arkansas International Airport', city: 'Blytheville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  BYI: { name: 'Burley Municipal Airport', city: 'Burley', country: 'United States', timezone: null, offset: 0 },
  BYK: { name: 'Bouaké Airport', city: 'Bouake', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  BYM: { name: 'Carlos Manuel de Cespedes Airport', city: 'Bayamo', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  BYN: { name: 'Bayankhongor Airport', city: 'Bayankhongor', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  BYO: { name: 'Bonito Airport', city: 'Bointo', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  BYS: { name: 'Bicycle Lake Army Air Field', city: 'Fort Irwin', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  BYT: { name: 'Bantry Aerodrome', city: 'Bantry', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  BYU: { name: 'Bayreuth Airport', city: 'Bayreuth', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  BYY: { name: 'Bay City Municipal Airport', city: 'Bay City', country: 'United States', timezone: null, offset: -5 },
  BZA: { name: 'San Pedro Airport', city: 'Bonanza', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  BZC: { name: 'Umberto Modiano Airport', city: 'Buzios', country: 'Brazil', timezone: null, offset: -3 },
  BZD: { name: 'Balranald Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  BZE: { name: 'Philip S. W. Goldson International Airport', city: 'Belize City', country: 'Belize', timezone: 'America/Belize', offset: -6 },
  BZG: { name: 'Bydgoszcz Ignacy Jan Paderewski Airport', city: 'Bydgoszcz', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  BZI: { name: 'Balıkesir Merkez Airport', city: 'Balikesir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  BZK: { name: 'Bryansk Airport', city: 'Bryansk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  BZL: { name: 'Barisal Airport', city: 'Barisal', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  BZN: { name: 'Gallatin Field', city: 'Bozeman', country: 'United States', timezone: 'America/Denver', offset: -7 },
  BZO: { name: 'Bolzano Airport', city: 'Bolzano', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  BZR: { name: 'Béziers-Vias Airport', city: 'Beziers', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  BZV: { name: 'Maya-Maya Airport', city: 'Brazzaville', country: 'Congo (Brazzaville)', timezone: 'Africa/Brazzaville', offset: 1 },
  BZY: { name: 'Balti International Airport', city: 'Saltsy', country: 'Moldova', timezone: 'Europe/Chisinau', offset: 2 },
  BZZ: { name: 'RAF Brize Norton', city: 'Brize Norton', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  C03: { name: 'Nappanee Municipal Airport', city: 'Nappanee ', country: 'United States', timezone: null, offset: -5 },
  CAB: { name: 'Cabinda Airport', city: 'Cabinda', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  CAC: { name: 'Cascavel Airport', city: 'Cascavel', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CAE: { name: 'Columbia Metropolitan Airport', city: 'Columbia', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CAF: { name: 'Carauari Airport', city: 'Carauari', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  CAG: { name: 'Cagliari Elmas Airport', city: 'Cagliari', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CAH: { name: 'Cà Mau Airport', city: 'Ca Mau', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  CAI: { name: 'Cairo International Airport', city: 'Cairo', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  CAJ: { name: 'Canaima Airport', city: 'Canaima', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CAK: { name: 'Akron Canton Regional Airport', city: 'Akron', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CAL: { name: 'Campbeltown Airport', city: 'Campbeltown', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CAN: { name: 'Guangzhou Baiyun International Airport', city: 'Guangzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CAP: { name: 'Cap Haitien International Airport', city: 'Cap Haitien', country: 'Haiti', timezone: 'America/Port-au-Prince', offset: -5 },
  CAQ: { name: 'Juan H White Airport', city: 'Caucasia', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CAR: { name: 'Caribou Municipal Airport', city: 'Caribou', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CAT: { name: 'New Bight Airport', city: 'Cat Island', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  CAU: { name: 'Caruaru Airport', city: 'Caruaru', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  CAW: { name: 'Bartolomeu Lisandro Airport', city: 'Campos', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CAX: { name: 'Carlisle Airport', city: 'Carlisle', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CAY: { name: 'Cayenne-Rochambeau Airport', city: 'Cayenne', country: 'French Guiana', timezone: 'America/Cayenne', offset: -3 },
  CAZ: { name: 'Cobar Airport', city: 'Cobar', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CBB: { name: 'Jorge Wilsterman International Airport', city: 'Cochabamba', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  CBE: { name: 'Greater Cumberland Regional Airport', city: 'Cumberland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CBF: { name: 'Council Bluffs Municipal Airport', city: 'Council Bluffs', country: 'United States', timezone: null, offset: -5 },
  CBG: { name: 'Cambridge Airport', city: 'Cambridge', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CBH: { name: 'Béchar Boudghene Ben Ali Lotfi Airport', city: 'Béchar', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  CBL: { name: 'Aeropuerto "General Tomas de Heres". Ciudad Bolivar', city: 'Ciudad Bolivar', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CBM: { name: 'Columbus Air Force Base', city: 'Colombus', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CBN: { name: 'Penggung Airport', city: 'Cirebon', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  CBO: { name: 'Awang Airport', city: 'Cotabato', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CBQ: { name: 'Margaret Ekpo International Airport', city: 'Calabar', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  CBR: { name: 'Canberra International Airport', city: 'Canberra', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CBT: { name: 'Catumbela Airport', city: 'Catumbela', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  CBV: { name: 'Coban Airport', city: 'Coban', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  CCB: { name: 'Cable Airport', city: 'Upland', country: 'United States', timezone: null, offset: -8 },
  CCC: { name: 'Jardines Del Rey Airport', city: 'Cunagua', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  CCF: { name: 'Carcassonne Airport', city: 'Carcassonne', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CCH: { name: 'Chile Chico Airport', city: 'Chile Chico', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  CCI: { name: 'Concórdia Airport', city: 'Concordia', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CCJ: { name: 'Calicut International Airport', city: 'Calicut', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  CCK: { name: 'Cocos (Keeling) Islands Airport', city: 'Cocos Keeling Island', country: 'Cocos (Keeling) Islands', timezone: 'Indian/Cocos', offset: 6 },
  CCL: { name: 'Chinchilla Airport', city: 'Chinchilla', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CCM: { name: 'Diomício Freitas Airport', city: 'Criciuma', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CCN: { name: 'Chakcharan Airport', city: 'Chaghcharan', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  CCO: { name: 'Newnan Coweta County Airport', city: 'Newnan', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CCP: { name: 'Carriel Sur Airport', city: 'Concepcion', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  CCR: { name: 'Buchanan Field', city: 'Concord', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CCS: { name: 'Simón Bolívar International Airport', city: 'Caracas', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CCU: { name: 'Netaji Subhash Chandra Bose International Airport', city: 'Kolkata', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  CCV: { name: 'Craig Cove Airport', city: 'Craig Cove', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  CCX: { name: 'Cáceres Airport', city: 'Caceres', country: 'Brazil', timezone: null, offset: -4 },
  CCY: { name: 'Northeast Iowa Regional Airport', city: 'Charles City', country: 'United States', timezone: null, offset: 0 },
  CCZ: { name: 'Chub Cay Airport', city: 'Chub Cay', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  CDA: { name: 'Cooinda Airport', city: 'Cooinda', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  CDB: { name: 'Cold Bay Airport', city: 'Cold Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CDC: { name: 'Cedar City Regional Airport', city: 'Cedar City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CDG: { name: 'Charles de Gaulle International Airport', city: 'Paris', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CDJ: { name: 'Conceição do Araguaia Airport', city: 'Conceicao Do Araguaia', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  CDN: { name: 'Woodward Field', city: 'Camden', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CDP: { name: 'Cuddapah Airport', city: 'Cuddapah', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  CDR: { name: 'Chadron Municipal Airport', city: 'Chadron', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CDS: { name: 'Childress Municipal Airport', city: 'Childress', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CDT: { name: 'Castellón-Costa Azahar Airport', city: 'Castellón de la Plana', country: 'Spain', timezone: null, offset: 0 },
  CDU: { name: 'Camden Airport', city: 'Camden', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CDV: { name: 'Merle K (Mudhole) Smith Airport', city: 'Cordova', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CDW: { name: 'Essex County Airport', city: 'Caldwell', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CEB: { name: 'Mactan Cebu International Airport', city: 'Cebu', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CEC: { name: 'Jack Mc Namara Field Airport', city: 'Crescent City', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CED: { name: 'Ceduna Airport', city: 'Ceduna', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  CEE: { name: 'Cherepovets Airport', city: 'Cherepovets', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  CEF: { name: 'Westover ARB/Metropolitan Airport', city: 'Chicopee Falls', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CEG: { name: 'Hawarden Airport', city: 'Hawarden', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CEI: { name: 'Chiang Rai International Airport', city: 'Chiang Rai', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  CEJ: { name: 'Chernihiv Shestovitsa Airport', city: 'Chernigov', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  CEK: { name: 'Chelyabinsk Balandino Airport', city: 'Chelyabinsk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  CEM: { name: 'Central Airport', city: 'Central', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CEN: { name: 'Ciudad Obregón International Airport', city: 'Ciudad Obregon', country: 'Mexico', timezone: 'America/Hermosillo', offset: -7 },
  CEQ: { name: 'Cannes-Mandelieu Airport', city: 'Cannes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CER: { name: 'Cherbourg-Maupertus Airport', city: 'Cherbourg', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CES: { name: 'Cessnock Airport', city: 'Cessnock', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CET: { name: 'Cholet Le Pontreau Airport', city: 'Cholet', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CEU: { name: 'Oconee County Regional Airport', city: 'Clemson', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CEW: { name: 'Bob Sikes Airport', city: 'Crestview', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CEZ: { name: 'Cortez Municipal Airport', city: 'Cortez', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CFB: { name: 'Cabo Frio Airport', city: 'Cabo Frio', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CFC: { name: 'Caçador Airport', city: 'Cacador', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CFD: { name: 'Coulter Field', city: 'Bryan', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CFE: { name: 'Clermont-Ferrand Auvergne Airport', city: 'Clermont-Ferrand', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CFG: { name: 'Jaime Gonzalez Airport', city: 'Cienfuegos', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  CFN: { name: 'Donegal Airport', city: 'Dongloe', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  CFO: { name: 'Confresa Airport', city: 'Confresa', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  CFR: { name: 'Caen-Carpiquet Airport', city: 'Caen', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CFS: { name: 'Coffs Harbour Airport', city: 'Coff\'s Harbour', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CFU: { name: 'Ioannis Kapodistrias International Airport', city: 'Kerkyra/corfu', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  CFV: { name: 'Coffeyville Municipal Airport', city: 'Coffeyville', country: 'United States', timezone: null, offset: -5 },
  CGB: { name: 'Marechal Rondon Airport', city: 'Cuiaba', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  CGC: { name: 'Crystal River Airport', city: 'Crystal River', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CGD: { name: 'Changde Airport', city: 'Changde', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CGF: { name: 'Cuyahoga County Airport', city: 'Richmond Heights', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CGH: { name: 'Congonhas Airport', city: 'Sao Paulo', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CGI: { name: 'Cape Girardeau Regional Airport', city: 'Cape Girardeau', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CGK: { name: 'Soekarno-Hatta International Airport', city: 'Jakarta', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  CGM: { name: 'Camiguin Airport', city: 'Camiguin', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CGN: { name: 'Cologne Bonn Airport', city: 'Cologne', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  CGO: { name: 'Zhengzhou Xinzheng International Airport', city: 'Zhengzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CGP: { name: 'Shah Amanat International Airport', city: 'Chittagong', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  CGQ: { name: 'Longjia Airport', city: 'Changchun', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CGR: { name: 'Campo Grande Airport', city: 'Campo Grande', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  CGX: { name: 'Chicago Meigs Airport', city: 'Chicago', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CGY: { name: 'Cagayan De Oro Airport', city: 'Ladag', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CGZ: { name: 'Casa Grande Municipal Airport', city: 'Casa Grande', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  CHA: { name: 'Lovell Field', city: 'Chattanooga', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CHC: { name: 'Christchurch International Airport', city: 'Christchurch', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  CHG: { name: 'Chaoyang Airport', city: 'Chaoyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CHH: { name: 'Chachapoyas Airport', city: 'Chachapoyas', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  CHM: { name: 'Teniente FAP Jaime A De Montreuil Morales Airport', city: 'Chimbote', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  CHN: { name: 'Jeon Ju Airport', city: 'Jhunju', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  CHO: { name: 'Charlottesville Albemarle Airport', city: 'Charlottesville VA', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CHQ: { name: 'Chania International Airport', city: 'Chania', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  CHR: { name: 'Châteauroux-Déols "Marcel Dassault" Airport', city: 'Chateauroux', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CHS: { name: 'Charleston Air Force Base-International Airport', city: 'Charleston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CHT: { name: 'Chatham Islands-Tuuta Airport', city: 'Chatham Island', country: 'New Zealand', timezone: 'Pacific/Chatham', offset: 12 },
  CHX: { name: 'Cap Manuel Niño International Airport', city: 'Changuinola', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  CHY: { name: 'Choiseul Bay Airport', city: 'Choiseul Bay', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  CIA: { name: 'Ciampino–G. B. Pastine International Airport', city: 'Rome', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CIC: { name: 'Chico Municipal Airport', city: 'Chico', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CID: { name: 'The Eastern Iowa Airport', city: 'Cedar Rapids', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CIF: { name: 'Chifeng Airport', city: 'Chifeng', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CIH: { name: 'Changzhi Airport', city: 'Changzhi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CIJ: { name: 'Capitán Aníbal Arab Airport', city: 'Cobija', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  CIK: { name: 'Chalkyitsik Airport', city: 'Chalkyitsik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CIO: { name: 'Teniente Col Carmelo Peralta Airport', city: 'Conception', country: 'Paraguay', timezone: 'America/Asuncion', offset: -4 },
  CIP: { name: 'Chipata Airport', city: 'Chipata', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  CIS: { name: 'Canton Island Airport', city: 'Canton Island', country: 'Kiribati', timezone: 'Pacific/Enderbury', offset: 13 },
  CIT: { name: 'Shymkent Airport', city: 'Chimkent', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  CIU: { name: 'Chippewa County International Airport', city: 'Sault Ste Marie', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CIW: { name: 'Canouan Airport', city: 'Canouan Island', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: -4 },
  CIX: { name: 'Capitan FAP Jose A Quinones Gonzales International Airport', city: 'Chiclayo', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  CIY: { name: 'Comiso Airport', city: 'Comiso', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CIZ: { name: 'Coari Airport', city: 'Coari', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  CJA: { name: 'Mayor General FAP Armando Revoredo Iglesias Airport', city: 'Cajamarca', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  CJB: { name: 'Coimbatore International Airport', city: 'Coimbatore', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  CJC: { name: 'El Loa Airport', city: 'Calama', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  CJF: { name: 'Coondewanna Airport', city: 'Coondewanna', country: 'Australia', timezone: null, offset: 8 },
  CJJ: { name: 'Cheongju International Airport', city: 'Chongju', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  CJL: { name: 'Chitral Airport', city: 'Chitral', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  CJM: { name: 'Chumphon Airport', city: 'Chumphon', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  CJS: { name: 'Abraham González International Airport', city: 'Ciudad Juarez', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  CJU: { name: 'Jeju International Airport', city: 'Cheju', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  CKB: { name: 'North Central West Virginia Airport', city: 'Clarksburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CKC: { name: 'Cherkasy International Airport', city: 'Cherkassy', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  CKF: { name: 'Crisp County Cordele Airport', city: 'Cordele', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CKG: { name: 'Chongqing Jiangbei International Airport', city: 'Chongqing', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CKH: { name: 'Chokurdakh Airport', city: 'Chokurdah', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  CKL: { name: 'Chkalovskiy Airport', city: 'Shchyolkovo', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  CKS: { name: 'Carajás Airport', city: 'Parauapebas', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  CKV: { name: 'Clarksville–Montgomery County Regional Airport', city: 'Clarksville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CKY: { name: 'Conakry International Airport', city: 'Conakry', country: 'Guinea', timezone: 'Africa/Conakry', offset: 0 },
  CKZ: { name: 'Çanakkale Airport', city: 'Canakkale', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  CLD: { name: 'Mc Clellan-Palomar Airport', city: 'Carlsbad', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CLE: { name: 'Cleveland Hopkins International Airport', city: 'Cleveland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CLJ: { name: 'Cluj-Napoca International Airport', city: 'Cluj-napoca', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  CLL: { name: 'Easterwood Field', city: 'College Station', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CLM: { name: 'William R Fairchild International Airport', city: 'Port Angeles', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CLN: { name: 'Brig. Lysias Augusto Rodrigues Airport', city: 'Carolina', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  CLO: { name: 'Alfonso Bonilla Aragon International Airport', city: 'Cali', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CLQ: { name: 'Licenciado Miguel de la Madrid Airport', city: 'Colima', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CLS: { name: 'Chehalis Centralia Airport', city: 'Chehalis', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CLT: { name: 'Charlotte Douglas International Airport', city: 'Charlotte', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CLU: { name: 'Columbus Municipal Airport', city: 'Columbus', country: 'United States', timezone: null, offset: 0 },
  CLV: { name: 'Nelson Ribeiro Guimarães Airport', city: 'Caldas Novas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CLW: { name: 'Clearwater Air Park', city: 'Clearwater', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CLY: { name: 'Calvi-Sainte-Catherine Airport', city: 'Calvi', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CMA: { name: 'Cunnamulla Airport', city: 'Cunnamulla', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CMB: { name: 'Bandaranaike International Colombo Airport', city: 'Colombo', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  CMD: { name: 'Cootamundra Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  CME: { name: 'Ciudad del Carmen International Airport', city: 'Ciudad Del Carmen', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CMF: { name: 'Chambéry-Savoie Airport', city: 'Chambery', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CMG: { name: 'Corumbá International Airport', city: 'Corumba', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  CMH: { name: 'Port Columbus International Airport', city: 'Columbus', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CMI: { name: 'University of Illinois Willard Airport', city: 'Champaign', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CMK: { name: 'Club Makokola Airport', city: 'Club Makokola', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  CMN: { name: 'Mohammed V International Airport', city: 'Casablanca', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  CMP: { name: 'Santana do Araguaia Airport', city: 'Santana do Araguaia', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  CMQ: { name: 'Clermont Airport', city: 'Clermont', country: 'Australia', timezone: null, offset: 10 },
  CMR: { name: 'Colmar-Houssen Airport', city: 'Colmar', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CMU: { name: 'Chimbu Airport', city: 'Kundiawa', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  CMW: { name: 'Ignacio Agramonte International Airport', city: 'Camaguey', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  CMX: { name: 'Houghton County Memorial Airport', city: 'Hancock', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CNB: { name: 'Coonamble Airport', city: 'Coonamble', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  CNC: { name: 'Coconut Island Airport', city: 'Coconut Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CND: { name: 'Mihail Kogălniceanu International Airport', city: 'Constanta', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  CNF: { name: 'Tancredo Neves International Airport', city: 'Belo Horizonte', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CNG: { name: 'Cognac-Châteaubernard (BA 709) Air Base', city: 'Cognac', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CNI: { name: 'Changhai Airport', city: 'Changhai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CNJ: { name: 'Cloncurry Airport', city: 'Cloncurry', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CNL: { name: 'Sindal Airport', city: 'Sindal', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  CNM: { name: 'Cavern City Air Terminal', city: 'Carlsbad', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CNN: { name: 'Chulman Airport', city: 'Neryungri', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  CNO: { name: 'Chino Airport', city: 'Chino', country: 'United States', timezone: null, offset: -8 },
  CNP: { name: 'Neerlerit Inaat Airport', city: 'Neerlerit Inaat', country: 'Greenland', timezone: 'America/Scoresbysund', offset: -1 },
  CNQ: { name: 'Corrientes Airport', city: 'Corrientes', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  CNR: { name: 'Chañaral Airport', city: 'Chañaral', country: 'Chile', timezone: null, offset: 0 },
  CNS: { name: 'Cairns International Airport', city: 'Cairns', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CNU: { name: 'Chanute Martin Johnson Airport', city: 'Chanute', country: 'United States', timezone: null, offset: 0 },
  CNW: { name: 'TSTC Waco Airport', city: 'Waco', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CNX: { name: 'Chiang Mai International Airport', city: 'Chiang Mai', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  CNY: { name: 'Canyonlands Field', city: 'Moab', country: 'United States', timezone: 'America/Denver', offset: -7 },
  COC: { name: 'Comodoro Pierrestegui Airport', city: 'Concordia', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  COD: { name: 'Yellowstone Regional Airport', city: 'Cody', country: 'United States', timezone: 'America/Denver', offset: -7 },
  COE: { name: 'Coeur D\'Alene - Pappy Boyington Field', city: 'Coeur d\'Alene', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  COF: { name: 'Patrick Air Force Base', city: 'Coco Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  COG: { name: 'Mandinga Airport', city: 'Condoto', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  COH: { name: 'Cooch Behar Airport', city: 'Cooch-behar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  COJ: { name: 'Coonabarabran Airport', city: 'Coonabarabran', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  COK: { name: 'Cochin International Airport', city: 'Kochi', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  CON: { name: 'Concord Municipal Airport', city: 'Concord NH', country: 'United States', timezone: 'America/New_York', offset: -5 },
  COO: { name: 'Cadjehoun Airport', city: 'Cotonou', country: 'Benin', timezone: 'Africa/Porto-Novo', offset: 1 },
  COQ: { name: 'Choibalsan Airport', city: 'Choibalsan', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  COR: { name: 'Ingeniero Ambrosio Taravella Airport', city: 'Cordoba', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  COS: { name: 'City of Colorado Springs Municipal Airport', city: 'Colorado Springs', country: 'United States', timezone: 'America/Denver', offset: -7 },
  COT: { name: 'Cotulla-La Salle County Airport', city: 'Cotulla', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  COU: { name: 'Columbia Regional Airport', city: 'Columbia', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  COX: { name: 'Congo Town Airport', city: 'Andros', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  COZ: { name: 'Constanza - Expedición 14 de Junio National Airport', city: 'Constanza', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  CPA: { name: 'Cape Palmas Airport', city: 'Greenville', country: 'Liberia', timezone: 'Africa/Monrovia', offset: 0 },
  CPB: { name: 'Capurganá Airport', city: 'Capurgana', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CPC: { name: 'Aviador C. Campos Airport', city: 'San Martin Des Andes', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  CPD: { name: 'Coober Pedy Airport', city: 'Coober Pedy', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  CPE: { name: 'Ingeniero Alberto Acuña Ongay International Airport', city: 'Campeche', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CPH: { name: 'Copenhagen Kastrup Airport', city: 'Copenhagen', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  CPO: { name: 'Chamonate Airport', city: 'Copiapo', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  CPQ: { name: 'Amarais Airport', city: 'Campinas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CPR: { name: 'Casper-Natrona County International Airport', city: 'Casper', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CPT: { name: 'Cape Town International Airport', city: 'Cape Town', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  CPV: { name: 'Presidente João Suassuna Airport', city: 'Campina Grande', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  CPX: { name: 'Benjamin Rivera Noriega Airport', city: 'Culebra Island', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  CQD: { name: 'Shahrekord Airport', city: 'Shahre Kord', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  CQF: { name: 'Calais-Dunkerque Airport', city: 'Calais', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CQM: { name: 'Ciudad Real Central Airport', city: 'Ciudad Real', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  CQW: { name: 'Cheraw Municipal Airport/Lynch Bellinger Field', city: 'Cheraw', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CRA: { name: 'Craiova Airport', city: 'Craiova', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  CRC: { name: 'Santa Ana Airport', city: 'Cartago', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CRD: { name: 'General E. Mosconi Airport', city: 'Comodoro Rivadavia', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  CRE: { name: 'Grand Strand Airport', city: 'North Myrtle Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CRG: { name: 'Jacksonville Executive at Craig Airport', city: 'Jacksonville', country: 'United States', timezone: null, offset: 0 },
  CRI: { name: 'Colonel Hill Airport', city: 'Colonel Hill', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  CRK: { name: 'Clark International Airport', city: 'Angeles City', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CRL: { name: 'Brussels South Charleroi Airport', city: 'Charleroi', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  CRM: { name: 'Catarman National Airport', city: 'Catarman', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CRP: { name: 'Corpus Christi International Airport', city: 'Corpus Christi', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CRQ: { name: 'Caravelas Airport', city: 'Caravelas', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  CRV: { name: 'Crotone Airport', city: 'Crotone', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CRW: { name: 'Yeager Airport', city: 'Charleston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CRZ: { name: 'Turkmenabat Airport', city: 'Chardzhou', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 5 },
  CSA: { name: 'Colonsay Airstrip', city: 'Colonsay', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CSB: { name: 'Caransebeş Airport', city: 'Caransebes', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  CSC: { name: 'Codela Airport', city: 'Guapiles', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  CSF: { name: 'Creil Air Base', city: 'Creil', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CSG: { name: 'Columbus Metropolitan Airport', city: 'Columbus', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CSH: { name: 'Solovki Airport', city: 'Solovetsky Islands', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  CSK: { name: 'Cap Skirring Airport', city: 'Cap Skiring', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  CSO: { name: 'Cochstedt Airport', city: 'Cochstedt', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  CSV: { name: 'Crossville Memorial Whitson Field', city: 'Crossville', country: 'United States', timezone: null, offset: 0 },
  CSX: { name: 'Changsha Huanghua International Airport', city: 'Changcha', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CSY: { name: 'Cheboksary Airport', city: 'Cheboksary', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  CTA: { name: 'Catania-Fontanarossa Airport', city: 'Catania', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CTB: { name: 'Cut Bank International Airport', city: 'Cutbank', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CTC: { name: 'Catamarca Airport', city: 'Catamarca', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  CTD: { name: 'Alonso Valderrama Airport', city: 'Chitré', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  CTG: { name: 'Rafael Nuñez International Airport', city: 'Cartagena', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CTH: { name: 'Chester County G O Carlson Airport', city: 'Coatesville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CTJ: { name: 'West Georgia Regional O V Gray Field', city: 'Carrollton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CTL: { name: 'Charleville Airport', city: 'Charlieville', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CTM: { name: 'Chetumal International Airport', city: 'Chetumal', country: 'Mexico', timezone: 'America/Cancun', offset: -5 },
  CTN: { name: 'Cooktown Airport', city: 'Cooktown', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CTS: { name: 'New Chitose Airport', city: 'Sapporo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  CTT: { name: 'Le Castellet Airport', city: 'Le Castellet', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CTU: { name: 'Chengdu Shuangliu International Airport', city: 'Chengdu', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  CTY: { name: 'Cross City Airport', city: 'Cross City', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CUA: { name: 'Ciudad Constitución Airport', city: 'Ciudad Constitución', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  CUB: { name: 'Jim Hamilton L.B. Owens Airport', city: 'Columbia', country: 'United States', timezone: null, offset: -4 },
  CUC: { name: 'Camilo Daza International Airport', city: 'Cucuta', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CUE: { name: 'Mariscal Lamar Airport', city: 'Cuenca', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  CUF: { name: 'Cuneo International Airport', city: 'Cuneo', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  CUL: { name: 'Bachigualato Federal International Airport', city: 'Culiacan', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  CUM: { name: 'Cumaná (Antonio José de Sucre) Airport', city: 'Cumana', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CUN: { name: 'Cancún International Airport', city: 'Cancun', country: 'Mexico', timezone: 'America/Cancun', offset: -5 },
  CUP: { name: 'General Francisco Bermúdez Airport', city: 'Carupano', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CUQ: { name: 'Coen Airport', city: 'Coen', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  CUR: { name: 'Hato International Airport', city: 'Willemstad', country: 'Netherlands Antilles', timezone: 'America/Curacao', offset: -4 },
  CUU: { name: 'General Roberto Fierro Villalobos International Airport', city: 'Chihuahua', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  CUZ: { name: 'Alejandro Velasco Astete International Airport', city: 'Cuzco', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  CVC: { name: 'Cleve Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  CVE: { name: 'Coveñas Airport', city: 'Coveñas', country: 'Colombia', timezone: null, offset: 0 },
  CVF: { name: 'Courchevel Airport', city: 'Courcheval', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  CVG: { name: 'Cincinnati Northern Kentucky International Airport', city: 'Cincinnati', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CVJ: { name: 'General Mariano Matamoros Airport', city: 'Cuernavaca', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CVM: { name: 'General Pedro Jose Mendez International Airport', city: 'Ciudad Victoria', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CVN: { name: 'Clovis Municipal Airport', city: 'Clovis', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CVO: { name: 'Corvallis Municipal Airport', city: 'Corvallis', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CVQ: { name: 'Carnarvon Airport', city: 'Carnarvon', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  CVS: { name: 'Cannon Air Force Base', city: 'Clovis', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CVT: { name: 'Coventry Airport', city: 'Coventry', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CVU: { name: 'Corvo Airport', city: 'Corvo', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  CVX: { name: 'Charlevoix Municipal Airport', city: 'Charelvoix', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CWA: { name: 'Central Wisconsin Airport', city: 'Wassau', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CWB: { name: 'Afonso Pena Airport', city: 'Curitiba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CWC: { name: 'Chernivtsi International Airport', city: 'Chernovtsk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  CWI: { name: 'Clinton Municipal Airport', city: 'Clinton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CWL: { name: 'Cardiff International Airport', city: 'Cardiff', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  CWT: { name: 'Cowra Airport', city: 'Chatsworth', country: 'United States', timezone: 'Australia/Sydney', offset: 10 },
  CWW: { name: 'Corowa Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  CXB: { name: 'Cox\'s Bazar Airport', city: 'Cox\'s Bazar', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  CXH: { name: 'Vancouver Harbour Water Aerodrome', city: 'Vancouver', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  CXI: { name: 'Cassidy International Airport', city: 'Kiritimati', country: 'Kiribati', timezone: null, offset: -12 },
  CXJ: { name: 'Hugo Cantergiani Regional Airport', city: 'Caxias Do Sul', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  CXL: { name: 'Calexico International Airport', city: 'Calexico', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  CXO: { name: 'Lone Star Executive Airport', city: 'Conroe', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  CXP: { name: 'Tunggul Wulung Airport', city: 'Cilacap', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  CXR: { name: 'Cam Ranh Airport', city: 'Nha Trang', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  CXY: { name: 'Capital City Airport', city: 'Harrisburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CYB: { name: 'Gerrard Smith International Airport', city: 'Cayman Brac', country: 'Cayman Islands', timezone: 'America/Cayman', offset: -5 },
  CYF: { name: 'Chefornak Airport', city: 'Chefornak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CYG: { name: 'Corryong Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  CYI: { name: 'Chiayi Airport', city: 'Chiayi', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  CYO: { name: 'Vilo Acuña International Airport', city: 'Cayo Largo del Sur', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  CYP: { name: 'Calbayog Airport', city: 'Calbayog City', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CYR: { name: 'Laguna de Los Patos International Airport', city: 'Colonia', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  CYS: { name: 'Cheyenne Regional Jerry Olson Field', city: 'Cheyenne', country: 'United States', timezone: 'America/Denver', offset: -7 },
  CYT: { name: 'Yakataga Airport', city: 'Yakataga', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CYU: { name: 'Cuyo Airport', city: 'Cuyo', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CYW: { name: 'Captain Rogelio Castillo National Airport', city: 'Celaya', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CYX: { name: 'Cherskiy Airport', city: 'Cherskiy', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  CYZ: { name: 'Cauayan Airport', city: 'Cauayan', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  CZA: { name: 'Chichen Itza International Airport', city: 'Chichen Itza', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  CZE: { name: 'José Leonardo Chirinos Airport', city: 'Coro', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  CZF: { name: 'Cape Romanzof LRRS Airport', city: 'Cape Romanzof', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  CZG: { name: 'Tri Cities Airport', city: 'Endicott', country: 'United States', timezone: 'America/New_York', offset: -5 },
  CZL: { name: 'Mohamed Boudiaf International Airport', city: 'Constantine', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  CZM: { name: 'Cozumel International Airport', city: 'Cozumel', country: 'Mexico', timezone: 'America/Cancun', offset: -5 },
  CZS: { name: 'Cruzeiro do Sul Airport', city: 'Cruzeiro do Sul', country: 'Brazil', timezone: 'America/Rio_Branco', offset: -5 },
  CZU: { name: 'Las Brujas Airport', city: 'Corozal', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  CZX: { name: 'Changzhou Benniu Airport', city: 'Changzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DAA: { name: 'Davison Army Air Field', city: 'Fort Belvoir', country: 'United States', timezone: null, offset: 0 },
  DAB: { name: 'Daytona Beach International Airport', city: 'Daytona Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DAC: { name: 'Dhaka / Hazrat Shahjalal International Airport', city: 'Dhaka', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  DAD: { name: 'Da Nang International Airport', city: 'Danang', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  DAG: { name: 'Barstow Daggett Airport', city: 'Daggett', country: 'United States', timezone: null, offset: 0 },
  DAL: { name: 'Dallas Love Field', city: 'Dallas', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DAM: { name: 'Damascus International Airport', city: 'Damascus', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  DAN: { name: 'Danville Regional Airport', city: 'Danville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DAR: { name: 'Julius Nyerere International Airport', city: 'Dar Es Salaam', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  DAT: { name: 'Datong Airport', city: 'Datong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DAU: { name: 'Daru Airport', city: 'Daru', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  DAV: { name: 'Enrique Malek International Airport', city: 'David', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  DAW: { name: 'Skyhaven Airport', city: 'Rochester', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DAX: { name: 'Dachuan Airport', city: 'Dazhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DAY: { name: 'James M Cox Dayton International Airport', city: 'Dayton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DBA: { name: 'Dalbandin Airport', city: 'Dalbandin', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  DBB: { name: 'El Alamein International Airport', city: 'Dabaa City', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  DBD: { name: 'Dhanbad Airport', city: 'Dhanbad', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DBM: { name: 'Debra Marcos Airport', city: 'Debre Marqos', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  DBN: { name: 'W H \'Bud\' Barron Airport', city: 'Dublin', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DBO: { name: 'Dubbo City Regional Airport', city: 'Dubbo', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  DBQ: { name: 'Dubuque Regional Airport', city: 'Dubuque IA', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DBT: { name: 'Debre Tabor Airport', city: 'Debre Tabor', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  DBV: { name: 'Dubrovnik Airport', city: 'Dubrovnik', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  DCA: { name: 'Ronald Reagan Washington National Airport', city: 'Washington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DCF: { name: 'Canefield Airport', city: 'Canefield', country: 'Dominica', timezone: 'America/Dominica', offset: -4 },
  DCI: { name: 'Decimomannu Air Base', city: 'Decimomannu', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  DCM: { name: 'Castres-Mazamet Airport', city: 'Castres', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  DCN: { name: 'RAAF Base Curtin', city: 'Derby', country: 'Australia', timezone: null, offset: 8 },
  DDC: { name: 'Dodge City Regional Airport', city: 'Dodge City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DEA: { name: 'Dera Ghazi Khan Airport', city: 'Dera Ghazi Khan', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  DEB: { name: 'Debrecen International Airport', city: 'Debrecen', country: 'Hungary', timezone: 'Europe/Budapest', offset: 1 },
  DEC: { name: 'Decatur Airport', city: 'Decatur', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DED: { name: 'Dehradun Airport', city: 'Dehra Dun', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DEE: { name: 'Cooma Hospital Helipad', city: 'Yuzhno-Kurilsk', country: 'Russia', timezone: 'Australia/Sydney', offset: 10 },
  DEL: { name: 'Indira Gandhi International Airport', city: 'Delhi', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DEM: { name: 'Dembidollo Airport', city: 'Dembidollo', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  DEN: { name: 'Denver International Airport', city: 'Denver', country: 'United States', timezone: 'America/Denver', offset: -7 },
  DES: { name: 'Desroches Airport', city: 'Desroches', country: 'Seychelles', timezone: 'Indian/Mahe', offset: 4 },
  DET: { name: 'Coleman A. Young Municipal Airport', city: 'Detroit', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DEZ: { name: 'Deir ez-Zor Airport', city: 'Deire Zor', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  DFW: { name: 'Dallas Fort Worth International Airport', city: 'Dallas-Fort Worth', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DGE: { name: 'Mudgee Airport', city: 'Mudgee', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  DGL: { name: 'Douglas Municipal Airport', city: 'Douglas', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  DGO: { name: 'General Guadalupe Victoria International Airport', city: 'Durango', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  DGP: { name: 'Daugavpils Intrenational Airport', city: 'Daugavpils', country: 'Latvia', timezone: null, offset: 0 },
  DGT: { name: 'Sibulan Airport', city: 'Dumaguete', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  DHA: { name: 'King Abdulaziz Air Base', city: 'Dhahran', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  DHI: { name: 'Dhangarhi Airport', city: 'Dhangarhi', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  DHM: { name: 'Kangra Airport', city: 'Kangra', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DHN: { name: 'Dothan Regional Airport', city: 'Dothan', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DHR: { name: 'De Kooy Airport', city: 'De Kooy', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  DHT: { name: 'Dalhart Municipal Airport', city: 'Dalhart', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DIB: { name: 'Dibrugarh Airport', city: 'Mohanbari', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DIE: { name: 'Arrachart Airport', city: 'Antsiranana', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  DIG: { name: 'Diqing Airport', city: 'Shangri-La', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DIJ: { name: 'Dijon-Bourgogne Airport', city: 'Dijon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  DIK: { name: 'Dickinson Theodore Roosevelt Regional Airport', city: 'Dickinson', country: 'United States', timezone: 'America/Denver', offset: -7 },
  DIL: { name: 'Presidente Nicolau Lobato International Airport', city: 'Dili', country: 'East Timor', timezone: 'Asia/Dili', offset: 9 },
  DIN: { name: 'Dien Bien Phu Airport', city: 'Dienbienphu', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  DIQ: { name: 'Brigadeiro Cabral Airport', city: 'Divinopolis', country: 'Brazil', timezone: null, offset: -3 },
  DIR: { name: 'Aba Tenna Dejazmach Yilma International Airport', city: 'Dire Dawa', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  DIS: { name: 'Ngot Nzoungou Airport', city: 'Loubomo', country: 'Congo (Brazzaville)', timezone: 'Africa/Brazzaville', offset: 1 },
  DIU: { name: 'Diu Airport', city: 'Diu', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DIY: { name: 'Diyarbakir Airport', city: 'Diyabakir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  DJB: { name: 'Sultan Thaha Airport', city: 'Jambi', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  DJE: { name: 'Djerba Zarzis International Airport', city: 'Djerba', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  DJG: { name: 'Djanet Inedbirene Airport', city: 'Djanet', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  DJJ: { name: 'Sentani Airport', city: 'Jayapura', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  DJO: { name: 'Daloa Airport', city: 'Daloa', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  DKI: { name: 'Dunk Island Airport', city: 'Dunk Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  DKK: { name: 'Chautauqua County-Dunkirk Airport', city: 'Dunkirk', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DKR: { name: 'Léopold Sédar Senghor International Airport', city: 'Dakar', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  DKS: { name: 'Dikson Airport', city: 'Dikson', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  DKX: { name: 'Knoxville Downtown Island Airport', city: 'Knoxville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DLA: { name: 'Douala International Airport', city: 'Douala', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  DLC: { name: 'Zhoushuizi Airport', city: 'Dalian', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DLE: { name: 'Dole-Tavaux Airport', city: 'Dole', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  DLF: { name: 'Laughlin Air Force Base', city: 'Del Rio', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DLG: { name: 'Dillingham Airport', city: 'Dillingham', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  DLH: { name: 'Duluth International Airport', city: 'Duluth', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DLI: { name: 'Lien Khuong Airport', city: 'Dalat', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  DLK: { name: 'Dulkaninna Airport', city: 'Dulkaninna', country: 'Australia', timezone: null, offset: 9 },
  DLL: { name: 'Baraboo Wisconsin Dells Airport', city: 'Baraboo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DLM: { name: 'Dalaman International Airport', city: 'Dalaman', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  DLU: { name: 'Dali Airport', city: 'Dali', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DLY: { name: 'Dillon\'s Bay Airport', city: 'Dillon\'s Bay', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  DLZ: { name: 'Dalanzadgad Airport', city: 'Dalanzadgad', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  DMA: { name: 'Davis Monthan Air Force Base', city: 'Tucson', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  DMB: { name: 'Taraz Airport', city: 'Dzhambul', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  DMD: { name: 'Doomadgee Airport', city: 'Doomadgee', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  DME: { name: 'Domodedovo International Airport', city: 'Moscow', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  DMK: { name: 'Don Mueang International Airport', city: 'Bangkok', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  DMM: { name: 'King Fahd International Airport', city: 'Dammam', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  DMN: { name: 'Deming Municipal Airport', city: 'Deming', country: 'United States', timezone: null, offset: 0 },
  DMT: { name: 'Diamantino Airport', city: 'Diamantino', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  DMU: { name: 'Dimapur Airport', city: 'Dimapur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  DNA: { name: 'Kadena Air Base', city: 'Kadena', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  DND: { name: 'Dundee Airport', city: 'Dundee', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  DNH: { name: 'Dunhuang Airport', city: 'Dunhuang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DNK: { name: 'Dnipropetrovsk International Airport', city: 'Dnepropetrovsk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  DNL: { name: 'Daniel Field', city: 'Augusta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DNN: { name: 'Dalton Municipal Airport', city: 'Dalton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DNP: { name: 'Tulsipur Airport', city: 'Dang', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  DNQ: { name: 'Deniliquin Airport', city: 'Deniliquin', country: 'Australia', timezone: null, offset: 0 },
  DNR: { name: 'Dinard-Pleurtuit-Saint-Malo Airport', city: 'Dinard', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  DNV: { name: 'Vermilion Regional Airport', city: 'Danville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DNZ: { name: 'Çardak Airport', city: 'Denizli', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  DOD: { name: 'Dodoma Airport', city: 'Dodoma', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  DOG: { name: 'Dongola Airport', city: 'Dongola', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  DOH: { name: 'Hamad International Airport', city: 'Doha', country: 'Qatar', timezone: 'Asia/Qatar', offset: 3 },
  DOK: { name: 'Donetsk International Airport', city: 'Donetsk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  DOL: { name: 'Deauville-Saint-Gatien Airport', city: 'Deauville', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  DOM: { name: 'Melville Hall Airport', city: 'Dominica', country: 'Dominica', timezone: 'America/Dominica', offset: -4 },
  DOP: { name: 'Dolpa Airport', city: 'Dolpa', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  DOU: { name: 'Dourados Airport', city: 'Dourados', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  DOV: { name: 'Dover Air Force Base', city: 'Dover', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DPA: { name: 'Dupage Airport', city: 'West Chicago', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DPL: { name: 'Dipolog Airport', city: 'Dipolog', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  DPO: { name: 'Devonport Airport', city: 'Devonport', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  DPS: { name: 'Ngurah Rai (Bali) International Airport', city: 'Denpasar', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  DQH: { name: 'Douglas Municipal Airport', city: 'Douglas', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DRA: { name: 'Desert Rock Airport', city: 'Mercury', country: 'United States', timezone: null, offset: 0 },
  DRB: { name: 'Derby Airport', city: 'Derby', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  DRG: { name: 'Deering Airport', city: 'Deering', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  DRI: { name: 'Beauregard Regional Airport', city: 'Deridder', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DRJ: { name: 'Drietabbetje Airport', city: 'Drietabbetje', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  DRK: { name: 'Drake Bay Airport', city: 'Puntarenas', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  DRM: { name: 'Drummond Island Airport', city: 'Drummond Island', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DRN: { name: 'Dirranbandi Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  DRO: { name: 'Durango La Plata County Airport', city: 'Durango', country: 'United States', timezone: 'America/Denver', offset: -7 },
  DRS: { name: 'Dresden Airport', city: 'Dresden', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  DRT: { name: 'Del Rio International Airport', city: 'Del Rio', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DRV: { name: 'Dharavandhoo Airport', city: 'DHARAVANDHOO', country: 'Maldives', timezone: null, offset: 5 },
  DRW: { name: 'Darwin International Airport', city: 'Darwin', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  DSA: { name: 'Robin Hood Doncaster Sheffield Airport', city: 'Doncaster, Sheffield', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  DSD: { name: 'La Désirade Airport', city: 'Grande Anse', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  DSE: { name: 'Combolcha Airport', city: 'Dessie', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  DSK: { name: 'Dera Ismael Khan Airport', city: 'Dera Ismael Khan', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  DSM: { name: 'Des Moines International Airport', city: 'Des Moines', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DSN: { name: 'Ordos Ejin Horo Airport', city: 'Dongsheng', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DSO: { name: 'Sondok Airport', city: 'Hamhung', country: 'North Korea', timezone: 'Asia/Pyongyang', offset: 8 },
  DTA: { name: 'Delta Municipal Airport', city: 'Delta', country: 'United States', timezone: 'America/Denver', offset: -7 },
  DTB: { name: 'Silangit Airport', city: 'Siborong-Borong', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  DTD: { name: 'Datadawai Airport', city: 'Datadawai-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  DTI: { name: 'Diamantina Airport', city: 'Diamantina', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  DTM: { name: 'Dortmund Airport', city: 'Dortmund', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  DTS: { name: 'Destin Executive Airport', city: 'Destin', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DTW: { name: 'Detroit Metropolitan Wayne County Airport', city: 'Detroit', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DU9: { name: 'Dunnville Airport', city: 'Dunnville', country: 'Canada', timezone: null, offset: -5 },
  DUB: { name: 'Dublin Airport', city: 'Dublin', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  DUC: { name: 'Halliburton Field', city: 'Duncan', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DUD: { name: 'Dunedin Airport', city: 'Dunedin', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  DUE: { name: 'Dundo Airport', city: 'Dundo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  DUG: { name: 'Bisbee Douglas International Airport', city: 'Douglas', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  DUH: { name: 'Oudtshoorn Airport', city: 'Oudtshoorn', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  DUJ: { name: 'DuBois Regional Airport', city: 'Du Bois', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DUM: { name: 'Pinang Kampai Airport', city: 'Dumai', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  DUR: { name: 'King Shaka International Airport', city: 'Durban', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  DUS: { name: 'Düsseldorf International Airport', city: 'Duesseldorf', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  DUT: { name: 'Unalaska Airport', city: 'Unalaska', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  DVL: { name: 'Devils Lake Regional Airport', city: 'Devils Lake', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DVO: { name: 'Francisco Bangoy International Airport', city: 'Davao', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  DVT: { name: 'Phoenix Deer Valley Airport', city: 'Phoenix ', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  DWA: { name: 'Yolo County Davis Woodland Winters Airport', city: 'Davis-Woodland-Winters', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  DWB: { name: 'Soalala Airport', city: 'Soalala', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  DWC: { name: 'Al Maktoum International Airport', city: 'Dubai', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  DWD: { name: 'Dawadmi Domestic Airport', city: 'Dawadmi', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  DWH: { name: 'David Wayne Hooks Memorial Airport', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DXB: { name: 'Dubai International Airport', city: 'Dubai', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  DXR: { name: 'Danbury Municipal Airport', city: 'Danbury', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DYA: { name: 'Dysart Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  DYG: { name: 'Dayong Airport', city: 'Dayong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  DYL: { name: 'Doylestown Airport', city: 'Doylestown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  DYR: { name: 'Ugolny Airport', city: 'Anadyr', country: 'Russia', timezone: 'Asia/Anadyr', offset: 12 },
  DYS: { name: 'Dyess Air Force Base', city: 'Abilene', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  DYU: { name: 'Dushanbe Airport', city: 'Dushanbe', country: 'Tajikistan', timezone: 'Asia/Dushanbe', offset: 5 },
  DZA: { name: 'Dzaoudzi Pamandzi International Airport', city: 'Dzaoudzi', country: 'Mayotte', timezone: 'Indian/Mayotte', offset: 3 },
  DZN: { name: 'Zhezkazgan Airport', city: 'Zhezkazgan', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  E25: { name: 'Wickenburg Municipal Airport', city: 'Wickenburg', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  E38: { name: 'Alpine Casparis Municipal Airport', city: 'Alpine', country: 'United States', timezone: null, offset: -5 },
  E63: { name: 'Gila Bend Municipal Airport', city: 'Gila Bend', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  EAA: { name: 'Eagle Airport', city: 'Eagle', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EAE: { name: 'Siwo Airport', city: 'Sangafa', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  EAM: { name: 'Nejran Airport', city: 'Nejran', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  EAS: { name: 'San Sebastian Airport', city: 'San Sebastian', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  EAT: { name: 'Pangborn Memorial Airport', city: 'Wenatchee', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  EAU: { name: 'Chippewa Valley Regional Airport', city: 'Eau Claire', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EBA: { name: 'Marina Di Campo Airport', city: 'Marina Di Campo', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  EBB: { name: 'Entebbe International Airport', city: 'Entebbe', country: 'Uganda', timezone: 'Africa/Kampala', offset: 3 },
  EBD: { name: 'El Obeid Airport', city: 'El Obeid', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  EBE: { name: 'Engels heliport', city: 'Ebenhofen', country: 'Germany', timezone: 'Europe/Brussels', offset: 1 },
  EBG: { name: 'El Bagre Airport', city: 'El Bagre', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  EBJ: { name: 'Esbjerg Airport', city: 'Esbjerg', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  EBL: { name: 'Erbil International Airport', city: 'Erbil', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  EBM: { name: 'El Borma Airport', city: 'El Borma', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  EBU: { name: 'Saint-Étienne-Bouthéon Airport', city: 'St-Etienne', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  ECA: { name: 'Iosco County Airport', city: 'East Tawas', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ECH: { name: 'Echuca Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  ECN: { name: 'Ercan International Airport', city: 'Nicosia', country: 'Cyprus', timezone: 'Asia/Nicosia', offset: 2 },
  ECP: { name: 'Northwest Florida Beaches International Airport', city: 'Panama City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ECV: { name: 'Cuatro Vientos Airport', city: 'Madrid', country: 'Spain', timezone: null, offset: 0 },
  EDF: { name: 'Elmendorf Air Force Base', city: 'Anchorage', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EDI: { name: 'Edinburgh Airport', city: 'Edinburgh', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  EDL: { name: 'Eldoret International Airport', city: 'Eldoret', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  EDM: { name: 'La Roche-sur-Yon Airport', city: 'La Roche-sur-yon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  EDN: { name: 'Enterprise Municipal Airport', city: 'Enterprise', country: 'United States', timezone: null, offset: -5 },
  EDO: { name: 'Balıkesir Körfez Airport', city: 'Balikesir Korfez', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  EDR: { name: 'Pormpuraaw Airport', city: 'Pormpuraaw', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  EDW: { name: 'Edwards Air Force Base', city: 'Edwards Afb', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  EED: { name: 'Needles Airport', city: 'Needles', country: 'United States', timezone: null, offset: 0 },
  EEK: { name: 'Eek Airport', city: 'Eek', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EEN: { name: 'Dillant Hopkins Airport', city: 'Keene', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EET: { name: 'Shelby County Airport', city: 'Alabaster', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EFD: { name: 'Ellington Airport', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EFL: { name: 'Kefallinia Airport', city: 'Keffallinia', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  EGC: { name: 'Bergerac-Roumanière Airport', city: 'Bergerac', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  EGE: { name: 'Eagle County Regional Airport', city: 'Vail', country: 'United States', timezone: 'America/Denver', offset: -7 },
  EGI: { name: 'Duke Field', city: 'Crestview', country: 'United States', timezone: null, offset: 0 },
  EGM: { name: 'Sege Airport', city: 'Sege', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  EGN: { name: 'Geneina Airport', city: 'Geneina', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  EGO: { name: 'Belgorod International Airport', city: 'Belgorod', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  EGR: { name: 'El Gora Airport', city: 'El Gorah', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  EGS: { name: 'Egilsstaðir Airport', city: 'Egilsstadir', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  EGT: { name: 'Wellington Municipal Airport', city: 'Wellington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EGV: { name: 'Eagle River Union Airport', city: 'Eagle River', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EGX: { name: 'Egegik Airport', city: 'Egegik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EHA: { name: 'Elkhart Morton County Airport', city: 'Elkhart', country: 'United States', timezone: null, offset: -5 },
  EHL: { name: 'El Bolson Airport', city: 'El Bolson', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  EHM: { name: 'Cape Newenham LRRS Airport', city: 'Cape Newenham', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EIE: { name: 'Yeniseysk Airport', city: 'Yeniseysk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  EIL: { name: 'Eielson Air Force Base', city: 'Fairbanks', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EIN: { name: 'Eindhoven Airport', city: 'Eindhoven', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  EIS: { name: 'Terrance B. Lettsome International Airport', city: 'Tortola', country: 'British Virgin Islands', timezone: 'America/Tortola', offset: -4 },
  EJA: { name: 'Yariguíes Airport', city: 'Barrancabermeja', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  EJH: { name: 'Al Wajh Domestic Airport', city: 'Wejh', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  EKA: { name: 'Murray Field', city: 'Eureka', country: 'United States', timezone: null, offset: 0 },
  EKI: { name: 'Elkhart Municipal Airport', city: 'Elkhart', country: 'United States', timezone: null, offset: -5 },
  EKN: { name: 'Elkins-Randolph Co-Jennings Randolph Field', city: 'Elkins', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EKO: { name: 'Elko Regional Airport', city: 'Elko', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  EKY: { name: 'Bessemer Airport', city: 'Bessemer', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ELC: { name: 'Elcho Island Airport', city: 'Elcho Island', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  ELD: { name: 'South Arkansas Regional At Goodwin Field', city: 'El Dorado', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ELF: { name: 'El Fasher Airport', city: 'El Fasher', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  ELG: { name: 'El Golea Airport', city: 'El Golea', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  ELH: { name: 'North Eleuthera Airport', city: 'North Eleuthera', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  ELI: { name: 'Elim Airport', city: 'Elim', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ELM: { name: 'Elmira Corning Regional Airport', city: 'Elmira', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ELP: { name: 'El Paso International Airport', city: 'El Paso', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ELQ: { name: 'Gassim Airport', city: 'Gassim', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  ELS: { name: 'Ben Schoeman Airport', city: 'East London', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  ELT: { name: 'El Tor Airport', city: 'El-tor', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  ELU: { name: 'Guemar Airport', city: 'Guemar', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  ELV: { name: 'Elfin Cove Seaplane Base', city: 'Elfin Cove', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ELY: { name: 'Ely Airport Yelland Field', city: 'Ely', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  EMA: { name: 'East Midlands Airport', city: 'East Midlands', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  EMD: { name: 'Emerald Airport', city: 'Emerald', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  EME: { name: 'Emden Airport', city: 'Emden', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  EMK: { name: 'Emmonak Airport', city: 'Emmonak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  EML: { name: 'Emmen Air Base', city: 'Emmen', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  EMN: { name: 'Néma Airport', city: 'Nema', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  EMP: { name: 'Emporia Municipal Airport', city: 'Kempten', country: 'Germany', timezone: 'America/Chicago', offset: -6 },
  EMT: { name: 'El Monte Airport', city: 'El Monte', country: 'United States', timezone: null, offset: -8 },
  EMV: { name: 'Emporia Greensville Regional Airport', city: 'Emporia', country: 'United States', timezone: null, offset: -5 },
  ENA: { name: 'Kenai Municipal Airport', city: 'Kenai', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ENC: { name: 'Nancy-Essey Airport', city: 'Nancy', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  END: { name: 'Vance Air Force Base', city: 'Enid', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ENE: { name: 'Ende (H Hasan Aroeboesman) Airport', city: 'Ende', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  ENF: { name: 'Enontekio Airport', city: 'Enontekio', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  ENH: { name: 'Enshi Airport', city: 'Enshi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ENK: { name: 'St Angelo Airport', city: 'Enniskillen', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ENN: { name: 'Nenana Municipal Airport', city: 'Nenana', country: 'United States', timezone: null, offset: 0 },
  ENS: { name: 'Twente Airfield', city: 'Enschede', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  ENT: { name: 'Eniwetok Airport', city: 'Eniwetok Atoll', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  ENU: { name: 'Akanu Ibiam International Airport', city: 'Enugu', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  ENV: { name: 'Wendover Airport', city: 'Wendover', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ENW: { name: 'Kenosha Regional Airport', city: 'Kenosha', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ENY: { name: 'Yan\'an Ershilipu Airport', city: 'Yan\'an', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  EOH: { name: 'Enrique Olaya Herrera Airport', city: 'Medellin', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  EOI: { name: 'Eday Airport', city: 'Eday', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  EOK: { name: 'Keokuk Municipal Airport', city: 'Keokuk', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EPL: { name: 'Épinal-Mirecourt Airport', city: 'Epinal', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  EPM: { name: 'Eastport Municipal Airport', city: 'Eastport', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EPR: { name: 'Esperance Airport', city: 'Esperance', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  EPS: { name: 'Arroyo Barril Airport', city: 'Samana', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  EPU: { name: 'Pärnu Airport', city: 'Parnu', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 2 },
  EQS: { name: 'Brigadier Antonio Parodi Airport', city: 'Esquel', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  ERC: { name: 'Erzincan Airport', city: 'Erzincan', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ERD: { name: 'Berdyansk Airport', city: 'Berdyansk', country: 'Ukraine', timezone: null, offset: 2 },
  ERF: { name: 'Erfurt Airport', city: 'Erfurt', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ERG: { name: 'Yerbogachen Airport', city: 'Yerbogachen', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  ERH: { name: 'Moulay Ali Cherif Airport', city: 'Er-rachidia', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  ERI: { name: 'Erie International Tom Ridge Field', city: 'Erie', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ERL: { name: 'Erenhot Saiwusu International Airport', city: 'Erenhot', country: 'China', timezone: null, offset: 8 },
  ERM: { name: 'Erechim Airport', city: 'Erechim', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  ERN: { name: 'Eirunepé Airport', city: 'Eirunepe', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  ERS: { name: 'Eros Airport', city: 'Windhoek', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  ERV: { name: 'Kerrville Municipal Louis Schreiner Field', city: 'Kerrville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ERY: { name: 'Luce County Airport', city: 'Newberry', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ERZ: { name: 'Erzurum International Airport', city: 'Erzurum', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ESB: { name: 'Esenboğa International Airport', city: 'Ankara', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ESC: { name: 'Delta County Airport', city: 'Escanaba', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ESD: { name: 'Orcas Island Airport', city: 'Eastsound', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ESE: { name: 'Ensenada Airport', city: 'Ensenada', country: 'Mexico', timezone: 'America/Tijuana', offset: -8 },
  ESF: { name: 'Esler Regional Airport', city: 'Alexandria', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ESH: { name: 'Shoreham Airport', city: 'Shoreham By Sea', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ESK: { name: 'Eskişehir Air Base', city: 'Eskisehir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ESL: { name: 'Elista Airport', city: 'Elista', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  ESM: { name: 'General Rivadeneira Airport', city: 'Esmeraldas', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  ESN: { name: 'Easton Newnam Field', city: 'Easton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ESR: { name: 'Ricardo García Posada Airport', city: 'El Salvador', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ESS: { name: 'Essen Mulheim Airport', city: 'Essen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ESU: { name: 'Mogador Airport', city: 'Essadouira', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  ESX: { name: 'Skå-Edeby Airport', city: 'Essen', country: 'Germany', timezone: 'Europe/Stockholm', offset: 1 },
  ETH: { name: 'Eilat Airport', city: 'Elat', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  ETR: { name: 'Santa Rosa International Airport', city: 'Santa Rosa', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  ETZ: { name: 'Metz-Nancy-Lorraine Airport', city: 'Metz', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  EUA: { name: 'Kaufana Airport', city: 'Eua Island', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  EUF: { name: 'Weedon Field', city: 'Eufala', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EUG: { name: 'Mahlon Sweet Field', city: 'Eugene', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  EUM: { name: 'Neumünster Airport', city: 'Neumuenster', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  EUN: { name: 'Hassan I Airport', city: 'El Aaiún', country: 'Western Sahara', timezone: 'Africa/El_Aaiun', offset: 0 },
  EUX: { name: 'F. D. Roosevelt Airport', city: 'Oranjestad', country: 'Netherlands Antilles', timezone: 'America/Curacao', offset: -4 },
  EVE: { name: 'Harstad/Narvik Airport, Evenes', city: 'Harstad/Narvik', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  EVG: { name: 'Sveg Airport', city: 'Sveg', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  EVN: { name: 'Zvartnots International Airport', city: 'Yerevan', country: 'Armenia', timezone: 'Asia/Yerevan', offset: 4 },
  EVV: { name: 'Evansville Regional Airport', city: 'Evansville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EVW: { name: 'Evanston-Uinta County Airport-Burns Field', city: 'Evanston', country: 'United States', timezone: 'America/Denver', offset: -7 },
  EWB: { name: 'New Bedford Regional Airport', city: 'New Bedford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EWD: { name: 'Wadi Al Dawasir Airport', city: 'Wadi-al-dawasir', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  EWK: { name: 'Newton City-County Airport', city: 'Newton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  EWN: { name: 'Coastal Carolina Regional Airport', city: 'New Bern', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EWR: { name: 'Newark Liberty International Airport', city: 'Newark', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EXT: { name: 'Exeter International Airport', city: 'Exeter', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  EYK: { name: 'Beloyarskiy Airport', city: 'Beloyarsky', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  EYP: { name: 'El Yopal Airport', city: 'Yopal', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  EYW: { name: 'Key West International Airport', city: 'Key West', country: 'United States', timezone: 'America/New_York', offset: -5 },
  EZE: { name: 'Ministro Pistarini International Airport', city: 'Buenos Aires', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  EZS: { name: 'Elazığ Airport', city: 'Elazig', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  FAA: { name: 'Faranah Airport', city: 'Faranah', country: 'Guinea', timezone: 'Africa/Conakry', offset: 0 },
  FAB: { name: 'Farnborough Airport', city: 'Farnborough', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  FAE: { name: 'Vagar Airport', city: 'Vagar', country: 'Faroe Islands', timezone: 'Atlantic/Faeroe', offset: 0 },
  FAF: { name: 'Felker Army Air Field', city: 'Fort Eustis', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FAH: { name: 'Farah Airport', city: 'Farah', country: 'Afghanistan', timezone: null, offset: 4 },
  FAI: { name: 'Fairbanks International Airport', city: 'Fairbanks', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  FAJ: { name: 'Diego Jimenez Torres Airport', city: 'Fajardo', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  FAN: { name: 'Lista Airport', city: 'Farsund', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  FAO: { name: 'Faro Airport', city: 'Faro', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  FAR: { name: 'Hector International Airport', city: 'Fargo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FAT: { name: 'Fresno Yosemite International Airport', city: 'Fresno', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  FAV: { name: 'Fakarava Airport', city: 'Fakarava', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  FAY: { name: 'Fayetteville Regional Grannis Field', city: 'Fayetteville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FBA: { name: 'Fonte Boa Airport', city: 'Fonte Boa', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  FBD: { name: 'Fayzabad Airport', city: 'Faizabad', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  FBE: { name: 'Francisco Beltrão Airport', city: 'Francisco Beltrao', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  FBG: { name: 'Simmons Army Air Field', city: 'Fredericksburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FBK: { name: 'Ladd AAF Airfield', city: 'Fort Wainwright', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  FBM: { name: 'Lubumbashi International Airport', city: 'Lubumashi', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  FBR: { name: 'Fort Bridger Airport', city: 'Fort Bridger', country: 'United States', timezone: 'America/Denver', offset: -7 },
  FBU: { name: 'Oslo, Fornebu Airport', city: 'Oslo', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  FCA: { name: 'Glacier Park International Airport', city: 'Kalispell', country: 'United States', timezone: 'America/Denver', offset: -7 },
  FCI: { name: 'Chesterfield County Airport', city: 'Richmond', country: 'United States', timezone: null, offset: -4 },
  FCM: { name: 'Flying Cloud Airport', city: 'Eden Prairie', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FCO: { name: 'Leonardo da Vinci–Fiumicino Airport', city: 'Rome', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  FCS: { name: 'Butts AAF (Fort Carson) Air Field', city: 'Fort Carson', country: 'United States', timezone: 'America/Denver', offset: -7 },
  FDF: { name: 'Martinique Aimé Césaire International Airport', city: 'Fort-de-france', country: 'Martinique', timezone: 'America/Martinique', offset: -4 },
  FDH: { name: 'Friedrichshafen Airport', city: 'Friedrichshafen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FDU: { name: 'Bandundu Airport', city: 'Bandoundu', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  FDW: { name: 'Fairfield County Airport', city: 'Winnsboro', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FDY: { name: 'Findlay Airport', city: 'Findley', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FEG: { name: 'Fergana International Airport', city: 'Fergana', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  FEL: { name: 'Fürstenfeldbruck Airport', city: 'Fuerstenfeldbruck', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FEN: { name: 'Fernando de Noronha Airport', city: 'Fernando Do Noronha', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  FET: { name: 'Fremont Municipal Airport', city: 'Fremont', country: 'United States', timezone: null, offset: -5 },
  FEZ: { name: 'Saïss Airport', city: 'Fes', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  FFA: { name: 'First Flight Airport', city: 'Kill Devil Hills', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FFC: { name: 'Peachtree City Falcon Field', city: 'Atlanta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FFD: { name: 'RAF Fairford', city: 'Fairford', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  FFO: { name: 'Wright-Patterson Air Force Base', city: 'Dayton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FFT: { name: 'Capital City Airport', city: 'Frankfort', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FFZ: { name: 'Falcon Field', city: 'Mesa', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  FGI: { name: 'Fagali\'i Airport', city: 'Apia', country: 'Samoa', timezone: 'Pacific/Apia', offset: 13 },
  FHU: { name: 'Sierra Vista Municipal Libby Army Air Field', city: 'Fort Huachuca', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  FIE: { name: 'Fair Isle Airport', city: 'Fair Isle', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  FIG: { name: 'Fria Airport', city: 'Fira', country: 'Guinea', timezone: 'Africa/Conakry', offset: 0 },
  FIH: { name: 'Ndjili International Airport', city: 'Kinshasa', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  FIT: { name: 'Fitchburg Municipal Airport', city: 'Fitchburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FIZ: { name: 'Fitzroy Crossing Airport', city: 'Fitzroy Crossing', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  FJR: { name: 'Fujairah International Airport', city: 'Fujeirah', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  FKB: { name: 'Karlsruhe Baden-Baden Airport', city: 'Karlsruhe/Baden-Baden', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FKI: { name: 'Bangoka International Airport', city: 'Kisangani', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  FKL: { name: 'Venango Regional Airport', city: 'Franklin', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FKQ: { name: 'Fakfak Airport', city: 'Fak Fak', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  FKS: { name: 'Fukushima Airport', city: 'Fukushima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  FLA: { name: 'Gustavo Artunduaga Paredes Airport', city: 'Florencia', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  FLB: { name: 'Cangapara Airport', city: 'Floriano', country: 'Brazil', timezone: null, offset: -3 },
  FLD: { name: 'Fond du Lac County Airport', city: 'Fond du Lac', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FLF: { name: 'Flensburg-Schäferhaus Airport', city: 'Flensburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FLG: { name: 'Flagstaff Pulliam Airport', city: 'Flagstaff', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  FLL: { name: 'Fort Lauderdale Hollywood International Airport', city: 'Fort Lauderdale', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FLN: { name: 'Hercílio Luz International Airport', city: 'Florianopolis', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  FLO: { name: 'Florence Regional Airport', city: 'Florence', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FLR: { name: 'Peretola Airport', city: 'Florence', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  FLS: { name: 'Flinders Island Airport', city: 'Flinders Island', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  FLV: { name: 'Sherman Army Air Field', city: 'Fort Leavenworth', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FLW: { name: 'Flores Airport', city: 'Flores', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  FMA: { name: 'Formosa Airport', city: 'Formosa', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  FME: { name: 'Tipton Airport', city: 'Fort Meade', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FMI: { name: 'Kalemie Airport', city: 'Kalemie', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  FMM: { name: 'Memmingen Allgau Airport', city: 'Memmingen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FMN: { name: 'Four Corners Regional Airport', city: 'Farmington', country: 'United States', timezone: 'America/Denver', offset: -7 },
  FMO: { name: 'Münster Osnabrück Airport', city: 'Munster', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FMY: { name: 'Page Field', city: 'Fort Myers', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FNA: { name: 'Lungi International Airport', city: 'Freetown', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  FNB: { name: 'Neubrandenburg Airport', city: 'Neubrandenburg', country: 'Germany', timezone: null, offset: 0 },
  FNC: { name: 'Madeira Airport', city: 'Funchal', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  FNI: { name: 'Nîmes-Arles-Camargue Airport', city: 'Nimes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  FNJ: { name: 'Pyongyang Sunan International Airport', city: 'Pyongyang', country: 'North Korea', timezone: 'Asia/Pyongyang', offset: 8 },
  FNL: { name: 'Fort Collins Loveland Municipal Airport', city: 'Fort Collins', country: 'United States', timezone: 'America/Denver', offset: -7 },
  FNR: { name: 'Funter Bay Seaplane Base', city: 'Funter Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  FNT: { name: 'Bishop International Airport', city: 'Flint', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FNU: { name: 'Oristano-Fenosu Airport', city: 'Oristano', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  FOC: { name: 'Fuzhou Changle International Airport', city: 'Fuzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  FOD: { name: 'Fort Dodge Regional Airport', city: 'Fort Dodge', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FOE: { name: 'Topeka Regional Airport - Forbes Field', city: 'Topeka', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FOG: { name: 'Foggia "Gino Lisa" Airport', city: 'Foggia', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  FOK: { name: 'Francis S Gabreski Airport', city: 'West Hampton Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FOM: { name: 'Foumban Nkounja Airport', city: 'Foumban', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  FON: { name: 'Arenal Airport', city: 'La Fortuna/San Carlos', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  FOR: { name: 'Pinto Martins International Airport', city: 'Fortaleza', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  FPO: { name: 'Grand Bahama International Airport', city: 'Freeport', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  FRA: { name: 'Frankfurt am Main International Airport', city: 'Frankfurt', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  FRB: { name: 'Forbes Airport', city: 'Forbes', country: 'Australia', timezone: null, offset: 0 },
  FRC: { name: 'Franca Airport', city: 'Franca', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  FRD: { name: 'Friday Harbor Airport', city: 'Friday Harbor', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  FRE: { name: 'Fera/Maringe Airport', city: 'Fera Island', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  FRI: { name: 'Marshall Army Air Field', city: 'Fort Riley', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FRJ: { name: 'Fréjus Airport', city: 'Frejus', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  FRL: { name: 'Forlì Airport', city: 'Forli', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  FRN: { name: 'Bryant Army Heliport', city: 'Fort Richardson', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  FRO: { name: 'Florø Airport', city: 'Floro', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  FRP: { name: 'St Lucie County International Airport', city: 'Fort Pierce', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FRS: { name: 'Mundo Maya International Airport', city: 'Flores', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  FRU: { name: 'Manas International Airport', city: 'Bishkek', country: 'Kyrgyzstan', timezone: 'Asia/Bishkek', offset: 6 },
  FRW: { name: 'Francistown Airport', city: 'Francistown', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  FSC: { name: 'Figari Sud-Corse Airport', city: 'Figari', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  FSD: { name: 'Joe Foss Field Airport', city: 'Sioux Falls', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FSI: { name: 'Henry Post Army Air Field (Fort Sill)', city: 'Fort Sill', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FSM: { name: 'Fort Smith Regional Airport', city: 'Fort Smith', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FSP: { name: 'St Pierre Airport', city: 'St.-pierre', country: 'Saint Pierre and Miquelon', timezone: 'America/Miquelon', offset: -3 },
  FSS: { name: 'RAF Kinloss', city: 'Kinloss', country: 'United Kingdom', timezone: null, offset: 0 },
  FST: { name: 'Fort Stockton Pecos County Airport', city: 'Fort Stockton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FTA: { name: 'Futuna Airport', city: 'Futuna Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  FTE: { name: 'El Calafate Airport', city: 'El Calafate', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  FTG: { name: 'Front Range Airport', city: 'Denver', country: 'United States', timezone: null, offset: -6 },
  FTI: { name: 'Fitiuta Airport', city: 'Fiti\'uta', country: 'American Samoa', timezone: 'Pacific/Pago_Pago', offset: -11 },
  FTK: { name: 'Godman Army Air Field', city: 'Fort Knox', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FTU: { name: 'Tôlanaro Airport', city: 'Tolagnaro', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  FTW: { name: 'Fort Worth Meacham International Airport', city: 'Fort Worth', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FTX: { name: 'Owando Airport', city: 'Owando', country: 'Congo (Kinshasa)', timezone: 'Africa/Brazzaville', offset: 1 },
  FTY: { name: 'Fulton County Airport Brown Field', city: 'Atlanta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FUE: { name: 'Fuerteventura Airport', city: 'Fuerteventura', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  FUG: { name: 'Fuyang Xiguan Airport', city: 'Fuyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  FUJ: { name: 'Fukue Airport', city: 'Fukue', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  FUK: { name: 'Fukuoka Airport', city: 'Fukuoka', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  FUL: { name: 'Fullerton Municipal Airport', city: 'Fullerton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  FUN: { name: 'Funafuti International Airport', city: 'Funafuti', country: 'Tuvalu', timezone: 'Pacific/Funafuti', offset: 12 },
  FUT: { name: 'Pointe Vele Airport', city: 'Futuna Island', country: 'Wallis and Futuna', timezone: 'Pacific/Wallis', offset: 12 },
  FWA: { name: 'Fort Wayne International Airport', city: 'Fort Wayne', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FXE: { name: 'Fort Lauderdale Executive Airport', city: 'Fort Lauderdale', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FXO: { name: 'Cuamba Airport', city: 'Cuamba', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  FYJ: { name: 'Dongji Aiport', city: 'Fuyuan', country: 'China', timezone: null, offset: 8 },
  FYT: { name: 'Faya Largeau Airport', city: 'Faya-largeau', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  FYU: { name: 'Fort Yukon Airport', city: 'Fort Yukon', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  FYV: { name: 'Drake Field', city: 'Fayetteville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  FZG: { name: 'Fitzgerald Municipal Airport', city: 'Fitzgerald', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FZI: { name: 'Fostoria Metropolitan Airport', city: 'Fostoria', country: 'United States', timezone: 'America/New_York', offset: -5 },
  FZO: { name: 'Bristol Filton Airport', city: 'Bristol', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  GAD: { name: 'Northeast Alabama Regional Airport', city: 'Gadsden', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GAE: { name: 'Gabès Matmata International Airport', city: 'Gabes', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  GAF: { name: 'Gafsa Ksar International Airport', city: 'Gafsa', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  GAH: { name: 'Gayndah Airport', city: 'Gayndah', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  GAI: { name: 'Montgomery County Airpark', city: 'Gaithersburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GAJ: { name: 'Yamagata Airport', city: 'Yamagata', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  GAL: { name: 'Edward G. Pitka Sr Airport', city: 'Galena', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  GAM: { name: 'Gambell Airport', city: 'Gambell', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  GAN: { name: 'Gan International Airport', city: 'Gan Island', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  GAO: { name: 'Mariana Grajales Airport', city: 'Guantanamo', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  GAQ: { name: 'Gao Airport', city: 'Gao', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  GAS: { name: 'Garissa Airport', city: 'Garissa', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  GAU: { name: 'Lokpriya Gopinath Bordoloi International Airport', city: 'Guwahati', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  GAY: { name: 'Gaya Airport', city: 'Gaya', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  GBB: { name: 'Gabala International Airport', city: 'Qabala', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  GBE: { name: 'Sir Seretse Khama International Airport', city: 'Gaberone', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  GBG: { name: 'Galesburg Municipal Airport', city: 'Galesburg', country: 'United States', timezone: null, offset: -5 },
  GBJ: { name: 'Les Bases Airport', city: 'Grand Bourg', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  GBK: { name: 'Gbangbatok Airport', city: 'Gbangbatok', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  GBN: { name: 'Great Bend Municipal Airport', city: 'Great Bend', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GBT: { name: 'Gorgan Airport', city: 'Gorgan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  GBZ: { name: 'Great Barrier Aerodrome', city: 'Claris', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  GCC: { name: 'Gillette Campbell County Airport', city: 'Gillette', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GCI: { name: 'Guernsey Airport', city: 'Guernsey', country: 'Guernsey', timezone: 'Europe/Guernsey', offset: 0 },
  GCJ: { name: 'Grand Central Airport', city: 'Johannesburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  GCK: { name: 'Garden City Regional Airport', city: 'Garden City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GCM: { name: 'Owen Roberts International Airport', city: 'Georgetown', country: 'Cayman Islands', timezone: 'America/Cayman', offset: -5 },
  GCN: { name: 'Grand Canyon National Park Airport', city: 'Grand Canyon', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  GDE: { name: 'Gode Airport', city: 'Gode', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  GDL: { name: 'Don Miguel Hidalgo Y Costilla International Airport', city: 'Guadalajara', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  GDN: { name: 'Gdańsk Lech Wałęsa Airport', city: 'Gdansk', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  GDQ: { name: 'Gonder Airport', city: 'Gondar', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  GDT: { name: 'JAGS McCartney International Airport', city: 'Cockburn Town', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  GDV: { name: 'Dawson Community Airport', city: 'Glendive', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GDW: { name: 'Gladwin Zettel Memorial Airport', city: 'Gladwin', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GDX: { name: 'Sokol Airport', city: 'Magadan', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  GDZ: { name: 'Gelendzhik Airport', city: 'Gelendzhik', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  GEA: { name: 'Nouméa Magenta Airport', city: 'Noumea', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  GED: { name: 'Sussex County Airport', city: 'Georgetown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GEG: { name: 'Spokane International Airport', city: 'Spokane', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  GEL: { name: 'Santo Ângelo Airport', city: 'Santo Angelo', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GEO: { name: 'Cheddi Jagan International Airport', city: 'Georgetown', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  GER: { name: 'Rafael Cabrera Airport', city: 'Nueva Gerona', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  GES: { name: 'General Santos International Airport', city: 'Romblon', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  GET: { name: 'Geraldton Airport', city: 'Geraldton', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  GEU: { name: 'Glendale Municipal Airport', city: 'Glendale', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  GEV: { name: 'Gällivare Airport', city: 'Gallivare', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  GEX: { name: 'Geelong Airport', city: 'Geelong', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  GFF: { name: 'Griffith Airport', city: 'Griffith', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  GFK: { name: 'Grand Forks International Airport', city: 'Grand Forks', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GFL: { name: 'Floyd Bennett Memorial Airport', city: 'Queensbury', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GFN: { name: 'Grafton Airport', city: 'Grafton', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  GFO: { name: 'Bartica A Airport', city: 'Bartica', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  GFR: { name: 'Granville Airport', city: 'Granville', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  GFY: { name: 'Grootfontein Airport', city: 'Grootfontein', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  GGE: { name: 'Georgetown County Airport', city: 'Georgetown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GGG: { name: 'East Texas Regional Airport', city: 'Longview', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GGS: { name: 'Gobernador Gregores Airport', city: 'Gobernador Gregores', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  GGT: { name: 'Exuma International Airport', city: 'Great Exuma', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  GGW: { name: 'Wokal Field Glasgow International Airport', city: 'Glasgow', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GHA: { name: 'Noumérat - Moufdi Zakaria Airport', city: 'Ghardaia', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  GHB: { name: 'Governor\'s Harbour Airport', city: 'Governor\'s Harbor', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  GHF: { name: '[Duplicate] Giebelstadt Army Air Field', city: 'Giebelstadt', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  GHG: { name: 'Marshfield Municipal George Harlow Field', city: 'Marshfield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GHT: { name: 'Ghat Airport', city: 'Ghat', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  GHU: { name: 'Gualeguaychu Airport', city: 'Gualeguaychu', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  GIB: { name: 'Gibraltar Airport', city: 'Gibraltar', country: 'Gibraltar', timezone: 'Europe/Gibraltar', offset: 1 },
  GIC: { name: 'Boigu Airport', city: 'Boigu', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  GIF: { name: 'Winter Haven Municipal Airport - Gilbert Field', city: 'Winter Haven', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GIG: { name: 'Rio Galeão – Tom Jobim International Airport', city: 'Rio De Janeiro', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GIL: { name: 'Gilgit Airport', city: 'Gilgit', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  GIS: { name: 'Gisborne Airport', city: 'Gisborne', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  GIU: { name: 'Sigiriya Air Force Base', city: 'Sigiriya', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  GIZ: { name: 'Jizan Regional Airport', city: 'Gizan', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  GJA: { name: 'La Laguna Airport', city: 'Guanaja', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  GJL: { name: 'Jijel Ferhat Abbas Airport', city: 'Jijel', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  GJR: { name: 'Gjögur Airport', city: 'Gjogur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  GJT: { name: 'Grand Junction Regional Airport', city: 'Grand Junction', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GKA: { name: 'Goroka Airport', city: 'Goroka', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  GKE: { name: 'Geilenkirchen Air Base', city: 'Geilenkirchen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  GKK: { name: 'Kooddoo Airport', city: 'Kooddoo', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  GKL: { name: 'Great Keppel Is Airport', city: 'Great Keppel Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  GKN: { name: 'Gulkana Airport', city: 'Gulkana', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  GKY: { name: 'Arlington Municipal Airport', city: 'Arlington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GLA: { name: 'Glasgow International Airport', city: 'Glasgow', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  GLD: { name: 'Renner Field-Goodland Municipal Airport', city: 'Goodland', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GLF: { name: 'Golfito Airport', city: 'Golfito', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  GLH: { name: 'Mid Delta Regional Airport', city: 'Greenville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GLI: { name: 'Glen Innes Airport', city: 'Glen Innes', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  GLJ: { name: 'La Jagua Airport', city: 'Garzón', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  GLK: { name: 'Galcaio Airport', city: 'Galcaio', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  GLO: { name: 'Gloucestershire Airport', city: 'Golouchestershire', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  GLS: { name: 'Scholes International At Galveston Airport', city: 'Galveston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GLT: { name: 'Gladstone Airport', city: 'Gladstone', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  GLU: { name: 'Gelephu Airport', city: 'Gelephu', country: 'Bhutan', timezone: null, offset: 6 },
  GLV: { name: 'Golovin Airport', city: 'Golovin', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  GMA: { name: 'Gemena Airport', city: 'Gemena', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  GMB: { name: 'Gambella Airport', city: 'Gambella', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  GME: { name: 'Gomel Airport', city: 'Gomel', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  GMJ: { name: 'Grove Municipal Airport', city: 'Grove', country: 'United States', timezone: null, offset: -5 },
  GML: { name: 'Gostomel Airport', city: 'Kiev', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  GMP: { name: 'Gimpo International Airport', city: 'Seoul', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  GMR: { name: 'Totegegie Airport', city: 'Totegegie', country: 'French Polynesia', timezone: 'Pacific/Gambier', offset: -9 },
  GMU: { name: 'Greenville Downtown Airport', city: 'Greenville', country: 'United States', timezone: null, offset: -4 },
  GMZ: { name: 'La Gomera Airport', city: 'La Gomera', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  GNA: { name: 'Hrodna Airport', city: 'Hrodna', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  GNB: { name: 'Grenoble-Isère Airport', city: 'Grenoble', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  GND: { name: 'Point Salines International Airport', city: 'Point Salines', country: 'Grenada', timezone: 'America/Grenada', offset: -4 },
  GNI: { name: 'Lyudao Airport', city: 'Green Island', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  GNM: { name: 'Guanambi Airport', city: 'Guanambi', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  GNS: { name: 'Binaka Airport', city: 'Gunung Sitoli', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  GNT: { name: 'Grants-Milan Municipal Airport', city: 'Grants', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GNV: { name: 'Gainesville Regional Airport', city: 'Gainesville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GNY: { name: 'Şanlıurfa GAP Airport', city: 'Sanliurfa', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  GNZ: { name: 'Ghanzi Airport', city: 'Ghanzi', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  GOA: { name: 'Genoa Cristoforo Colombo Airport', city: 'Genoa', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  GOB: { name: 'Robe Airport', city: 'Goba', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  GOH: { name: 'Godthaab / Nuuk Airport', city: 'Godthaab', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  GOI: { name: 'Dabolim Airport', city: 'Goa', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  GOJ: { name: 'Nizhny Novgorod Strigino International Airport', city: 'Nizhniy Novgorod', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  GOM: { name: 'Goma International Airport', city: 'Goma', country: 'Congo (Kinshasa)', timezone: 'Africa/Kigali', offset: 2 },
  GON: { name: 'Groton New London Airport', city: 'Groton CT', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GOP: { name: 'Gorakhpur Airport', city: 'Gorakhpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  GOQ: { name: 'Golmud Airport', city: 'Golmud', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  GOR: { name: 'Gore Airport', city: 'Gore', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  GOT: { name: 'Gothenburg-Landvetter Airport', city: 'Gothenborg', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  GOU: { name: 'Garoua International Airport', city: 'Garoua', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  GOV: { name: 'Gove Airport', city: 'Gove', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  GOY: { name: 'Ampara Airport', city: 'Galoya', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  GOZ: { name: 'Gorna Oryahovitsa Airport', city: 'Gorna Orechovica', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 2 },
  GPA: { name: 'Araxos Airport', city: 'Patras', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  GPB: { name: 'Tancredo Thomas de Faria Airport', city: 'Guarapuava', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GPI: { name: 'Juan Casiano Airport', city: 'Guapi', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  GPN: { name: 'Garden Point Airport', city: 'Pirlangimpi', country: 'Australia', timezone: null, offset: 9 },
  GPS: { name: 'Seymour Airport', city: 'Galapagos', country: 'Ecuador', timezone: 'Pacific/Galapagos', offset: -6 },
  GPT: { name: 'Gulfport Biloxi International Airport', city: 'Gulfport', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GPZ: { name: 'Grand Rapids Itasca Co-Gordon Newstrom field', city: 'Grand Rapids MN', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GQQ: { name: 'Galion Municipal Airport', city: 'Galion', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GRB: { name: 'Austin Straubel International Airport', city: 'Green Bay', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GRF: { name: 'Gray Army Air Field', city: 'Fort Lewis', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  GRI: { name: 'Central Nebraska Regional Airport', city: 'Grand Island', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GRJ: { name: 'George Airport', city: 'George', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  GRK: { name: 'Robert Gray  Army Air Field Airport', city: 'Killeen', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GRM: { name: 'Grand Marais Cook County Airport', city: 'Grand Marais', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GRO: { name: 'Girona Airport', city: 'Gerona', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  GRP: { name: 'Gurupi Airport', city: 'Gurupi', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  GRQ: { name: 'Eelde Airport', city: 'Groningen', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  GRR: { name: 'Gerald R. Ford International Airport', city: 'Grand Rapids', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GRS: { name: 'Grosseto Air Base', city: 'Grosseto', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  GRU: { name: 'Guarulhos - Governador André Franco Montoro International Airport', city: 'Sao Paulo', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GRV: { name: 'Khankala Air Base', city: 'Grozny', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  GRW: { name: 'Graciosa Airport', city: 'Graciosa Island', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  GRX: { name: 'Federico Garcia Lorca Airport', city: 'Granada', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  GRY: { name: 'Grímsey Airport', city: 'Grímsey', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  GRZ: { name: 'Graz Airport', city: 'Graz', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  GSB: { name: 'Seymour Johnson Air Force Base', city: 'Goldsboro', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GSE: { name: 'Gothenburg City Airport', city: 'Gothenborg', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  GSO: { name: 'Piedmont Triad International Airport', city: 'Greensboro', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GSP: { name: 'Greenville Spartanburg International Airport', city: 'Greenville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GSQ: { name: 'Shark El Oweinat International Airport', city: 'Sharq Al-Owainat', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  GST: { name: 'Gustavus Airport', city: 'Gustavus', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  GTB: { name: 'Wheeler Sack Army Air Field', city: 'Fort Drum', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GTE: { name: 'Groote Eylandt Airport', city: 'Groote Eylandt', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  GTF: { name: 'Great Falls International Airport', city: 'Great Falls', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GTI: { name: 'Rügen Airport', city: 'Ruegen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  GTO: { name: 'Jalaluddin Airport', city: 'Gorontalo', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  GTR: { name: 'Golden Triangle Regional Airport', city: 'Columbus Mississippi', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GTU: { name: 'Georgetown Municipal Airport', city: 'Georgetown', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GUA: { name: 'La Aurora Airport', city: 'Guatemala City', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  GUB: { name: 'Guerrero Negro Airport', city: 'Guerrero Negro', country: 'Mexico', timezone: 'America/Tijuana', offset: -8 },
  GUC: { name: 'Gunnison Crested Butte Regional Airport', city: 'Gunnison', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GUH: { name: 'Gunnedah Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  GUI: { name: 'Guiria Airport', city: 'Guiria', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  GUL: { name: 'Goulburn Airport', city: 'Goulburn', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  GUM: { name: 'Antonio B. Won Pat International Airport', city: 'Agana', country: 'Guam', timezone: 'Pacific/Guam', offset: 10 },
  GUP: { name: 'Gallup Municipal Airport', city: 'Gallup', country: 'United States', timezone: 'America/Denver', offset: -7 },
  GUQ: { name: 'Guanare Airport', city: 'Guanare', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  GUR: { name: 'Gurney Airport', city: 'Gurney', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  GUS: { name: 'Grissom Air Reserve Base', city: 'Peru', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GUT: { name: 'Gütersloh Air Base', city: 'Guetersloh', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  GUW: { name: 'Atyrau Airport', city: 'Atyrau', country: 'Kazakhstan', timezone: 'Asia/Oral', offset: 5 },
  GUY: { name: 'Guymon Municipal Airport', city: 'Guymon', country: 'United States', timezone: null, offset: -5 },
  GUZ: { name: 'Guarapari Airport', city: 'Guarapari', country: 'Brazil', timezone: null, offset: -3 },
  GVA: { name: 'Geneva Cointrin International Airport', city: 'Geneva', country: 'Switzerland', timezone: 'Europe/Paris', offset: 1 },
  GVL: { name: 'Lee Gilmer Memorial Airport', city: 'Gainesville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GVN: { name: 'Maygatka Airport.', city: 'Sovetskaya Gavan', country: 'Russia', timezone: null, offset: 0 },
  GVQ: { name: 'Genesee County Airport', city: 'Batavia', country: 'United States', timezone: 'America/New_York', offset: -5 },
  GVR: { name: 'Coronel Altino Machado de Oliveira Airport', city: 'Governador Valadares', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GVT: { name: 'Majors Airport', city: 'Greenvile', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GVX: { name: 'Gävle Sandviken Airport', city: 'Gavle', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  GWD: { name: 'Gwadar International Airport', city: 'Gwadar', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  GWE: { name: 'Thornhill Air Base', city: 'Gwert', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  GWL: { name: 'Gwalior Airport', city: 'Gwalior', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  GWO: { name: 'Greenwood–Leflore Airport', city: 'Greenwood', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GWT: { name: 'Westerland Sylt Airport', city: 'Westerland', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  GWY: { name: 'Galway Airport', city: 'Galway', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  GXF: { name: 'Sayun International Airport', city: 'Sayun Intl', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  GXG: { name: 'Negage Airport', city: 'Negage', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  GXH: { name: 'Gannan Xiahe Airport', city: 'Xiahe city', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  GXQ: { name: 'Teniente Vidal Airport', city: 'Coyhaique', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  GXY: { name: 'Greeley–Weld County Airport', city: 'Greeley', country: 'United States', timezone: null, offset: -7 },
  GYA: { name: 'Capitán de Av. Emilio Beltrán Airport', city: 'Guayaramerín', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  GYD: { name: 'Heydar Aliyev International Airport', city: 'Baku', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  GYE: { name: 'José Joaquín de Olmedo International Airport', city: 'Guayaquil', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  GYH: { name: 'Donaldson Center Airport', city: 'Greenville', country: 'United States', timezone: null, offset: -4 },
  GYI: { name: 'Gisenyi Airport', city: 'Gisenyi', country: 'Rwanda', timezone: 'Africa/Kigali', offset: 2 },
  GYL: { name: 'Argyle Airport', city: 'Argyle', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  GYM: { name: 'General José María Yáñez International Airport', city: 'Guaymas', country: 'Mexico', timezone: 'America/Hermosillo', offset: -7 },
  GYN: { name: 'Santa Genoveva Airport', city: 'Goiania', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  GYS: { name: 'Guangyuan Airport', city: 'Guangyuan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  GYU: { name: 'Guyuan Liupanshan Airport', city: 'Guyuan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  GYY: { name: 'Gary Chicago International Airport', city: 'Gary', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  GZA: { name: 'Yasser Arafat International Airport', city: 'Gaza', country: 'Palestine', timezone: 'Asia/Gaza', offset: 2 },
  GZM: { name: 'Xewkija Heliport', city: 'Gozo', country: 'Malta', timezone: 'Europe/Malta', offset: 1 },
  GZO: { name: 'Nusatupe Airport', city: 'Gizo', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  GZP: { name: 'Gazipaşa Airport', city: 'Alanya', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  GZT: { name: 'Gaziantep International Airport', city: 'Gaziantep', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  HAA: { name: 'Hasvik Airport', city: 'Hasvik', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HAC: { name: 'Hachijojima Airport', city: 'Hachijojima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HAD: { name: 'Halmstad Airport', city: 'Halmstad', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  HAF: { name: 'Half Moon Bay Airport', city: 'Half Moon Bay', country: 'United States', timezone: null, offset: -8 },
  HAH: { name: 'Prince Said Ibrahim International Airport', city: 'Moroni', country: 'Comoros', timezone: 'Indian/Comoro', offset: 3 },
  HAJ: { name: 'Hannover Airport', city: 'Hannover', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HAK: { name: 'Haikou Meilan International Airport', city: 'Haikou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HAM: { name: 'Hamburg Airport', city: 'Hamburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HAN: { name: 'Noi Bai International Airport', city: 'Hanoi', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  HAQ: { name: 'Hanimaadhoo Airport', city: 'Haa Dhaalu Atoll', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  HAS: { name: 'Ha\'il Airport', city: 'Hail', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  HAU: { name: 'Haugesund Airport', city: 'Haugesund', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HAV: { name: 'José Martí International Airport', city: 'Havana', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  HBA: { name: 'Hobart International Airport', city: 'Hobart', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  HBE: { name: 'Borg El Arab International Airport', city: 'Alexandria', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  HBG: { name: 'Hattiesburg Bobby L Chain Municipal Airport', city: 'Hattiesburg', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HBI: { name: 'Asheboro Regional Airport', city: 'Asheboro', country: 'United States', timezone: null, offset: -5 },
  HBR: { name: 'Hobart Regional Airport', city: 'Hobart', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HBT: { name: 'King Khaled Military City Airport', city: 'King Khalid Mil.city', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  HBX: { name: 'Hubli Airport', city: 'Hubli', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  HCJ: { name: 'Hechi Jinchengjiang Airport', city: 'Hechi', country: 'China', timezone: null, offset: 8 },
  HCN: { name: 'Hengchun Airport', city: 'Hengchun', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  HCQ: { name: 'Halls Creek Airport', city: 'Halls Creek', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  HCR: { name: 'Holy Cross Airport', city: 'Holy Cross', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HDD: { name: 'Hyderabad Airport', city: 'Hyderabad', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  HDE: { name: 'Brewster Field', city: 'Holdredge', country: 'United States', timezone: null, offset: -5 },
  HDF: { name: 'Heringsdorf Airport', city: 'Heringsdorf', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HDG: { name: 'Handan Airport', city: 'Handan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HDH: { name: 'Dillingham Airfield', city: 'Dillingham', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  HDI: { name: 'Hardwick Field', city: 'Cleveland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HDM: { name: 'Hamadan Airport', city: 'Hamadan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  HDN: { name: 'Yampa Valley Airport', city: 'Hayden', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HDO: { name: 'South Texas Regional Airport at Hondo', city: 'Hondo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HDS: { name: 'Hoedspruit Air Force Base Airport', city: 'Hoedspruit', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  HDY: { name: 'Hat Yai International Airport', city: 'Hat Yai', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  HEA: { name: 'Herat Airport', city: 'Herat', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  HEH: { name: 'Heho Airport', city: 'Heho', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  HEI: { name: 'Heide-Büsum Airport', city: 'Büsum', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HEK: { name: 'Heihe Airport', city: 'Heihe', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HEL: { name: 'Helsinki Vantaa Airport', city: 'Helsinki', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  HEM: { name: 'Helsinki Malmi Airport', city: 'Helsinki', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  HEN: { name: 'Hernesaari Heliport', city: 'Helsinki', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  HER: { name: 'Heraklion International Nikos Kazantzakis Airport', city: 'Heraklion', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  HET: { name: 'Baita International Airport', city: 'Hohhot', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HEW: { name: 'Athen Helenikon Airport', city: 'Athens', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  HEX: { name: 'Herrera Airport', city: 'Santo Domingo', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  HFA: { name: 'Haifa International Airport', city: 'Haifa', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  HFD: { name: 'Hartford Brainard Airport', city: 'Hartford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HFE: { name: 'Hefei Luogang International Airport', city: 'Hefei', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HFN: { name: 'Hornafjörður Airport', city: 'Hofn', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  HFS: { name: 'Hagfors Airport', city: 'Hagfors', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  HFT: { name: 'Hammerfest Airport', city: 'Hammerfest', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HGA: { name: 'Egal International Airport', city: 'Hargeisa', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  HGD: { name: 'Hughenden Airport', city: 'Hughenden', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  HGH: { name: 'Hangzhou Xiaoshan International Airport', city: 'Hangzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HGL: { name: 'Helgoland-Düne Airport', city: 'Helgoland', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HGN: { name: 'Mae Hong Son Airport', city: 'Mae Hong Son', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  HGO: { name: 'Korhogo Airport', city: 'Korhogo', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  HGR: { name: 'Hagerstown Regional Richard A Henson Field', city: 'Hagerstown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HGS: { name: 'Hastings Airport', city: 'Freetown', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  HGU: { name: 'Mount Hagen Kagamuga Airport', city: 'Mount Hagen', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  HHH: { name: 'Hilton Head Airport', city: 'Hilton Head Island', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HHI: { name: 'Wheeler Army Airfield', city: 'Wahiawa', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  HHN: { name: 'Frankfurt-Hahn Airport', city: 'Hahn', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HHQ: { name: 'Hua Hin Airport', city: 'Prachuap Khiri Khan', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  HHR: { name: 'Jack Northrop Field Hawthorne Municipal Airport', city: 'Hawthorne', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  HIB: { name: 'Range Regional Airport', city: 'Hibbing', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HID: { name: 'Horn Island Airport', city: 'Horn Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  HIF: { name: 'Hill Air Force Base', city: 'Ogden', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HII: { name: 'Lake Havasu City Airport', city: 'Lake Havasu City', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  HIJ: { name: 'Hiroshima Airport', city: 'Hiroshima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HIM: { name: 'Hingurakgoda Air Force Base', city: 'Polonnaruwa Town', country: 'Sri Lanka', timezone: null, offset: 0 },
  HIN: { name: 'Sacheon Air Base', city: 'Sacheon', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  HIO: { name: 'Portland Hillsboro Airport', city: 'Hillsboro', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  HIR: { name: 'Honiara International Airport', city: 'Honiara', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  HIS: { name: 'Hayman Island Heliport', city: 'Hayman Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  HIW: { name: 'Hiroshimanishi Airport', city: 'Hiroshima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HJJ: { name: 'Zhijiang Airport', city: 'Zhijiang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HJR: { name: 'Khajuraho Airport', city: 'Khajuraho', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  HKB: { name: 'Healy River Airport', city: 'Healy', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HKD: { name: 'Hakodate Airport', city: 'Hakodate', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HKG: { name: 'Chek Lap Kok International Airport', city: 'Hong Kong', country: 'Hong Kong', timezone: 'Asia/Hong_Kong', offset: 8 },
  HKK: { name: 'Hokitika Airfield', city: 'Hokitika', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  HKN: { name: 'Kimbe Airport', city: 'Hoskins', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  HKT: { name: 'Phuket International Airport', city: 'Phuket', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  HKY: { name: 'Hickory Regional Airport', city: 'Hickory', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HLA: { name: 'Lanseria Airport', city: 'Johannesburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  HLD: { name: 'Dongshan Airport', city: 'Hailar', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HLF: { name: 'Hultsfred Airport', city: 'Hultsfred', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  HLG: { name: 'Wheeling Ohio County Airport', city: 'Wheeling', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HLH: { name: 'Ulanhot Airport', city: 'Ulanhot', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HLJ: { name: 'Barysiai Airport', city: 'Barysiai', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  HLN: { name: 'Helena Regional Airport', city: 'Helena', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HLP: { name: 'Halim Perdanakusuma International Airport', city: 'Jakarta', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  HLR: { name: 'Hood Army Air Field', city: 'Fort Hood', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HLT: { name: 'Hamilton Airport', city: 'Hamilton', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  HLZ: { name: 'Hamilton International Airport', city: 'Hamilton', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  HMA: { name: 'Khanty Mansiysk Airport', city: 'Khanty-Mansiysk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  HMB: { name: 'Sohag International Airport', city: 'Sohag', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  HME: { name: 'Oued Irara Airport', city: 'Hassi Messaoud', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  HMI: { name: 'Hami Airport', city: 'Hami', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HMJ: { name: 'Khmelnytskyi Airport', city: 'Khmeinitskiy', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  HMN: { name: 'Holloman Air Force Base', city: 'Alamogordo', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HMO: { name: 'General Ignacio P. Garcia International Airport', city: 'Hermosillo', country: 'Mexico', timezone: 'America/Hermosillo', offset: -7 },
  HMR: { name: 'Stafsberg Airport', city: 'Hamar', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HMV: { name: 'Hemavan Airport', city: 'Hemavan', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  HNA: { name: 'Hanamaki Airport', city: 'Hanamaki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HND: { name: 'Tokyo Haneda International Airport', city: 'Tokyo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HNH: { name: 'Hoonah Airport', city: 'Hoonah', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HNL: { name: 'Honolulu International Airport', city: 'Honolulu', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  HNM: { name: 'Hana Airport', city: 'Hana', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  HNS: { name: 'Haines Airport', city: 'Haines', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HNY: { name: 'Hengyang Nanyue Airport', city: 'Hengyang', country: 'China', timezone: null, offset: 8 },
  HNZ: { name: 'Henderson Oxford Airport', city: 'Oxford', country: 'United States', timezone: null, offset: -5 },
  HOA: { name: 'Hola Airport', city: 'Hola', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  HOB: { name: 'Lea County Regional Airport', city: 'Hobbs', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HOD: { name: 'Hodeidah International Airport', city: 'Hodeidah', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  HOF: { name: 'Al Ahsa Airport', city: 'Al-ahsa', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  HOG: { name: 'Frank Pais International Airport', city: 'Holguin', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  HOI: { name: 'Hao Airport', city: 'Hao Island', country: 'French Polynesia', timezone: null, offset: -10 },
  HOJ: { name: 'Hohenems-Dornbirn Airport', city: 'Hohenems', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  HOK: { name: 'Hooker Creek Airport', city: 'Hooker Creek', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  HOM: { name: 'Homer Airport', city: 'Homer', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HON: { name: 'Huron Regional Airport', city: 'Huron', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HOP: { name: 'Campbell AAF (Fort Campbell) Air Field', city: 'Hopkinsville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HOQ: { name: 'Hof-Plauen Airport', city: 'Hof', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  HOR: { name: 'Horta Airport', city: 'Horta', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  HOT: { name: 'Memorial Field', city: 'Hot Springs', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HOU: { name: 'William P Hobby Airport', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HOV: { name: 'Ørsta-Volda Airport, Hovden', city: 'Orsta-Volda', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HPA: { name: 'Lifuka Island Airport', city: 'Lifuka', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  HPB: { name: 'Hooper Bay Airport', city: 'Hooper Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HPG: { name: 'Shennongjia Hongping Airport', city: 'Shennongjia', country: 'China', timezone: null, offset: 8 },
  HPH: { name: 'Cat Bi International Airport', city: 'Haiphong', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  HPN: { name: 'Westchester County Airport', city: 'White Plains', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HQM: { name: 'Bowerman Airport', city: 'Hoquiam', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  HQU: { name: 'Thomson-McDuffie County Airport', city: 'Thomson', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HRB: { name: 'Taiping Airport', city: 'Harbin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HRE: { name: 'Harare International Airport', city: 'Harare', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  HRG: { name: 'Hurghada International Airport', city: 'Hurghada', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  HRI: { name: 'Mattala Rajapaksa International Airport', city: 'Mattala', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  HRK: { name: 'Kharkiv International Airport', city: 'Kharkov', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  HRL: { name: 'Valley International Airport', city: 'Harlingen', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HRM: { name: 'Hassi R\'Mel Airport', city: 'Tilrempt', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  HRO: { name: 'Boone County Airport', city: 'Harrison', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HRT: { name: 'RAF Linton-On-Ouse', city: 'Linton-on-ouse', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  HSG: { name: 'Saga Airport', city: 'Saga', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  HSH: { name: 'Henderson Executive Airport', city: 'Henderson', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  HSK: { name: 'Huesca/Pirineos Airport', city: 'Huesca', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  HSL: { name: 'Huslia Airport', city: 'Huslia', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HSM: { name: 'Horsham Airport', city: 'Horsham', country: 'Australia', timezone: null, offset: 10 },
  HSN: { name: 'Zhoushan Airport', city: 'Zhoushan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HST: { name: 'Homestead ARB Airport', city: 'Homestead', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HSV: { name: 'Huntsville International Carl T Jones Field', city: 'Huntsville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HTA: { name: 'Chita-Kadala Airport', city: 'Chita', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  HTG: { name: 'Khatanga Airport', city: 'Khatanga', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  HTI: { name: 'Hamilton Island Airport', city: 'Hamilton Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  HTL: { name: 'Roscommon County - Blodgett Memorial Airport', city: 'Houghton Lake', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HTN: { name: 'Hotan Airport', city: 'Hotan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HTR: { name: 'Hateruma Airport', city: 'Taketomi', country: 'Japan', timezone: null, offset: 9 },
  HTS: { name: 'Tri-State/Milton J. Ferguson Field', city: 'Huntington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HTT: { name: 'Huatugou Airport', city: 'Mengnai', country: 'China', timezone: null, offset: 0 },
  HTU: { name: 'Hopetoun Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  HTV: { name: 'Huntsville Regional Airport', city: 'Huntsville', country: 'United States', timezone: null, offset: 0 },
  HTY: { name: 'Hatay Airport', city: 'Hatay', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  HUA: { name: 'Redstone Army Air Field', city: 'Redstone', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HUE: { name: 'Humera Airport', city: 'Humera', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  HUF: { name: 'Terre Haute International Hulman Field', city: 'Terre Haute', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HUH: { name: 'Huahine-Fare Airport', city: 'Huahine Island', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  HUI: { name: 'Phu Bai Airport', city: 'Hue', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  HUL: { name: 'Houlton International Airport', city: 'Houlton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HUN: { name: 'Hualien Airport', city: 'Hualien', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  HUS: { name: 'Hughes Airport', city: 'Hughes', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HUT: { name: 'Hutchinson Municipal Airport', city: 'Hutchinson', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HUU: { name: 'Alferez Fap David Figueroa Fernandini Airport', city: 'Huánuco', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  HUV: { name: 'Hudiksvall Airport', city: 'Hudiksvall', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  HUW: { name: 'Humaitá Airport', city: 'Humaita', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  HUX: { name: 'Bahías de Huatulco International Airport', city: 'Huatulco', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  HUY: { name: 'Humberside Airport', city: 'Humberside', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  HVA: { name: 'Analalava Airport', city: 'Analalava', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  HVB: { name: 'Hervey Bay Airport', city: 'Hervey Bay', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  HVD: { name: 'Khovd Airport', city: 'Khovd', country: 'Mongolia', timezone: 'Asia/Hovd', offset: 7 },
  HVG: { name: 'Valan Airport', city: 'Honningsvag', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  HVN: { name: 'Tweed New Haven Airport', city: 'New Haven', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HVR: { name: 'Havre City County Airport', city: 'Havre', country: 'United States', timezone: 'America/Denver', offset: -7 },
  HVS: { name: 'Hartsville Regional Airport', city: 'Hartsville', country: 'United States', timezone: null, offset: -5 },
  HWD: { name: 'Hayward Executive Airport', city: 'Hayward', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  HWO: { name: 'North Perry Airport', city: 'Hollywood', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HXX: { name: 'Hay Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  HYA: { name: 'Barnstable Municipal Boardman Polando Field', city: 'Barnstable', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HYG: { name: 'Hydaburg Seaplane Base', city: 'Hydaburg', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  HYI: { name: 'San Marcos Municipal Airport', city: 'San Marcos', country: 'United States', timezone: null, offset: 0 },
  HYN: { name: 'Huangyan Luqiao Airport', city: 'Huangyan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HYR: { name: 'Sawyer County Airport', city: 'Hayward', country: 'United States', timezone: null, offset: 0 },
  HYS: { name: 'Hays Regional Airport', city: 'Hays', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  HYV: { name: 'Hyvinkää Airfield', city: 'Hyvinkaa', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  HYW: { name: 'Conway Horry County Airport', city: 'Conway', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HZG: { name: 'Hanzhong Chenggu Airport', city: 'Hanzhong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HZH: { name: 'Liping Airport', city: 'Liping', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  HZK: { name: 'Húsavík Airport', city: 'Husavik', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  HZL: { name: 'Hazleton Municipal Airport', city: 'Hazleton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  HZP: { name: 'Fort Mackay / Horizon Airport', city: 'Wood Buffalo', country: 'Canada', timezone: null, offset: -7 },
  I16: { name: 'Kee Field', city: 'Pineville', country: 'United States', timezone: null, offset: -5 },
  IAA: { name: 'Igarka Airport', city: 'Igarka', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  IAB: { name: 'Mc Connell Air Force Base', city: 'Wichita', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IAD: { name: 'Washington Dulles International Airport', city: 'Washington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IAG: { name: 'Niagara Falls International Airport', city: 'Niagara Falls', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IAH: { name: 'George Bush Intercontinental Houston Airport', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IAM: { name: 'In Aménas Airport', city: 'Zarzaitine', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  IAN: { name: 'Bob Baker Memorial Airport', city: 'Kiana', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  IAO: { name: 'Siargao Airport', city: 'Siargao', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  IAR: { name: 'Tunoshna Airport', city: 'Yaroslavl', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  IAS: { name: 'Iaşi Airport', city: 'Iasi', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  IBA: { name: 'Ibadan Airport', city: 'Ibadan', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  IBE: { name: 'Perales Airport', city: 'Ibague', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  IBR: { name: 'Hyakuri Airport', city: 'Ibaraki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  IBZ: { name: 'Ibiza Airport', city: 'Ibiza', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  ICI: { name: 'Cicia Airport', city: 'Cicia', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  ICK: { name: 'Nieuw Nickerie Airport', city: 'Nieuw Nickerie', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  ICN: { name: 'Incheon International Airport', city: 'Seoul', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  ICT: { name: 'Wichita Mid Continent Airport', city: 'Wichita', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IDA: { name: 'Idaho Falls Regional Airport', city: 'Idaho Falls', country: 'United States', timezone: 'America/Denver', offset: -7 },
  IDL: { name: 'Indianola Municipal Airport', city: 'New York', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IDP: { name: 'Independence Municipal Airport', city: 'Independence', country: 'United States', timezone: null, offset: -5 },
  IDR: { name: 'Devi Ahilyabai Holkar Airport', city: 'Indore', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IDY: { name: 'Île d\'Yeu Airport', city: 'Île d\'Yeu', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  IEG: { name: 'Zielona Góra-Babimost Airport', city: 'Zielona Gora', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  IEJ: { name: 'Ie Jima Airport', city: 'Ie', country: 'Japan', timezone: null, offset: 0 },
  IEO: { name: 'Aioun el Atrouss Airport', city: 'Aioun El Atrouss', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  IEV: { name: 'Kiev Zhuliany International Airport', city: 'Kiev', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  IFJ: { name: 'Ísafjörður Airport', city: 'Isafjordur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  IFL: { name: 'Innisfail Airport', city: 'Innisfail', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  IFN: { name: 'Esfahan Shahid Beheshti International Airport', city: 'Esfahan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  IFO: { name: 'Ivano-Frankivsk International Airport', city: 'Ivano-Frankivsk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  IFU: { name: 'Ifuru Airport', city: 'Ifuru', country: 'Maldives', timezone: null, offset: 5 },
  IGA: { name: 'Inagua Airport', city: 'Matthew Town', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  IGB: { name: 'Cabo F.A.A. H. R. Bordón Airport', city: 'Ingeniero Jacobacci', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  IGG: { name: 'Igiugig Airport', city: 'Igiugig', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  IGL: { name: 'Çiğli Airport', city: 'Izmir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  IGQ: { name: 'Lansing Municipal Airport', city: 'Lansing', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IGR: { name: 'Cataratas Del Iguazú International Airport', city: 'Iguazu Falls', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  IGS: { name: 'Ingolstadt Manching Airport', city: 'Ingolstadt', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  IGT: { name: 'Magas Airport', city: 'Magas', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  IGU: { name: 'Cataratas International Airport', city: 'Foz Do Iguacu', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  IGX: { name: 'Horace Williams Airport', city: 'Chapel Hill', country: 'United States', timezone: null, offset: -5 },
  IIA: { name: 'Inishmaan Aerodrome', city: 'Inishmaan', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  IIL: { name: 'Ilam Airport', city: 'Ilam', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  IJD: { name: 'Windham Airport', city: 'Willimantic', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IJK: { name: 'Izhevsk Airport', city: 'Izhevsk', country: 'Russia', timezone: 'Europe/Samara', offset: 4 },
  IKA: { name: 'Imam Khomeini International Airport', city: 'Tehran', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  IKI: { name: 'Iki Airport', city: 'Iki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  IKK: { name: 'Greater Kankakee Airport', city: 'Kankakee', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IKL: { name: 'Ikela Airport', city: 'Ikela', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  IKS: { name: 'Tiksi Airport', city: 'Tiksi', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  IKT: { name: 'Irkutsk Airport', city: 'Irkutsk', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  IKU: { name: 'Issyk-Kul International Airport', city: 'Tamchy', country: 'Kyrgyzstan', timezone: null, offset: 0 },
  IKV: { name: 'Ankeny Regional Airport', city: 'Ankeny', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ILD: { name: 'Lleida-Alguaire Airport', city: 'Lleida', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  ILF: { name: 'Ilford Airport', city: 'Ilford', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ILG: { name: 'New Castle Airport', city: 'Wilmington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ILI: { name: 'Iliamna Airport', city: 'Iliamna', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ILM: { name: 'Wilmington International Airport', city: 'Wilmington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ILN: { name: 'Wilmington Airpark', city: 'Wilmington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ILO: { name: 'Iloilo International Airport', city: 'Iloilo', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  ILP: { name: 'Île des Pins Airport', city: 'Île des Pins', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  ILR: { name: 'Ilorin International Airport', city: 'Ilorin', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  ILU: { name: 'Kilaguni Airport', city: 'Kilaguni', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  ILY: { name: 'Islay Airport', city: 'Islay', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ILZ: { name: 'Žilina Airport', city: 'Žilina', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  IMB: { name: 'Imbaimadai Airport', city: 'Imbaimadai', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  IMF: { name: 'Imphal Airport', city: 'Imphal', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IMK: { name: 'Simikot Airport', city: 'Simikot', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  IMM: { name: 'Immokalee Regional Airport', city: 'Immokalee ', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IMP: { name: 'Prefeito Renato Moreira Airport', city: 'Imperatriz', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  IMT: { name: 'Ford Airport', city: 'Iron Mountain', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  INA: { name: 'Inta Airport', city: 'Inta', country: 'Russia', timezone: null, offset: 0 },
  INC: { name: 'Yinchuan Airport', city: 'Yinchuan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  IND: { name: 'Indianapolis International Airport', city: 'Indianapolis', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ING: { name: 'Lago Argentino Airport', city: 'El Calafate', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  INH: { name: 'Inhambane Airport', city: 'Inhambane', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  INI: { name: 'Nis Airport', city: 'Nis', country: 'Serbia', timezone: 'Europe/Belgrade', offset: 1 },
  INJ: { name: 'Hillsboro Municipal Airport', city: 'Hillsboro', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  INK: { name: 'Winkler County Airport', city: 'Wink', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  INL: { name: 'Falls International Airport', city: 'International Falls', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  INN: { name: 'Innsbruck Airport', city: 'Innsbruck', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  INO: { name: 'Inongo Airport', city: 'Inongo', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  INQ: { name: 'Inisheer Aerodrome', city: 'Inisheer', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  INS: { name: 'Creech Air Force Base', city: 'Indian Springs', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  INT: { name: 'Smith Reynolds Airport', city: 'Winston-salem', country: 'United States', timezone: 'America/New_York', offset: -5 },
  INU: { name: 'Nauru International Airport', city: 'Nauru', country: 'Nauru', timezone: 'Pacific/Nauru', offset: 12 },
  INV: { name: 'Inverness Airport', city: 'Inverness', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  INW: { name: 'Winslow Lindbergh Regional Airport', city: 'Winslow', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  INZ: { name: 'In Salah Airport', city: 'In Salah', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  IOA: { name: 'Ioannina Airport', city: 'Ioannina', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  IOB: { name: 'Mount Sterling Montgomery County Airport', city: 'Mount Sterling', country: 'United States', timezone: null, offset: -5 },
  IOM: { name: 'Isle of Man Airport', city: 'Isle Of Man', country: 'Isle of Man', timezone: 'Europe/Isle_of_Man', offset: 0 },
  IOR: { name: 'Inishmore Aerodrome', city: 'Inis Mor', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  IOS: { name: 'Bahia - Jorge Amado Airport', city: 'Ilheus', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  IOW: { name: 'Iowa City Municipal Airport', city: 'Iowa City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IPA: { name: 'Ipota Airport', city: 'Ipota', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  IPC: { name: 'Mataveri Airport', city: 'Easter Island', country: 'Chile', timezone: 'Pacific/Easter', offset: -6 },
  IPH: { name: 'Sultan Azlan Shah Airport', city: 'Ipoh', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  IPI: { name: 'San Luis Airport', city: 'Ipiales', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  IPL: { name: 'Imperial County Airport', city: 'Imperial', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  IPN: { name: 'Usiminas Airport', city: 'Ipatinga', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  IPT: { name: 'Williamsport Regional Airport', city: 'Williamsport', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IQM: { name: 'Qiemo Airport', city: 'Qiemo', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  IQN: { name: 'Qingyang Airport', city: 'Qingyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  IQQ: { name: 'Diego Aracena Airport', city: 'Iquique', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  IQT: { name: 'Coronel FAP Francisco Secada Vignetta International Airport', city: 'Iquitos', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  IRA: { name: 'Ngorangora Airport', city: 'Kirakira', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  IRC: { name: 'Circle City /New/ Airport', city: 'Circle', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  IRD: { name: 'Ishurdi Airport', city: 'Ishurdi', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  IRG: { name: 'Lockhart River Airport', city: 'Lockhart River', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  IRI: { name: 'Iringa Airport', city: 'Iringa', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  IRJ: { name: 'Capitan V A Almonacid Airport', city: 'La Rioja', country: 'Argentina', timezone: 'America/Argentina/La_Rioja', offset: -3 },
  IRK: { name: 'Kirksville Regional Airport', city: 'Kirksville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IRP: { name: 'Matari Airport', city: 'Isiro', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  IRZ: { name: 'Tapuruquara Airport', city: 'Santa Isabel do Rio Negro', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  ISA: { name: 'Mount Isa Airport', city: 'Mount Isa', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  ISB: { name: 'Benazir Bhutto International Airport', city: 'Islamabad', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  ISC: { name: 'St. Mary\'s Airport', city: 'ST MARY\'S', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ISE: { name: 'Süleyman Demirel International Airport', city: 'Isparta', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ISG: { name: 'Ishigaki Airport', city: 'Ishigaki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  ISJ: { name: 'Isla Mujeres Airport', city: 'Isla Mujeres', country: 'Mexico', timezone: 'America/Cancun', offset: -5 },
  ISK: { name: 'Gandhinagar Airport', city: 'Nasik Road', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  ISM: { name: 'Kissimmee Gateway Airport', city: 'Kissimmee', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ISN: { name: 'Sloulin Field International Airport', city: 'Williston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ISO: { name: 'Kinston Regional Jetport At Stallings Field', city: 'Kinston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ISP: { name: 'Long Island Mac Arthur Airport', city: 'Islip', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IST: { name: 'Atatürk International Airport', city: 'Istanbul', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ISU: { name: 'Sulaymaniyah International Airport', city: 'Sulaymaniyah', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  ISW: { name: 'Alexander Field South Wood County Airport', city: 'Wisconsin Rapids', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ITB: { name: 'Itaituba Airport', city: 'Itaituba', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  ITH: { name: 'Ithaca Tompkins Regional Airport', city: 'Ithaca', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ITM: { name: 'Osaka International Airport', city: 'Osaka', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  ITO: { name: 'Hilo International Airport', city: 'Hilo', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  ITP: { name: 'Itaperuna Airport', city: 'Itaperuna', country: 'Brazil', timezone: null, offset: -3 },
  IUD: { name: 'Al Udeid Air Base', city: 'Doha', country: 'Qatar', timezone: 'Asia/Qatar', offset: 3 },
  IUE: { name: 'Niue International Airport', city: 'Alofi', country: 'Niue', timezone: 'Pacific/Niue', offset: -11 },
  IVA: { name: 'Ampampamena Airport', city: 'Ampampamena', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  IVC: { name: 'Invercargill Airport', city: 'Invercargill', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  IVL: { name: 'Ivalo Airport', city: 'Ivalo', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  IVR: { name: 'Inverell Airport', city: 'Inverell', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  IWA: { name: 'Ivanovo South Airport', city: 'Ivanovo', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  IWJ: { name: 'Iwami Airport', city: 'Iwami', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  IWO: { name: 'Iwo Jima Airport', city: 'Iwojima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  IWS: { name: 'West Houston Airport', city: 'Houston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  IXA: { name: 'Agartala Airport', city: 'Agartala', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXB: { name: 'Bagdogra Airport', city: 'Baghdogra', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXC: { name: 'Chandigarh Airport', city: 'Chandigarh', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXD: { name: 'Allahabad Airport', city: 'Allahabad', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXE: { name: 'Mangalore International Airport', city: 'Mangalore', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXG: { name: 'Belgaum Airport', city: 'Belgaum', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXH: { name: 'Kailashahar Airport', city: 'Kailashahar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXI: { name: 'North Lakhimpur Airport', city: 'Lilabari', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXJ: { name: 'Jammu Airport', city: 'Jammu', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXK: { name: 'Keshod Airport', city: 'Keshod', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXL: { name: 'Leh Kushok Bakula Rimpochee Airport', city: 'Leh', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXM: { name: 'Madurai Airport', city: 'Madurai', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXR: { name: 'Birsa Munda Airport', city: 'Ranchi', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXS: { name: 'Silchar Airport', city: 'Silchar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXT: { name: 'Pasighat Airport', city: 'Pasighat', country: 'India', timezone: null, offset: 5 },
  IXU: { name: 'Aurangabad Airport', city: 'Aurangabad', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXW: { name: 'Jamshedpur Airport', city: 'Jamshedpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXY: { name: 'Kandla Airport', city: 'Kandla', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IXZ: { name: 'Vir Savarkar International Airport', city: 'Port Blair', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  IYK: { name: 'Inyokern Airport', city: 'Inyokern', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  IZA: { name: 'Zona da Mata Regional Airport', city: 'Juiz de Fora', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  IZG: { name: 'Eastern Slopes Regional Airport', city: 'Fryeburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  IZO: { name: 'Izumo Airport', city: 'Izumo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  JAA: { name: 'Jalalabad Airport', city: 'Jalalabad', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  JAB: { name: 'Jabiru Airport', city: 'Jabiru', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  JAC: { name: 'Jackson Hole Airport', city: 'Jacksn Hole', country: 'United States', timezone: 'America/Denver', offset: -7 },
  JAD: { name: 'Perth Jandakot Airport', city: 'Perth', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  JAE: { name: 'Shumba Airport', city: 'Jaén', country: 'Peru', timezone: null, offset: 0 },
  JAF: { name: 'Kankesanturai Airport', city: 'Jaffna', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  JAI: { name: 'Jaipur International Airport', city: 'Jaipur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JAL: { name: 'El Lencero Airport', city: 'Jalapa', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  JAN: { name: 'Jackson-Medgar Wiley Evers International Airport', city: 'Jackson', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JAS: { name: 'Jasper County Airport-Bell Field', city: 'Jasper', country: 'United States', timezone: null, offset: -5 },
  JAV: { name: 'Ilulissat Airport', city: 'Ilulissat', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JAX: { name: 'Jacksonville International Airport', city: 'Jacksonville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JBQ: { name: 'La Isabela International Airport', city: 'La Isabela', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  JBR: { name: 'Jonesboro Municipal Airport', city: 'Jonesboro', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JCB: { name: 'Santa Terezinha Airport', city: 'Joacaba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  JCH: { name: 'Qasigiannguit Heliport', city: 'Qasigiannguit', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JCI: { name: 'New Century Aircenter Airport', city: 'Olathe', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JCK: { name: 'Julia Creek Airport', city: 'Julia Creek', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  JCT: { name: 'Kimble County Airport', city: 'Junction', country: 'United States', timezone: null, offset: 0 },
  JDF: { name: 'Francisco de Assis Airport', city: 'Juiz De Fora', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  JDH: { name: 'Jodhpur Airport', city: 'Jodhpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JDO: { name: 'Orlando Bezerra de Menezes Airport', city: 'Juazeiro Do Norte', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  JDZ: { name: 'Jingdezhen Airport', city: 'Jingdezhen', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JED: { name: 'King Abdulaziz International Airport', city: 'Jeddah', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  JEE: { name: 'Jérémie Airport', city: 'Jeremie', country: 'Haiti', timezone: 'America/Port-au-Prince', offset: -5 },
  JEF: { name: 'Jefferson City Memorial Airport', city: 'Jefferson City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JEG: { name: 'Aasiaat Airport', city: 'Aasiaat', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JER: { name: 'Jersey Airport', city: 'Jersey', country: 'Jersey', timezone: 'Europe/Jersey', offset: 0 },
  JES: { name: 'Jesup Wayne County Airport', city: 'Jesup', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JFK: { name: 'John F Kennedy International Airport', city: 'New York', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JFR: { name: 'Paamiut Heliport', city: 'Paamiut', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JGA: { name: 'Jamnagar Airport', city: 'Jamnagar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JGG: { name: 'Williamsburg Jamestown Airport', city: 'Williamsburg', country: 'United States', timezone: null, offset: -5 },
  JGN: { name: 'Jiayuguan Airport', city: 'Jiayuguan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JGO: { name: 'Qeqertarsuaq Heliport', city: 'Qeqertarsuaq Airport', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JHB: { name: 'Senai International Airport', city: 'Johor Bahru', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  JHG: { name: 'Xishuangbanna Gasa Airport', city: 'Jinghonggasa', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JHM: { name: 'Kapalua Airport', city: 'Lahania-kapalua', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  JHQ: { name: 'Shute Harbour Airport', city: 'Shute Harbour', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  JHS: { name: 'Sisimiut Airport', city: 'Sisimiut', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JHW: { name: 'Chautauqua County-Jamestown Airport', city: 'Jamestown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JIA: { name: 'Juína Airport', city: 'Juina', country: 'Brazil', timezone: null, offset: -4 },
  JIB: { name: 'Djibouti-Ambouli Airport', city: 'Djibouti', country: 'Djibouti', timezone: 'Africa/Djibouti', offset: 3 },
  JIJ: { name: 'Wilwal International Airport', city: 'Jijiga', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  JIK: { name: 'Ikaria Airport', city: 'Ikaria', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JIM: { name: 'Jimma Airport', city: 'Jimma', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  JIU: { name: 'Jiujiang Lushan Airport', city: 'Jiujiang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JIW: { name: 'Jiwani Airport', city: 'Jiwani', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  JJI: { name: 'Juanjui Airport', city: 'Juanjui', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  JJN: { name: 'Quanzhou Jinjiang International Airport', city: 'Quanzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JJU: { name: 'Qaqortoq Heliport', city: 'Qaqortoq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JKA: { name: 'Jack Edwards Airport', city: 'Gulf Shores', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JKG: { name: 'Jönköping Airport', city: 'Joenkoeping', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  JKH: { name: 'Chios Island National Airport', city: 'Chios', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JKL: { name: 'Kalymnos Airport', city: 'Kalymnos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JLD: { name: 'Landskrona Airport', city: 'Landskrona', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  JLN: { name: 'Joplin Regional Airport', city: 'Joplin', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JLR: { name: 'Jabalpur Airport', city: 'Jabalpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JMK: { name: 'Mikonos Airport', city: 'Mykonos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JMO: { name: 'Jomsom Airport', city: 'Jomsom', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  JMS: { name: 'Jamestown Regional Airport', city: 'Jamestown', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JMU: { name: 'Jiamusi Airport', city: 'Jiamusi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JNB: { name: 'OR Tambo International Airport', city: 'Johannesburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  JNG: { name: 'Jining Qufu Airport', city: 'Jining', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JNN: { name: 'Nanortalik Heliport', city: 'Nanortalik', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JNS: { name: 'Narsaq Heliport', city: 'Narsaq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JNU: { name: 'Juneau International Airport', city: 'Juneau', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  JNX: { name: 'Naxos Airport', city: 'Cyclades Islands', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JNZ: { name: 'Jinzhou Airport', city: 'Jinzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JOE: { name: 'Joensuu Airport', city: 'Joensuu', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  JOG: { name: 'Adi Sutjipto International Airport', city: 'Yogyakarta', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  JOI: { name: 'Lauro Carneiro de Loyola Airport', city: 'Joinville', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  JOK: { name: 'Yoshkar-Ola Airport', city: 'Yoshkar-Ola', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  JOL: { name: 'Jolo Airport', city: 'Jolo', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  JON: { name: 'Johnston Atoll Airport', city: 'Johnston Island', country: 'Johnston Atoll', timezone: 'Pacific/Johnston', offset: -10 },
  JOS: { name: 'Yakubu Gowon Airport', city: 'Jos', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  JOT: { name: 'Joliet Regional Airport', city: 'Joliet', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JPA: { name: 'Presidente Castro Pinto International Airport', city: 'Joao Pessoa', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  JPR: { name: 'Ji-Paraná Airport', city: 'Ji-Paraná', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  JQA: { name: 'Qaarsut Airport', city: 'Uummannaq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JQE: { name: 'Jaqué Airport', city: 'Jaqué', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  JRH: { name: 'Jorhat Airport', city: 'Jorhat', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JRN: { name: 'Juruena Airport', city: 'Juruena', country: 'Brazil', timezone: null, offset: -4 },
  JRO: { name: 'Kilimanjaro International Airport', city: 'Kilimanjaro', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  JSA: { name: 'Jaisalmer Airport', city: 'Jaisalmer', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  JSH: { name: 'Sitia Airport', city: 'Sitia', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JSI: { name: 'Skiathos Island National Airport', city: 'Skiathos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JSM: { name: 'Jose De San Martin Airport', city: 'Jose de San Martin', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  JSR: { name: 'Jessore Airport', city: 'Jessore', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  JST: { name: 'John Murtha Johnstown Cambria County Airport', city: 'Johnstown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JSU: { name: 'Maniitsoq Airport', city: 'Maniitsoq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JSV: { name: 'Sallisaw Municipal Airport', city: 'Sallisaw', country: 'United States', timezone: null, offset: -5 },
  JSY: { name: 'Syros Airport', city: 'Syros Island', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JTC: { name: 'Bauru - Arealva Airport', city: 'Bauru', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  JTR: { name: 'Santorini Airport', city: 'Thira', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JTY: { name: 'Astypalaia Airport', city: 'Astypalaia', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  JUA: { name: 'Inácio Luís do Nascimento Airport', city: 'Juara', country: 'Brazil', timezone: null, offset: -4 },
  JUB: { name: 'Juba International Airport', city: 'Juba', country: 'South Sudan', timezone: 'Africa/Juba', offset: 3 },
  JUI: { name: 'Juist Airport', city: 'Juist', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  JUJ: { name: 'Gobernador Horacio Guzman International Airport', city: 'Jujuy', country: 'Argentina', timezone: 'America/Jujuy', offset: -3 },
  JUL: { name: 'Inca Manco Capac International Airport', city: 'Juliaca', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  JUM: { name: 'Jumla Airport', city: 'Jumla', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  JUV: { name: 'Upernavik Airport', city: 'Upernavik', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  JUZ: { name: 'Quzhou Airport', city: 'Quzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JVA: { name: 'Ankavandra Airport', city: 'Ankavandra', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  JVL: { name: 'Southern Wisconsin Regional Airport', city: 'Janesville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  JWA: { name: 'Jwaneng Airport', city: 'Jwaneng', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  JXA: { name: 'Jixi Xingkaihu Airport', city: 'Jixi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JXN: { name: 'Jackson County Reynolds Field', city: 'Jackson', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JYL: { name: 'Plantation Airpark', city: 'Sylvania', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JYO: { name: 'Leesburg Executive Airport', city: 'Leesburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JYV: { name: 'Jyvaskyla Airport', city: 'Jyvaskyla', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  JZH: { name: 'Jiuzhai Huanglong Airport', city: 'Jiuzhaigou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  JZI: { name: 'Charleston Executive Airport', city: 'Charleston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  JZP: { name: 'Pickens County Airport', city: 'Jasper', country: 'United States', timezone: 'America/New_York', offset: -5 },
  K83: { name: 'Sabetha Municipal Airport', city: 'Sabetha', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  KAA: { name: 'Kasama Airport', city: 'Kasama', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  KAB: { name: 'Kariba International Airport', city: 'Kariba', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  KAC: { name: 'Kamishly Airport', city: 'Kamishly', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  KAD: { name: 'Kaduna Airport', city: 'Kaduna', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  KAG: { name: 'Gangneung Airport', city: 'Kangnung', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  KAI: { name: 'Kaieteur International Airport', city: 'Kaieteur', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  KAJ: { name: 'Kajaani Airport', city: 'Kajaani', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KAL: { name: 'Kaltag Airport', city: 'Kaltag', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KAN: { name: 'Mallam Aminu International Airport', city: 'Kano', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  KAO: { name: 'Kuusamo Airport', city: 'Kuusamo', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KAR: { name: 'Kamarang Airport', city: 'Kamarang', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  KAT: { name: 'Kaitaia Airport', city: 'Kaitaia', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  KAU: { name: 'Kauhava Airport', city: 'Kauhava', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KAW: { name: 'Kawthoung Airport', city: 'Kawthoung', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  KAX: { name: 'Kalbarri Airport', city: 'Kalbarri', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  KAZ: { name: 'Kao Airport', city: 'Kao', country: 'Indonesia', timezone: null, offset: 9 },
  KBL: { name: 'Kabul International Airport', city: 'Kabul', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  KBN: { name: 'Tunta Airport', city: 'Kabinda', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  KBP: { name: 'Boryspil International Airport', city: 'Kiev', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  KBR: { name: 'Sultan Ismail Petra Airport', city: 'Kota Bahru', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KBS: { name: 'Bo Airport', city: 'Bo', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  KBV: { name: 'Krabi Airport', city: 'Krabi', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  KBZ: { name: 'Kaikoura Airport', city: 'Kaikoura', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  KCA: { name: 'Kuqa Airport', city: 'Kuqa', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KCH: { name: 'Kuching International Airport', city: 'Kuching', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KCM: { name: 'Kahramanmaraş Airport', city: 'Kahramanmaras', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  KCT: { name: 'Koggala Airport', city: 'Koggala', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  KCZ: { name: 'Kōchi Ryōma Airport', city: 'Kochi', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KDD: { name: 'Khuzdar Airport', city: 'Khuzdar', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  KDH: { name: 'Kandahar Airport', city: 'Kandahar', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  KDI: { name: 'Wolter Monginsidi Airport', city: 'Kendari', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  KDL: { name: 'Kärdla Airport', city: 'Kardla', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 2 },
  KDM: { name: 'Kaadedhdhoo Airport', city: 'Kaadedhdhoo', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  KDO: { name: 'Kadhdhoo Airport', city: 'Laamu Atoll', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  KDU: { name: 'Skardu Airport', city: 'Skardu', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  KDV: { name: 'Vunisea Airport', city: 'Vunisea', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  KED: { name: 'Kaédi Airport', city: 'Kaedi', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  KEF: { name: 'Keflavik International Airport', city: 'Keflavik', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  KEJ: { name: 'Kemerovo Airport', city: 'Kemorovo', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  KEL: { name: 'Kiel-Holtenau Airport', city: 'Kiel', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  KEM: { name: 'Kemi-Tornio Airport', city: 'Kemi', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KEN: { name: 'Kenema Airport', city: 'Kenema', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  KEP: { name: 'Nepalgunj Airport', city: 'Nepalgunj', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  KER: { name: 'Kerman Airport', city: 'Kerman', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  KES: { name: 'Kelsey Airport', city: 'Kelsey', country: 'Canada', timezone: null, offset: 0 },
  KET: { name: 'Kengtung Airport', city: 'Kengtung', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  KEV: { name: 'Halli Airport', city: 'Halli', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KEW: { name: 'Keewaywin Airport', city: 'Keewaywin', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  KEY: { name: 'Kericho Airport', city: 'Kericho', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  KFA: { name: 'Kiffa Airport', city: 'Kiffa', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  KFE: { name: 'Fortescue - Dave Forrest Aerodrome', city: 'Cloudbreak', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  KFG: { name: 'Kalkgurung Airport', city: 'Kalkgurung', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  KFP: { name: 'False Pass Airport', city: 'False Pass', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KFS: { name: 'Kastamonu Airport', city: 'Kastamonu', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  KGA: { name: 'Kananga Airport', city: 'Kananga', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  KGC: { name: 'Kingscote Airport', city: 'Kingscote', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  KGD: { name: 'Khrabrovo Airport', city: 'Kaliningrad', country: 'Russia', timezone: 'Europe/Kaliningrad', offset: 2 },
  KGE: { name: 'Kaghau Airport', city: 'Kagau Island', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  KGF: { name: 'Sary-Arka Airport', city: 'Karaganda', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  KGG: { name: 'Kédougou Airport', city: 'Kedougou', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  KGI: { name: 'Kalgoorlie Boulder Airport', city: 'Kalgoorlie', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  KGJ: { name: 'Karonga Airport', city: 'Karonga', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  KGK: { name: 'Koliganek Airport', city: 'Koliganek', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KGL: { name: 'Kigali International Airport', city: 'Kigali', country: 'Rwanda', timezone: 'Africa/Kigali', offset: 2 },
  KGN: { name: 'Kasongo Airport', city: 'Kasongo', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  KGO: { name: 'Kirovograd Airport', city: 'Kirovograd', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  KGP: { name: 'Kogalym International Airport', city: 'Kogalym', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  KGS: { name: 'Kos Airport', city: 'Kos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KGY: { name: 'Kingaroy Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  KHC: { name: 'Kerch Airport', city: 'Kerch', country: 'Ukraine', timezone: 'Europe/Simferopol', offset: 3 },
  KHD: { name: 'Khoram Abad Airport', city: 'Khorram Abad', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  KHE: { name: 'Chernobayevka Airport', city: 'Kherson', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  KHG: { name: 'Kashgar Airport', city: 'Kashi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KHH: { name: 'Kaohsiung International Airport', city: 'Kaohsiung', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  KHI: { name: 'Jinnah International Airport', city: 'Karachi', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  KHM: { name: 'Kanti Airport', city: 'Khamti', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  KHN: { name: 'Nanchang Changbei International Airport', city: 'Nanchang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KHS: { name: 'Khasab Air Base', city: 'Khasab', country: 'Oman', timezone: 'Asia/Muscat', offset: 4 },
  KHT: { name: 'Khost Airport', city: 'Khost', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  KHV: { name: 'Khabarovsk-Novy Airport', city: 'Khabarovsk', country: 'Russia', timezone: 'Asia/Vladivostok', offset: 10 },
  KHW: { name: 'Khwai River Lodge Airport', city: 'Khwai River', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  KID: { name: 'Kristianstad Airport', city: 'Kristianstad', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KIE: { name: 'Kieta Airport', city: 'Kieta', country: 'Papua New Guinea', timezone: null, offset: 10 },
  KIF: { name: 'Kingfisher Lake Airport', city: 'Kingfisher Lake', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  KIH: { name: 'Kish International Airport', city: 'Kish Island', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  KIJ: { name: 'Niigata Airport', city: 'Niigata', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KIK: { name: 'Kirkuk Air Base', city: 'Kirkuk', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  KIM: { name: 'Kimberley Airport', city: 'Kimberley', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  KIN: { name: 'Norman Manley International Airport', city: 'Kingston', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  KIO: { name: 'Kili Airport', city: 'Kili Island', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  KIR: { name: 'Kerry Airport', city: 'Kerry', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  KIS: { name: 'Kisumu Airport', city: 'Kisumu', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  KIT: { name: 'Kithira Airport', city: 'Kithira', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KIV: { name: 'Chişinău International Airport', city: 'Chisinau', country: 'Moldova', timezone: 'Europe/Chisinau', offset: 2 },
  KIW: { name: 'Southdowns Airport', city: 'Southdowns', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  KIX: { name: 'Kansai International Airport', city: 'Osaka', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KJA: { name: 'Yemelyanovo Airport', city: 'Krasnoyarsk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  KJH: { name: 'Kaili Airport', city: 'Kaili', country: 'China', timezone: null, offset: 8 },
  KJI: { name: 'Kanas Airport', city: 'Burqin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KJP: { name: 'Kerama Airport', city: 'Kerama', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KKA: { name: 'Koyuk Alfred Adams Airport', city: 'Koyuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KKC: { name: 'Khon Kaen Airport', city: 'Khon Kaen', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  KKE: { name: 'Kerikeri Airport', city: 'Kerikeri', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  KKH: { name: 'Kongiganak Airport', city: 'Kongiganak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KKJ: { name: 'Kitakyūshū Airport', city: 'Kitakyushu', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KKN: { name: 'Kirkenes Airport (Høybuktmoen)', city: 'Kirkenes', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  KKR: { name: 'Kaukura Airport', city: 'Kaukura Atoll', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  KKW: { name: 'Kikwit Airport', city: 'Kikwit', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  KKX: { name: 'Kikai Airport', city: 'Kikai', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KLC: { name: 'Kaolack Airport', city: 'Kaolack', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  KLD: { name: 'Migalovo Air Base', city: 'Tver', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KLF: { name: 'Grabtsevo Airport', city: 'Kaluga', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KLG: { name: 'Kalskag Airport', city: 'Kalskag', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KLH: { name: 'Kolhapur Airport', city: 'Kolhapur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  KLN: { name: 'Larsen Bay Airport', city: 'Larsen Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KLO: { name: 'Kalibo International Airport', city: 'Kalibo', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  KLR: { name: 'Kalmar Airport', city: 'Kalkmar', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KLS: { name: 'Southwest Washington Regional Airport', city: 'Kelso', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  KLU: { name: 'Klagenfurt Airport', city: 'Klagenfurt', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  KLV: { name: 'Karlovy Vary International Airport', city: 'Karlovy Vary', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  KLW: { name: 'Klawock Airport', city: 'Klawock', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KLX: { name: 'Kalamata Airport', city: 'Kalamata', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KLZ: { name: 'Kleinsee Airport', city: 'Kleinsee', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  KM8: { name: 'Opapimiskan Lake Airport', city: 'Musselwhite Mine', country: 'Canada', timezone: null, offset: -5 },
  KMA: { name: 'Kerema Airport', city: 'Kerema', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  KME: { name: 'Kamembe Airport', city: 'Kamembe', country: 'Rwanda', timezone: 'Africa/Kigali', offset: 2 },
  KMG: { name: 'Kunming Changshui International Airport', city: 'Kunming', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KMH: { name: 'Johan Pienaar Airport', city: 'Kuruman', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  KMI: { name: 'Miyazaki Airport', city: 'Miyazaki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KMJ: { name: 'Kumamoto Airport', city: 'Kumamoto', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KMN: { name: 'Kamina Base Airport', city: 'Kamina Base', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  KMO: { name: 'Manokotak Airport', city: 'Manokotak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KMP: { name: 'Keetmanshoop Airport', city: 'Keetmanshoop', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  KMQ: { name: 'Komatsu Airport', city: 'Kanazawa', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KMS: { name: 'Kumasi Airport', city: 'Kumasi', country: 'Ghana', timezone: 'Africa/Accra', offset: 0 },
  KMU: { name: 'Kisimayu Airport', city: 'Kismayu', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  KMV: { name: 'Kalay Airport', city: 'Kalemyo', country: 'Myanmar', timezone: 'Asia/Rangoon', offset: 6 },
  KMW: { name: 'Kostroma Sokerkino Airport', city: 'Kostroma', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KMX: { name: 'King Khaled Air Base', city: null, country: 'Saudi Arabia', timezone: null, offset: 0 },
  KND: { name: 'Kindu Airport', city: 'Kindu', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  KNF: { name: 'RAF Marham', city: 'Marham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  KNG: { name: 'Kaimana Airport', city: 'Kaimana', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  KNH: { name: 'Kinmen Airport', city: 'Kinmen', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  KNO: { name: 'Kualanamu International Airport', city: 'Medan', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  KNQ: { name: 'Koné Airport', city: 'Kone', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  KNR: { name: 'Jam Airport', city: 'Kangan', country: 'Iran', timezone: null, offset: 0 },
  KNS: { name: 'King Island Airport', city: 'King Island', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  KNU: { name: 'Kanpur Airport', city: 'Kanpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  KNW: { name: 'New Stuyahok Airport', city: 'New Stuyahok', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KNX: { name: 'Kununurra Airport', city: 'Kununurra', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  KOA: { name: 'Kona International At Keahole Airport', city: 'Kona', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  KOC: { name: 'Koumac Airport', city: 'Koumac', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  KOE: { name: 'El Tari Airport', city: 'Kupang', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  KOI: { name: 'Kirkwall Airport', city: 'Kirkwall', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  KOJ: { name: 'Kagoshima Airport', city: 'Kagoshima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KOK: { name: 'Kokkola-Pietarsaari Airport', city: 'Kruunupyy', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KOO: { name: 'Kongolo Airport', city: 'Kongolo', country: 'Congo (Kinshasa)', timezone: null, offset: 1 },
  KOP: { name: 'Nakhon Phanom Airport', city: 'Nakhon Phanom', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  KOS: { name: 'Sihanoukville International Airport', city: 'Sihanoukville', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  KOT: { name: 'Kotlik Airport', city: 'Kotlik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KOU: { name: 'Koulamoutou Mabimbi Airport', city: 'Koulamoutou', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  KOV: { name: 'Kokshetau Airport', city: 'Kokshetau', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  KOW: { name: 'Ganzhou Airport', city: 'Ganzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KOX: { name: 'Kokonau Airport', city: 'Kokonau', country: 'Indonesia', timezone: null, offset: 9 },
  KPN: { name: 'Kipnuk Airport', city: 'Kipnuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KPO: { name: 'Pohang Airport', city: 'Pohang', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  KPS: { name: 'Kempsey Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  KPV: { name: 'Perryville Airport', city: 'Perryville', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KQA: { name: 'Akutan Seaplane Base', city: 'Akutan', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KRA: { name: 'Kerang Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  KRB: { name: 'Karumba Airport', city: 'Karumba', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  KRF: { name: 'Kramfors Sollefteå Airport', city: 'Kramfors', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KRH: { name: 'Redhill Aerodrome', city: 'Redhill', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  KRI: { name: 'Kikori Airport', city: 'Kikori', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  KRK: { name: 'John Paul II International Airport Kraków-Balice Airport', city: 'Krakow', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  KRL: { name: 'Korla Airport', city: 'Korla', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KRN: { name: 'Kiruna Airport', city: 'Kiruna', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KRO: { name: 'Kurgan Airport', city: 'Kurgan', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  KRP: { name: 'Karup Airport', city: 'Karup', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  KRQ: { name: 'Kramatorsk Airport', city: 'Kramatorsk', country: 'Ukraine', timezone: null, offset: 2 },
  KRR: { name: 'Krasnodar Pashkovsky International Airport', city: 'Krasnodar', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KRS: { name: 'Kristiansand Airport', city: 'Kristiansand', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  KRT: { name: 'Khartoum International Airport', city: 'Khartoum', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  KRW: { name: 'Turkmenbashi Airport', city: 'Krasnovodsk', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 5 },
  KRY: { name: 'Karamay Airport', city: 'Karamay', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KRZ: { name: 'Basango Mboliasa Airport', city: 'Kiri', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  KSA: { name: 'Kosrae International Airport', city: 'Kosrae', country: 'Micronesia', timezone: 'Pacific/Kosrae', offset: 11 },
  KSC: { name: 'Košice Airport', city: 'Kosice', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  KSD: { name: 'Karlstad Airport', city: 'Karlstad', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KSF: { name: 'Kassel-Calden Airport', city: 'Kassel', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  KSH: { name: 'Shahid Ashrafi Esfahani Airport', city: 'Bakhtaran', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  KSI: { name: 'Kissidougou Airport', city: 'Kissidougou', country: 'Guinea', timezone: 'Africa/Conakry', offset: 0 },
  KSJ: { name: 'Kasos Airport', city: 'Kasos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KSK: { name: 'Karlskoga Airport', city: 'Karlskoga', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KSL: { name: 'Kassala Airport', city: 'Kassala', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  KSM: { name: 'St Mary\'s Airport', city: 'St Mary\'s', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KSN: { name: 'Kostanay West Airport', city: 'Kostanay', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  KSO: { name: 'Kastoria National Airport', city: 'Kastoria', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KSQ: { name: 'Karshi Khanabad Airport', city: 'Khanabad', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  KSS: { name: 'Sikasso Airport', city: 'Sikasso', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  KSU: { name: 'Kristiansund Airport (Kvernberget)', city: 'Kristiansund', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  KSY: { name: 'Kars Airport', city: 'Kars', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  KSZ: { name: 'Kotlas Airport', city: 'Kotlas', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KTA: { name: 'Karratha Airport', city: 'Karratha', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  KTD: { name: 'Kitadaito Airport', city: 'Kitadaito', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KTE: { name: 'Kerteh Airport', city: 'Kerteh', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KTF: { name: 'Takaka Airport', city: 'Takaka', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  KTG: { name: 'Ketapang(Rahadi Usman) Airport', city: 'Ketapang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  KTI: { name: 'Kratie Airport', city: 'Kratie', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  KTL: { name: 'Kitale Airport', city: 'Kitale', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  KTM: { name: 'Tribhuvan International Airport', city: 'Kathmandu', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  KTN: { name: 'Ketchikan International Airport', city: 'Ketchikan', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KTP: { name: 'Tinson Pen Airport', city: 'Kingston', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  KTR: { name: 'Tindal Airport', city: 'Katherine', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  KTS: { name: 'Brevig Mission Airport', city: 'Brevig Mission', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KTT: { name: 'Kittilä Airport', city: 'Kittila', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KTU: { name: 'Kota Airport', city: 'Kota', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  KTW: { name: 'Katowice International Airport', city: 'Katowice', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  KUA: { name: 'Kuantan Airport', city: 'Kuantan', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KUC: { name: 'Kuria Airport', city: 'Kuria', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  KUD: { name: 'Kudat Airport', city: 'Kudat', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KUF: { name: 'Kurumoch International Airport', city: 'Samara', country: 'Russia', timezone: 'Europe/Samara', offset: 4 },
  KUG: { name: 'Kubin Airport', city: 'Kubin', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  KUH: { name: 'Kushiro Airport', city: 'Kushiro', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KUK: { name: 'Kasigluk Airport', city: 'Kasigluk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KUL: { name: 'Kuala Lumpur International Airport', city: 'Kuala Lumpur', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  KUM: { name: 'Yakushima Airport', city: 'Yakushima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  KUN: { name: 'Kaunas International Airport', city: 'Kaunas', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  KUO: { name: 'Kuopio Airport', city: 'Kuopio', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  KUT: { name: 'Kopitnari Airport', city: 'Kutaisi', country: 'Georgia', timezone: 'Asia/Tbilisi', offset: 4 },
  KUU: { name: 'Kullu Manali Airport', city: 'Kulu', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  KUV: { name: 'Kunsan Air Base', city: 'Kunsan', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  KVA: { name: 'Alexander the Great International Airport', city: 'Kavala', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KVB: { name: 'Skövde Airport', city: 'Skovde', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  KVC: { name: 'King Cove Airport', city: 'King Cove', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KVD: { name: 'Ganja Airport', city: 'Ganja', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  KVG: { name: 'Kavieng Airport', city: 'Kavieng', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  KVK: { name: 'Kirovsk-Apatity Airport', city: 'Apatity', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KVL: { name: 'Kivalina Airport', city: 'Kivalina', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KVM: { name: 'Markovo Airport', city: 'Markovo', country: 'Russia', timezone: 'Asia/Anadyr', offset: 12 },
  KVX: { name: 'Pobedilovo Airport', city: 'Kirov', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KWA: { name: 'Bucholz Army Air Field', city: 'Kwajalein', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  KWB: { name: 'Dewadaru - Kemujan Island', city: 'Karimunjawa', country: 'Indonesia', timezone: null, offset: 7 },
  KWE: { name: 'Longdongbao Airport', city: 'Guiyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KWG: { name: 'Kryvyi Rih International Airport', city: 'Krivoy Rog', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  KWI: { name: 'Kuwait International Airport', city: 'Kuwait', country: 'Kuwait', timezone: 'Asia/Kuwait', offset: 3 },
  KWJ: { name: 'Gwangju Airport', city: 'Kwangju', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  KWK: { name: 'Kwigillingok Airport', city: 'Kwigillingok', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KWL: { name: 'Guilin Liangjiang International Airport', city: 'Guilin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  KWM: { name: 'Kowanyama Airport', city: 'Kowanyama', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  KWN: { name: 'Quinhagak Airport', city: 'Quinhagak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KWT: { name: 'Kwethluk Airport', city: 'Kwethluk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KWZ: { name: 'Kolwezi Airport', city: 'Kolwezi', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  KXF: { name: 'Koro Island Airport', city: 'Koro Island', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  KXK: { name: 'Komsomolsk-on-Amur Airport', city: 'Komsomolsk-on-Amur', country: 'Russia', timezone: 'Asia/Vladivostok', offset: 10 },
  KYA: { name: 'Konya Airport', city: 'Konya', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  KYD: { name: 'Lanyu Airport', city: 'Lanyu', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  KYK: { name: 'Karluk Airport', city: 'Karluk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KYP: { name: 'Kyaukpyu Airport', city: 'Kyaukpyu', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  KYS: { name: 'Kayes Dag Dag Airport', city: 'Kayes', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  KYU: { name: 'Koyukuk Airport', city: 'Koyukuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  KYZ: { name: 'Kyzyl Airport', city: 'Kyzyl', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  KZI: { name: 'Filippos Airport', city: 'Kozani', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  KZN: { name: 'Kazan International Airport', city: 'Kazan', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  KZO: { name: 'Kzyl-Orda Southwest Airport', city: 'Kzyl-Orda', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  KZR: { name: 'Zafer Airport', city: 'Kutahya', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  KZS: { name: 'Kastelorizo Airport', city: 'Kastelorizo', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  LAA: { name: 'Lamar Municipal Airport', city: 'Lamar', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LAD: { name: 'Quatro de Fevereiro Airport', city: 'Luanda', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  LAE: { name: 'Nadzab Airport', city: 'Nadzab', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  LAF: { name: 'Purdue University Airport', city: 'Lafayette', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LAI: { name: 'Lannion-Côte de Granit Airport', city: 'Lannion', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LAK: { name: 'Aklavik/Freddie Carmichael Airport', city: 'Aklavik', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  LAL: { name: 'Lakeland Linder Regional Airport', city: 'Lakeland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LAM: { name: 'Los Alamos Airport', city: 'Los Alamos', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LAN: { name: 'Capital City Airport', city: 'Lansing', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LAO: { name: 'Laoag International Airport', city: 'Laoag', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  LAP: { name: 'Manuel Márquez de León International Airport', city: 'La Paz', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  LAQ: { name: 'La Abraq Airport', city: 'Al Bayda\'', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  LAR: { name: 'Laramie Regional Airport', city: 'Laramie', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LAS: { name: 'McCarran International Airport', city: 'Las Vegas', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LAU: { name: 'Manda Airstrip', city: 'Lamu', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  LAW: { name: 'Lawton Fort Sill Regional Airport', city: 'Lawton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LAX: { name: 'Los Angeles International Airport', city: 'Los Angeles', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LAY: { name: 'Ladysmith Airport', city: 'Ladysmith', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  LAZ: { name: 'Bom Jesus da Lapa Airport', city: 'Bom Jesus Da Lapa', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  LBA: { name: 'Leeds Bradford Airport', city: 'Leeds', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LBB: { name: 'Lubbock Preston Smith International Airport', city: 'Lubbock', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LBC: { name: 'Lübeck Blankensee Airport', city: 'Luebeck', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  LBD: { name: 'Khudzhand Airport', city: 'Khudzhand', country: 'Tajikistan', timezone: 'Asia/Dushanbe', offset: 5 },
  LBE: { name: 'Arnold Palmer Regional Airport', city: 'Latrobe', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LBF: { name: 'North Platte Regional Airport Lee Bird Field', city: 'North Platte', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LBG: { name: 'Paris-Le Bourget Airport', city: 'Paris', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LBI: { name: 'Albi-Le Séquestre Airport', city: 'Albi', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LBJ: { name: 'Komodo (Mutiara II) Airport', city: 'Labuhan Bajo', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  LBL: { name: 'Liberal Mid-America Regional Airport', city: 'Liberal', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LBQ: { name: 'Lambarene Airport', city: 'Lambarene', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  LBR: { name: 'Lábrea Airport', city: 'Labrea', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  LBS: { name: 'Labasa Airport', city: 'Lambasa', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  LBT: { name: 'Lumberton Regional Airport', city: 'Lumberton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LBU: { name: 'Labuan Airport', city: 'Labuan', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LBV: { name: 'Libreville Leon M\'ba International Airport', city: 'Libreville', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  LBW: { name: 'Long Bawan Airport', city: 'Long Bawan-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  LBX: { name: 'Lubang Airport', city: 'Lubang', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  LBZ: { name: 'Lucapa Airport', city: 'Lucapa', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  LCA: { name: 'Larnaca International Airport', city: 'Larnaca', country: 'Cyprus', timezone: 'Asia/Nicosia', offset: 2 },
  LCC: { name: 'Lecce Galatina Air Base', city: 'Lecce', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  LCE: { name: 'Goloson International Airport', city: 'La Ceiba', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  LCG: { name: 'A Coruña Airport', city: 'La Coruna', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  LCH: { name: 'Lake Charles Regional Airport', city: 'Lake Charles', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LCJ: { name: 'Łódź Władysław Reymont Airport', city: 'Lodz', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  LCK: { name: 'Rickenbacker International Airport', city: 'Columbus', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LCL: { name: 'La Coloma Airport', city: 'La Coloma', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  LCQ: { name: 'Lake City Gateway Airport', city: 'Lake City', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LCX: { name: 'Longyan Guanzhishan Airport', city: 'Longyan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LCY: { name: 'London City Airport', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LDB: { name: 'Governador José Richa Airport', city: 'Londrina', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  LDE: { name: 'Tarbes-Lourdes-Pyrénées Airport', city: 'Tarbes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LDG: { name: 'Leshukonskoye Airport', city: 'Arkhangelsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  LDH: { name: 'Lord Howe Island Airport', city: 'Lord Howe Island', country: 'Australia', timezone: 'Australia/Lord_Howe', offset: 10 },
  LDI: { name: 'Kikwetu Airport', city: 'Lindi', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  LDJ: { name: 'Linden Airport', city: 'Linden', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LDK: { name: 'Lidköping-Hovby Airport', city: 'Lidkoping', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  LDM: { name: 'Mason County Airport', city: 'Ludington', country: 'United States', timezone: null, offset: -5 },
  LDN: { name: 'Lamidanda Airport', city: 'Lamidanda', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  LDS: { name: 'Lindu Airport', city: 'Yinchun', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LDU: { name: 'Lahad Datu Airport', city: 'Lahad Datu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LDX: { name: 'Saint-Laurent-du-Maroni Airport', city: 'Saint-Laurent-du-Maroni', country: 'French Guiana', timezone: 'America/Cayenne', offset: -3 },
  LDY: { name: 'City of Derry Airport', city: 'Londonderry', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LEA: { name: 'Learmonth Airport', city: 'Learmonth', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  LEB: { name: 'Lebanon Municipal Airport', city: 'Lebanon', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LEC: { name: 'Coronel Horácio de Mattos Airport', city: 'Lençóis', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  LED: { name: 'Pulkovo Airport', city: 'St. Petersburg', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  LEE: { name: 'Leesburg International Airport', city: 'Leesburg', country: 'United States', timezone: null, offset: -5 },
  LEH: { name: 'Le Havre Octeville Airport', city: 'Le Havre', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LEI: { name: 'Almería International Airport', city: 'Almeria', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  LEJ: { name: 'Leipzig Halle Airport', city: 'Leipzig', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  LEK: { name: 'Tata Airport', city: 'Labe', country: 'Guinea', timezone: 'Africa/Conakry', offset: 0 },
  LEL: { name: 'Lake Evella Airport', city: 'Lake Evella', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  LEN: { name: 'Leon Airport', city: 'Leon', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  LEQ: { name: 'Land\'s End Airport', city: 'Land\'s End', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LER: { name: 'Leinster Airport', city: 'Leinster', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  LET: { name: 'Alfredo Vásquez Cobo International Airport', city: 'Leticia', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  LEU: { name: 'Pirineus - la Seu d\'Urgel Airport', city: 'Seo De Urgel', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  LEV: { name: 'Levuka Airfield', city: 'Levuka', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  LEW: { name: 'Auburn Lewiston Municipal Airport', city: 'Lewiston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LEX: { name: 'Blue Grass Airport', city: 'Lexington KY', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LEY: { name: 'Lelystad Airport', city: 'Lelystad', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  LFI: { name: 'Langley Air Force Base', city: 'Hampton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LFK: { name: 'Angelina County Airport', city: 'Lufkin', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LFR: { name: 'La Fria Airport', city: 'La Fria', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  LFT: { name: 'Lafayette Regional Airport', city: 'Lafayette', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LFW: { name: 'Lomé-Tokoin Airport', city: 'Lome', country: 'Togo', timezone: 'Africa/Lome', offset: 0 },
  LGA: { name: 'La Guardia Airport', city: 'New York', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LGB: { name: 'Long Beach /Daugherty Field/ Airport', city: 'Long Beach', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LGC: { name: 'Lagrange Callaway Airport', city: 'LaGrange', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LGD: { name: 'La Grande/Union County Airport', city: 'La Grande', country: 'United States', timezone: null, offset: -7 },
  LGG: { name: 'Liège Airport', city: 'Liege', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  LGH: { name: 'Leigh Creek Airport', city: 'Leigh Creek', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  LGI: { name: 'Deadman\'s Cay Airport', city: 'Dead Man\'s Cay', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  LGK: { name: 'Langkawi International Airport', city: 'Langkawi', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LGL: { name: 'Long Lellang Airport', city: 'Long Datih', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LGO: { name: 'Langeoog Airport', city: 'Langeoog', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  LGP: { name: 'Legazpi City International Airport', city: 'Legazpi', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  LGR: { name: 'Cochrane Airport', city: 'Cochrane', country: 'Chile', timezone: null, offset: 0 },
  LGS: { name: 'Comodoro D.R. Salomón Airport', city: 'Malargue', country: 'Argentina', timezone: 'America/Mendoza', offset: -3 },
  LGU: { name: 'Logan-Cache Airport', city: 'Logan', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LGW: { name: 'London Gatwick Airport', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LHA: { name: 'Lahr Airport', city: 'Lahr', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  LHC: { name: 'Caballococha Airport', city: 'Caballococha', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  LHD: { name: 'Lake Hood Seaplane Base', city: 'Anchorage', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  LHE: { name: 'Alama Iqbal International Airport', city: 'Lahore', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  LHG: { name: 'Lightning Ridge Airport', city: 'Lightning Ridge', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  LHK: { name: 'Guangzhou MR Air Base', city: 'Guanghua', country: 'China', timezone: null, offset: 0 },
  LHM: { name: 'Lincoln Regional Karl Harder Field', city: 'Lincoln', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LHR: { name: 'London Heathrow Airport', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LHS: { name: 'Las Heras Airport', city: 'Las Heras', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  LHV: { name: 'William T. Piper Memorial Airport', city: 'Lock Haven', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LHW: { name: 'Lanzhou Zhongchuan Airport', city: 'Lanzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LHX: { name: 'La Junta Municipal Airport', city: 'La Junta', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LID: { name: 'Valkenburg Naval Air Base', city: 'Valkenburg', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  LIF: { name: 'Lifou Airport', city: 'Lifou', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  LIG: { name: 'Limoges Airport', city: 'Limoges', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LIH: { name: 'Lihue Airport', city: 'Lihue', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  LII: { name: 'Mulia Airport', city: 'Mulia', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  LIL: { name: 'Lille-Lesquin Airport', city: 'Lille', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LIM: { name: 'Jorge Chávez International Airport', city: 'Lima', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  LIN: { name: 'Milano Linate Airport', city: 'Milan', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  LIO: { name: 'Limon International Airport', city: 'Limon', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  LIP: { name: 'Lins Airport', city: 'Lins', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  LIQ: { name: 'Lisala Airport', city: 'Lisala', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  LIR: { name: 'Daniel Oduber Quiros International Airport', city: 'Liberia', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  LIS: { name: 'Lisbon Portela Airport', city: 'Lisbon', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  LIT: { name: 'Bill & Hillary Clinton National Airport/Adams Field', city: 'Little Rock', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LIW: { name: 'Loikaw Airport', city: 'Loikaw', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  LIX: { name: 'Likoma Island Airport', city: 'Likoma Island', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  LJA: { name: 'Lodja Airport', city: 'Lodja', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  LJG: { name: 'Lijiang Airport', city: 'Lijiang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LJU: { name: 'Ljubljana Jože Pučnik Airport', city: 'Ljubljana', country: 'Slovenia', timezone: 'Europe/Ljubljana', offset: 1 },
  LKB: { name: 'Lakeba Island Airport', city: 'Lakeba Island', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  LKG: { name: 'Lokichoggio Airport', city: 'Lokichoggio', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  LKH: { name: 'Long Akah Airport', city: 'Long Akah', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LKL: { name: 'Banak Airport', city: 'Lakselv', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  LKN: { name: 'Leknes Airport', city: 'Leknes', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  LKO: { name: 'Chaudhary Charan Singh International Airport', city: 'Lucknow', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  LKP: { name: 'Lake Placid Airport', city: 'Lake Placid', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LKS: { name: 'Sazená Airport', city: 'Sazena', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  LKY: { name: 'Lake Manyara Airport', city: 'Lake Manyara', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  LLA: { name: 'Luleå Airport', city: 'Lulea', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  LLF: { name: 'Lingling Airport', city: 'Yongzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LLI: { name: 'Lalibella Airport', city: 'Lalibella', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  LLK: { name: 'Lankaran International Airport', city: 'Lankaran', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  LLQ: { name: 'Monticello Municipal Ellis Field', city: 'Monticello', country: 'United States', timezone: null, offset: 0 },
  LLU: { name: 'Alluitsup Paa Heliport', city: 'Alluitsup Paa', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  LLW: { name: 'Lilongwe International Airport', city: 'Lilongwe', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  LME: { name: 'Le Mans-Arnage Airport', city: 'Le Mans', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LMM: { name: 'Valle del Fuerte International Airport', city: 'Los Mochis', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  LMN: { name: 'Limbang Airport', city: 'Limbang', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LMO: { name: 'RAF Lossiemouth', city: 'Lossiemouth', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LMP: { name: 'Lampedusa Airport', city: 'Lampedusa', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  LMR: { name: 'Lime Acres Finsch Mine Airport', city: 'Lime Acres', country: 'South Africa', timezone: null, offset: 0 },
  LMT: { name: 'Klamath Falls Airport', city: 'Klamath Falls', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LNA: { name: 'Palm Beach County Park Airport', city: 'West Palm Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LNB: { name: 'Lamen Bay Airport', city: 'Lamen Bay', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  LND: { name: 'Hunt Field', city: 'Lindau', country: 'Germany', timezone: 'America/Denver', offset: -7 },
  LNE: { name: 'Lonorore Airport', city: 'Lonorore', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  LNK: { name: 'Lincoln Airport', city: 'Lincoln', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LNN: { name: 'Willoughby Lost Nation Municipal Airport', city: 'Willoughby', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LNO: { name: 'Leonora Airport', city: 'Leonora', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  LNR: { name: 'Tri-County Regional Airport', city: 'Lone Rock', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LNS: { name: 'Lancaster Airport', city: 'Lancaster', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LNY: { name: 'Lanai Airport', city: 'Lanai', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  LNZ: { name: 'Linz Hörsching Airport', city: 'Linz', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  LOD: { name: 'Longana Airport', city: 'Longana', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  LOE: { name: 'Loei Airport', city: 'Loei', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  LOH: { name: 'Camilo Ponce Enriquez Airport', city: 'La Toma (Catamayo)', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  LOK: { name: 'Lodwar Airport', city: 'Lodwar', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  LOL: { name: 'Derby Field', city: 'Lovelock', country: 'United States', timezone: null, offset: 0 },
  LOO: { name: 'Laghouat Airport', city: 'Laghouat', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  LOP: { name: 'Lombok International Airport', city: 'Praya', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  LOS: { name: 'Murtala Muhammed International Airport', city: 'Lagos', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  LOT: { name: 'Lewis University Airport', city: 'Lockport', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LOU: { name: 'Bowman Field', city: 'Louisville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LOV: { name: 'Monclova International Airport', city: 'Monclova', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  LOZ: { name: 'London-Corbin Airport/Magee Field', city: 'London', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LPA: { name: 'Gran Canaria Airport', city: 'Gran Canaria', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  LPB: { name: 'El Alto International Airport', city: 'La Paz', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  LPC: { name: 'Lompoc Airport', city: 'Lompoc', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LPD: { name: 'La Pedrera Airport', city: 'La Pedrera', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  LPG: { name: 'La Plata Airport', city: 'La Plata', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  LPI: { name: 'Linköping City Airport', city: 'Linkoeping', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  LPK: { name: 'Lipetsk Airport', city: 'Lipetsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  LPL: { name: 'Liverpool John Lennon Airport', city: 'Liverpool', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LPM: { name: 'Lamap Airport', city: 'Lamap', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  LPP: { name: 'Lappeenranta Airport', city: 'Lappeenranta', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  LPQ: { name: 'Luang Phabang International Airport', city: 'Luang Prabang', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  LPR: { name: 'Lorain County Regional Airport', city: 'Lorain-Elyria', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LPS: { name: 'Lopez Island Airport', city: 'Lopez', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LPT: { name: 'Lampang Airport', city: 'Lampang', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  LPU: { name: 'Long Apung Airport', city: 'Long Apung-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  LPX: { name: 'Liepāja International Airport', city: 'Liepaja', country: 'Latvia', timezone: 'Europe/Riga', offset: 2 },
  LPY: { name: 'Le Puy-Loudes Airport', city: 'Le Puy', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LQM: { name: 'Caucaya Airport', city: 'Puerto Leguízamo', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  LQR: { name: 'Larned Pawnee County Airport', city: 'Larned', country: 'United States', timezone: null, offset: -5 },
  LRA: { name: 'Larisa Airport', city: 'Larissa', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  LRD: { name: 'Laredo International Airport', city: 'Laredo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LRE: { name: 'Longreach Airport', city: 'Longreach', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  LRF: { name: 'Little Rock Air Force Base', city: 'Jacksonville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LRH: { name: 'La Rochelle-Île de Ré Airport', city: 'La Rochelle', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LRL: { name: 'Niamtougou International Airport', city: 'Niatougou', country: 'Togo', timezone: 'Africa/Lome', offset: 0 },
  LRM: { name: 'Casa De Campo International Airport', city: 'La Romana', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  LRO: { name: 'Mt Pleasant Regional-Faison field', city: 'Mount Pleasant', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LRR: { name: 'Lar Airport', city: 'Lar', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  LRS: { name: 'Leros Airport', city: 'Leros', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  LRT: { name: 'Lorient South Brittany (Bretagne Sud) Airport', city: 'Lorient', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LRU: { name: 'Las Cruces International Airport', city: 'Las Cruces', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LRV: { name: 'Los Roques Airport', city: 'Los Roques', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  LSC: { name: 'La Florida Airport', city: 'La Serena', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  LSE: { name: 'La Crosse Municipal Airport', city: 'La Crosse', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LSF: { name: 'Lawson Army Air Field (Fort Benning)', city: 'Fort Benning', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LSH: { name: 'Lashio Airport', city: 'Lashio', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  LSI: { name: 'Sumburgh Airport', city: 'Sumburgh', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LSP: { name: 'Josefa Camejo International Airport', city: 'Paraguana', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  LSQ: { name: 'María Dolores Airport', city: 'Los Angeles', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  LSS: { name: 'Terre-de-Haut Airport', city: 'Les Saintes', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  LST: { name: 'Launceston Airport', city: 'Launceston', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  LSV: { name: 'Nellis Air Force Base', city: 'Las Vegas', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LSW: { name: 'Malikus Saleh Airport', city: 'Lhok Seumawe-Sumatra Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  LSY: { name: 'Lismore Airport', city: 'Lismore', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  LSZ: { name: 'Lošinj Island Airport', city: 'Mali Losinj', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  LTA: { name: 'Tzaneen Airport', city: 'Tzaneen', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  LTD: { name: 'Ghadames East Airport', city: 'Ghadames', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  LTI: { name: 'Altai Airport', city: 'Altai', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  LTK: { name: 'Bassel Al-Assad International Airport', city: 'Latakia', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  LTM: { name: 'Lethem Airport', city: 'Lethem', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  LTN: { name: 'London Luton Airport', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LTO: { name: 'Loreto International Airport', city: 'Loreto', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  LTQ: { name: 'Le Touquet-Côte d\'Opale Airport', city: 'Le Tourquet', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LTS: { name: 'Altus Air Force Base', city: 'Altus', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LTT: { name: 'La Môle Airport', city: 'La Môle', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LTX: { name: 'Cotopaxi International Airport', city: 'Latacunga', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  LUA: { name: 'Lukla Airport', city: 'Lukla', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  LUD: { name: 'Luderitz Airport', city: 'Luderitz', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  LUF: { name: 'Luke Air Force Base', city: 'Phoenix', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  LUG: { name: 'Lugano Airport', city: 'Lugano', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  LUH: { name: 'Ludhiana Airport', city: 'Ludhiaha', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  LUK: { name: 'Cincinnati Municipal Airport Lunken Field', city: 'Cincinnati', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LUM: { name: 'Mangshi Airport', city: 'Luxi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LUN: { name: 'Kenneth Kaunda International Airport Lusaka', city: 'Lusaka', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  LUO: { name: 'Luena Airport', city: 'Luena', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  LUP: { name: 'Kalaupapa Airport', city: 'Molokai', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  LUQ: { name: 'Brigadier Mayor D Cesar Raul Ojeda Airport', city: 'San Luis', country: 'Argentina', timezone: 'America/Argentina/San_Luis', offset: -3 },
  LUR: { name: 'Cape Lisburne LRRS Airport', city: 'Cape Lisburne', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  LUV: { name: 'Dumatumbun Airport', city: 'Langgur-Kei Islands', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  LUW: { name: 'Bubung Airport', city: 'Luwuk', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  LUX: { name: 'Luxembourg-Findel International Airport', city: 'Luxemburg', country: 'Luxembourg', timezone: 'Europe/Luxembourg', offset: 1 },
  LVA: { name: 'Laval-Entrammes Airport', city: 'Laval', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LVI: { name: 'Livingstone Airport', city: 'Livingstone', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  LVK: { name: 'Livermore Municipal Airport', city: 'Livermore', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LVM: { name: 'Mission Field', city: 'Livingston-Montana', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LVO: { name: 'Laverton Airport', city: 'Laverton', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  LVR: { name: 'Fazenda Colen Airport', city: 'Lucas do Rio Verde', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  LVS: { name: 'Las Vegas Municipal Airport', city: 'Las Vegas', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LWA: { name: 'South Haven Area Regional Airport', city: 'South Haven', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LWB: { name: 'Greenbrier Valley Airport', city: 'Lewisburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LWC: { name: 'Lawrence Municipal Airport', city: 'Lawrence', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LWK: { name: 'Lerwick / Tingwall Airport', city: 'Lerwick', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LWM: { name: 'Lawrence Municipal Airport', city: 'Lawrence', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LWN: { name: 'Gyumri Shirak Airport', city: 'Gyumri', country: 'Armenia', timezone: 'Asia/Yerevan', offset: 4 },
  LWO: { name: 'Lviv International Airport', city: 'Lvov', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  LWR: { name: 'Leeuwarden Air Base', city: 'Leeuwarden', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  LWS: { name: 'Lewiston Nez Perce County Airport', city: 'Lewiston', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  LWT: { name: 'Lewistown Municipal Airport', city: 'Lewistown', country: 'United States', timezone: 'America/Denver', offset: -7 },
  LWY: { name: 'Lawas Airport', city: 'Lawas', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  LXA: { name: 'Lhasa Gonggar Airport', city: 'Lhasa', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LXG: { name: 'Luang Namtha Airport', city: 'Luang Namtha', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  LXN: { name: 'Jim Kelly Field', city: 'Lexington', country: 'United States', timezone: null, offset: -5 },
  LXR: { name: 'Luxor International Airport', city: 'Luxor', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  LXS: { name: 'Limnos Airport', city: 'Limnos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  LYA: { name: 'Luoyang Airport', city: 'Luoyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LYB: { name: 'Edward Bodden Airfield', city: 'Little Cayman', country: 'Cayman Islands', timezone: 'America/Cayman', offset: -5 },
  LYC: { name: 'Lycksele Airport', city: 'Lycksele', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  LYE: { name: 'RAF Lyneham', city: 'Lyneham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LYG: { name: 'Lianyungang Airport', city: 'Lianyungang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LYH: { name: 'Lynchburg Regional Preston Glenn Field', city: 'Lynchburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LYI: { name: 'Shubuling Airport', city: 'Linyi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LYM: { name: 'Lympne Airport', city: 'Lympne', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LYN: { name: 'Lyon-Bron Airport', city: 'Lyon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LYP: { name: 'Faisalabad International Airport', city: 'Faisalabad', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  LYR: { name: 'Svalbard Airport, Longyear', city: 'Svalbard', country: 'Norway', timezone: 'Arctic/Longyearbyen', offset: 1 },
  LYS: { name: 'Lyon Saint-Exupéry Airport', city: 'Lyon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  LYU: { name: 'Ely Municipal Airport', city: 'Ely', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  LYX: { name: 'Lydd Airport', city: 'Lydd', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  LZC: { name: 'Lázaro Cárdenas Airport', city: 'Lazard Cardenas', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  LZH: { name: 'Liuzhou Bailian Airport', city: 'Liuzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LZN: { name: 'Matsu Nangan Airport', city: 'Matsu Islands', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  LZO: { name: 'Luzhou Airport', city: 'Luzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LZR: { name: 'Lizard Island Airport', city: 'Lizard Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  LZU: { name: 'Gwinnett County Briscoe Field', city: 'Lawrenceville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  LZY: { name: 'Nyingchi Airport', city: 'Nyingchi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  LZZ: { name: 'Lampasas Airport', city: 'Lampasas', country: 'United States', timezone: null, offset: -5 },
  M94: { name: 'Desert Aire Airport', city: 'Mattawa', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MAA: { name: 'Chennai International Airport', city: 'Madras', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  MAB: { name: 'João Correa da Rocha Airport', city: 'Maraba', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  MAD: { name: 'Adolfo Suárez Madrid–Barajas Airport', city: 'Madrid', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  MAE: { name: 'Madera Municipal Airport', city: 'Madera', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MAF: { name: 'Midland International Airport', city: 'Midland', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MAG: { name: 'Madang Airport', city: 'Madang', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  MAH: { name: 'Menorca Airport', city: 'Menorca', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  MAI: { name: 'Mangochi Airport', city: 'Mangochi', country: 'Malawi', timezone: null, offset: 0 },
  MAJ: { name: 'Marshall Islands International Airport', city: 'Majuro', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  MAK: { name: 'Malakal Airport', city: 'Malakal', country: 'Sudan', timezone: 'Africa/Juba', offset: 3 },
  MAM: { name: 'General Servando Canales International Airport', city: 'Matamoros', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MAN: { name: 'Manchester Airport', city: 'Manchester', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  MAO: { name: 'Eduardo Gomes International Airport', city: 'Manaus', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  MAQ: { name: 'Mae Sot Airport', city: 'Tak', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  MAR: { name: 'La Chinita International Airport', city: 'Maracaibo', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  MAS: { name: 'Momote Airport', city: 'Momote', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  MAT: { name: 'Tshimpi Airport', city: 'Matadi', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  MAU: { name: 'Maupiti Airport', city: 'Maupiti', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  MAX: { name: 'Ouro Sogui Airport', city: 'Matam', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  MAZ: { name: 'Eugenio Maria De Hostos Airport', city: 'Mayaguez', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  MBA: { name: 'Mombasa Moi International Airport', city: 'Mombasa', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  MBD: { name: 'Mmabatho International Airport', city: 'Mafeking', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  MBE: { name: 'Monbetsu Airport', city: 'Monbetsu', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MBG: { name: 'Mobridge Municipal Airport', city: 'Mobridge', country: 'United States', timezone: null, offset: 0 },
  MBH: { name: 'Maryborough Airport', city: 'Maryborough', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  MBI: { name: 'Mbeya Airport', city: 'Mbeya', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  MBJ: { name: 'Sangster International Airport', city: 'Montego Bay', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  MBL: { name: 'Manistee Co Blacker Airport', city: 'Manistee', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MBS: { name: 'MBS International Airport', city: 'Saginaw', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MBT: { name: 'Moises R. Espinosa Airport', city: 'Masbate', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  MBU: { name: 'Babanakira Airport', city: 'Mbambanakira', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  MBW: { name: 'Melbourne Moorabbin Airport', city: 'Melbourne', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  MBX: { name: 'Maribor Airport', city: 'Maribor', country: 'Slovenia', timezone: 'Europe/Ljubljana', offset: 1 },
  MBZ: { name: 'Maués Airport', city: 'Maues', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  MCB: { name: 'Mc Comb/Pike County Airport/John E Lewis Field', city: 'Mc Comb', country: 'United States', timezone: null, offset: 0 },
  MCC: { name: 'Mc Clellan Airfield', city: 'Sacramento', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MCE: { name: 'Merced Regional Macready Field', city: 'Merced', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MCF: { name: 'Mac Dill Air Force Base', city: 'Tampa', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MCG: { name: 'McGrath Airport', city: 'Mcgrath', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MCH: { name: 'General Manuel Serrano Airport', city: 'Machala', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  MCI: { name: 'Kansas City International Airport', city: 'Kansas City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MCK: { name: 'Mc Cook Ben Nelson Regional Airport', city: 'McCook', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MCL: { name: 'McKinley National Park Airport', city: 'McKinley Park', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MCN: { name: 'Middle Georgia Regional Airport', city: 'Macon', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MCO: { name: 'Orlando International Airport', city: 'Orlando', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MCP: { name: 'Alberto Alcolumbre Airport', city: 'Macapa', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  MCT: { name: 'Muscat International Airport', city: 'Muscat', country: 'Oman', timezone: 'Asia/Muscat', offset: 4 },
  MCU: { name: 'Montluçon-Guéret Airport', city: 'Montlucon-gueret', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MCV: { name: 'McArthur River Mine Airport', city: 'McArthur River Mine', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  MCW: { name: 'Mason City Municipal Airport', city: 'Mason City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MCX: { name: 'Uytash Airport', city: 'Makhachkala', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  MCY: { name: 'Sunshine Coast Airport', city: 'Maroochydore', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  MCZ: { name: 'Zumbi dos Palmares Airport', city: 'Maceio', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  MDC: { name: 'Sam Ratulangi Airport', city: 'Manado', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  MDE: { name: 'Jose Maria Córdova International Airport', city: 'Rio Negro', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  MDG: { name: 'Mudanjiang Hailang International Airport', city: 'Mudanjiang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  MDH: { name: 'Southern Illinois Airport', city: 'Carbondale/Murphysboro', country: 'United States', timezone: null, offset: 0 },
  MDI: { name: 'Makurdi Airport', city: 'Makurdi', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  MDK: { name: 'Mbandaka Airport', city: 'Mbandaka', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  MDL: { name: 'Mandalay International Airport', city: 'Mandalay', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MDO: { name: 'El Jagüel / Punta del Este Airport', city: 'Maldonado', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  MDQ: { name: 'Ástor Piazzola International Airport', city: 'Mar Del Plata', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  MDS: { name: 'Middle Caicos Airport', city: 'Middle Caicos', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  MDT: { name: 'Harrisburg International Airport', city: 'Harrisburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MDU: { name: 'Mendi Airport', city: 'Mendi', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  MDW: { name: 'Chicago Midway International Airport', city: 'Chicago', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MDY: { name: 'Henderson Field', city: 'Midway', country: 'Midway Islands', timezone: 'Pacific/Midway', offset: -11 },
  MDZ: { name: 'El Plumerillo Airport', city: 'Mendoza', country: 'Argentina', timezone: 'America/Mendoza', offset: -3 },
  MEA: { name: 'Macaé Airport', city: 'Macaé', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  MEB: { name: 'Melbourne Essendon Airport', city: 'Melbourne', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  MEC: { name: 'Eloy Alfaro International Airport', city: 'Manta', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  MED: { name: 'Prince Mohammad Bin Abdulaziz Airport', city: 'Madinah', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  MEE: { name: 'Maré Airport', city: 'Mare', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  MEG: { name: 'Malanje Airport', city: 'Malanje', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  MEH: { name: 'Mehamn Airport', city: 'Mehamn', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  MEI: { name: 'Key Field', city: 'Meridian', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MEJ: { name: 'Meade Municipal Airport', city: 'Meade', country: 'United States', timezone: null, offset: -5 },
  MEK: { name: 'Bassatine Airport', city: 'Meknes', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  MEL: { name: 'Melbourne International Airport', city: 'Melbourne', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  MEM: { name: 'Memphis International Airport', city: 'Memphis', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MEN: { name: 'Mende-Brenoux Airport', city: 'Mende', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MEO: { name: 'Dare County Regional Airport', city: 'Manteo', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MEQ: { name: 'Seunagan Airport', city: 'Nagan Raya', country: 'Indonesia', timezone: null, offset: 7 },
  MER: { name: 'Castle Airport', city: 'Merced', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MES: { name: 'Soewondo Air Force Base', city: 'Medan', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  MEX: { name: 'Licenciado Benito Juarez International Airport', city: 'Mexico City', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MEY: { name: 'Meghauli Airport', city: 'Meghauli', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  MEZ: { name: 'Morningside Farm Airport', city: 'Musina', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  MFA: { name: 'Mafia Island Airport', city: 'Mafia Island', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  MFD: { name: 'Mansfield Lahm Regional Airport', city: 'Mansfield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MFE: { name: 'Mc Allen Miller International Airport', city: 'Mcallen', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MFG: { name: 'Muzaffarabad Airport', city: 'Muzaffarabad', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  MFI: { name: 'Marshfield Municipal Airport', city: 'Marshfield', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MFJ: { name: 'Moala Airport', city: 'Moala', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  MFK: { name: 'Matsu Beigan Airport', city: 'Matsu Islands', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  MFM: { name: 'Macau International Airport', city: 'Macau', country: 'Macau', timezone: 'Asia/Macau', offset: 8 },
  MFN: { name: 'Milford Sound Airport', city: 'Milford Sound', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  MFQ: { name: 'Maradi Airport', city: 'Maradi', country: 'Niger', timezone: 'Africa/Niamey', offset: 1 },
  MFR: { name: 'Rogue Valley International Medford Airport', city: 'Medford', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MFU: { name: 'Mfuwe Airport', city: 'Mfuwe', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  MFX: { name: 'Méribel Altiport', city: 'Ajaccio', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MGA: { name: 'Augusto C. Sandino (Managua) International Airport', city: 'Managua', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  MGB: { name: 'Mount Gambier Airport', city: 'Mount Gambier', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  MGC: { name: 'Michigan City Municipal Airport', city: 'Michigan City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MGE: { name: 'Dobbins Air Reserve Base', city: 'Marietta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MGF: { name: 'Regional de Maringá - Sílvio Nane Junior Airport', city: 'Maringa', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  MGH: { name: 'Margate Airport', city: 'Margate', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  MGJ: { name: 'Orange County Airport', city: 'Montgomery', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MGL: { name: 'Mönchengladbach Airport', city: 'Moenchengladbach', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  MGM: { name: 'Montgomery Regional (Dannelly Field) Airport', city: 'MONTGOMERY', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MGN: { name: 'Baracoa Airport', city: 'Magangue', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  MGQ: { name: 'Aden Adde International Airport', city: 'Mogadishu', country: 'Somalia', timezone: 'Africa/Mogadishu', offset: 3 },
  MGS: { name: 'Mangaia Island Airport', city: 'Mangaia Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  MGT: { name: 'Milingimbi Airport', city: 'Milingimbi', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  MGW: { name: 'Morgantown Municipal Walter L. Bill Hart Field', city: 'Morgantown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MGY: { name: 'Dayton-Wright Brothers Airport', city: 'Dayton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MGZ: { name: 'Myeik Airport', city: 'Myeik', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MHA: { name: 'Mahdia Airport', city: 'Mahdia', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  MHC: { name: 'Mocopulli Airport', city: 'Castro', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  MHD: { name: 'Mashhad International Airport', city: 'Mashhad', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  MHG: { name: 'Mannheim-City Airport', city: 'Mannheim', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  MHH: { name: 'Marsh Harbour International Airport', city: 'Marsh Harbor', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  MHK: { name: 'Manhattan Regional Airport', city: 'Manhattan', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MHM: { name: 'Minchumina Airport', city: 'Lake Minchumina', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MHP: { name: 'Minsk 1 Airport', city: 'Minsk', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  MHQ: { name: 'Mariehamn Airport', city: 'Mariehamn', country: 'Finland', timezone: 'Europe/Mariehamn', offset: 2 },
  MHR: { name: 'Sacramento Mather Airport', city: 'Sacramento', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MHT: { name: 'Manchester Airport', city: 'Manchester NH', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MHU: { name: 'Mount Hotham Airport', city: 'Mount Hotham', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  MHV: { name: 'Mojave Airport', city: 'Mojave', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MHX: { name: 'Manihiki Island Airport', city: 'Manihiki Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  MHZ: { name: 'RAF Mildenhall', city: 'Mildenhall', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  MIA: { name: 'Miami International Airport', city: 'Miami', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MIB: { name: 'Minot Air Force Base', city: 'Minot', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MIC: { name: 'Crystal Airport', city: 'Crystal', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MID: { name: 'Licenciado Manuel Crescencio Rejon Int Airport', city: 'Merida', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MIE: { name: 'Delaware County Johnson Field', city: 'Muncie', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MIG: { name: 'Mianyang Airport', city: 'Mianyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  MII: { name: 'Frank Miloye Milenkowichi–Marília State Airport', city: 'Marília', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  MIJ: { name: 'Mili Island Airport', city: 'Mili Island', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  MIK: { name: 'Mikkeli Airport', city: 'Mikkeli', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  MIM: { name: 'Merimbula Airport', city: 'Merimbula', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  MIR: { name: 'Monastir Habib Bourguiba International Airport', city: 'Monastir', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  MIS: { name: 'Misima Island Airport', city: 'Misima Island', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  MIU: { name: 'Maiduguri International Airport', city: 'Maiduguri', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  MIV: { name: 'Millville Municipal Airport', city: 'Millville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MJA: { name: 'Manja Airport', city: 'Manja', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MJC: { name: 'Man Airport', city: 'Man', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  MJD: { name: 'Moenjodaro Airport', city: 'Moenjodaro', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  MJF: { name: 'Mosjøen Airport (Kjærstad)', city: 'Mosjoen', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  MJI: { name: 'Mitiga Airport', city: 'Tripoli', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  MJK: { name: 'Shark Bay Airport', city: 'Shark Bay', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  MJL: { name: 'Mouilla Ville Airport', city: 'Mouila', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  MJM: { name: 'Mbuji Mayi Airport', city: 'Mbuji-mayi', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  MJN: { name: 'Amborovy Airport', city: 'Mahajanga', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MJT: { name: 'Mytilene International Airport', city: 'Mytilini', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  MJV: { name: 'San Javier Airport', city: 'Murcia', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  MJZ: { name: 'Mirny Airport', city: 'Mirnyj', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  MKC: { name: 'Charles B. Wheeler Downtown Airport', city: 'Kansas City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MKE: { name: 'General Mitchell International Airport', city: 'Milwaukee', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MKG: { name: 'Muskegon County Airport', city: 'Muskegon', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MKK: { name: 'Molokai Airport', city: 'Molokai', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  MKL: { name: 'Mc Kellar Sipes Regional Airport', city: 'Jackson', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MKM: { name: 'Mukah Airport', city: 'Mukah', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  MKO: { name: 'Davis Field', city: 'Muskogee', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MKP: { name: 'Makemo Airport', city: 'Makemo', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  MKQ: { name: 'Mopah Airport', city: 'Merauke', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  MKR: { name: 'Meekatharra Airport', city: 'Meekatharra', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  MKS: { name: 'Mekane Selam Airport', city: 'Mekane Selam', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  MKT: { name: 'Mankato Regional Airport', city: 'Mankato', country: 'United States', timezone: null, offset: -6 },
  MKU: { name: 'Makokou Airport', city: 'Makokou', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  MKW: { name: 'Rendani Airport', city: 'Manokwari', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  MKY: { name: 'Mackay Airport', city: 'Mackay', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  MKZ: { name: 'Malacca Airport', city: 'Malacca', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  MLA: { name: 'Malta International Airport', city: 'Malta', country: 'Malta', timezone: 'Europe/Malta', offset: 1 },
  MLB: { name: 'Melbourne International Airport', city: 'Melbourne', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MLC: { name: 'Mc Alester Regional Airport', city: 'Mcalester', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MLD: { name: 'Malad City Airport', city: 'Malad City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MLE: { name: 'Malé International Airport', city: 'Male', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  MLG: { name: 'Abdul Rachman Saleh Airport', city: 'Malang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  MLI: { name: 'Quad City International Airport', city: 'Moline', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MLJ: { name: 'Baldwin County Airport', city: 'Milledgeville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MLL: { name: 'Marshall Don Hunter Sr Airport', city: 'Marshall', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MLM: { name: 'General Francisco J. Mujica International Airport', city: 'Morelia', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MLN: { name: 'Melilla Airport', city: 'Melilla', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  MLO: { name: 'Milos Airport', city: 'Milos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  MLS: { name: 'Frank Wiley Field', city: 'Miles City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MLT: { name: 'Millinocket Municipal Airport', city: 'Millinocket', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MLU: { name: 'Monroe Regional Airport', city: 'Monroe', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MLW: { name: 'Spriggs Payne Airport', city: 'Monrovia', country: 'Liberia', timezone: 'Africa/Monrovia', offset: 0 },
  MLX: { name: 'Malatya Tulga Airport', city: 'Malatya', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  MLY: { name: 'Manley Hot Springs Airport', city: 'Manley Hot Springs', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MLZ: { name: 'Cerro Largo International Airport', city: 'Melo', country: 'Uruguay', timezone: null, offset: -3 },
  MMB: { name: 'Memanbetsu Airport', city: 'Memanbetsu', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MMD: { name: 'Minami-Daito Airport', city: 'Minami Daito', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MME: { name: 'Durham Tees Valley Airport', city: 'Teesside', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  MMG: { name: 'Mount Magnet Airport', city: 'Mount Magnet', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  MMH: { name: 'Mammoth Yosemite Airport', city: 'Mammoth Lakes', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MMI: { name: 'McMinn County Airport', city: 'Athens', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MMJ: { name: 'Matsumoto Airport', city: 'Matsumoto', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MMK: { name: 'Murmansk Airport', city: 'Murmansk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  MMO: { name: 'Maio Airport', city: 'Maio', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  MMT: { name: 'Mc Entire Joint National Guard Base', city: 'Eastover', country: 'United States', timezone: null, offset: 0 },
  MMU: { name: 'Morristown Municipal Airport', city: 'Morristown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MMV: { name: 'Mc Minnville Municipal Airport', city: 'Mackminnville', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MMX: { name: 'Malmö Sturup Airport', city: 'Malmoe', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  MMY: { name: 'Miyako Airport', city: 'Miyako', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MMZ: { name: 'Maimana Airport', city: 'Maimama', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  MNA: { name: 'Melangguane Airport', city: 'Melonguane', country: 'Indonesia', timezone: null, offset: 8 },
  MNB: { name: 'Muanda Airport', city: 'Muanda', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  MNC: { name: 'Nacala Airport', city: 'Nacala', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  MNF: { name: 'Mana Island Airport', city: 'Mana Island', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  MNG: { name: 'Maningrida Airport', city: 'Maningrida', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  MNI: { name: 'John A. Osborne Airport', city: 'Geralds', country: 'Montserrat', timezone: 'America/Montserrat', offset: -4 },
  MNJ: { name: 'Mananjary Airport', city: 'Mananjary', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MNK: { name: 'Maiana Airport', city: 'Maiana', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  MNL: { name: 'Ninoy Aquino International Airport', city: 'Manila', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  MNM: { name: 'Menominee Marinette Twin County Airport', city: 'Macon', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MNU: { name: 'Mawlamyine Airport', city: 'Mawlamyine', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MNX: { name: 'Manicoré Airport', city: 'Manicore', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  MNY: { name: 'Mono Airport', city: 'Stirling Island', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  MNZ: { name: 'Manassas Regional Airport/Harry P. Davis Field', city: 'Manassas', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MOA: { name: 'Orestes Acosta Airport', city: 'Moa', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  MOB: { name: 'Mobile Regional Airport', city: 'Mobile', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MOC: { name: 'Mário Ribeiro Airport', city: 'Montes Claros', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  MOD: { name: 'Modesto City Co-Harry Sham Field', city: 'Modesto', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MOF: { name: 'Maumere(Wai Oti) Airport', city: 'Maumere', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  MOG: { name: 'Mong Hsat Airport', city: 'Mong Hsat', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MOI: { name: 'Mitiaro Island Airport', city: 'Mitiaro Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  MOL: { name: 'Molde Airport', city: 'Molde', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  MON: { name: 'Mount Cook Airport', city: 'Mount Cook', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  MOO: { name: 'Moomba Airport', city: 'Moomba', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  MOQ: { name: 'Morondava Airport', city: 'Morondava', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MOT: { name: 'Minot International Airport', city: 'Minot', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MOU: { name: 'Mountain Village Airport', city: 'Mountain Village', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MOV: { name: 'Moranbah Airport', city: 'Moranbah', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  MOZ: { name: 'Moorea Airport', city: 'Moorea', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  MPA: { name: 'Katima Mulilo Airport', city: 'Mpacha', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  MPD: { name: 'Mirpur Khas Air Base', city: 'Mirpur Khas', country: 'Pakistan', timezone: null, offset: 0 },
  MPH: { name: 'Godofredo P. Ramos Airport', city: 'Caticlan', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  MPK: { name: 'Mokpo Heliport', city: 'Mokpo', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  MPL: { name: 'Montpellier-Méditerranée Airport', city: 'Montpellier', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MPM: { name: 'Maputo Airport', city: 'Maputo', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  MPN: { name: 'Mount Pleasant Airport', city: 'Mount Pleasant', country: 'Falkland Islands', timezone: 'Atlantic/Stanley', offset: -3 },
  MPO: { name: 'Pocono Mountains Municipal Airport', city: 'Mount Pocono', country: 'United States', timezone: null, offset: -5 },
  MPR: { name: 'Mc Pherson Airport', city: 'Mc Pherson', country: 'United States', timezone: null, offset: -5 },
  MPV: { name: 'Edward F Knapp State Airport', city: 'Montpelier', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MPW: { name: 'Mariupol International Airport', city: 'Mariupol International', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  MPY: { name: 'Maripasoula Airport', city: 'Maripasoula', country: 'French Guiana', timezone: 'America/Cayenne', offset: -3 },
  MQC: { name: 'Miquelon Airport', city: 'Miquelon', country: 'Saint Pierre and Miquelon', timezone: 'America/Miquelon', offset: -3 },
  MQF: { name: 'Magnitogorsk International Airport', city: 'Magnetiogorsk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  MQH: { name: 'Minaçu Airport', city: 'Minacu', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  MQL: { name: 'Mildura Airport', city: 'Mildura', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  MQM: { name: 'Mardin Airport', city: 'Mardin', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  MQN: { name: 'Mo i Rana Airport, Røssvoll', city: 'Mo i Rana', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  MQP: { name: 'Kruger Mpumalanga International Airport', city: 'Mpumalanga', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  MQQ: { name: 'Moundou Airport', city: 'Moundou', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  MQS: { name: 'Mustique Airport', city: 'Mustique', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: -4 },
  MQT: { name: 'Sawyer International Airport', city: 'Gwinn', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MQX: { name: 'Mekele Airport', city: 'Makale', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  MQZ: { name: 'Marandúa Air Base', city: 'Marandúa', country: 'Colombia', timezone: null, offset: 0 },
  MRB: { name: 'Eastern WV Regional Airport/Shepherd Field', city: 'Martinsburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MRD: { name: 'Alberto Carnevalli Airport', city: 'Merida', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  MRE: { name: 'Mara Serena Lodge Airstrip', city: 'Masai Mara', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  MRF: { name: 'Marfa Municipal Airport', city: 'Marfa', country: 'United States', timezone: null, offset: -5 },
  MRG: { name: 'Mareeba Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  MRI: { name: 'Merrill Field', city: 'Anchorage', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MRK: { name: 'Marco Island Airport', city: 'Marco Island Airport', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MRN: { name: 'Foothills Regional Airport', city: 'Morganton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MRO: { name: 'Hood Airport', city: 'Masterton', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  MRQ: { name: 'Marinduque Airport', city: 'Gasan', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  MRS: { name: 'Marseille Provence Airport', city: 'Marseille', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MRT: { name: 'Union County Airport', city: 'Marysville', country: 'United States', timezone: null, offset: -5 },
  MRU: { name: 'Sir Seewoosagur Ramgoolam International Airport', city: 'Plaisance', country: 'Mauritius', timezone: 'Indian/Mauritius', offset: 4 },
  MRV: { name: 'Mineralnyye Vody Airport', city: 'Mineralnye Vody', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  MRX: { name: 'Mahshahr Airport', city: 'Bandar Mahshahr', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  MRY: { name: 'Monterey Peninsula Airport', city: 'Monterey', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MRZ: { name: 'Moree Airport', city: 'Moree', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  MSA: { name: 'Muskrat Dam Airport', city: 'Muskrat Dam', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  MSE: { name: 'Kent International Airport', city: 'Manston', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  MSH: { name: 'Masirah Air Base', city: 'Masirah', country: 'Oman', timezone: 'Asia/Muscat', offset: 4 },
  MSI: { name: 'Fazenda Palmital Airport', city: 'South Aari Atoll', country: 'Maldives', timezone: 'America/Sao_Paulo', offset: -3 },
  MSJ: { name: 'Misawa Air Base', city: 'Misawa', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MSL: { name: 'Northwest Alabama Regional Airport', city: 'Muscle Shoals', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MSN: { name: 'Dane County Regional Truax Field', city: 'Madison', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MSO: { name: 'Missoula International Airport', city: 'Missoula', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MSP: { name: 'Minneapolis-St Paul International/Wold-Chamberlain Airport', city: 'Minneapolis', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MSQ: { name: 'Minsk National Airport', city: 'Minsk 2', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  MSR: { name: 'Muş Airport', city: 'Mus', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  MSS: { name: 'Massena International Richards Field', city: 'Massena', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MST: { name: 'Maastricht Aachen Airport', city: 'Maastricht', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  MSU: { name: 'Moshoeshoe I International Airport', city: 'Maseru', country: 'Lesotho', timezone: 'Africa/Maseru', offset: 2 },
  MSW: { name: 'Massawa International Airport', city: 'Massawa', country: 'Eritrea', timezone: 'Africa/Asmera', offset: 3 },
  MSY: { name: 'Louis Armstrong New Orleans International Airport', city: 'New Orleans', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MSZ: { name: 'Namibe Airport', city: 'Mocamedes', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  MTC: { name: 'Selfridge Angb Airport', city: 'Mount Clemens', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MTF: { name: 'Mizan Teferi Airport', city: 'Mizan Teferi', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  MTH: { name: 'The Florida Keys Marathon Airport', city: 'Marathon', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MTJ: { name: 'Montrose Regional Airport', city: 'Montrose CO', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MTK: { name: 'Makin Island Airport', city: 'Makin', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  MTL: { name: 'Maitland Airport', city: 'Maitland', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  MTM: { name: 'Metlakatla Seaplane Base', city: 'Metakatla', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MTN: { name: 'Martin State Airport', city: 'Baltimore', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MTP: { name: 'Montauk Airport', city: 'Montauk', country: 'United States', timezone: null, offset: -4 },
  MTR: { name: 'Los Garzones Airport', city: 'Monteria', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  MTS: { name: 'Matsapha Airport', city: 'Manzini', country: 'Swaziland', timezone: 'Africa/Mbabane', offset: 2 },
  MTT: { name: 'Minatitlán/Coatzacoalcos National Airport', city: 'Minatitlan', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MTV: { name: 'Mota Lava Airport', city: 'Ablow', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  MTY: { name: 'General Mariano Escobedo International Airport', city: 'Monterrey', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  MUA: { name: 'Munda Airport', city: 'Munda', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  MUB: { name: 'Maun Airport', city: 'Maun', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  MUC: { name: 'Munich International Airport', city: 'Munich', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  MUE: { name: 'Waimea Kohala Airport', city: 'Kamuela', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  MUH: { name: 'Mersa Matruh Airport', city: 'Mersa-matruh', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  MUI: { name: 'Muir Army Air Field (Fort Indiantown Gap) Airport', city: 'Muir', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MUK: { name: 'Mauke Airport', city: 'Mauke Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  MUN: { name: 'Maturín Airport', city: 'Maturin', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  MUO: { name: 'Mountain Home Air Force Base', city: 'Mountain Home', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MUR: { name: 'Marudi Airport', city: 'Marudi', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  MUW: { name: 'Ghriss Airport', city: 'Ghriss', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  MUX: { name: 'Multan International Airport', city: 'Multan', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  MUZ: { name: 'Musoma Airport', city: 'Musoma', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  MVA: { name: 'Reykjahlíð Airport', city: 'Myvatn', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  MVB: { name: 'M\'Vengue El Hadj Omar Bongo Ondimba International Airport', city: 'Franceville', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  MVD: { name: 'Carrasco International /General C L Berisso Airport', city: 'Montevideo', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  MVF: { name: 'Dix-Sept Rosado Airport', city: 'Mocord', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  MVL: { name: 'Morrisville Stowe State Airport', city: 'Morrisville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MVP: { name: 'Fabio Alberto Leon Bentley Airport', city: 'Mitu', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  MVQ: { name: 'Mogilev Airport', city: 'Mogilev', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  MVR: { name: 'Salak Airport', city: 'Maroua', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  MVS: { name: 'Mucuri Airport', city: 'Mucuri', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  MVT: { name: 'Mataiva Airport', city: 'Mataiva', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  MVV: { name: 'Megève Airport', city: 'Verdun', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MVY: { name: 'Martha\'s Vineyard Airport', city: 'Vineyard Haven MA', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MVZ: { name: 'Masvingo International Airport', city: 'Masvingo', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  MWA: { name: 'Williamson County Regional Airport', city: 'Marion', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MWC: { name: 'Lawrence J Timmerman Airport', city: 'Milwaukee', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MWE: { name: 'Merowe New Airport', city: 'Merowe', country: 'Sudan', timezone: null, offset: 0 },
  MWF: { name: 'Maewo-Naone Airport', city: 'Maewo Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  MWH: { name: 'Grant County International Airport', city: 'Grant County Airport', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MWK: { name: 'Tarempa Airport', city: 'Anambas Islands', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  MWL: { name: 'Mineral Wells Airport', city: 'Mineral Wells', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MWM: { name: 'Windom Municipal Airport', city: 'Windom', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MWQ: { name: 'Magway Airport', city: 'Magwe', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MWX: { name: 'Muan International Airport', city: 'Muan', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  MWZ: { name: 'Mwanza Airport', city: 'Mwanza', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  MXF: { name: 'Maxwell Air Force Base', city: 'Montgomery', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  MXH: { name: 'Moro Airport', city: 'Moro', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  MXI: { name: 'Mati National Airport', city: null, country: 'Philippines', timezone: null, offset: 0 },
  MXJ: { name: 'Minna Airport', city: 'Minna', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  MXL: { name: 'General Rodolfo Sánchez Taboada International Airport', city: 'Mexicali', country: 'Mexico', timezone: 'America/Tijuana', offset: -8 },
  MXM: { name: 'Morombe Airport', city: 'Morombe', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MXN: { name: 'Morlaix-Ploujean Airport', city: 'Morlaix', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MXP: { name: 'Malpensa International Airport', city: 'Milano', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  MXS: { name: 'Maota Airport', city: 'Savaii Island', country: 'Samoa', timezone: 'Pacific/Apia', offset: 13 },
  MXT: { name: 'Maintirano Airport', city: 'Maintirano', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  MXV: { name: 'Mörön Airport', city: 'Muren', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  MXX: { name: 'Mora Airport', city: 'Mora', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  MXZ: { name: 'Meixian Airport', city: 'Meixian', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  MYA: { name: 'Moruya Airport', city: 'Moruya', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  MYB: { name: 'Mayumba Airport', city: 'Mayumba', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  MYC: { name: 'Escuela Mariscal Sucre Airport', city: 'Maracay', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  MYD: { name: 'Malindi Airport', city: 'Malindi', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  MYE: { name: 'Miyakejima Airport', city: 'Miyakejima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MYG: { name: 'Mayaguana Airport', city: 'Mayaguana', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  MYI: { name: 'Murray Island Airport', city: 'Murray Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  MYJ: { name: 'Matsuyama Airport', city: 'Matsuyama', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  MYL: { name: 'McCall Municipal Airport', city: 'McCall', country: 'United States', timezone: 'America/Denver', offset: -7 },
  MYP: { name: 'Mary Airport', city: 'Mary', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 5 },
  MYQ: { name: 'Mysore Airport', city: 'Mysore', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  MYR: { name: 'Myrtle Beach International Airport', city: 'Myrtle Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  MYT: { name: 'Myitkyina Airport', city: 'Myitkyina', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  MYU: { name: 'Mekoryuk Airport', city: 'Mekoryuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  MYV: { name: 'Yuba County Airport', city: 'Yuba City', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  MYW: { name: 'Mtwara Airport', city: 'Mtwara', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  MYY: { name: 'Miri Airport', city: 'Miri', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  MZB: { name: 'Mocímboa da Praia Airport', city: 'Mocimboa Da Praia', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  MZG: { name: 'Makung Airport', city: 'Makung', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  MZH: { name: 'Amasya Merzifon Airport', city: 'Merzifon', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  MZI: { name: 'Mopti Airport', city: 'Mopti', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  MZJ: { name: 'Pinal Airpark', city: 'Marana', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  MZK: { name: 'Marakei Airport', city: 'Marakei', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  MZL: { name: 'La Nubia Airport', city: 'Manizales', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  MZM: { name: 'Metz-Frescaty (BA 128) Air Base', city: 'Metz', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  MZO: { name: 'Sierra Maestra Airport', city: 'Manzanillo', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  MZP: { name: 'Motueka Airport', city: 'Motueka', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  MZR: { name: 'Mazar I Sharif Airport', city: 'Mazar-i-sharif', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  MZT: { name: 'General Rafael Buelna International Airport', city: 'Mazatlan', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  MZV: { name: 'Mulu Airport', city: 'Mulu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  'N/A': { name: 'Francisco de Miranda Airport', city: 'Caracas', country: 'Venezuela', timezone: null, offset: -4 },
  NAA: { name: 'Narrabri Airport', city: 'Narrabri', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  NAG: { name: 'Dr. Babasaheb Ambedkar International Airport', city: 'Nagpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  NAH: { name: 'Naha Airport', city: 'Naha', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  NAI: { name: 'Annai Airport', city: 'Annai', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  NAJ: { name: 'Nakhchivan Airport', city: 'Nakhchivan', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  NAK: { name: 'Nakhon Ratchasima Airport', city: 'Nakhon Ratchasima', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  NAL: { name: 'Nalchik Airport', city: 'Nalchik', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  NAN: { name: 'Nadi International Airport', city: 'Nandi', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  NAO: { name: 'Nanchong Airport', city: 'Nanchong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NAP: { name: 'Naples International Airport', city: 'Naples', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  NAQ: { name: 'Qaanaaq Airport', city: 'Qaanaaq', country: 'Greenland', timezone: 'America/Thule', offset: -4 },
  NAS: { name: 'Lynden Pindling International Airport', city: 'Nassau', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  NAT: { name: 'Governador Aluízio Alves International Airport', city: 'Natal', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  NAV: { name: 'Nevşehir Kapadokya Airport', city: 'Nevsehir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  NAW: { name: 'Narathiwat Airport', city: 'Narathiwat', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  NAY: { name: 'Beijing Nanyuan Airport', city: 'Beijing', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NBB: { name: 'Berezovo Airport', city: 'Berezovo', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NBC: { name: 'Begishevo Airport', city: 'Nizhnekamsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  NBG: { name: 'New Orleans NAS JRB/Alvin Callender Field', city: 'New Orleans', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NBO: { name: 'Jomo Kenyatta International Airport', city: 'Nairobi', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  NBX: { name: 'Nabire Airport', city: 'Nabire', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  NCA: { name: 'North Caicos Airport', city: 'North Caicos', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  NCE: { name: 'Nice-Côte d\'Azur Airport', city: 'Nice', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  NCL: { name: 'Newcastle Airport', city: 'Newcastle', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NCN: { name: 'Chenega Bay Airport', city: 'Chenega', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NCS: { name: 'Newcastle Airport', city: 'Newcastle', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  NCU: { name: 'Nukus Airport', city: 'Nukus', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  NCY: { name: 'Annecy-Haute-Savoie-Mont Blanc Airport', city: 'Annecy', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  ND4: { name: 'Stanhope Municipal Airport', city: 'Haliburton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  NDB: { name: 'Nouadhibou International Airport', city: 'Nouadhibou', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  NDC: { name: 'Nanded Airport', city: 'Nanded', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  NDD: { name: 'Sumbe Airport', city: 'Sumbe', country: 'Angola', timezone: null, offset: 0 },
  NDG: { name: 'Qiqihar Sanjiazi Airport', city: 'Qiqihar', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NDJ: { name: 'N\'Djamena International Airport', city: 'N\'djamena', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  NDR: { name: 'Nador International Airport', city: 'El Aroui', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  NDU: { name: 'Rundu Airport', city: 'Rundu', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  NDY: { name: 'Sanday Airport', city: 'Sanday', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NDZ: { name: 'Whiting Field Naval Air Station South Airport', city: 'Cuxhaven', country: 'Germany', timezone: 'America/Chicago', offset: -6 },
  NEC: { name: 'Necochea Airport', city: 'Necochea', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  NEG: { name: 'Negril Airport', city: 'Negril', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  NEL: { name: 'Lakehurst Maxfield Field Airport', city: 'Lakehurst', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NEV: { name: 'Vance W. Amory International Airport', city: 'Charlestown', country: 'Saint Kitts and Nevis', timezone: 'America/St_Kitts', offset: -4 },
  NEW: { name: 'Lakefront Airport', city: 'New Orleans', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NF4: { name: 'Lindsay Airport', city: 'Lindsay', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  NFG: { name: 'Nefteyugansk Airport', city: 'Nefteyugansk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NFO: { name: 'Mata\'aho Airport', city: 'Angaha, Niuafo\'ou Island', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  NGA: { name: 'Young Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  NGB: { name: 'Ningbo Lishe International Airport', city: 'Ninbo', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NGD: { name: 'Captain Auguste George Airport', city: 'Anegada', country: 'British Virgin Islands', timezone: null, offset: -4 },
  NGE: { name: 'N\'Gaoundéré Airport', city: 'N\'gaoundere', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  NGF: { name: 'Kaneohe Bay MCAS (Marion E. Carl Field) Airport', city: 'Kaneohe Bay', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  NGI: { name: 'Ngau Airport', city: 'Ngau', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  NGO: { name: 'Chubu Centrair International Airport', city: 'Nagoya', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  NGP: { name: 'Corpus Christi Naval Air Station/Truax Field', city: 'Corpus Christi', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NGQ: { name: 'Ngari Gunsa Airport', city: 'Shiquanhe', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NGS: { name: 'Nagasaki Airport', city: 'Nagasaki', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  NGU: { name: 'Norfolk Ns (Chambers Fld) Airport', city: 'Norfolk', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NGX: { name: 'Manang Airport', city: 'Manang', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  NHA: { name: 'Nha Trang Air Base', city: 'Nhatrang', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  NHD: { name: 'Al Minhad Air Base', city: 'Minhad AB', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  NHK: { name: 'Patuxent River Naval Air Station/Trapnell Field Aiport', city: 'Patuxent River', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NHT: { name: 'RAF Northolt', city: 'Northolt', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NHV: { name: 'Nuku Hiva Airport', city: 'Nuku Hiva', country: 'French Polynesia', timezone: 'Pacific/Marquesas', offset: -9 },
  NHZ: { name: 'Brunswick Executive Airport', city: 'Brunswick', country: 'United States', timezone: null, offset: 0 },
  NIB: { name: 'Nikolai Airport', city: 'Nikolai', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NID: { name: 'China Lake Naws (Armitage Field) Airport', city: 'China Lake', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NIG: { name: 'Nikunau Airport', city: 'Nikunau', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  NIM: { name: 'Diori Hamani International Airport', city: 'Niamey', country: 'Niger', timezone: 'Africa/Niamey', offset: 1 },
  NIO: { name: 'Nioki Airport', city: 'Nioki', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  NIT: { name: 'Niort-Souché Airport', city: 'Niort', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  NIU: { name: 'Naiu Airport', city: 'Niau', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  NJC: { name: 'Nizhnevartovsk Airport', city: 'Nizhnevartovsk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NJF: { name: 'Al Najaf International Airport', city: 'Najaf', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  NJK: { name: 'El Centro Naf Airport', city: 'El Centro', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NKC: { name: 'Nouakchott International Airport', city: 'Nouakschott', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  NKG: { name: 'Nanjing Lukou Airport', city: 'Nanjing', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NKM: { name: 'Nagoya Airport', city: 'Nagoya', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  NKT: { name: 'Şırnak Şerafettin Elçi Airport', city: 'Cizre', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  NKX: { name: 'Miramar Marine Corps Air Station - Mitscher Field', city: 'Miramar', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NLA: { name: 'Simon Mwansa Kapwepwe International Airport', city: 'Ndola', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  NLC: { name: 'Lemoore Naval Air Station (Reeves Field) Airport', city: 'Lemoore', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NLD: { name: 'Quetzalcóatl International Airport', city: 'Nuevo Laredo', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  NLF: { name: 'Darnley Island Airport', city: 'Darnley Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  NLG: { name: 'Nelson Lagoon Airport', city: 'Nelson Lagoon', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NLI: { name: 'Nikolayevsk-na-Amure Airport', city: 'Nikolayevsk-na-Amure Airport', country: 'Russia', timezone: null, offset: 0 },
  NLK: { name: 'Norfolk Island International Airport', city: 'Norfolk Island', country: 'Norfolk Island', timezone: 'Pacific/Norfolk', offset: 11 },
  NLO: { name: 'Ndolo Airport', city: 'Kinshasa', country: 'Congo (Kinshasa)', timezone: 'Africa/Kinshasa', offset: 1 },
  NLP: { name: 'Nelspruit Airport', city: 'Nelspruit', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  NLT: { name: 'Xinyuan Nalati Airport', city: 'Xinyuan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NLV: { name: 'Mykolaiv International Airport', city: 'Nikolayev', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  NMA: { name: 'Namangan Airport', city: 'Namangan', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  NMB: { name: 'Daman Airport', city: 'Daman', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  NME: { name: 'Nightmute Airport', city: 'Nightmute', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NNA: { name: 'Kenitra Airport', city: 'Kentira', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  NNB: { name: 'Santa Ana Airport', city: 'Santa Ana', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  NNG: { name: 'Nanning Wuxu Airport', city: 'Nanning', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NNL: { name: 'Nondalton Airport', city: 'Nondalton', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NNM: { name: 'Naryan Mar Airport', city: 'Naryan-Mar', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  NNR: { name: 'Connemara Regional Airport', city: 'Indreabhan', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  NNT: { name: 'Nan Airport', city: 'Nan', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  NNX: { name: 'Nunukan Airport', city: 'Nunukan-Nunukan Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  NNY: { name: 'Nanyang Jiangying Airport', city: 'Nanyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  NOA: { name: 'Nowra Airport', city: 'Nowra', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  NOB: { name: 'Nosara Airport', city: 'Nosara Beach', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  NOC: { name: 'Ireland West Knock Airport', city: 'Connaught', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  NOE: { name: 'Norden-Norddeich Airport', city: 'Norden', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  NOG: { name: 'Nogales International Airport', city: 'Nogales', country: 'Mexico', timezone: 'America/Hermosillo', offset: -7 },
  NOJ: { name: 'Noyabrsk Airport', city: 'Noyabrsk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NON: { name: 'Nonouti Airport', city: 'Nonouti', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  NOR: { name: 'Norðfjörður Airport', city: 'Nordfjordur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  NOS: { name: 'Fascene Airport', city: 'Nosy-be', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  NOU: { name: 'La Tontouta International Airport', city: 'Noumea', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  NOV: { name: 'Nova Lisboa Airport', city: 'Huambo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  NOW: { name: 'Port Angeles Cgas Airport', city: 'Port Angeles', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NOZ: { name: 'Spichenkovo Airport', city: 'Novokuznetsk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  NPA: { name: 'Pensacola Naval Air Station/Forrest Sherman Field', city: 'Pensacola', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NPE: { name: 'Hawke\'s Bay Airport', city: 'NAPIER', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  NPL: { name: 'New Plymouth Airport', city: 'New Plymouth', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  NQA: { name: 'Millington Regional Jetport Airport', city: 'Millington', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NQN: { name: 'Presidente Peron Airport', city: 'Neuquen', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  NQT: { name: 'Nottingham Airport', city: 'Nottingham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NQU: { name: 'Reyes Murillo Airport', city: 'Nuquí', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  NQX: { name: 'Naval Air Station Key West/Boca Chica Field', city: 'Key West', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NQY: { name: 'Newquay Cornwall Airport', city: 'Newquai', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NRA: { name: 'Narrandera Airport', city: 'Narrandera', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  NRB: { name: 'Naval Station Mayport (Admiral David L. Mcdonald Field)', city: 'Mayport', country: 'United States', timezone: null, offset: 0 },
  NRD: { name: 'Norderney Airport', city: 'Norderney', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  NRK: { name: 'Norrköping Airport', city: 'Norrkoeping', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  NRL: { name: 'North Ronaldsay Airport', city: 'North Ronaldsay', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NRN: { name: 'Weeze Airport', city: 'Weeze', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  NRT: { name: 'Narita International Airport', city: 'Tokyo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  NSE: { name: 'Whiting Field Naval Air Station - North', city: 'Milton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  NSH: { name: 'Noshahr Airport', city: 'Noshahr', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  NSI: { name: 'Yaoundé Nsimalen International Airport', city: 'Yaounde', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  NSK: { name: 'Norilsk-Alykel Airport', city: 'Norilsk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  NSN: { name: 'Nelson Airport', city: 'Nelson', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  NSO: { name: 'Scone Airport', city: 'Scone', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  NST: { name: 'Nakhon Si Thammarat Airport', city: 'Nakhon Si Thammarat', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  NSY: { name: 'Sigonella Navy Air Base', city: 'Sigonella', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  NTB: { name: 'Notodden Airport', city: 'Notodden', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  NTD: { name: 'Point Mugu Naval Air Station (Naval Base Ventura Co)', city: 'Point Mugu', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NTE: { name: 'Nantes Atlantique Airport', city: 'Nantes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  NTI: { name: 'Stenkol Airport', city: 'Bintuni', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  NTL: { name: 'Newcastle Airport', city: 'Newcastle', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  NTN: { name: 'Normanton Airport', city: 'Normanton', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  NTQ: { name: 'Noto Airport', city: 'Wajima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  NTR: { name: 'Del Norte International Airport', city: 'Monterrey', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  NTT: { name: 'Kuini Lavenia Airport', city: 'Niuatoputapu', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  NTU: { name: 'Oceana NAS', city: 'Oceana', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NTX: { name: 'Ranai Airport', city: 'Ranai-Natuna Besar Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  NTY: { name: 'Pilanesberg International Airport', city: 'Pilanesberg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  NU8: { name: 'Markham Airport', city: 'Markham', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  NUE: { name: 'Nuremberg Airport', city: 'Nuernberg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  NUI: { name: 'Nuiqsut Airport', city: 'Nuiqsut', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NUL: { name: 'Nulato Airport', city: 'Nulato', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  NUQ: { name: 'Moffett Federal Airfield', city: 'Mountain View', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NUS: { name: 'Norsup Airport', city: 'Norsup', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  NUW: { name: 'Whidbey Island Naval Air Station /Ault Field/ Airport', city: 'Whidbey Island', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NUX: { name: 'Novy Urengoy Airport', city: 'Novy Urengoy', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NVA: { name: 'Benito Salas Airport', city: 'Neiva', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  NVI: { name: 'Navoi Airport', city: 'Navoi', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  NVK: { name: 'Narvik Framnes Airport', city: 'Narvik', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  NVP: { name: 'Novo Aripuanã Airport', city: 'Novo Aripuana', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  NVS: { name: 'Nevers-Fourchambault Airport', city: 'Nevers', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  NVT: { name: 'Ministro Victor Konder International Airport', city: 'Navegantes', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  NWA: { name: 'Mohéli Bandar Es Eslam Airport', city: 'Moheli', country: 'Comoros', timezone: 'Indian/Comoro', offset: 3 },
  NWI: { name: 'Norwich International Airport', city: 'Norwich', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  NXP: { name: 'Twentynine Palms (Self) Airport', city: 'Twenty Nine Palms', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  NXX: { name: 'Willow Grove Naval Air Station/Joint Reserve Base', city: 'Willow Grove', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NYA: { name: 'Nyagan Airport', city: 'Nyagan', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NYE: { name: 'Nyeri Airport', city: 'NYERI', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  NYG: { name: 'Quantico MCAF /Turner field', city: 'Quantico', country: 'United States', timezone: 'America/New_York', offset: -5 },
  NYI: { name: 'Sunyani Airport', city: 'Sunyani', country: 'Ghana', timezone: 'Africa/Accra', offset: 0 },
  NYK: { name: 'Nanyuki Airport', city: 'Nanyuki', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  NYM: { name: 'Nadym Airport', city: 'Nadym', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  NYO: { name: 'Stockholm Skavsta Airport', city: 'Stockholm', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  NYT: { name: 'Naypyidaw Airport', city: 'Naypyidaw', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  NYU: { name: 'Bagan Airport', city: 'Bagan', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  NZA: { name: 'Nzagi Airport', city: 'Nzagi', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  NZC: { name: 'Maria Reiche Neuman Airport', city: 'Nazca', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  NZY: { name: 'North Island Naval Air Station-Halsey Field', city: 'San Diego', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OAG: { name: 'Orange Airport', city: 'Orange', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  OAJ: { name: 'Albert J Ellis Airport', city: 'Jacksonville NC', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OAK: { name: 'Metropolitan Oakland International Airport', city: 'Oakland', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OAL: { name: 'Cacoal Airport', city: 'Cacoal', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  OAM: { name: 'Oamaru Airport', city: 'Oamaru', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  OAR: { name: 'Marina Municipal Airport', city: 'Fort Ord', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OAX: { name: 'Xoxocotlán International Airport', city: 'Oaxaca', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  OBC: { name: 'Obock Airport', city: 'Obock', country: 'Djibouti', timezone: 'Africa/Djibouti', offset: 3 },
  OBE: { name: 'Okeechobee County Airport', city: 'Okeechobee', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OBF: { name: 'Oberpfaffenhofen Airport', city: 'Oberpfaffenhofen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  OBN: { name: 'Oban Airport', city: 'North Connel', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  OBO: { name: 'Tokachi-Obihiro Airport', city: 'Obihiro', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OBS: { name: 'Aubenas-Ardèche Méridional Airport', city: 'Aubenas-vals-lanas', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  OBU: { name: 'Kobuk Airport', city: 'Kobuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  OBY: { name: 'Ittoqqortoormiit Heliport', city: 'Ittoqqortoormiit', country: 'Greenland', timezone: 'America/Scoresbysund', offset: -1 },
  OCA: { name: 'Ocean Reef Club Airport', city: 'Ocean Reef Club Airport', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OCC: { name: 'Francisco De Orellana Airport', city: 'Coca', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  OCF: { name: 'Ocala International Airport - Jim Taylor Field', city: 'Ocala', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OCJ: { name: 'Boscobel Aerodrome', city: 'Ocho Rios', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  OCV: { name: 'Aguas Claras Airport', city: 'Ocana', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  OCW: { name: 'Warren Field', city: 'Washington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ODB: { name: 'Córdoba Airport', city: 'Cordoba', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  ODE: { name: 'Odense Airport', city: 'Odense', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  ODH: { name: 'RAF Odiham', city: 'Odiham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  ODN: { name: 'Long Seridan Airport', city: 'Long Seridan', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  ODO: { name: 'Bodaybo Airport', city: 'Bodaibo', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  ODS: { name: 'Odessa International Airport', city: 'Odessa', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  ODY: { name: 'Oudomsay Airport', city: 'Muang Xay', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  OEB: { name: 'Branch County Memorial Airport', city: 'Coldwater', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OEL: { name: 'Oryol Yuzhny Airport', city: 'Oakley', country: 'United States', timezone: null, offset: -5 },
  OEM: { name: 'Vincent Fayks Airport', city: 'Paloemeu', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  OER: { name: 'Örnsköldsvik Airport', city: 'Ornskoldsvik', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  OES: { name: 'Antoine De St Exupery Airport', city: 'San Antonio Oeste', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  OFF: { name: 'Offutt Air Force Base', city: 'Omaha', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OFK: { name: 'Karl Stefan Memorial Airport', city: 'Norfolk  Nebraska', country: 'United States', timezone: null, offset: -6 },
  OGB: { name: 'Orangeburg Municipal Airport', city: 'Orangeburg', country: 'United States', timezone: null, offset: 0 },
  OGD: { name: 'Ogden Hinckley Airport', city: 'Ogden', country: 'United States', timezone: 'America/Denver', offset: -7 },
  OGG: { name: 'Kahului Airport', city: 'Kahului', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  OGL: { name: 'Ogle Airport', city: 'Georgetown', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  OGN: { name: 'Yonaguni Airport', city: 'Yonaguni Jima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OGS: { name: 'Ogdensburg International Airport', city: 'Ogdensburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OGX: { name: 'Ain el Beida Airport', city: 'Ouargla', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  OGZ: { name: 'Beslan Airport', city: 'Beslan', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  OHD: { name: 'Ohrid St. Paul the Apostle Airport', city: 'Ohrid', country: 'Macedonia', timezone: 'Europe/Skopje', offset: 1 },
  OHE: { name: 'Gu-Lian Airport', city: 'Mohe County', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  OHO: { name: 'Okhotsk Airport', city: 'Okhotsk', country: 'Russia', timezone: 'Asia/Vladivostok', offset: 10 },
  OHS: { name: 'Sohar Airport', city: 'Sohar', country: 'Oman', timezone: null, offset: 4 },
  OIA: { name: 'Ourilândia do Norte Airport', city: 'Ourilandia do Norte', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  OIM: { name: 'Oshima Airport', city: 'Oshima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OIR: { name: 'Okushiri Airport', city: 'Okushiri', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OIT: { name: 'Oita Airport', city: 'Oita', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OJC: { name: 'Johnson County Executive Airport', city: 'Olathe', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OKA: { name: 'Naha Airport', city: 'Okinawa', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OKB: { name: 'Oceanside Municipal Airport', city: 'Fraser Island', country: 'Australia', timezone: 'America/Los_Angeles', offset: -8 },
  OKC: { name: 'Will Rogers World Airport', city: 'Oklahoma City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OKD: { name: 'Okadama Airport', city: 'Sapporo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OKF: { name: 'Okaukuejo Airport', city: 'Okaukuejo', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  OKI: { name: 'Oki Airport', city: 'Oki Island', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OKJ: { name: 'Okayama Airport', city: 'Okayama', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OKK: { name: 'Kokomo Municipal Airport', city: 'Kokomo', country: 'United States', timezone: null, offset: -4 },
  OKL: { name: 'Oksibil Airport', city: 'Oksibil', country: 'Indonesia', timezone: null, offset: 9 },
  OKN: { name: 'Okondja Airport', city: 'Okondja', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  OKO: { name: 'Yokota Air Base', city: 'Yokota', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  OKR: { name: 'Yorke Island Airport', city: 'Yorke Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  OKU: { name: 'Mokuti Lodge Airport', city: 'Mokuti Lodge', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  OKY: { name: 'Oakey Airport', city: 'Oakey', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  OLA: { name: 'Ørland Airport', city: 'Orland', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  OLB: { name: 'Olbia Costa Smeralda Airport', city: 'Olbia', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  OLC: { name: 'Senadora Eunice Micheles Airport', city: 'Sao Paulo de Olivenca', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  OLF: { name: 'L M Clayton Airport', city: 'Wolf Point', country: 'United States', timezone: 'America/Denver', offset: -7 },
  OLM: { name: 'Olympia Regional Airport', city: 'Olympia', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OLP: { name: 'Olympic Dam Airport', city: 'Olympic Dam', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  OLS: { name: 'Nogales International Airport', city: 'Nogales', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  OLU: { name: 'Columbus Municipal Airport', city: 'Columbus', country: 'United States', timezone: null, offset: 0 },
  OLV: { name: 'Olive Branch Airport', city: 'Olive Branch', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OLZ: { name: 'North West Santo Airport', city: 'Olpoi', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  OMA: { name: 'Eppley Airfield', city: 'Omaha', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OMB: { name: 'Omboue Hopital Airport', city: 'Omboue Hospial', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  OMC: { name: 'Ormoc Airport', city: 'Ormoc City', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  OMD: { name: 'Oranjemund Airport', city: 'Oranjemund', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  OME: { name: 'Nome Airport', city: 'Nome', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  OMF: { name: 'King Hussein Air College', city: 'Mafraq', country: 'Jordan', timezone: 'Asia/Amman', offset: 2 },
  OMH: { name: 'Urmia Airport', city: 'Uromiyeh', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  OMN: { name: 'Ormond Beach Municipal Airport', city: 'Ormond Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OMO: { name: 'Mostar International Airport', city: 'Mostar', country: 'Bosnia and Herzegovina', timezone: 'Europe/Sarajevo', offset: 1 },
  OMR: { name: 'Oradea International Airport', city: 'Oradea', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  OMS: { name: 'Omsk Central Airport', city: 'Omsk', country: 'Russia', timezone: 'Asia/Omsk', offset: 6 },
  OND: { name: 'Ondangwa Airport', city: 'Ondangwa', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  ONG: { name: 'Mornington Island Airport', city: 'Mornington Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  ONJ: { name: 'Odate Noshiro Airport', city: 'Odate Noshiro', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  ONP: { name: 'Newport Municipal Airport', city: 'Newport', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ONQ: { name: 'Zonguldak Airport', city: 'Zonguldak', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  ONS: { name: 'Onslow Airport', city: 'Onslow', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  ONT: { name: 'Ontario International Airport', city: 'Ontario', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ONX: { name: 'Enrique Adolfo Jimenez Airport', city: 'Colón', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  OOK: { name: 'Toksook Bay Airport', city: 'Toksook Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  OOL: { name: 'Gold Coast Airport', city: 'Coolangatta', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  OOM: { name: 'Cooma Snowy Mountains Airport', city: 'Cooma', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  OPF: { name: 'Opa-locka Executive Airport', city: 'Miami', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OPO: { name: 'Francisco de Sá Carneiro Airport', city: 'Porto', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  OPS: { name: 'Presidente João Batista Figueiredo Airport', city: 'Sinop', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  OQN: { name: 'Brandywine Airport', city: 'West Goshen Township', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OQU: { name: 'Quonset State Airport', city: 'North Kingstown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ORA: { name: 'Orán Airport', city: 'Oran', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  ORB: { name: 'Örebro Airport', city: 'Orebro', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  ORD: { name: 'Chicago O\'Hare International Airport', city: 'Chicago', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  ORE: { name: 'Orléans-Bricy (BA 123) Air Base', city: 'Orleans', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  ORF: { name: 'Norfolk International Airport', city: 'Norfolk', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ORG: { name: 'Zorg en Hoop Airport', city: 'Paramaribo', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  ORH: { name: 'Worcester Regional Airport', city: 'Worcester', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ORJ: { name: 'Orinduik Airport', city: 'Orinduik', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  ORK: { name: 'Cork Airport', city: 'Cork', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  ORL: { name: 'Orlando Executive Airport', city: 'Orlando', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ORN: { name: 'Es Senia Airport', city: 'Oran', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  ORP: { name: 'Orapa Airport', city: 'Orapa', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  ORT: { name: 'Northway Airport', city: 'Northway', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ORV: { name: 'Robert (Bob) Curtis Memorial Airport', city: 'Noorvik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ORW: { name: 'Ormara Airport', city: 'Ormara Raik', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  ORX: { name: 'Oriximiná Airport', city: 'Oriximina', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  ORY: { name: 'Paris-Orly Airport', city: 'Paris', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  OSB: { name: 'Mosul International Airport', city: 'Mosul', country: 'Iraq', timezone: 'Asia/Baghdad', offset: 3 },
  OSC: { name: 'Oscoda Wurtsmith Airport', city: 'Oscoda', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OSD: { name: 'Åre Östersund Airport', city: 'Östersund', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  OSH: { name: 'Wittman Regional Airport', city: 'Oshkosh', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OSI: { name: 'Osijek Airport', city: 'Osijek', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  OSK: { name: 'Oskarshamn Airport', city: 'Oskarshamn', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  OSL: { name: 'Oslo Gardermoen Airport', city: 'Oslo', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  OSN: { name: 'Osan Air Base', city: 'Osan', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  OSP: { name: 'Redzikowo Air Base', city: 'Slupsk', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  OSR: { name: 'Ostrava Leos Janáček Airport', city: 'Ostrava', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  OSS: { name: 'Osh Airport', city: 'Osh', country: 'Kyrgyzstan', timezone: 'Asia/Bishkek', offset: 6 },
  OST: { name: 'Ostend-Bruges International Airport', city: 'Ostend', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  OSU: { name: 'Ohio State University Airport', city: 'Columbus', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OSW: { name: 'Orsk Airport', city: 'Orsk', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  OSY: { name: 'Namsos Høknesøra Airport', city: 'Namsos', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  OSZ: { name: 'Koszalin Zegrze Pomorskie Air Base', city: 'Koszalin', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  OTH: { name: 'Southwest Oregon Regional Airport', city: 'North Bend', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OTI: { name: 'Pitu Airport', city: 'Morotai Island', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  OTM: { name: 'Ottumwa Regional Airport', city: 'Ottumwa', country: 'United States', timezone: null, offset: 0 },
  OTP: { name: 'Henri Coandă International Airport', city: 'Bucharest', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  OTR: { name: 'Coto 47 Airport', city: 'Coto 47', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  OTU: { name: 'Otu Airport', city: 'Otu', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  OTZ: { name: 'Ralph Wien Memorial Airport', city: 'Kotzebue', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  OUA: { name: 'Ouagadougou Airport', city: 'Ouagadougou', country: 'Burkina Faso', timezone: 'Africa/Ouagadougou', offset: 0 },
  OUD: { name: 'Angads Airport', city: 'Oujda', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  OUE: { name: 'Ouesso Airport', city: 'Ouesso', country: 'Congo (Kinshasa)', timezone: 'Africa/Brazzaville', offset: 1 },
  OUI: { name: 'Ban Huoeisay Airport', city: 'Huay Xai', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  OUL: { name: 'Oulu Airport', city: 'Oulu', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  OUZ: { name: 'Tazadit Airport', city: 'Zouerat', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  OVA: { name: 'Bekily Airport', city: 'Bekily', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  OVB: { name: 'Tolmachevo Airport', city: 'Novosibirsk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  OVD: { name: 'Asturias Airport', city: 'Aviles', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  OVS: { name: 'Sovetskiy Airport', city: 'Sovetskiy', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  OWB: { name: 'Owensboro Daviess County Airport', city: 'Owensboro', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OWD: { name: 'Norwood Memorial Airport', city: 'Norwood', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OXB: { name: 'Osvaldo Vieira International Airport', city: 'Bissau', country: 'Guinea-Bissau', timezone: 'Africa/Bissau', offset: 0 },
  OXC: { name: 'Waterbury Oxford Airport', city: 'Oxford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  OXF: { name: 'Oxford (Kidlington) Airport', city: 'Oxford', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  OXR: { name: 'Oxnard Airport', city: 'Oxnard', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  OYE: { name: 'Oyem Airport', city: 'Oyem', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  OYL: { name: 'Moyale Airport', city: 'Moyale', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  OZA: { name: 'Ozona Municipal Airport', city: 'Ozona', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  OZC: { name: 'Labo Airport', city: 'Ozamis', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  OZH: { name: 'Zaporizhzhia International Airport', city: 'Zaporozhye', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  OZP: { name: 'Moron Air Base', city: 'Sevilla', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  OZR: { name: 'Cairns AAF (Fort Rucker) Air Field', city: 'Fort Rucker/Ozark', country: 'United States', timezone: null, offset: 0 },
  OZZ: { name: 'Ouarzazate Airport', city: 'Ouarzazate', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  P08: { name: 'Coolidge Municipal Airport', city: 'Cooldige', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  P52: { name: 'Cottonwood Airport', city: 'Cottonwood', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  PAB: { name: 'Bilaspur Airport', city: 'Bilaspur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PAC: { name: 'Marcos A. Gelabert International Airport', city: 'Panama', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  PAD: { name: 'Paderborn Lippstadt Airport', city: 'Paderborn', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  PAE: { name: 'Snohomish County (Paine Field) Airport', city: 'Everett', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PAF: { name: 'Bugungu Airport', city: 'Pakuba', country: 'Uganda', timezone: 'Africa/Kampala', offset: 3 },
  PAG: { name: 'Pagadian Airport', city: 'Pagadian', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  PAH: { name: 'Barkley Regional Airport', city: 'PADUCAH', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PAJ: { name: 'Parachinar Airport', city: 'Parachinar', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  PAL: { name: 'German Olano Air Base', city: 'La Dorada', country: 'Colombia', timezone: null, offset: 0 },
  PAM: { name: 'Tyndall Air Force Base', city: 'Panama City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PAN: { name: 'Pattani Airport', city: 'Pattani', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  PAO: { name: 'Palo Alto Airport of Santa Clara County', city: 'Palo Alto', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PAP: { name: 'Toussaint Louverture International Airport', city: 'Port-au-prince', country: 'Haiti', timezone: 'America/Port-au-Prince', offset: -5 },
  PAQ: { name: 'Palmer Municipal Airport', city: 'Palmer', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PAS: { name: 'Paros Airport', city: 'Paros', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  PAT: { name: 'Lok Nayak Jayaprakash Airport', city: 'Patina', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PAV: { name: 'Paulo Afonso Airport', city: 'Paulo Alfonso', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  PAX: { name: 'Port-de-Paix Airport', city: 'Port-de-Paix', country: 'Haiti', timezone: 'America/Port-au-Prince', offset: -5 },
  PAZ: { name: 'El Tajín National Airport', city: 'Poza Rico', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PBC: { name: 'Hermanos Serdán International Airport', city: 'Puebla', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PBD: { name: 'Porbandar Airport', city: 'Porbandar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PBF: { name: 'Grider Field', city: 'Pine Bluff', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PBG: { name: 'Plattsburgh International Airport', city: 'Plattsburgh', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PBH: { name: 'Paro Airport', city: 'Thimphu', country: 'Bhutan', timezone: 'Asia/Thimphu', offset: 6 },
  PBI: { name: 'Palm Beach International Airport', city: 'West Palm Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PBJ: { name: 'Tavie Airport', city: 'Paama Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  PBL: { name: 'General Bartolome Salom International Airport', city: 'Puerto Cabello', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  PBM: { name: 'Johan Adolf Pengel International Airport', city: 'Zandery', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  PBN: { name: 'Porto Amboim Airport', city: 'Porto Amboim', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  PBO: { name: 'Paraburdoo Airport', city: 'Paraburdoo', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  PBP: { name: 'Islita Airport', city: 'Nandayure', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  PBR: { name: 'Puerto Barrios Airport', city: 'Puerto Barrios', country: 'Guatemala', timezone: 'America/Guatemala', offset: -6 },
  PBU: { name: 'Putao Airport', city: 'Putao', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  PBX: { name: 'Pike County-Hatcher Field', city: 'Pikeville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PCA: { name: 'Ingeniero Juan Guillermo Villasana Airport', city: 'Pachuca', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PCB: { name: 'Pondok Cabe Air Base', city: 'Jakarta', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PCL: { name: 'Cap FAP David Abenzur Rengifo International Airport', city: 'Pucallpa', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  PCN: { name: 'Picton Aerodrome', city: 'Picton', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  PCP: { name: 'Principe Airport', city: 'Principe', country: 'Sao Tome and Principe', timezone: 'Africa/Sao_Tome', offset: 0 },
  PCR: { name: 'German Olano Airport', city: 'Puerto Carreno', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PCS: { name: 'Picos Airport', city: 'Picos', country: 'Brazil', timezone: null, offset: -3 },
  PCW: { name: 'Carl R Keller Field', city: 'Port Clinton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PCZ: { name: 'Waupaca Municipal Airport', city: 'Waupaca', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PDA: { name: 'Obando Airport', city: 'Puerto Inírida', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PDG: { name: 'Tabing Airport', city: 'Padang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PDK: { name: 'DeKalb Peachtree Airport', city: 'Atlanta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PDL: { name: 'João Paulo II Airport', city: 'Ponta Delgada', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  PDO: { name: 'Pendopo Airport', city: 'Talang Gudang-Sumatra Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PDP: { name: 'Capitan Corbeta CA Curbelo International Airport', city: 'Punta del Este', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  PDS: { name: 'Piedras Negras International Airport', city: 'Piedras Negras', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PDT: { name: 'Eastern Oregon Regional At Pendleton Airport', city: 'Pendleton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PDU: { name: 'Tydeo Larre Borges Airport', city: 'Paysandu', country: 'Uruguay', timezone: null, offset: -3 },
  PDV: { name: 'Plovdiv International Airport', city: 'Plovdiv', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 2 },
  PDX: { name: 'Portland International Airport', city: 'Portland', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PEA: { name: 'Penneshaw Airport', city: 'Penneshaw', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  PED: { name: 'Pardubice Airport', city: 'Pardubice', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  PEE: { name: 'Bolshoye Savino Airport', city: 'Perm', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  PEF: { name: 'Peenemünde Airport', city: 'Peenemunde', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  PEG: { name: 'Perugia San Francesco d\'Assisi – Umbria International Airport', city: 'Perugia', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PEI: { name: 'Matecaña International Airport', city: 'Pereira', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PEK: { name: 'Beijing Capital International Airport', city: 'Beijing', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  PEM: { name: 'Padre Aldamiz International Airport', city: 'Puerto Maldonado', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  PEN: { name: 'Penang International Airport', city: 'Penang', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  PEQ: { name: 'Pecos Municipal Airport', city: 'Pecos', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PER: { name: 'Perth International Airport', city: 'Perth', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  PES: { name: 'Petrozavodsk Airport', city: 'Petrozavodsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  PET: { name: 'João Simões Lopes Neto International Airport', city: 'Pelotas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  PEU: { name: 'Puerto Lempira Airport', city: 'Puerto Lempira', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  PEV: { name: 'Pécs-Pogány Airport', city: 'Pécs-Pogány', country: 'Hungary', timezone: 'Europe/Budapest', offset: 1 },
  PEW: { name: 'Peshawar International Airport', city: 'Peshawar', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  PEX: { name: 'Pechora Airport', city: 'Pechora', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  PEZ: { name: 'Penza Airport', city: 'Penza', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  PFB: { name: 'Lauro Kurtz Airport', city: 'Passo Fundo', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  PFJ: { name: 'Patreksfjörður Airport', city: 'Patreksfjordur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  PFN: { name: 'Panama City-Bay Co International Airport', city: 'Panama City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PFO: { name: 'Paphos International Airport', city: 'Paphos', country: 'Cyprus', timezone: 'Asia/Nicosia', offset: 2 },
  PFQ: { name: 'Parsabade Moghan Airport', city: 'Parsabad', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  PFR: { name: 'Ilebo Airport', city: 'Ilebo', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  PGA: { name: 'Page Municipal Airport', city: 'Page', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  PGD: { name: 'Charlotte County Airport', city: 'Punta Gorda', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PGF: { name: 'Perpignan-Rivesaltes (Llabanère) Airport', city: 'Perpignan', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  PGH: { name: 'Pantnagar Airport', city: 'Nainital', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PGK: { name: 'Pangkal Pinang (Depati Amir) Airport', city: 'Pangkal Pinang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PGU: { name: 'Persian Gulf International Airport', city: 'Khalije Fars', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  PGV: { name: 'Pitt Greenville Airport', city: 'Greenville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PGX: { name: 'Périgueux-Bassillac Airport', city: 'Perigueux', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  PHA: { name: 'Phan Rang Airport', city: 'Phan Rang', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  PHC: { name: 'Port Harcourt International Airport', city: 'Port Hartcourt', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  PHD: { name: 'Harry Clever Field', city: 'New Philadelpha', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PHE: { name: 'Port Hedland International Airport', city: 'Port Hedland', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  PHF: { name: 'Newport News Williamsburg International Airport', city: 'Newport News', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PHK: { name: 'Palm Beach County Glades Airport', city: 'Pahokee', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PHL: { name: 'Philadelphia International Airport', city: 'Philadelphia', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PHN: { name: 'St Clair County International Airport', city: 'Port Huron', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PHS: { name: 'Phitsanulok Airport', city: 'Phitsanulok', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  PHW: { name: 'Hendrik Van Eck Airport', city: 'Phalaborwa', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  PHX: { name: 'Phoenix Sky Harbor International Airport', city: 'Phoenix', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  PHY: { name: 'Phetchabun Airport', city: 'Phetchabun', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  PIA: { name: 'General Wayne A. Downing Peoria International Airport', city: 'Peoria', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PIB: { name: 'Hattiesburg Laurel Regional Airport', city: 'Hattiesburg/Laurel', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PID: { name: 'Nassau Paradise Island Airport', city: 'Nassau', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  PIE: { name: 'St Petersburg Clearwater International Airport', city: 'St. Petersburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PIF: { name: 'Pingtung North Airport', city: 'Pingtung', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  PIH: { name: 'Pocatello Regional Airport', city: 'Pocatello', country: 'United States', timezone: 'America/Denver', offset: -7 },
  PIK: { name: 'Glasgow Prestwick Airport', city: 'Prestwick', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  PIM: { name: 'Harris County Airport', city: 'Pine Mountain', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PIN: { name: 'Parintins Airport', city: 'Parintins', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  PIO: { name: 'Capitán FAP Renán Elías Olivera International Airport', city: 'Pisco', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  PIP: { name: 'Pilot Point Airport', city: 'Pilot Point', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PIR: { name: 'Pierre Regional Airport', city: 'Pierre', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PIS: { name: 'Poitiers-Biard Airport', city: 'Poitiers', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  PIT: { name: 'Pittsburgh International Airport', city: 'Pittsburgh', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PIU: { name: 'Capitán FAP Guillermo Concha Iberico International Airport', city: 'Piura', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  PIW: { name: 'Pikwitonei Airport', city: 'Pikwitonei', country: 'Canada', timezone: null, offset: 0 },
  PIX: { name: 'Pico Airport', city: 'Pico', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  PIZ: { name: 'Point Lay LRRS Airport', city: 'Point Lay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PJA: { name: 'Pajala Airport', city: 'Pajala', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  PJC: { name: 'Dr Augusto Roberto Fuster International Airport', city: 'Pedro Juan Caballero', country: 'Paraguay', timezone: 'America/Asuncion', offset: -4 },
  PJG: { name: 'Panjgur Airport', city: 'Panjgur', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  PJM: { name: 'Puerto Jimenez Airport', city: 'Puerto Jimenez', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  PKA: { name: 'Napaskiak Airport', city: 'Napaskiak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PKB: { name: 'Mid Ohio Valley Regional Airport', city: 'PARKERSBURG', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PKC: { name: 'Yelizovo Airport', city: 'Petropavlovsk', country: 'Russia', timezone: 'Asia/Anadyr', offset: 12 },
  PKE: { name: 'Parkes Airport', city: 'Parkes', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  PKG: { name: 'Pulau Pangkor Airport', city: 'Pangkor Island', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  PKH: { name: 'Porto Cheli Airport', city: 'Porto Heli', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  PKK: { name: 'Pakhokku Airport', city: 'Pakhokku', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  PKN: { name: 'Iskandar Airport', city: 'Pangkalan Bun', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PKP: { name: 'Puka Puka Airport', city: 'Puka Puka', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  PKR: { name: 'Pokhara Airport', city: 'Pokhara', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  PKT: { name: 'Port Keats Airport', city: 'Wadeye', country: 'Australia', timezone: null, offset: 9 },
  PKU: { name: 'Sultan Syarif Kasim Ii (Simpang Tiga) Airport', city: 'Pekanbaru', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PKV: { name: 'Pskov Airport', city: 'Pskov', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  PKW: { name: 'Selebi Phikwe Airport', city: 'Selebi-phikwe', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  PKY: { name: 'Tjilik Riwut Airport', city: 'Palangkaraya', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PKZ: { name: 'Pakse International Airport', city: 'Pakse', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  PLD: { name: 'Playa Samara/Carrillo Airport', city: 'Carrillo', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  PLH: { name: 'Plymouth City Airport', city: 'Plymouth', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  PLL: { name: 'Ponta Pelada Airport', city: 'Manaus', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  PLM: { name: 'Sultan Mahmud Badaruddin II Airport', city: 'Palembang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PLN: { name: 'Pellston Regional Airport of Emmet County Airport', city: 'Pellston', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PLO: { name: 'Port Lincoln Airport', city: 'Port Lincoln', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  PLP: { name: 'Captain Ramon Xatruch Airport', city: 'La Palma', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  PLQ: { name: 'Palanga International Airport', city: 'Palanga', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  PLS: { name: 'Providenciales Airport', city: 'Providenciales', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  PLU: { name: 'Pampulha - Carlos Drummond de Andrade Airport', city: 'Belo Horizonte', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  PLV: { name: 'Suprunovka Airport', city: 'Poltava', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  PLW: { name: 'Mutiara Airport', city: 'Palu', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  PLX: { name: 'Semipalatinsk Airport', city: 'Semiplatinsk', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  PLZ: { name: 'Port Elizabeth Airport', city: 'Port Elizabeth', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  PMA: { name: 'Pemba Airport', city: 'Pemba', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  PMB: { name: 'Pembina Municipal Airport', city: 'Pembina', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PMC: { name: 'El Tepual Airport', city: 'Puerto Montt', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  PMD: { name: 'Palmdale Regional/USAF Plant 42 Airport', city: 'Palmdale', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PMF: { name: 'Parma Airport', city: 'Parma', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PMG: { name: 'Ponta Porã Airport', city: 'Ponta Pora', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  PMI: { name: 'Palma De Mallorca Airport', city: 'Palma de Mallorca', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  PMK: { name: 'Palm Island Airport', city: 'Palm Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  PML: { name: 'Port Moller Airport', city: 'Cold Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PMO: { name: 'Falcone–Borsellino Airport', city: 'Palermo', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PMP: { name: 'Pompano Beach Airpark', city: 'Pompano Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PMQ: { name: 'Perito Moreno Airport', city: 'Perito Moreno', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  PMR: { name: 'Palmerston North Airport', city: 'Palmerston North', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  PMS: { name: 'Palmyra Airport', city: 'Palmyra', country: 'Syria', timezone: 'Asia/Damascus', offset: 2 },
  PMV: { name: 'Del Caribe Santiago Mariño International Airport', city: 'Porlamar', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  PMW: { name: 'Brigadeiro Lysias Rodrigues Airport', city: 'Palmas', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  PMY: { name: 'El Tehuelche Airport', city: 'Puerto Madryn', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  PMZ: { name: 'Palmar Sur Airport', city: 'Palmar Sur', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  PNA: { name: 'Pamplona Airport', city: 'Pamplona', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  PNB: { name: 'Porto Nacional Airport', city: 'Porto Nacional', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  PNC: { name: 'Ponca City Regional Airport', city: 'Ponca City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PNE: { name: 'Northeast Philadelphia Airport', city: 'Philadelphia', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PNH: { name: 'Phnom Penh International Airport', city: 'Phnom-penh', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  PNI: { name: 'Pohnpei International Airport', city: 'Pohnpei', country: 'Micronesia', timezone: 'Pacific/Ponape', offset: 11 },
  PNK: { name: 'Supadio Airport', city: 'Pontianak', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PNL: { name: 'Pantelleria Airport', city: 'Pantelleria', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PNM: { name: 'Princeton Municipal Airport', city: 'Princeton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PNP: { name: 'Girua Airport', city: 'Girua', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  PNQ: { name: 'Pune Airport', city: 'Pune', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PNR: { name: 'Pointe Noire Airport', city: 'Pointe-noire', country: 'Congo (Brazzaville)', timezone: 'Africa/Brazzaville', offset: 1 },
  PNS: { name: 'Pensacola Regional Airport', city: 'Pensacola', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PNT: { name: 'Tte. Julio Gallardo Airport', city: 'Puerto Natales', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  PNV: { name: 'Panevėžys Air Base', city: 'Panevezys', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  PNY: { name: 'Pondicherry Airport', city: 'Pendicherry', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  PNZ: { name: 'Senador Nilo Coelho Airport', city: 'Petrolina', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  POA: { name: 'Salgado Filho Airport', city: 'Porto Alegre', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  POB: { name: 'Pope Field', city: 'Fort Bragg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  POC: { name: 'Brackett Field', city: 'La Verne', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  POE: { name: 'Polk Army Air Field', city: 'Fort Polk', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  POF: { name: 'Poplar Bluff Municipal Airport', city: 'Poplar Bluff', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  POG: { name: 'Port Gentil Airport', city: 'Port Gentil', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  POI: { name: 'Capitan Nicolas Rojas Airport', city: 'Potosi', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  POJ: { name: 'Patos de Minas Airport', city: 'Patos de Minas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  POL: { name: 'Pemba Airport', city: 'Pemba', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  POM: { name: 'Port Moresby Jacksons International Airport', city: 'Port Moresby', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  POO: { name: 'Poços de Caldas - Embaixador Walther Moreira Salles Airport', city: 'Pocos De Caldas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  POP: { name: 'Gregorio Luperon International Airport', city: 'Puerto Plata', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  POR: { name: 'Pori Airport', city: 'Pori', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  POS: { name: 'Piarco International Airport', city: 'Port-of-spain', country: 'Trinidad and Tobago', timezone: 'America/Port_of_Spain', offset: -4 },
  POT: { name: 'Ken Jones Airport', city: 'Port Antonio', country: 'Jamaica', timezone: 'America/Jamaica', offset: -5 },
  POU: { name: 'Dutchess County Airport', city: 'Poughkeepsie', country: 'United States', timezone: null, offset: 0 },
  POW: { name: 'Portoroz Airport', city: 'Portoroz', country: 'Slovenia', timezone: 'Europe/Ljubljana', offset: 1 },
  POX: { name: 'Pontoise - Cormeilles-en-Vexin Airport', city: 'Pontoise', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  POZ: { name: 'Poznań-Ławica Airport', city: 'Poznan', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  PPB: { name: 'Presidente Prudente Airport', city: 'President Prudente', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  PPC: { name: 'Prospect Creek Airport', city: 'Prospect Creek', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PPE: { name: 'Puerto Peñasco International Airport', city: 'Punta Penasco', country: 'Mexico', timezone: 'America/Hermosillo', offset: -7 },
  PPG: { name: 'Pago Pago International Airport', city: 'Pago Pago', country: 'American Samoa', timezone: 'Pacific/Pago_Pago', offset: -11 },
  PPI: { name: 'Port Pirie Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  PPK: { name: 'Petropavlosk South Airport', city: 'Petropavlosk', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  PPL: { name: 'Phaplu Airport', city: 'Phaplu', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  PPN: { name: 'Guillermo León Valencia Airport', city: 'Popayan', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PPP: { name: 'Proserpine Whitsunday Coast Airport', city: 'Prosserpine', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  PPQ: { name: 'Paraparaumu Airport', city: 'Paraparaumu', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  PPS: { name: 'Puerto Princesa Airport', city: 'Puerto Princesa', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  PPT: { name: 'Faa\'a International Airport', city: 'Papeete', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  PPW: { name: 'Papa Westray Airport', city: 'Papa Westray', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  PPY: { name: 'Pouso Alegre Airport', city: 'Pouso Alegre', country: 'Brazil', timezone: null, offset: -3 },
  PQC: { name: 'Phu Quoc International Airport', city: 'Phuquoc', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  PQI: { name: 'Northern Maine Regional Airport at Presque Isle', city: 'Presque Isle', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PQQ: { name: 'Port Macquarie Airport', city: 'Port Macquarie', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  PRA: { name: 'General Urquiza Airport', city: 'Parana', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  PRB: { name: 'Paso Robles Municipal Airport', city: 'Paso Robles', country: 'United States', timezone: null, offset: -8 },
  PRC: { name: 'Ernest A. Love Field', city: 'Prescott', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  PRG: { name: 'Václav Havel Airport Prague', city: 'Prague', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  PRH: { name: 'Phrae Airport', city: 'Phrae', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  PRI: { name: 'Praslin Airport', city: 'Praslin', country: 'Seychelles', timezone: 'Indian/Mahe', offset: 4 },
  PRN: { name: 'Priština International Airport', city: 'Pristina', country: 'Serbia', timezone: 'Europe/Belgrade', offset: 1 },
  PRV: { name: 'Přerov Air Base', city: 'Prerov', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 1 },
  PRX: { name: 'Cox Field', city: 'Paris', country: 'United States', timezone: null, offset: -5 },
  PRY: { name: 'Wonderboom Airport', city: 'Pretoria', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  PSA: { name: 'Pisa International Airport', city: 'Pisa', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PSC: { name: 'Tri Cities Airport', city: 'Pasco', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PSD: { name: 'Port Said Airport', city: 'Port Said', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  PSE: { name: 'Mercedita Airport', city: 'Ponce', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  PSG: { name: 'Petersburg James A Johnson Airport', city: 'Petersburg', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PSH: { name: 'St. Peter-Ording Airport', city: 'Sankt Peter-Ording', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  PSI: { name: 'Pasni Airport', city: 'Pasni', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  PSJ: { name: 'Kasiguncu Airport', city: 'Poso', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  PSL: { name: 'Perth/Scone Airport', city: 'Perth', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  PSM: { name: 'Portsmouth International at Pease Airport', city: 'Portsmouth', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PSO: { name: 'Antonio Narino Airport', city: 'Pasto', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PSP: { name: 'Palm Springs International Airport', city: 'Palm Springs', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PSR: { name: 'Pescara International Airport', city: 'Pescara', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  PSS: { name: 'Libertador Gral D Jose De San Martin Airport', city: 'Posadas', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  PSU: { name: 'Pangsuma Airport', city: 'Putussibau-Borneo Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  PSX: { name: 'Palacios Municipal Airport', city: 'Palacios', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PSY: { name: 'Port Stanley Airport', city: 'Stanley', country: 'Falkland Islands', timezone: 'Atlantic/Stanley', offset: -3 },
  PSZ: { name: 'Capitán Av. Salvador Ogaya G. airport', city: 'Puerto Suarez', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  PTB: { name: 'Dinwiddie County Airport', city: 'Petersburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PTF: { name: 'Malolo Lailai Island Airport', city: 'Malolo Lailai Island', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  PTG: { name: 'Polokwane International Airport', city: 'Potgietersrus', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  PTH: { name: 'Port Heiden Airport', city: 'Port Heiden', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PTJ: { name: 'Portland Airport', city: 'Portland', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  PTK: { name: 'Oakland County International Airport', city: 'Pontiac', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PTP: { name: 'Pointe-à-Pitre Le Raizet', city: 'Pointe-a-pitre', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  PTT: { name: 'Pratt Regional Airport', city: 'Pratt', country: 'United States', timezone: null, offset: -5 },
  PTU: { name: 'Platinum Airport', city: 'Port Moller', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  PTY: { name: 'Tocumen International Airport', city: 'Panama City', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  PTZ: { name: 'Rio Amazonas Airport', city: 'Pastaza', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  PUB: { name: 'Pueblo Memorial Airport', city: 'Pueblo', country: 'United States', timezone: 'America/Denver', offset: -7 },
  PUC: { name: 'Carbon County Regional/Buck Davis Field', city: 'Price', country: 'United States', timezone: 'America/Denver', offset: -7 },
  PUD: { name: 'Puerto Deseado Airport', city: 'Puerto Deseado', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  PUE: { name: 'Puerto Obaldia Airport', city: 'Puerto Obaldia', country: 'Panama', timezone: 'America/Panama', offset: -5 },
  PUF: { name: 'Pau Pyrénées Airport', city: 'Pau', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  PUG: { name: 'Port Augusta Airport', city: 'Argyle', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  PUJ: { name: 'Punta Cana International Airport', city: 'Punta Cana', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  PUK: { name: 'Pukarua Airport', city: 'Pukarua', country: 'French Polynesia', timezone: null, offset: -10 },
  PUQ: { name: 'Pdte. carlos Ibañez del Campo Airport', city: 'Punta Arenas', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  PUR: { name: 'Puerto Rico Airport', city: 'Puerto Rico/Manuripi', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  PUS: { name: 'Gimhae International Airport', city: 'Busan', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  PUT: { name: 'Sri Sathya Sai Airport', city: 'Puttaparthi', country: 'India', timezone: null, offset: 0 },
  PUU: { name: 'Tres De Mayo Airport', city: 'Puerto Asis', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PUW: { name: 'Pullman Moscow Regional Airport', city: 'Pullman', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PUY: { name: 'Pula Airport', city: 'Pula', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  PUZ: { name: 'Puerto Cabezas Airport', city: 'Puerto Cabezas', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  PVA: { name: 'El Embrujo Airport', city: 'Providencia', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  PVC: { name: 'Provincetown Municipal Airport', city: 'Provincetown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PVD: { name: 'Theodore Francis Green State Airport', city: 'Providence', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PVG: { name: 'Shanghai Pudong International Airport', city: 'Shanghai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  PVH: { name: 'Governador Jorge Teixeira de Oliveira Airport', city: 'Porto Velho', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  PVK: { name: 'Aktion National Airport', city: 'Preveza', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  PVO: { name: 'Reales Tamarindos Airport', city: 'Portoviejo', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  PVR: { name: 'Licenciado Gustavo Díaz Ordaz International Airport', city: 'Puerto Vallarta', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PVS: { name: 'Provideniya Bay Airport', city: 'Provideniya Bay', country: 'Russia', timezone: 'Asia/Anadyr', offset: 12 },
  PVU: { name: 'Provo Municipal Airport', city: 'Provo', country: 'United States', timezone: 'America/Denver', offset: -7 },
  PWE: { name: 'Pevek Airport', city: 'Pevek', country: 'Russia', timezone: null, offset: 12 },
  PWK: { name: 'Chicago Executive Airport', city: 'Chicago-Wheeling', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PWM: { name: 'Portland International Jetport Airport', city: 'Portland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PWQ: { name: 'Pavlodar Airport', city: 'Pavlodar', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  PWT: { name: 'Bremerton National Airport', city: 'Bremerton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  PWY: { name: 'Ralph Wenz Field', city: 'Pinedale', country: 'United States', timezone: null, offset: 0 },
  PXE: { name: 'Perry Houston County Airport', city: 'Perry', country: 'United States', timezone: null, offset: -4 },
  PXH: { name: 'Prominent Hill Airport', city: 'Prominent Hill', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  PXM: { name: 'Puerto Escondido International Airport', city: 'Puerto Escondido', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  PXO: { name: 'Porto Santo Airport', city: 'Porto Santo', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  PXU: { name: 'Pleiku Airport', city: 'Pleiku', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  PYE: { name: 'Tongareva Airport', city: 'Penrhyn Island', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  PYH: { name: 'Cacique Aramare Airport', city: 'Puerto Ayacucho', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  PYJ: { name: 'Polyarny Airport', city: 'Yakutia', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  PYK: { name: 'Payam International Airport', city: 'Karaj', country: 'Iran', timezone: null, offset: 0 },
  PYM: { name: 'Plymouth Municipal Airport', city: 'Plymouth', country: 'United States', timezone: 'America/New_York', offset: -5 },
  PYP: { name: 'Centre-Piedmont-Cherokee County Regional Airport', city: 'Centre', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  PYR: { name: 'Andravida Air Base', city: 'Andravida', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  PYY: { name: 'Mae Hong Son Airport', city: 'Pai', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  PZA: { name: 'Paz De Ariporo Airport', city: 'Paz De Ariporo', country: 'Colombia', timezone: null, offset: 0 },
  PZB: { name: 'Pietermaritzburg Airport', city: 'Pietermaritzburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  PZE: { name: 'Penzance Heliport', city: 'Penzance', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  PZH: { name: 'Zhob Airport', city: 'Zhob', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  PZL: { name: 'Zulu Inyala Airport', city: 'Phinda', country: 'South Africa', timezone: null, offset: 2 },
  PZO: { name: 'General Manuel Carlos Piar International Airport', city: 'Guayana', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  PZU: { name: 'Port Sudan New International Airport', city: 'Port Sudan', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  PZY: { name: 'Piešťany Airport', city: 'Piestany', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  QAS: { name: 'Ech Cheliff Airport', city: 'Ech-cheliff', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  QBC: { name: 'Bella Coola Airport', city: 'Bella Coola', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  QBX: { name: 'Sobral Airport', city: 'Sobral', country: 'Brazil', timezone: null, offset: -3 },
  QCJ: { name: 'Botucatu - Tancredo de Almeida Neves Airport', city: 'Botucatu', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  QCY: { name: 'RAF Coningsby', city: 'Coningsby', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  QDJ: { name: 'Tsletsi Airport', city: 'Djelfa', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  QEF: { name: 'Frankfurt-Egelsbach Airport', city: 'Egelsbach', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  QFD: { name: 'Boufarik Airport', city: 'Boufarik', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  QFO: { name: 'Duxford Airport', city: 'Duxford', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  QGY: { name: 'Győr-Pér International Airport', city: 'Győr', country: 'Hungary', timezone: 'Europe/Budapest', offset: 1 },
  QHP: { name: 'Base de Aviação de Taubaté Airport', city: 'Taubaté', country: 'Brazil', timezone: null, offset: 0 },
  QHR: { name: 'Harar Meda Airport', city: 'Debre Zeyit', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  QKT: { name: 'Wevelgem Airport', city: 'Kortrijk-vevelgem', country: 'Belgium', timezone: 'Europe/Brussels', offset: 1 },
  QLA: { name: 'Lasham Airport', city: 'Lasham', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  QLP: { name: 'Sarzana-Luni Air Base', city: 'Sarzana (SP)', country: 'Italy', timezone: null, offset: 0 },
  QLS: { name: 'Lausanne-Blécherette Airport', city: 'Lausanne', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  QLT: { name: 'Latina Air Base', city: 'Latina', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  QMJ: { name: 'Shahid Asyaee Airport', city: 'Masjed Soleiman', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  QNC: { name: 'Neuchatel Airport', city: 'Neuchatel', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  QNJ: { name: 'Annemasse Airport', city: 'Annemasse', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  QNV: { name: 'Aeroclube Airport', city: 'Nova Iguacu', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  QNX: { name: 'Mâcon-Charnay Airport', city: 'Macon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  QOW: { name: 'Sam Mbakwe International Airport', city: 'Imo', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  QPA: { name: 'Padova Airport', city: 'Padova', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  QPG: { name: 'Paya Lebar Air Base', city: 'Paya Lebar', country: 'Singapore', timezone: 'Asia/Singapore', offset: 8 },
  QPS: { name: 'Campo Fontenelle Airport', city: 'Piracununga', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  QPZ: { name: 'Piacenza San Damiano Air Base', city: 'Piacenza', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  QRA: { name: 'Rand Airport', city: 'Johannesburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  QRM: { name: 'Narromine Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  QRO: { name: 'Querétaro Intercontinental Airport', city: 'Queretaro', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  QRR: { name: 'Warren Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  QSA: { name: 'Sabadell Airport', city: 'Sabadell', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  QSC: { name: 'São Carlos Airport', city: 'Sao Carlos', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  QSF: { name: 'Ain Arnat Airport', city: 'Setif', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  QSN: { name: 'San Nicolas De Bari Airport', city: 'San Nicolás', country: 'Cuba', timezone: null, offset: 0 },
  QSR: { name: 'Salerno Costa d\'Amalfi Airport', city: 'Salerno', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  QUO: { name: 'Akwa Ibom International Airport', city: 'Uyo', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  QUS: { name: 'Gusau Airport', city: 'Gusau', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  QUT: { name: 'Utsunomiya Airport', city: null, country: 'Japan', timezone: null, offset: 0 },
  QVY: { name: 'Utti Air Base', city: 'Utti', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  QXB: { name: 'Aix-en-Provence (BA 114) Airport', city: 'Aix-les-milles', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  QXR: { name: 'Radom Airport', city: 'RADOM', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  QYD: { name: 'Oksywie Military Air Base', city: 'Gdynia', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  QYR: { name: 'Troyes-Barberey Airport', city: 'Troyes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RAB: { name: 'Tokua Airport', city: 'Tokua', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  RAC: { name: 'John H Batten Airport', city: 'Racine', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RAE: { name: 'Arar Domestic Airport', city: 'Arar', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  RAH: { name: 'Rafha Domestic Airport', city: 'Rafha', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  RAI: { name: 'Praia International Airport', city: 'Praia, Santiago Island', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  RAJ: { name: 'Rajkot Airport', city: 'Rajkot', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  RAK: { name: 'Menara Airport', city: 'Marrakech', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  RAL: { name: 'Riverside Municipal Airport', city: 'Riverside', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RAM: { name: 'Ramingining Airport', city: 'Ramingining', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  RAO: { name: 'Leite Lopes Airport', city: 'Ribeirao Preto', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  RAP: { name: 'Rapid City Regional Airport', city: 'Rapid City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RAR: { name: 'Rarotonga International Airport', city: 'Avarua', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: -10 },
  RAS: { name: 'Sardar-e-Jangal Airport', city: 'Rasht', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  RAT: { name: 'Raduzhny Airport', city: 'Raduzhnyi', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  RAZ: { name: 'Rawalakot Airport', city: 'Rawala Kot', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  RBA: { name: 'Rabat-Salé Airport', city: 'Rabat', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  RBB: { name: 'Borba Airport', city: 'Borba', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  RBD: { name: 'Dallas Executive Airport', city: 'Dallas', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RBE: { name: 'Ratanakiri Airport', city: 'Ratanakiri', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  RBK: { name: 'French Valley Airport', city: 'Murrieta-Temecula', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RBM: { name: 'Straubing Airport', city: 'Straubing', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  RBR: { name: 'Plácido de Castro Airport', city: 'Rio Branco', country: 'Brazil', timezone: 'America/Rio_Branco', offset: -5 },
  RBV: { name: 'Ramata Airport', city: 'Ramata', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  RBX: { name: 'Rumbek Airport', city: 'Rumbek', country: 'Sudan', timezone: 'Africa/Juba', offset: 3 },
  RBY: { name: 'Ruby Airport', city: 'Ruby', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  RCA: { name: 'Ellsworth Air Force Base', city: 'Rapid City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RCB: { name: 'Richards Bay Airport', city: 'Richard\'s Bay', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  RCH: { name: 'Almirante Padilla Airport', city: 'Rio Hacha', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  RCL: { name: 'Redcliffe Airport', city: 'Redcliffe', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  RCM: { name: 'Richmond Airport', city: 'Richmond', country: 'Australia', timezone: null, offset: 10 },
  RCO: { name: 'Rochefort-Saint-Agnant (BA 721) Airport', city: 'Rochefort', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RCS: { name: 'Rochester Airport', city: 'Rochester', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  RCU: { name: 'Area De Material Airport', city: 'Rio Cuarto', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  RCZ: { name: 'Richmond County Airport', city: 'Rockingham', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RDC: { name: 'Redenção Airport', city: 'Redencao', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  RDD: { name: 'Redding Municipal Airport', city: 'Redding', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RDG: { name: 'Reading Regional Carl A Spaatz Field', city: 'Reading', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RDM: { name: 'Roberts Field', city: 'Redmond-Bend', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RDN: { name: 'LTS Pulau Redang Airport', city: 'Redang', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  RDP: { name: 'Kazi Nazrul Islam Airport', city: 'Durgapur', country: 'India', timezone: null, offset: 0 },
  RDR: { name: 'Grand Forks Air Force Base', city: 'Red River', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RDS: { name: 'Rincon De Los Sauces Airport', city: 'Rincon de los Sauces', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  RDU: { name: 'Raleigh Durham International Airport', city: 'Raleigh-durham', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RDZ: { name: 'Rodez-Marcillac Airport', city: 'Rodez', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  REA: { name: 'Reao Airport', city: 'Reao', country: 'French Polynesia', timezone: null, offset: -10 },
  REC: { name: 'Guararapes - Gilberto Freyre International Airport', city: 'Recife', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  REG: { name: 'Reggio Calabria Airport', city: 'Reggio Calabria', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  REI: { name: 'Redlands Municipal Airport', city: 'Redlands', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  REL: { name: 'Almirante Marco Andres Zar Airport', city: 'Trelew', country: 'Argentina', timezone: 'America/Catamarca', offset: -3 },
  REN: { name: 'Orenburg Central Airport', city: 'Orenburg', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  REP: { name: 'Siem Reap International Airport', city: 'Siem-reap', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  RES: { name: 'Resistencia International Airport', city: 'Resistencia', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  RET: { name: 'Røst Airport', city: 'Røst', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  REU: { name: 'Reus Air Base', city: 'Reus', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  REX: { name: 'General Lucio Blanco International Airport', city: 'Reynosa', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  REY: { name: 'Reyes Airport', city: 'Reyes', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  RFD: { name: 'Chicago Rockford International Airport', city: 'Rockford', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RFP: { name: 'Raiatea Airport', city: 'Raiatea Island', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  RFS: { name: 'Rosita Airport', city: 'Rosita', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  RGA: { name: 'Hermes Quijada International Airport', city: 'Rio Grande', country: 'Argentina', timezone: 'America/Argentina/Ushuaia', offset: -3 },
  RGI: { name: 'Rangiroa Airport', city: 'Rangiroa', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  RGK: { name: 'Gorno-Altaysk Airport', city: 'Gorno-Altaysk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  RGL: { name: 'Piloto Civil N. Fernández Airport', city: 'Rio Gallegos', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  RGN: { name: 'Yangon International Airport', city: 'Yangon', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  RGS: { name: 'Burgos Airport', city: 'Burgos', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  RGT: { name: 'Japura Airport', city: 'Rengat', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  RHD: { name: 'Termas de Río Hondo international Airport', city: 'Rio Hondo', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  RHE: { name: 'Reims-Champagne (BA 112) Air Base', city: 'Reims', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RHI: { name: 'Rhinelander Oneida County Airport', city: 'Rhinelander', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RHO: { name: 'Diagoras Airport', city: 'Rhodos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  RHP: { name: 'Ramechhap Airport', city: 'Ramechhap', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  RHV: { name: 'Reid-Hillview Airport of Santa Clara County', city: 'San Jose', country: 'United States', timezone: null, offset: 8 },
  RIA: { name: 'Santa Maria Airport', city: 'Santa Maria', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  RIB: { name: 'Capitán Av. Selin Zeitun Lopez Airport', city: 'Riberalta', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  RIC: { name: 'Richmond International Airport', city: 'Richmond', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RID: { name: 'Richmond Municipal Airport', city: 'Richmond', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RIF: { name: 'Richfield Municipal Airport', city: 'Richfield', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RIJ: { name: 'Juan Simons Vela Airport', city: 'Rioja', country: 'Peru', timezone: null, offset: 0 },
  RIL: { name: 'Garfield County Regional Airport', city: 'Rifle', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RIN: { name: 'Ringi Cove Airport', city: 'Ringi Cove', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  RIR: { name: 'Flabob Airport', city: 'Riverside', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RIS: { name: 'Rishiri Airport', city: 'Rishiri Island', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  RIU: { name: 'Rancho Murieta Airport', city: 'Rancho Murieta', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RIV: { name: 'March ARB Airport', city: 'Riverside', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RIW: { name: 'Riverton Regional Airport', city: 'Riverton WY', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RIX: { name: 'Riga International Airport', city: 'Riga', country: 'Latvia', timezone: 'Europe/Riga', offset: 2 },
  RIY: { name: 'Mukalla International Airport', city: 'Mukalla', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  RJA: { name: 'Rajahmundry Airport', city: 'Rajahmundry', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  RJH: { name: 'Shah Mokhdum Airport', city: 'Rajshahi', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  RJK: { name: 'Rijeka Airport', city: 'Rijeka', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  RJL: { name: 'Logroño-Agoncillo Airport', city: 'Logroño-Agoncillo', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  RJN: { name: 'Rafsanjan Airport', city: 'Rafsanjan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  RKD: { name: 'Knox County Regional Airport', city: 'Rockland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RKE: { name: 'Copenhagen Roskilde Airport', city: 'Copenhagen', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  RKH: { name: 'Rock Hill - York County Airport', city: 'Rock Hill', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RKP: { name: 'Aransas County Airport', city: 'Rockport', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RKR: { name: 'Robert S Kerr Airport', city: 'Poteau', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RKS: { name: 'Rock Springs Sweetwater County Airport', city: 'Rock Springs', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RKT: { name: 'Ras Al Khaimah International Airport', city: 'Ras Al Khaimah', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  RKV: { name: 'Reykjavik Airport', city: 'Reykjavik', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  RLG: { name: 'Rostock-Laage Airport', city: 'Laage', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  RLK: { name: 'Bayannur Tianjitai Airport', city: 'Bayannur', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  RMA: { name: 'Roma Airport', city: 'Roma', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  RME: { name: 'Griffiss International Airport', city: 'Rome', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RMF: { name: 'Marsa Alam International Airport', city: 'Marsa Alam', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  RMG: { name: 'Richard B Russell Airport', city: 'Rome', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RMI: { name: 'Federico Fellini International Airport', city: 'Rimini', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  RMK: { name: 'Renmark Airport', city: 'Renmark', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  RML: { name: 'Colombo Ratmalana Airport', city: 'Colombo', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  RMQ: { name: 'Taichung Ching Chuang Kang Airport', city: 'Taichung', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  RMS: { name: 'Ramstein Air Base', city: 'Ramstein', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  RMT: { name: 'Rimatara Airport', city: 'Rimatara', country: 'French Polynesia', timezone: null, offset: -10 },
  RMY: { name: 'Mariposa Yosemite Airport', city: 'Mariposa', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RNA: { name: 'Ulawa Airport', city: 'Ulawa', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  RNB: { name: 'Ronneby Airport', city: 'Ronneby', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  RND: { name: 'Randolph Air Force Base', city: 'San Antonio', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RNE: { name: 'Roanne-Renaison Airport', city: 'Roanne', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RNH: { name: 'New Richmond Regional Airport', city: 'New Richmond', country: 'United States', timezone: null, offset: 0 },
  RNI: { name: 'Corn Island', city: 'Corn Island', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  RNJ: { name: 'Yoron Airport', city: 'Yoron', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  RNL: { name: 'Rennell/Tingoa Airport', city: 'Rennell Island', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  RNM: { name: 'Ramona Airport', city: 'Ramona', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RNN: { name: 'Bornholm Airport', city: 'Ronne', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  RNO: { name: 'Reno Tahoe International Airport', city: 'Reno', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  RNS: { name: 'Rennes-Saint-Jacques Airport', city: 'Rennes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RNT: { name: 'Renton Municipal Airport', city: 'Renton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  ROA: { name: 'Roanoke–Blacksburg Regional Airport', city: 'Roanoke VA', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ROB: { name: 'Roberts International Airport', city: 'Monrovia', country: 'Liberia', timezone: 'Africa/Monrovia', offset: 0 },
  ROC: { name: 'Greater Rochester International Airport', city: 'Rochester', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ROD: { name: 'Robertson Airport', city: 'Robertson', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  ROI: { name: 'Roi Et Airport', city: 'Roi Et', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  ROK: { name: 'Rockhampton Airport', city: 'Rockhampton', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  ROO: { name: 'Maestro Marinho Franco Airport', city: 'Rondonopolis', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  ROP: { name: 'Rota International Airport', city: 'Rota', country: 'Northern Mariana Islands', timezone: 'Pacific/Saipan', offset: 10 },
  ROR: { name: 'Babelthuap Airport', city: 'Babelthuap', country: 'Palau', timezone: 'Pacific/Palau', offset: 9 },
  ROS: { name: 'Islas Malvinas Airport', city: 'Rosario', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  ROT: { name: 'Rotorua Regional Airport', city: 'Rotorua', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  ROV: { name: 'Rostov-on-Don Airport', city: 'Rostov', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  ROW: { name: 'Roswell International Air Center Airport', city: 'Roswell', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RPB: { name: 'Roper Bar Airport', city: 'Roper Bar', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  RPM: { name: 'Ngukurr Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  RPN: { name: 'Ben Ya\'akov Airport', city: 'Rosh Pina', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  RPR: { name: 'Raipur Airport', city: 'Raipur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  RQW: { name: 'Qayyarah West Airport', city: 'Qayyarah', country: 'Iraq', timezone: null, offset: 0 },
  RRG: { name: 'Sir Charles Gaetan Duval Airport', city: 'Rodriguez Island', country: 'Mauritius', timezone: 'Indian/Mauritius', offset: 4 },
  RRK: { name: 'Rourkela Airport', city: 'Rourkela', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  RRS: { name: 'Røros Airport', city: 'Roros', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  RSA: { name: 'Santa Rosa Airport', city: 'Santa Rosa', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  RSD: { name: 'Rock Sound Airport', city: 'Rock Sound', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  RSH: { name: 'Russian Mission Airport', city: 'Russian Mission', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  RSL: { name: 'Russell Municipal Airport', city: 'Russell', country: 'United States', timezone: null, offset: 0 },
  RST: { name: 'Rochester International Airport', city: 'Rochester', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RSU: { name: 'Yeosu Airport', city: 'Yeosu', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  RSW: { name: 'Southwest Florida International Airport', city: 'Fort Myers', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RTA: { name: 'Rotuma Airport', city: 'Rotuma', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  RTB: { name: 'Juan Manuel Galvez International Airport', city: 'Roatan', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  RTC: { name: 'Ratnagiri Airport', city: null, country: 'India', timezone: null, offset: 0 },
  RTG: { name: 'Frans Sales Lega Airport', city: 'Ruteng', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  RTM: { name: 'Rotterdam The Hague Airport', city: 'Rotterdam', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  RTS: { name: 'Rottnest Island Airport', city: 'Rottnest Island', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  RTW: { name: 'Saratov Central Airport', city: 'Saratov', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  RUA: { name: 'Arua Airport', city: 'Arua', country: 'Uganda', timezone: 'Africa/Kampala', offset: 3 },
  RUG: { name: 'Rugao Air Base', city: 'Rugao', country: 'China', timezone: null, offset: 0 },
  RUH: { name: 'King Khaled International Airport', city: 'Riyadh', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  RUK: { name: 'Rukumkot Airport', city: 'Rukumkot', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  RUM: { name: 'Rumjatar Airport', city: 'Rumjatar', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  RUN: { name: 'Roland Garros Airport', city: 'St.-denis', country: 'Reunion', timezone: 'Indian/Reunion', offset: 4 },
  RUR: { name: 'Rurutu Airport', city: 'Rurutu', country: 'French Polynesia', timezone: null, offset: -10 },
  RUS: { name: 'Marau Airport', city: 'Marau', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  RUT: { name: 'Rutland - Southern Vermont Regional Airport', city: 'Rutland', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RUV: { name: 'Rubelsanto Airport', city: 'Rubelsanto', country: 'Guatemala', timezone: null, offset: 0 },
  RVA: { name: 'Farafangana Airport', city: 'Farafangana', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  RVD: { name: 'General Leite de Castro Airport', city: 'Rio Verde', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  RVE: { name: 'Los Colonizadores Airport', city: 'Saravena', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  RVK: { name: 'Rørvik Airport, Ryum', city: 'Rørvik', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  RVN: { name: 'Rovaniemi Airport', city: 'Rovaniemi', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  RVR: { name: 'José Aponte de la Torre Airport', city: 'Ceiba', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  RVS: { name: 'Richard Lloyd Jones Jr Airport', city: 'Tulsa', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  RVT: { name: 'Ravensthorpe Airport', city: 'Ravensthorpe', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  RVV: { name: 'Raivavae Airport', city: 'Raivavae', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  RVY: { name: 'Presidente General Don Oscar D. Gestido International Airport', city: 'Rivera', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  RWF: { name: 'Redwood Falls Municipal Airport', city: 'Redwood Falls', country: 'United States', timezone: null, offset: 0 },
  RWI: { name: 'Rocky Mount Wilson Regional Airport', city: 'Rocky Mount', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RWL: { name: 'Rawlins Municipal Airport/Harvey Field', city: 'Rawlins', country: 'United States', timezone: 'America/Denver', offset: -7 },
  RWN: { name: 'Rivne International Airport', city: 'Rivne', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  RXS: { name: 'Roxas Airport', city: 'Roxas City', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  RYB: { name: 'Staroselye Airport', city: 'Rybinsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  RYG: { name: 'Moss-Rygge Airport', city: 'Rygge', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  RYK: { name: 'Shaikh Zaid Airport', city: 'Rahim Yar Khan', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  RYN: { name: 'Royan-Médis Airport', city: 'Royan', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  RYY: { name: 'Cobb County-Mc Collum Field', city: 'Atlanta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  RZA: { name: 'Santa Cruz Airport', city: 'Santa Cruz', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  RZE: { name: 'Rzeszów-Jasionka Airport', city: 'Rzeszow', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  RZP: { name: 'Cesar Lim Rodriguez Airport', city: 'Taytay', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  RZR: { name: 'Ramsar Airport', city: 'Ramsar', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  RZS: { name: 'Sahiwal Airport', city: 'Sindh', country: 'Pakistan', timezone: null, offset: 5 },
  S40: { name: 'Prosser Airport', city: 'Prosser', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  S46: { name: 'Port O\'Connor Private Airport', city: 'Port O\'Connor', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SAA: { name: 'Shively Field', city: 'SARATOGA', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SAB: { name: 'Juancho E. Yrausquin Airport', city: 'Saba', country: 'Netherlands Antilles', timezone: 'America/Curacao', offset: -4 },
  SAC: { name: 'Sacramento Executive Airport', city: 'Sacramento', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SAD: { name: 'Safford Regional Airport', city: 'Safford', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  SAF: { name: 'Santa Fe Municipal Airport', city: 'Santa Fe', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SAH: { name: 'Sana\'a International Airport', city: 'Sanaa', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  SAK: { name: 'Sauðárkrókur Airport', city: 'Saudarkrokur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  SAL: { name: 'El Salvador International Airport', city: 'San Salvador', country: 'El Salvador', timezone: 'America/El_Salvador', offset: -6 },
  SAN: { name: 'San Diego International Airport', city: 'San Diego', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SAP: { name: 'Ramón Villeda Morales International Airport', city: 'San Pedro Sula', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  SAQ: { name: 'San Andros Airport', city: 'San Andros', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  SAT: { name: 'San Antonio International Airport', city: 'San Antonio', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SAV: { name: 'Savannah Hilton Head International Airport', city: 'Savannah', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SAW: { name: 'Sabiha Gökçen International Airport', city: 'Istanbul', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  SAY: { name: 'Siena-Ampugnano Airport', city: 'Siena', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  SBA: { name: 'Santa Barbara Municipal Airport', city: 'Santa Barbara', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SBD: { name: 'San Bernardino International Airport', city: 'San Bernardino', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SBG: { name: 'Maimun Saleh Airport', city: 'Sabang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SBH: { name: 'Gustaf III Airport', city: 'Gustavia', country: 'France', timezone: null, offset: -4 },
  SBK: { name: 'Saint-Brieuc-Armor Airport', city: 'St.-brieuc Armor', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  SBM: { name: 'Sheboygan County Memorial Airport', city: 'Sheboygan', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SBN: { name: 'South Bend Regional Airport', city: 'South Bend', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SBO: { name: 'Emanuel County Airport', city: 'Santa Barbara', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SBP: { name: 'San Luis County Regional Airport', city: 'San Luis Obispo', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SBR: { name: 'Saibai Island Airport', city: 'Saibai Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  SBS: { name: 'Steamboat Springs Bob Adams Field', city: 'Steamboat Springs', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SBU: { name: 'Springbok Airport', city: 'Springbok', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  SBW: { name: 'Sibu Airport', city: 'Sibu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  SBY: { name: 'Salisbury Ocean City Wicomico Regional Airport', city: 'Salisbury', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SBZ: { name: 'Sibiu International Airport', city: 'Sibiu', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  SCC: { name: 'Deadhorse Airport', city: 'Deadhorse', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SCD: { name: 'Merkel Field Sylacauga Municipal Airport', city: 'Sylacauga', country: 'United States', timezone: null, offset: -5 },
  SCE: { name: 'University Park Airport', city: 'State College Pennsylvania', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SCH: { name: 'Schenectady County Airport', city: 'Scotia NY', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SCK: { name: 'Stockton Metropolitan Airport', city: 'Stockton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SCL: { name: 'Comodoro Arturo Merino Benítez International Airport', city: 'Santiago', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  SCM: { name: 'Scammon Bay Airport', city: 'Scammon Bay', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SCN: { name: 'Saarbrücken Airport', city: 'Saarbruecken', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  SCO: { name: 'Aktau Airport', city: 'Aktau', country: 'Kazakhstan', timezone: 'Asia/Oral', offset: 5 },
  SCQ: { name: 'Santiago de Compostela Airport', city: 'Santiago', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  SCS: { name: 'Scatsta Airport', city: 'Scatsta', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SCT: { name: 'Socotra International Airport', city: 'Socotra', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  SCU: { name: 'Antonio Maceo International Airport', city: 'Santiago De Cuba', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  SCV: { name: 'Suceava Stefan cel Mare Airport', city: 'Suceava', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  SCW: { name: 'Syktyvkar Airport', city: 'Syktyvkar', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  SCY: { name: 'San Cristóbal Airport', city: 'San Cristóbal', country: 'Ecuador', timezone: 'Pacific/Galapagos', offset: -6 },
  SCZ: { name: 'Santa Cruz/Graciosa Bay/Luova Airport', city: 'Santa Cruz/Graciosa Bay/Luova', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  SDC: { name: 'Williamson Sodus Airport', city: 'Williamson', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SDD: { name: 'Lubango Airport', city: 'Lubango', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  SDE: { name: 'Vicecomodoro Angel D. La Paz Aragonés Airport', city: 'Santiago Del Estero', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  SDF: { name: 'Louisville International Standiford Field', city: 'Louisville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SDG: { name: 'Sanandaj Airport', city: 'Sanandaj', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  SDJ: { name: 'Sendai Airport', city: 'Sendai', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SDK: { name: 'Sandakan Airport', city: 'Sandakan', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  SDL: { name: 'Sundsvall-Härnösand Airport', city: 'Sundsvall', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  SDM: { name: 'Brown Field Municipal Airport', city: 'San Diego', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SDN: { name: 'Sandane Airport (Anda)', city: 'Sandane', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SDP: { name: 'Sand Point Airport', city: 'Sand Point', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SDQ: { name: 'Las Américas International Airport', city: 'Santo Domingo', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  SDR: { name: 'Santander Airport', city: 'Santander', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  SDS: { name: 'Sado Airport', city: 'Sado', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SDT: { name: 'Saidu Sharif Airport', city: 'Saidu Sharif', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  SDU: { name: 'Santos Dumont Airport', city: 'Rio De Janeiro', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  SDV: { name: 'Sde Dov Airport', city: 'Tel-aviv', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  SDX: { name: 'Sedona Airport', city: 'Sedona', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  SDY: { name: 'Sidney Richland Municipal Airport', city: 'Sidney', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SEA: { name: 'Seattle Tacoma International Airport', city: 'Seattle', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SEB: { name: 'Sabha Airport', city: 'Sebha', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  SEE: { name: 'Gillespie Field', city: 'El Cajon', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SEF: { name: 'Sebring Regional Airport', city: 'Sebring', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SEH: { name: 'Senggeh Airport', city: 'Senggeh-Papua Island', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  SEK: { name: 'Srednekolymsk Airport', city: 'Srednekolymsk', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  SEM: { name: 'Craig Field', city: 'Selma', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SEN: { name: 'Southend Airport', city: 'Southend', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SEU: { name: 'Seronera Airport', city: 'Seronera', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  SEY: { name: 'Sélibaby Airport', city: 'Selibabi', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  SEZ: { name: 'Seychelles International Airport', city: 'Mahe', country: 'Seychelles', timezone: 'Indian/Mahe', offset: 4 },
  SFA: { name: 'Sfax Thyna International Airport', city: 'Sfax', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  SFB: { name: 'Orlando Sanford International Airport', city: 'Sanford', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SFC: { name: 'St-François Airport', city: 'St-François', country: 'Guadeloupe', timezone: 'America/Guadeloupe', offset: -4 },
  SFD: { name: 'San Fernando De Apure Airport', city: 'San Fernando De Apure', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  SFE: { name: 'San Fernando Airport', city: 'San Fernando', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  SFF: { name: 'Felts Field', city: 'Spokane', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SFG: { name: 'L\'Espérance Airport', city: 'St. Martin', country: 'Guadeloupe', timezone: null, offset: -4 },
  SFH: { name: 'San Felipe International Airport', city: 'San Filipe', country: 'Mexico', timezone: 'America/Tijuana', offset: -8 },
  SFJ: { name: 'Kangerlussuaq Airport', city: 'Sondrestrom', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  SFK: { name: 'Soure Airport', city: 'Soure', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  SFL: { name: 'São Filipe Airport', city: 'Sao Filipe, Fogo Island', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  SFN: { name: 'Sauce Viejo Airport', city: 'Santa Fe', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  SFO: { name: 'San Francisco International Airport', city: 'San Francisco', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SFQ: { name: 'Şanlıurfa Airport', city: 'Sanliurfa', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  SFS: { name: 'Subic Bay International Airport', city: 'Olongapo City', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  SFT: { name: 'Skellefteå Airport', city: 'Skelleftea', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  SFZ: { name: 'North Central State Airport', city: 'Smithfield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SGC: { name: 'Surgut Airport', city: 'Surgut', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  SGD: { name: 'Sønderborg Airport', city: 'Soenderborg', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  SGF: { name: 'Springfield Branson National Airport', city: 'Springfield', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SGH: { name: 'Springfield-Beckley Municipal Airport', city: 'Springfield', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SGI: { name: 'Mushaf Air Base', city: 'Sargodha', country: 'Pakistan', timezone: null, offset: 0 },
  SGN: { name: 'Tan Son Nhat International Airport', city: 'Ho Chi Minh City', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  SGO: { name: 'St George Airport', city: 'St George', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  SGQ: { name: 'Sanggata/Sangkimah Airport', city: 'Sanggata', country: 'Indonesia', timezone: null, offset: 8 },
  SGR: { name: 'Sugar Land Regional Airport', city: 'Sugar Land', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SGS: { name: 'Sanga Sanga Airport', city: 'Sanga Sanga', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  SGU: { name: 'St George Municipal Airport', city: 'Saint George', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SGV: { name: 'Sierra Grande Airport', city: 'Sierra Grande', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  SGX: { name: 'Songea Airport', city: 'Songea', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  SGY: { name: 'Skagway Airport', city: 'Skagway', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SHA: { name: 'Shanghai Hongqiao International Airport', city: 'Shanghai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SHB: { name: 'Nakashibetsu Airport', city: 'Nakashibetsu', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SHD: { name: 'Shenandoah Valley Regional Airport', city: 'Weyers Cave', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SHE: { name: 'Taoxian Airport', city: 'Shenyang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SHG: { name: 'Shungnak Airport', city: 'Shungnak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SHH: { name: 'Shishmaref Airport', city: 'Shishmaref', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SHI: { name: 'Shimojishima Airport', city: 'Shimojishima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SHJ: { name: 'Sharjah International Airport', city: 'Sharjah', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  SHL: { name: 'Shillong Airport', city: 'Shillong', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  SHM: { name: 'Nanki Shirahama Airport', city: 'Nanki-shirahama', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SHN: { name: 'Sanderson Field', city: 'Shelton', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SHO: { name: 'Sokcho Airport', city: 'Sokch\'o', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  SHP: { name: 'Shanhaiguan Airport', city: 'Qinhuangdao', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SHR: { name: 'Sheridan County Airport', city: 'Sheridan', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SHT: { name: 'Shepparton Airport', city: 'Shepparton', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  SHV: { name: 'Shreveport Regional Airport', city: 'Shreveport', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SHW: { name: 'Sharurah Airport', city: 'Sharurah', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  SHX: { name: 'Shageluk Airport', city: 'Shageluk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SHY: { name: 'Shinyanga Airport', city: 'Shinyanga', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  SIA: { name: 'Xi\'an Xiguan Airport', city: 'Xi\'AN', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SIC: { name: 'Sinop Airport', city: 'Sinop', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  SID: { name: 'Amílcar Cabral International Airport', city: 'Amilcar Cabral', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  SIF: { name: 'Simara Airport', city: 'Simara', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  SIG: { name: 'Fernando Luis Ribas Dominicci Airport', city: 'San Juan', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  SIJ: { name: 'Siglufjörður Airport', city: 'Siglufjordur', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  SIK: { name: 'Sikeston Memorial Municipal Airport', city: 'Sikeston', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SIN: { name: 'Singapore Changi Airport', city: 'Singapore', country: 'Singapore', timezone: 'Asia/Singapore', offset: 8 },
  SIO: { name: 'Smithton Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  SIP: { name: 'Simferopol International Airport', city: 'Simferopol', country: 'Ukraine', timezone: 'Europe/Simferopol', offset: 3 },
  SIQ: { name: 'Dabo Airport', city: 'Singkep', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SIR: { name: 'Sion Airport', city: 'Sion', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  SIS: { name: 'Sishen Airport', city: 'Sishen', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  SIT: { name: 'Sitka Rocky Gutierrez Airport', city: 'Sitka', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SIU: { name: 'Siuna', city: 'Siuna', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  SJC: { name: 'Norman Y. Mineta San Jose International Airport', city: 'San Jose', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SJD: { name: 'Los Cabos International Airport', city: 'San Jose Del Cabo', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  SJE: { name: 'Jorge E. Gonzalez Torres Airport', city: 'San Jose Del Guaviare', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  SJI: { name: 'San Jose Airport', city: 'San Jose', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  SJJ: { name: 'Sarajevo International Airport', city: 'Sarajevo', country: 'Bosnia and Herzegovina', timezone: 'Europe/Sarajevo', offset: 1 },
  SJK: { name: 'Professor Urbano Ernesto Stumpf Airport', city: 'Sao Jose Dos Campos', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  SJL: { name: 'São Gabriel da Cachoeira Airport', city: 'Sao Gabriel', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  SJO: { name: 'Juan Santamaria International Airport', city: 'San Jose', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  SJP: { name: 'Prof. Eribelto Manoel Reino State Airport', city: 'Sao Jose Do Rio Preto', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  SJT: { name: 'San Angelo Regional Mathis Field', city: 'San Angelo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SJU: { name: 'Luis Munoz Marin International Airport', city: 'San Juan', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  SJW: { name: 'Shijiazhuang Daguocun International Airport', city: 'Shijiazhuang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SJY: { name: 'Seinäjoki Airport', city: 'Seinäjoki / Ilmajoki', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  SJZ: { name: 'São Jorge Airport', city: 'Sao Jorge Island', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  SKA: { name: 'Fairchild Air Force Base', city: 'Spokane', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SKB: { name: 'Robert L. Bradshaw International Airport', city: 'Basse Terre', country: 'Saint Kitts and Nevis', timezone: 'America/St_Kitts', offset: -4 },
  SKD: { name: 'Samarkand Airport', city: 'Samarkand', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  SKE: { name: 'Skien Airport', city: 'Skien', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SKF: { name: 'Lackland Air Force Base', city: 'San Antonio', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SKG: { name: 'Thessaloniki Macedonia International Airport', city: 'Thessaloniki', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  SKH: { name: 'Surkhet Airport', city: 'Surkhet', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  SKK: { name: 'Shaktoolik Airport', city: 'Shaktoolik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SKN: { name: 'Stokmarknes Skagen Airport', city: 'Stokmarknes', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SKO: { name: 'Sadiq Abubakar III International Airport', city: 'Sokoto', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  SKP: { name: 'Skopje Alexander the Great Airport', city: 'Skopje', country: 'Macedonia', timezone: 'Europe/Skopje', offset: 1 },
  SKS: { name: 'Skrydstrup Air Base', city: 'Skrydstrup', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  SKT: { name: 'Sialkot Airport', city: 'Sialkot', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  SKU: { name: 'Skiros Airport', city: 'Skiros', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  SKV: { name: 'St Catherine International Airport', city: 'St. Catherine', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  SKX: { name: 'Saransk Airport', city: 'Saransk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  SKY: { name: 'Griffing Sandusky Airport', city: 'Sandusky', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SKZ: { name: 'Sukkur Airport', city: 'Sukkur', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  SLA: { name: 'Martin Miguel De Guemes International Airport', city: 'Salta', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  SLC: { name: 'Salt Lake City International Airport', city: 'Salt Lake City', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SLD: { name: 'Sliač Airport', city: 'Sliac', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  SLE: { name: 'Salem Municipal Airport/McNary Field', city: 'Salem', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SLF: { name: 'Sulayel Airport', city: 'Sulayel', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  SLH: { name: 'Sola Airport', city: 'Sola', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  SLI: { name: 'Los Alamitos Army Air Field', city: 'Solwesi', country: 'Zambia', timezone: 'America/Los_Angeles', offset: -8 },
  SLK: { name: 'Adirondack Regional Airport', city: 'Saranac Lake', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SLL: { name: 'Salalah Airport', city: 'Salalah', country: 'Oman', timezone: 'Asia/Muscat', offset: 4 },
  SLM: { name: 'Salamanca Airport', city: 'Salamanca', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  SLN: { name: 'Salina Municipal Airport', city: 'Salina', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SLP: { name: 'Ponciano Arriaga International Airport', city: 'San Luis Potosi', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  SLQ: { name: 'Sleetmute Airport', city: 'Sleetmute', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SLU: { name: 'George F. L. Charles Airport', city: 'Castries', country: 'Saint Lucia', timezone: 'America/St_Lucia', offset: -4 },
  SLV: { name: 'Shimla Airport', city: 'Shimla', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  SLW: { name: 'Plan De Guadalupe International Airport', city: 'Saltillo', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  SLX: { name: 'Salt Cay Airport', city: 'Salt Cay', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  SLY: { name: 'Salekhard Airport', city: 'Salekhard', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  SLZ: { name: 'Marechal Cunha Machado International Airport', city: 'Sao Luis', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  SMA: { name: 'Santa Maria Airport', city: 'Santa Maria (island)', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  SMD: { name: 'Smith Field', city: 'Fort Wayne IN', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SME: { name: 'Lake Cumberland Regional Airport', city: 'Somerset', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SMF: { name: 'Sacramento International Airport', city: 'Sacramento', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SMI: { name: 'Samos Airport', city: 'Samos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  SMK: { name: 'St Michael Airport', city: 'St. Michael', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SML: { name: 'Stella Maris Airport', city: 'Stella Maris', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  SMN: { name: 'Lemhi County Airport', city: 'Salmon', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SMO: { name: 'Santa Monica Municipal Airport', city: 'Santa Monica', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SMQ: { name: 'Sampit(Hasan) Airport', city: 'Sampit-Borneo Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SMR: { name: 'Simón Bolívar International Airport', city: 'Santa Marta', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  SMS: { name: 'Sainte Marie Airport', city: 'Sainte Marie', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  SMV: { name: 'Samedan Airport', city: 'Samedan', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  SMW: { name: 'Smara Airport', city: 'Smara', country: 'Western Sahara', timezone: 'Africa/El_Aaiun', offset: 0 },
  SMX: { name: 'Santa Maria Pub/Capt G Allan Hancock Field', city: 'Santa Maria', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SMZ: { name: 'Stoelmanseiland Airport', city: 'Stoelmans Eiland', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  SNA: { name: 'John Wayne Airport-Orange County Airport', city: 'Santa Ana', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SNB: { name: 'Snake Bay Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  SNC: { name: 'General Ulpiano Paez Airport', city: 'Salinas', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  SNE: { name: 'Preguiça Airport', city: 'Sao Nocolau Island', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  SNJ: { name: 'San Julian Air Base', city: 'Pinar Del Rio', country: 'Cuba', timezone: null, offset: 0 },
  SNN: { name: 'Shannon Airport', city: 'Shannon', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  SNO: { name: 'Sakon Nakhon Airport', city: 'Sakon Nakhon', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  SNP: { name: 'St Paul Island Airport', city: 'St. Paul Island', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SNR: { name: 'Saint-Nazaire-Montoir Airport', city: 'St.-nazaire', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  SNS: { name: 'Salinas Municipal Airport', city: 'Salinas', country: 'United States', timezone: null, offset: 0 },
  SNU: { name: 'Abel Santamaria Airport', city: 'Santa Clara', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  SNV: { name: 'Santa Elena de Uairen Airport', city: 'Santa Ana De Uairen', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  SNW: { name: 'Thandwe Airport', city: 'Thandwe', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  SNY: { name: 'Sidney Municipal-Lloyd W Carr Field', city: 'Sidney', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SOA: { name: 'Sonora Municipal Airport', city: 'Sonora', country: 'United States', timezone: null, offset: 0 },
  SOB: { name: 'Sármellék International Airport', city: 'Sármellék', country: 'Hungary', timezone: 'Europe/Budapest', offset: 1 },
  SOC: { name: 'Adi Sumarmo Wiryokusumo Airport', city: 'Solo City', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SOD: { name: 'Sorocaba Airport', city: 'Sorocaba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  SOF: { name: 'Sofia Airport', city: 'Sofia', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 2 },
  SOG: { name: 'Sogndal Airport', city: 'Sogndal', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SOJ: { name: 'Sørkjosen Airport', city: 'Sorkjosen', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SOM: { name: 'San Tomé Airport', city: 'San Tome', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  SON: { name: 'Santo Pekoa International Airport', city: 'Santo', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  SOO: { name: 'Söderhamn Airport', city: 'Soderhamn', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  SOP: { name: 'Moore County Airport', city: 'Pinehurst-Southern Pines', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SOQ: { name: 'Dominique Edward Osok Airport', city: 'Sorong', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  SOT: { name: 'Sodankyla Airport', city: 'Sodankyla', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  SOU: { name: 'Southampton Airport', city: 'Southampton', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SOW: { name: 'Show Low Regional Airport', city: 'Show Low', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  SOY: { name: 'Stronsay Airport', city: 'Stronsay', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SOZ: { name: 'Solenzara (BA 126) Air Base', city: 'Solenzara', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  SPB: { name: 'Charlotte Amalie Harbor Seaplane Base', city: 'Charlotte Amalie', country: 'Virgin Islands', timezone: 'America/St_Thomas', offset: -4 },
  SPC: { name: 'La Palma Airport', city: 'Santa Cruz De La Palma', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  SPD: { name: 'Saidpur Airport', city: 'Saidpur', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  SPF: { name: 'Black Hills Airport-Clyde Ice Field', city: 'Spearfish-South Dakota', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SPG: { name: 'Albert Whitted Airport', city: 'St. Petersburg', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SPI: { name: 'Abraham Lincoln Capital Airport', city: 'Springfield', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SPK: { name: 'Chitose Air Base', city: 'Chitose', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SPM: { name: 'Spangdahlem Air Base', city: 'Spangdahlem', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  SPN: { name: 'Saipan International Airport', city: 'Saipan', country: 'Northern Mariana Islands', timezone: 'Pacific/Saipan', offset: 10 },
  SPP: { name: 'Menongue Airport', city: 'Menongue', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  SPS: { name: 'Sheppard Air Force Base-Wichita Falls Municipal Airport', city: 'Wichita Falls', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SPU: { name: 'Split Airport', city: 'Split', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  SPW: { name: 'Spencer Municipal Airport', city: 'Spencer', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SPY: { name: 'San Pedro Airport', city: 'San Pedro', country: 'Cote d\'Ivoire', timezone: 'Africa/Abidjan', offset: 0 },
  SPZ: { name: 'Silver Springs Airport', city: 'Silver Springs', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SQG: { name: 'Sintang(Susilo) Airport', city: 'Sintang-Borneo Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SQH: { name: 'Na-San Airport', city: 'Son-La', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  SQL: { name: 'San Carlos Airport', city: 'San Carlos', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SQO: { name: 'Storuman Airport', city: 'Mohed', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  SQQ: { name: 'Šiauliai International Airport', city: 'Siauliai', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  SQX: { name: 'São Miguel do Oeste Airport', city: 'Sao Miguel do Oeste', country: 'Brazil', timezone: null, offset: -3 },
  SRA: { name: 'Santa Rosa Airport', city: 'Santa Rosa', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  SRE: { name: 'Juana Azurduy De Padilla Airport', city: 'Sucre', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  SRG: { name: 'Achmad Yani Airport', city: 'Semarang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SRH: { name: 'Sarh Airport', city: 'Sarh', country: 'Chad', timezone: 'Africa/Ndjamena', offset: 1 },
  SRI: { name: 'Temindung Airport', city: 'Samarinda', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  SRJ: { name: 'Capitán Av. German Quiroga G. Airport', city: 'San Borja', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  SRN: { name: 'Strahan Airport', city: 'Strahan', country: 'Australia', timezone: 'Australia/Melbourne', offset: 10 },
  SRP: { name: 'Stord Airport', city: 'Stord', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SRQ: { name: 'Sarasota Bradenton International Airport', city: 'Sarasota', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SRR: { name: 'Sierra Blanca Regional Airport', city: 'Ruidoso', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SRT: { name: 'Soroti Airport', city: 'Soroti', country: 'Uganda', timezone: 'Africa/Kampala', offset: 3 },
  SRX: { name: 'Gardabya Airport', city: 'Sirt', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  SRY: { name: 'Dasht-e Naz Airport', city: 'Dasht-e-naz', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  SRZ: { name: 'El Trompillo Airport', city: 'Santa Cruz', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  SSA: { name: 'Deputado Luiz Eduardo Magalhães International Airport', city: 'Salvador', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  SSC: { name: 'Shaw Air Force Base', city: 'Sumter', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SSE: { name: 'Solapur Airport', city: 'Sholapur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  SSF: { name: 'Stinson Municipal Airport', city: 'Stinson', country: 'United States', timezone: null, offset: -5 },
  SSG: { name: 'Malabo Airport', city: 'Malabo', country: 'Equatorial Guinea', timezone: 'Africa/Malabo', offset: 1 },
  SSH: { name: 'Sharm El Sheikh International Airport', city: 'Sharm El Sheikh', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  SSI: { name: 'Malcolm McKinnon Airport', city: 'Brunswick', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SSJ: { name: 'Sandnessjøen Airport (Stokka)', city: 'Sandnessjoen', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SSN: { name: 'Seoul Air Base', city: 'Seoul East', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  SSR: { name: 'Sara Airport', city: 'Pentecost Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  SST: { name: 'Santa Teresita Airport', city: 'Santa Teresita', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  SSV: { name: 'Lasikin Airport', city: 'Sinabang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SSY: { name: 'Mbanza Congo Airport', city: 'M\'banza-congo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  SSZ: { name: 'Base Aérea de Santos Airport', city: 'Santos', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  STA: { name: 'Stauning Airport', city: 'Stauning', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  STB: { name: 'Santa Bárbara del Zulia Airport', city: 'Santa Barbara', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  STC: { name: 'St Cloud Regional Airport', city: 'Saint Cloud', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  STD: { name: 'Mayor Buenaventura Vivas International Airport', city: 'Santo Domingo', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  STE: { name: 'Stevens Point Municipal Airport', city: 'Stevens Point', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  STG: { name: 'St George Airport', city: 'Point Barrow', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  STI: { name: 'Cibao International Airport', city: 'Santiago', country: 'Dominican Republic', timezone: 'America/Santo_Domingo', offset: -4 },
  STJ: { name: 'Rosecrans Memorial Airport', city: 'Rosecrans', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  STK: { name: 'Sterling Municipal Airport', city: 'Sterling', country: 'United States', timezone: 'America/Denver', offset: -7 },
  STL: { name: 'Lambert St Louis International Airport', city: 'St. Louis', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  STM: { name: 'Maestro Wilson Fonseca Airport', city: 'Santarem', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  STN: { name: 'London Stansted Airport', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  STP: { name: 'St Paul Downtown Holman Field', city: 'St. Paul', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  STR: { name: 'Stuttgart Airport', city: 'Stuttgart', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  STS: { name: 'Charles M. Schulz Sonoma County Airport', city: 'Santa Rosa', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  STT: { name: 'Cyril E. King Airport', city: 'St. Thomas', country: 'Virgin Islands', timezone: 'America/St_Thomas', offset: -4 },
  STU: { name: 'Santa Cruz Airport', city: 'Rio De Janeiro', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  STV: { name: 'Surat Airport', city: 'Surat', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  STW: { name: 'Stavropol Shpakovskoye Airport', city: 'Stavropol', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  STX: { name: 'Henry E Rohlsen Airport', city: 'St. Croix Island', country: 'Virgin Islands', timezone: 'America/St_Thomas', offset: -4 },
  STY: { name: 'Nueva Hesperides International Airport', city: 'Salto', country: 'Uruguay', timezone: 'America/Montevideo', offset: -3 },
  STZ: { name: 'Santa Terezinha Airport', city: 'Santa Terezinha', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  SUA: { name: 'Witham Field', city: 'Stuart', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SUB: { name: 'Juanda International Airport', city: 'Surabaya', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  SUF: { name: 'Lamezia Terme Airport', city: 'Lamezia', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  SUG: { name: 'Surigao Airport', city: 'Sangley Point', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  SUI: { name: 'Sukhumi Dranda Airport', city: 'Sukhumi', country: 'Georgia', timezone: 'Asia/Tbilisi', offset: 4 },
  SUJ: { name: 'Satu Mare Airport', city: 'Satu Mare', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  SUL: { name: 'Sui Airport', city: 'Sui', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  SUN: { name: 'Friedman Memorial Airport', city: 'Hailey', country: 'United States', timezone: 'America/Denver', offset: -7 },
  SUR: { name: 'Summer Beaver Airport', city: 'Summer Beaver', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  SUS: { name: 'Spirit of St Louis Airport', city: 'Null', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SUT: { name: 'Brunswick County Airport', city: 'Oak Island', country: 'United States', timezone: null, offset: -5 },
  SUU: { name: 'Travis Air Force Base', city: 'Fairfield', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  SUV: { name: 'Nausori International Airport', city: 'Nausori', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  SUX: { name: 'Sioux Gateway Col. Bud Day Field', city: 'Sioux City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SUY: { name: 'Suntar Airport', city: 'Suntar', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  SUZ: { name: 'Saline County Regional Airport', city: 'Benton', country: 'United States', timezone: null, offset: 0 },
  SVA: { name: 'Savoonga Airport', city: 'Savoonga', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SVB: { name: 'Sambava Airport', city: 'Sambava', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  SVD: { name: 'E. T. Joshua Airport', city: 'Kingstown', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: -4 },
  SVG: { name: 'Stavanger Airport Sola', city: 'Stavanger', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SVH: { name: 'Statesville Regional Airport', city: 'Statesville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SVI: { name: 'Eduardo Falla Solano Airport', city: 'San Vincente De Caguan', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  SVJ: { name: 'Svolvær Helle Airport', city: 'Svolvær', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  SVL: { name: 'Savonlinna Airport', city: 'Savonlinna', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  SVN: { name: 'Hunter Army Air Field', city: 'Hunter Aaf', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SVO: { name: 'Sheremetyevo International Airport', city: 'Moscow', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  SVP: { name: 'Kuito Airport', city: 'Kuito', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  SVQ: { name: 'Sevilla Airport', city: 'Sevilla', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  SVU: { name: 'Savusavu Airport', city: 'Savusavu', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  SVW: { name: 'Sparrevohn LRRS Airport', city: 'Sparrevohn', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SVX: { name: 'Koltsovo Airport', city: 'Yekaterinburg', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  SVZ: { name: 'San Antonio Del Tachira Airport', city: 'San Antonio', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  SWA: { name: 'Jieyang Chaoshan International Airport', city: 'Shantou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SWC: { name: 'Stawell Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  SWD: { name: 'Seward Airport', city: 'Seward', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SWF: { name: 'Stewart International Airport', city: 'Newburgh', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SWH: { name: 'Swan Hill Airport', city: 'Swan Hill', country: 'Australia', timezone: null, offset: 10 },
  SWJ: { name: 'Southwest Bay Airport', city: 'Malekula Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  SWP: { name: 'Swakopmund Airport', city: 'Swakopmund', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  SWQ: { name: 'Sumbawa Besar Airport', city: 'Sumbawa Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  SWS: { name: 'Swansea Airport', city: 'Swansea', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SWT: { name: 'Strezhevoy Airport', city: 'Strezhevoy', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  SWU: { name: 'Suwon Airport', city: 'Suwon', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  SWX: { name: 'Shakawe Airport', city: 'Shakawe', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  SXB: { name: 'Strasbourg Airport', city: 'Strasbourg', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  SXE: { name: 'West Sale Airport', city: 'Sale', country: 'Australia', timezone: null, offset: 0 },
  SXF: { name: 'Berlin-Schönefeld International Airport', city: 'Berlin', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  SXJ: { name: 'Shanshan Airport', city: 'Shanshan', country: 'China', timezone: null, offset: 0 },
  SXK: { name: 'Saumlaki/Olilit Airport', city: 'Saumlaki', country: 'Indonesia', timezone: null, offset: 9 },
  SXL: { name: 'Sligo Airport', city: 'Sligo', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  SXM: { name: 'Princess Juliana International Airport', city: 'Philipsburg', country: 'Netherlands Antilles', timezone: 'America/Curacao', offset: -4 },
  SXN: { name: 'Sua Pan Airport', city: 'Sowa', country: 'Botswana', timezone: null, offset: 0 },
  SXO: { name: 'São Félix do Araguaia Airport', city: 'Sao Felix do Araguaia', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  SXQ: { name: 'Soldotna Airport', city: 'Soldotna', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  SXR: { name: 'Sheikh ul Alam Airport', city: 'Srinagar', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  SXX: { name: 'São Félix do Xingu Airport', city: 'Sao Felix do Xingu', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  SYD: { name: 'Sydney Kingsford Smith International Airport', city: 'Sydney', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  SYH: { name: 'Syangboche Airport', city: 'Syangboche', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  SYM: { name: 'Pu\'er Simao Airport', city: 'Simao', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SYO: { name: 'Shonai Airport', city: 'Shonai', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  SYQ: { name: 'Tobias Bolanos International Airport', city: 'San Jose', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  SYR: { name: 'Syracuse Hancock International Airport', city: 'Syracuse', country: 'United States', timezone: 'America/New_York', offset: -5 },
  SYU: { name: 'Warraber Island Airport', city: 'Sue Islet', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  SYW: { name: 'Sehwan Sharif Airport', city: 'Sehwan Sharif', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  SYX: { name: 'Sanya Phoenix International Airport', city: 'Sanya', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SYY: { name: 'Stornoway Airport', city: 'Stornoway', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  SYZ: { name: 'Shiraz Shahid Dastghaib International Airport', city: 'Shiraz', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  SZA: { name: 'Soyo Airport', city: 'Soyo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  SZB: { name: 'Sultan Abdul Aziz Shah International Airport', city: 'Kuala Lumpur', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  SZF: { name: 'Samsun Çarşamba Airport', city: 'Samsun', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  SZG: { name: 'Salzburg Airport', city: 'Salzburg', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  SZK: { name: 'Skukuza Airport', city: 'Skukuza', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  SZL: { name: 'Whiteman Air Force Base', city: 'Knobnoster', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  SZS: { name: 'Ryans Creek Aerodrome', city: 'Stewart Island', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  SZT: { name: 'San Cristobal de las Casas Airport', city: 'San Cristobal de las Casas', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  SZV: { name: 'Suzhou Guangfu Airport', city: 'Suzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SZW: { name: 'Schwerin Parchim Airport', city: 'Parchim', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  SZX: { name: 'Shenzhen Bao\'an International Airport', city: 'Shenzhen', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  SZY: { name: 'Szczytno-Szymany International Airport', city: 'Szczytno-Szymany', country: 'Poland', timezone: null, offset: 1 },
  SZZ: { name: 'Szczecin-Goleniów "Solidarność" Airport', city: 'Szczecin', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  TAB: { name: 'Tobago-Crown Point Airport', city: 'Scarborough', country: 'Trinidad and Tobago', timezone: 'America/Port_of_Spain', offset: -4 },
  TAC: { name: 'Daniel Z. Romualdez Airport', city: 'Tacloban', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  TAE: { name: 'Daegu Airport', city: 'Taegu', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  TAF: { name: 'Tafaraoui Airport', city: 'Oran', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TAG: { name: 'Tagbilaran Airport', city: 'Tagbilaran', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  TAH: { name: 'Tanna Airport', city: 'Tanna', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  TAI: { name: 'Ta\'izz International Airport', city: 'Taiz', country: 'Yemen', timezone: 'Asia/Aden', offset: 3 },
  TAK: { name: 'Takamatsu Airport', city: 'Takamatsu', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TAM: { name: 'General Francisco Javier Mina International Airport', city: 'Tampico', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TAN: { name: 'Taunton Municipal King Field', city: 'Taunton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TAO: { name: 'Liuting Airport', city: 'Qingdao', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TAP: { name: 'Tapachula International Airport', city: 'Tapachula', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TAR: { name: 'Taranto-Grottaglie "Marcello Arlotta" Airport', city: 'Grottaglie', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TAS: { name: 'Tashkent International Airport', city: 'Tashkent', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  TAT: { name: 'Poprad-Tatry Airport', city: 'Poprad', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 1 },
  TAY: { name: 'Tartu Airport', city: 'Tartu', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 2 },
  TAZ: { name: 'Daşoguz Airport', city: 'Dasoguz', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 5 },
  TBB: { name: 'Dong Tac Airport', city: 'Tuy Hoa', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  TBF: { name: 'Tabiteuea North Airport', city: 'Tabiteuea North', country: 'Kiribati', timezone: null, offset: 12 },
  TBG: { name: 'Tabubil Airport', city: 'Tabubil', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  TBH: { name: 'Tugdan Airport', city: 'Romblon', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  TBJ: { name: 'Tabarka 7 Novembre Airport', city: 'Tabarka', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  TBN: { name: 'Waynesville-St. Robert Regional Forney field', city: 'Fort Leonardwood', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TBO: { name: 'Tabora Airport', city: 'Tabora', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  TBP: { name: 'Capitan FAP Pedro Canga Rodriguez Airport', city: 'Tumbes', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TBS: { name: 'Tbilisi International Airport', city: 'Tbilisi', country: 'Georgia', timezone: 'Asia/Tbilisi', offset: 4 },
  TBT: { name: 'Tabatinga Airport', city: 'Tabatinga', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  TBU: { name: 'Fua\'amotu International Airport', city: 'Tongatapu', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  TBW: { name: 'Donskoye Airport', city: 'Tambow', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  TBZ: { name: 'Tabriz International Airport', city: 'Tabriz', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  TCA: { name: 'Tennant Creek Airport', city: 'Tennant Creek', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  TCB: { name: 'Treasure Cay Airport', city: 'Treasure Cay', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  TCC: { name: 'Tucumcari Municipal Airport', city: 'Tucumcari', country: 'United States', timezone: 'America/Denver', offset: -7 },
  TCE: { name: 'Tulcea Airport', city: 'Tulcea', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  TCG: { name: 'Tacheng Airport', city: 'Tacheng', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TCH: { name: 'Tchibanga Airport', city: 'Tchibanga', country: 'Gabon', timezone: 'Africa/Libreville', offset: 1 },
  TCL: { name: 'Tuscaloosa Regional Airport', city: 'Tuscaloosa AL', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TCM: { name: 'McChord Air Force Base', city: 'Tacoma', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TCN: { name: 'Tehuacan Airport', city: 'Tehuacan', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TCO: { name: 'La Florida Airport', city: 'Tumaco', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  TCP: { name: 'Taba International Airport', city: 'Taba', country: 'Egypt', timezone: 'Africa/Cairo', offset: 2 },
  TCQ: { name: 'Coronel FAP Carlos Ciriani Santa Rosa International Airport', city: 'Tacna', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TCS: { name: 'Truth Or Consequences Municipal Airport', city: 'Truth Or Consequences', country: 'United States', timezone: 'America/Denver', offset: -7 },
  TCZ: { name: 'Tengchong Tuofeng Airport', city: 'Tengchong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TDD: { name: 'Teniente Av. Jorge Henrich Arauz Airport', city: 'Trinidad', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  TDF: { name: 'Person County Airport', city: 'Roxboro', country: 'United States', timezone: null, offset: -5 },
  TDG: { name: 'Tandag Airport', city: 'Tandag', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  TDJ: { name: 'Tadjoura Airport', city: 'Tadjoura', country: 'Djibouti', timezone: 'Africa/Djibouti', offset: 3 },
  TDK: { name: 'Ak Bashat Airport', city: 'Taldykorgan', country: 'Kazakhstan', timezone: 'Asia/Bishkek', offset: 6 },
  TDL: { name: 'Héroes De Malvinas Airport', city: 'Tandil', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  TDR: { name: 'Theodore Airport', city: 'Theodore', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  TDX: { name: 'Trat Airport', city: 'Trat', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  TEA: { name: 'Tela Airport', city: 'Tela', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  TEB: { name: 'Teterboro Airport', city: 'Teterboro', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TED: { name: 'Thisted Airport', city: 'Thisted', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 1 },
  TEE: { name: 'Cheikh Larbi Tébessi Airport', city: 'Tebessa', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TEF: { name: 'Telfer Airport', city: 'Telfer', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  TEM: { name: 'Temora Airport', city: 'Temora', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  TEN: { name: 'Tongren Fenghuang Airport', city: 'Tongren', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TEQ: { name: 'Tekirdağ Çorlu Airport', city: 'Çorlu', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  TER: { name: 'Lajes Field', city: 'Lajes (terceira Island)', country: 'Portugal', timezone: 'Atlantic/Azores', offset: -1 },
  TET: { name: 'Chingozi Airport', city: 'Tete', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  TEU: { name: 'Manapouri Airport', city: 'Manapouri', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  TEV: { name: 'Teruel Airport', city: 'Teruel', country: 'Spain', timezone: null, offset: 0 },
  TEX: { name: 'Telluride Regional Airport', city: 'Telluride', country: 'United States', timezone: 'America/Denver', offset: -7 },
  TEZ: { name: 'Tezpur Airport', city: 'Tezpur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  TFF: { name: 'Tefé Airport', city: 'Tefe', country: 'Brazil', timezone: 'America/Boa_Vista', offset: -4 },
  TFL: { name: 'Juscelino Kubitscheck Airport', city: 'Teofilo Otoni', country: 'Brazil', timezone: null, offset: -3 },
  TFN: { name: 'Tenerife Norte Airport', city: 'Tenerife', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  TFS: { name: 'Tenerife South Airport', city: 'Tenerife', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  TGD: { name: 'Podgorica Airport', city: 'Podgorica', country: 'Montenegro', timezone: 'Europe/Podgorica', offset: 1 },
  TGG: { name: 'Sultan Mahmud Airport', city: 'Kuala Terengganu', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  TGH: { name: 'Tongoa Airport', city: 'Tongoa Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  TGI: { name: 'Tingo Maria Airport', city: 'Tingo Maria', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TGJ: { name: 'Tiga Airport', city: 'Tiga', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  TGM: { name: 'Transilvania Târgu Mureş International Airport', city: 'Tirgu Mures', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  TGN: { name: 'Latrobe Valley Airport', city: 'Morwell', country: 'Australia', timezone: null, offset: 0 },
  TGO: { name: 'Tongliao Airport', city: 'Tongliao', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TGR: { name: 'Touggourt Sidi Madhi Airport', city: 'Touggourt', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TGT: { name: 'Tanga Airport', city: 'Tanga', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  TGU: { name: 'Toncontín International Airport', city: 'Tegucigalpa', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  TGZ: { name: 'Angel Albino Corzo International Airport', city: 'Tuxtla Gutierrez', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  THE: { name: 'Senador Petrônio Portela Airport', city: 'Teresina', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  THF: { name: 'Berlin-Tempelhof International Airport', city: 'Berlin', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  THG: { name: 'Thangool Airport', city: 'Biloela', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  THL: { name: 'Tachileik Airport', city: 'Tachilek', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  THN: { name: 'Trollhättan-Vänersborg Airport', city: 'Trollhattan', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  THO: { name: 'Thorshofn Airport', city: 'Thorshofn', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  THR: { name: 'Mehrabad International Airport', city: 'Teheran', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  THS: { name: 'Sukhothai Airport', city: 'Sukhothai', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  THU: { name: 'Thule Air Base', city: 'Thule', country: 'Greenland', timezone: 'America/Thule', offset: -4 },
  THZ: { name: 'Tahoua Airport', city: 'Tahoua', country: 'Niger', timezone: 'Africa/Niamey', offset: 1 },
  TIA: { name: 'Tirana International Airport Mother Teresa', city: 'Tirana', country: 'Albania', timezone: 'Europe/Tirane', offset: 1 },
  TID: { name: 'Bou Chekif Airport', city: 'Tiaret', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TIE: { name: 'Tippi Airport', city: 'Tippi', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 3 },
  TIF: { name: 'Ta’if Regional Airport', city: 'Taif', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  TIH: { name: 'Tikehau Airport', city: 'Tikehau', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  TII: { name: 'Tarin Kowt Airport', city: 'Tarin Kowt', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  TIJ: { name: 'General Abelardo L. Rodríguez International Airport', city: 'Tijuana', country: 'Mexico', timezone: 'America/Tijuana', offset: -8 },
  TIK: { name: 'Tinker Air Force Base', city: 'Oklahoma City', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TIM: { name: 'Moses Kilangin Airport', city: 'Timika', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  TIN: { name: 'Tindouf Airport', city: 'Tindouf', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TIP: { name: 'Tripoli International Airport', city: 'Tripoli', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  TIQ: { name: 'Tinian International Airport', city: 'West Tinian', country: 'Northern Mariana Islands', timezone: 'Pacific/Saipan', offset: 10 },
  TIR: { name: 'Tirupati Airport', city: 'Tirupeti', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  TIU: { name: 'Timaru Airport', city: 'Timaru', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  TIV: { name: 'Tivat Airport', city: 'Tivat', country: 'Montenegro', timezone: 'Europe/Podgorica', offset: 1 },
  TIW: { name: 'Tacoma Narrows Airport', city: 'Tacoma', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TIX: { name: 'Space Coast Regional Airport', city: 'Titusville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TIY: { name: 'Tidjikja Airport', city: 'Tidjikja', country: 'Mauritania', timezone: 'Africa/Nouakchott', offset: 0 },
  TIZ: { name: 'Tari Airport', city: 'Tari', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  TJA: { name: 'Capitan Oriel Lea Plaza Airport', city: 'Tarija', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  TJG: { name: 'Warukin Airport', city: 'Tanjung-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  TJH: { name: 'Tajima Airport', city: 'Toyooka', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TJL: { name: 'Plínio Alarcom Airport', city: 'Tres Lagoas', country: 'Brazil', timezone: 'America/Campo_Grande', offset: -4 },
  TJM: { name: 'Roshchino International Airport', city: 'Tyumen', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  TJQ: { name: 'Buluh Tumbang (H A S Hanandjoeddin) Airport', city: 'Tanjung Pandan', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  TJS: { name: 'Tanjung Harapan Airport', city: 'Tanjung Selor-Borneo Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  TJU: { name: 'Kulob Airport', city: 'Kulyab', country: 'Tajikistan', timezone: 'Asia/Dushanbe', offset: 5 },
  TKA: { name: 'Talkeetna Airport', city: 'Talkeetna', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TKC: { name: 'Tiko Airport', city: 'Tiko', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  TKD: { name: 'Takoradi Airport', city: 'Takoradi', country: 'Ghana', timezone: 'Africa/Accra', offset: 0 },
  TKF: { name: 'Truckee Tahoe Airport', city: 'Truckee', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TKG: { name: 'Radin Inten II (Branti) Airport', city: 'Bandar Lampung-Sumatra Island', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  TKI: { name: 'Collin County Regional At Mc Kinney Airport', city: 'DALLAS', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TKJ: { name: 'Tok Junction Airport', city: 'Tok', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TKK: { name: 'Chuuk International Airport', city: 'Chuuk', country: 'Micronesia', timezone: 'Pacific/Truk', offset: 10 },
  TKN: { name: 'Tokunoshima Airport', city: 'Tokunoshima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TKP: { name: 'Takapoto Airport', city: 'Takapoto', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  TKQ: { name: 'Kigoma Airport', city: 'Kigoma', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  TKS: { name: 'Tokushima Airport', city: 'Tokushima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TKT: { name: 'Tak Airport', city: 'Tak', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  TKU: { name: 'Turku Airport', city: 'Turku', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  TKX: { name: 'Takaroa Airport', city: 'Takaroa', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  TLA: { name: 'Teller Airport', city: 'Teller', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TLC: { name: 'Licenciado Adolfo Lopez Mateos International Airport', city: 'Toluca', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TLD: { name: 'Limpopo Valley Airport', city: 'Tuli Lodge', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 2 },
  TLE: { name: 'Toliara Airport', city: 'Toliara', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  TLH: { name: 'Tallahassee Regional Airport', city: 'Tallahassee', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TLI: { name: 'Sultan Bantilan Airport', city: 'Toli-Toli', country: 'Indonesia', timezone: null, offset: 8 },
  TLJ: { name: 'Tatalina LRRS Airport', city: 'Tatalina', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TLL: { name: 'Lennart Meri Tallinn Airport', city: 'Tallinn-ulemiste International', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 2 },
  TLM: { name: 'Zenata – Messali El Hadj Airport', city: 'Tlemcen', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TLN: { name: 'Toulon-Hyères Airport', city: 'Hyeres', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  TLQ: { name: 'Turpan Jiaohe Airport', city: 'Turpan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TLS: { name: 'Toulouse-Blagnac Airport', city: 'Toulouse', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  TLU: { name: 'Golfo de Morrosquillo Airport', city: 'Tolu', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  TLV: { name: 'Ben Gurion International Airport', city: 'Tel-aviv', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  TLX: { name: 'Panguilemo Airport', city: 'Talca', country: 'Chile', timezone: null, offset: 0 },
  TMA: { name: 'Henry Tift Myers Airport', city: 'Tifton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TMB: { name: 'Kendall-Tamiami Executive Airport', city: 'Kendall-tamiami', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TMC: { name: 'Tambolaka Airport', city: 'Waikabubak-Sumba Island', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  TME: { name: 'Gustavo Vargas Airport', city: 'Tame', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  TMF: { name: 'Thimarafushi Airport', city: 'Thimarafushi', country: 'Maldives', timezone: null, offset: 5 },
  TMG: { name: 'Tomanggong Airport', city: 'Tomanggong', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  TMI: { name: 'Tumling Tar Airport', city: 'Tumling Tar', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  TMJ: { name: 'Termez Airport', city: 'Termez', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  TML: { name: 'Tamale Airport', city: 'Tamale', country: 'Ghana', timezone: 'Africa/Accra', offset: 0 },
  TMM: { name: 'Toamasina Airport', city: 'Toamasina', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  TMN: { name: 'Tamana Island Airport', city: 'Tamana', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  TMP: { name: 'Tampere-Pirkkala Airport', city: 'Tampere', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  TMR: { name: 'Aguenar – Hadj Bey Akhamok Airport', city: 'Tamanrasset', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TMS: { name: 'São Tomé International Airport', city: 'Sao Tome', country: 'Sao Tome and Principe', timezone: 'Africa/Sao_Tome', offset: 0 },
  TMT: { name: 'Trombetas Airport', city: 'Oriximina', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  TMU: { name: 'Tambor Airport', city: 'Nicoya', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  TMW: { name: 'Tamworth Airport', city: 'Tamworth', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  TMX: { name: 'Timimoun Airport', city: 'Timimoun', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  TN0: { name: 'Trento-Mattarello Airport', city: 'Trento (TN)', country: 'Italy', timezone: null, offset: 0 },
  TNA: { name: 'Yaoqiang Airport', city: 'Jinan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TNC: { name: 'Tin City Long Range Radar Station Airport', city: 'Tin City', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TND: { name: 'Alberto Delgado Airport', city: 'Trinidad', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  TNE: { name: 'New Tanegashima Airport', city: 'Tanegashima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TNF: { name: 'Toussus-le-Noble Airport', city: 'Toussous-le-noble', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  TNG: { name: 'Ibn Batouta Airport', city: 'Tanger', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  TNH: { name: 'Tonghua Sanyuanpu Airport', city: 'Tonghua', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TNI: { name: 'Satna Airport', city: 'Satna', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  TNJ: { name: 'Raja Haji Fisabilillah International Airport', city: 'Tanjung Pinang', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 7 },
  TNL: { name: 'Ternopil International Airport', city: 'Ternopol', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  TNN: { name: 'Tainan Airport', city: 'Tainan', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  TNO: { name: 'Cabo Velas Airport', city: 'Nicoya', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  TNR: { name: 'Ivato Airport', city: 'Antananarivo', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  TNT: { name: 'Dade Collier Training and Transition Airport', city: 'Miami', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TNX: { name: 'Stung Treng Airport', city: 'Stung Treng', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 7 },
  TOA: { name: 'Zamperini Field', city: 'Torrance', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TOB: { name: 'Gamal Abdel Nasser Airport', city: 'Tobruk', country: 'Libya', timezone: 'Africa/Tripoli', offset: 2 },
  TOC: { name: 'Toccoa Airport - R.G. Letourneau Field', city: 'Toccoa', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TOD: { name: 'Pulau Tioman Airport', city: 'Tioman', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  TOE: { name: 'Tozeur Nefta International Airport', city: 'Tozeur', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  TOF: { name: 'Bogashevo Airport', city: 'Tomsk', country: 'Russia', timezone: 'Asia/Krasnoyarsk', offset: 7 },
  TOG: { name: 'Togiak Airport', city: 'Togiak Village', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  TOH: { name: 'Torres Airstrip', city: 'Loh/Linua', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  TOI: { name: 'Troy Municipal Airport', city: 'Troy', country: 'United States', timezone: null, offset: -5 },
  TOJ: { name: 'Torrejón Airport', city: 'Madrid', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  TOL: { name: 'Toledo Express Airport', city: 'Toledo', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TOM: { name: 'Timbuktu Airport', city: 'Tombouctou', country: 'Mali', timezone: 'Africa/Bamako', offset: 0 },
  TOP: { name: 'Philip Billard Municipal Airport', city: 'Topeka', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TOQ: { name: 'Barriles Airport', city: 'Tocopilla', country: 'Chile', timezone: null, offset: 0 },
  TOS: { name: 'Tromsø Airport', city: 'Tromso', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  TOT: { name: 'Totness Airport', city: 'Totness', country: 'Suriname', timezone: 'America/Paramaribo', offset: -3 },
  TOU: { name: 'Touho Airport', city: 'Touho', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  TOW: { name: 'Toledo Airport', city: 'Toledo', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  TOY: { name: 'Toyama Airport', city: 'Toyama', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TPA: { name: 'Tampa International Airport', city: 'Tampa', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TPC: { name: 'Tarapoa Airport', city: 'Tarapoa', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  TPE: { name: 'Taiwan Taoyuan International Airport', city: 'Taipei', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  TPF: { name: 'Peter O Knight Airport', city: 'Tampa', country: 'United States', timezone: null, offset: -5 },
  TPH: { name: 'Tonopah Airport', city: 'Tonopah', country: 'United States', timezone: null, offset: 0 },
  TPJ: { name: 'Taplejung Airport', city: 'Taplejung', country: 'Nepal', timezone: 'Asia/Katmandu', offset: 5 },
  TPL: { name: 'Draughon Miller Central Texas Regional Airport', city: 'Temple', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TPN: { name: 'Tiputini Airport', city: 'Tiputini', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  TPP: { name: 'Cadete FAP Guillermo Del Castillo Paredes Airport', city: 'Tarapoto', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TPQ: { name: 'Amado Nervo National Airport', city: 'Tepic', country: 'Mexico', timezone: 'America/Mazatlan', offset: -7 },
  TPS: { name: 'Vincenzo Florio Airport Trapani-Birgi', city: 'Trapani', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TQK: { name: 'Scott City Municipal Airport', city: 'Scott City', country: 'United States', timezone: null, offset: -5 },
  TQL: { name: 'Tarko-Sale Airport', city: 'Tarko-Sale', country: 'Russia', timezone: null, offset: 5 },
  TQS: { name: 'Tres Esquinas Air Base', city: 'Tres Esquinas', country: 'Colombia', timezone: null, offset: 0 },
  TRC: { name: 'Francisco Sarabia International Airport', city: 'Torreon', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TRD: { name: 'Trondheim Airport Værnes', city: 'Trondheim', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  TRE: { name: 'Tiree Airport', city: 'Tiree', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  TRF: { name: 'Sandefjord Airport, Torp', city: 'Sandefjord', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  TRG: { name: 'Tauranga Airport', city: 'Tauranga', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  TRI: { name: 'Tri Cities Regional Tn Va Airport', city: 'BRISTOL', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TRK: { name: 'Juwata Airport', city: 'Taraken', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  TRM: { name: 'Jacqueline Cochran Regional Airport', city: 'Palm Springs', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TRN: { name: 'Turin Airport', city: 'Torino', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TRO: { name: 'Taree Airport', city: 'Taree', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  TRR: { name: 'China Bay Airport', city: 'Trinciomalee', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  TRS: { name: 'Trieste–Friuli Venezia Giulia Airport', city: 'Ronchi De Legionari', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TRU: { name: 'Capitan FAP Carlos Martinez De Pinillos International Airport', city: 'Trujillo', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TRV: { name: 'Trivandrum International Airport', city: 'Trivandrum', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  TRW: { name: 'Bonriki International Airport', city: 'Tarawa', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  TRZ: { name: 'Tiruchirapally Civil Airport Airport', city: 'Tiruchirappalli', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  TSA: { name: 'Taipei Songshan Airport', city: 'Taipei', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  TSB: { name: 'Tsumeb Airport', city: 'Tsumeb', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  TSE: { name: 'Astana International Airport', city: 'Tselinograd', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  TSF: { name: 'Treviso-Sant\'Angelo Airport', city: 'Treviso', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TSH: { name: 'Tshikapa Airport', city: 'Tshikapa', country: 'Congo (Kinshasa)', timezone: 'Africa/Lubumbashi', offset: 2 },
  TSJ: { name: 'Tsushima Airport', city: 'Tsushima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TSL: { name: 'Tamuin Airport', city: 'Tamuin', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TSN: { name: 'Tianjin Binhai International Airport', city: 'Tianjin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TSO: { name: 'Tresco Heliport', city: 'Tresco', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  TSR: { name: 'Timişoara Traian Vuia Airport', city: 'Timisoara', country: 'Romania', timezone: 'Europe/Bucharest', offset: 2 },
  TST: { name: 'Trang Airport', city: 'Trang', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  TSU: { name: 'Tabiteuea South Airport', city: 'Tabiteuea', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 12 },
  TSV: { name: 'Townsville Airport', city: 'Townsville', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  TTA: { name: 'Tan Tan Airport', city: 'Tan Tan', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  TTB: { name: 'Tortolì Airport', city: 'Tortoli', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  TTC: { name: 'Las Breas Airport', city: 'Taltal', country: 'Chile', timezone: null, offset: 0 },
  TTD: { name: 'Portland Troutdale Airport', city: 'Troutdale', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TTE: { name: 'Sultan Khairun Babullah Airport', city: 'Ternate', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  TTG: { name: 'General Enrique Mosconi Airport', city: 'Tartagal', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  TTH: { name: 'Thumrait Air Base', city: 'Thumrait', country: 'Oman', timezone: 'Asia/Muscat', offset: 4 },
  TTI: { name: 'Tetiaroa Airport', city: 'Tetiaroa', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  TTJ: { name: 'Tottori Airport', city: 'Tottori', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TTL: { name: 'Turtle Island Seaplane Base', city: 'Turtle Island', country: 'Fiji', timezone: null, offset: 12 },
  TTN: { name: 'Trenton Mercer Airport', city: 'Trenton', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TTQ: { name: 'Aerotortuguero Airport', city: 'Roxana', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  TTR: { name: 'Pongtiku Airport', city: 'Makale', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  TTS: { name: 'Nasa Shuttle Landing Facility Airport', city: 'Tsaratanana', country: 'Madagascar', timezone: 'America/New_York', offset: -5 },
  TTT: { name: 'Taitung Airport', city: 'Fengnin', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  TTU: { name: 'Saniat R\'mel Airport', city: 'Tetouan', country: 'Morocco', timezone: 'Africa/Casablanca', offset: 0 },
  TUA: { name: 'Teniente Coronel Luis a Mantilla Airport', city: 'Tulcan', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  TUB: { name: 'Tubuai Airport', city: 'Tubuai', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  TUC: { name: 'Teniente Benjamin Matienzo Airport', city: 'Tucuman', country: 'Argentina', timezone: 'America/Argentina/Tucuman', offset: -3 },
  TUD: { name: 'Tambacounda Airport', city: 'Tambacounda', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  TUF: { name: 'Tours-Val-de-Loire Airport', city: 'Tours', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  TUG: { name: 'Tuguegarao Airport', city: 'Tuguegarao', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  TUI: { name: 'Turaif Domestic Airport', city: 'Turaif', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  TUK: { name: 'Turbat International Airport', city: 'Turbat', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  TUL: { name: 'Tulsa International Airport', city: 'Tulsa', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TUM: { name: 'Tumut Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  TUN: { name: 'Tunis Carthage International Airport', city: 'Tunis', country: 'Tunisia', timezone: 'Africa/Tunis', offset: 1 },
  TUO: { name: 'Taupo Airport', city: 'Taupo', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  TUP: { name: 'Tupelo Regional Airport', city: 'Tupelo', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TUR: { name: 'Tucuruí Airport', city: 'Tucurui', country: 'Brazil', timezone: 'America/Belem', offset: -3 },
  TUS: { name: 'Tucson International Airport', city: 'Tucson', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  TUU: { name: 'Tabuk Airport', city: 'Tabuk', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  TUV: { name: 'Tucupita Airport', city: 'Tucupita', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  TVA: { name: 'Morafenobe Airport', city: 'Morafenobe', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  TVC: { name: 'Cherry Capital Airport', city: 'Traverse City', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TVF: { name: 'Thief River Falls Regional Airport', city: 'Thief River Falls', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TVI: { name: 'Thomasville Regional Airport', city: 'Thomasville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TVL: { name: 'Lake Tahoe Airport', city: 'South Lake Tahoe', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  TVU: { name: 'Matei Airport', city: 'Matei', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  TVY: { name: 'Dawei Airport', city: 'Dawei', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  TWB: { name: 'Toowoomba Airport', city: 'Toowoomba', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  TWF: { name: 'Joslin Field Magic Valley Regional Airport', city: 'Twin Falls', country: 'United States', timezone: 'America/Denver', offset: -7 },
  TWU: { name: 'Tawau Airport', city: 'Tawau', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 8 },
  TXA: { name: 'Tlaxcala Airport', city: 'Tlaxcala', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  TXG: { name: 'Taichung Airport', city: 'Taichung', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  TXK: { name: 'Texarkana Regional Webb Field', city: 'Texarkana', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TXL: { name: 'Berlin-Tegel International Airport', city: 'Berlin', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  TXN: { name: 'Tunxi International Airport', city: 'Huangshan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TYB: { name: 'Tibooburra Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  TYF: { name: 'Torsby Airport', city: 'Torsby', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  TYL: { name: 'Capitan Montes Airport', city: 'Talara', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  TYN: { name: 'Taiyuan Wusu Airport', city: 'Taiyuan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  TYO: { name: 'Tokyo (All Airports)', city: 'Tokyo', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  TYR: { name: 'Tyler Pounds Regional Airport', city: 'Tyler', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  TYS: { name: 'McGhee Tyson Airport', city: 'Knoxville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  TZR: { name: 'Taszár Air Base', city: 'Columbus', country: 'United States', timezone: 'Europe/Budapest', offset: 1 },
  TZX: { name: 'Trabzon International Airport', city: 'Trabzon', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  UAB: { name: 'İncirlik Air Base', city: 'Adana', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  UAH: { name: 'Ua Huka Airport', city: 'Ua Huka', country: 'French Polynesia', timezone: 'Pacific/Marquesas', offset: -9 },
  UAK: { name: 'Narsarsuaq Airport', city: 'Narssarssuaq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  UAM: { name: 'Andersen Air Force Base', city: 'Andersen', country: 'Guam', timezone: 'Pacific/Guam', offset: 10 },
  UAO: { name: 'Aurora State Airport', city: 'Aurora', country: 'United States', timezone: null, offset: 0 },
  UAP: { name: 'Ua Pou Airport', city: 'Ua Pou', country: 'French Polynesia', timezone: 'Pacific/Marquesas', offset: -9 },
  UAQ: { name: 'Domingo Faustino Sarmiento Airport', city: 'San Julian', country: 'Argentina', timezone: 'America/Argentina/San_Juan', offset: -3 },
  UAS: { name: 'Samburu South Airport', city: 'Samburu South', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  UBA: { name: 'Mário de Almeida Franco Airport', city: 'Uberaba', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  UBB: { name: 'Mabuiag Island Airport', city: 'Mabuiag Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  UBJ: { name: 'Yamaguchi Ube Airport', city: 'Yamaguchi', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  UBP: { name: 'Ubon Ratchathani Airport', city: 'Ubon Ratchathani', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  UBT: { name: 'Ubatuba Airport', city: 'Ubatuba', country: 'Brazil', timezone: null, offset: -3 },
  UCT: { name: 'Ukhta Airport', city: 'Ukhta', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  UDD: { name: 'Bermuda Dunes Airport', city: 'Palm Springs', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  UDG: { name: 'Darlington County Jetport Airport', city: 'Darlington', country: 'United States', timezone: 'America/New_York', offset: -5 },
  UDI: { name: 'Ten. Cel. Aviador César Bombonato Airport', city: 'Uberlandia', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  UDJ: { name: 'Uzhhorod International Airport', city: 'Uzhgorod', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  UDR: { name: 'Maharana Pratap Airport', city: 'Udaipur', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  UEL: { name: 'Quelimane Airport', city: 'Quelimane', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  UEO: { name: 'Kumejima Airport', city: 'Kumejima', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  UET: { name: 'Quetta International Airport', city: 'Quetta', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  UFA: { name: 'Ufa International Airport', city: 'Ufa', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  UGA: { name: 'Bulgan Airport', city: 'Bulgan', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  UGC: { name: 'Urgench Airport', city: 'Urgench', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 5 },
  UGN: { name: 'Waukegan National Airport', city: 'Chicago', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  UGO: { name: 'Uige Airport', city: 'Uige', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  UIB: { name: 'El Caraño Airport', city: 'Quibdo', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  UIH: { name: 'Phu Cat Airport', city: 'Phucat', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  UII: { name: 'Utila Airport', city: 'Utila', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: -6 },
  UIK: { name: 'Ust-Ilimsk Airport', city: 'Ust Ilimsk', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  UIN: { name: 'Quincy Regional Baldwin Field', city: 'Quincy', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  UIO: { name: 'Mariscal Sucre International Airport', city: 'Quito', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  UIP: { name: 'Quimper-Cornouaille Airport', city: 'Quimper', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  UJE: { name: 'Ujae Atoll Airport', city: 'Ujae Atoll', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  UKA: { name: 'Ukunda Airstrip', city: 'Ukunda', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  UKB: { name: 'Kobe Airport', city: 'Kobe', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  UKC: { name: 'Lutsk Airport', city: 'Lutsk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  UKF: { name: 'Wilkes County Airport', city: 'North Wilkesboro', country: 'United States', timezone: 'America/New_York', offset: -5 },
  UKI: { name: 'Ukiah Municipal Airport', city: 'Ukiah', country: 'United States', timezone: null, offset: 0 },
  UKK: { name: 'Ust-Kamennogorsk Airport', city: 'Ust Kamenogorsk', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 6 },
  UKS: { name: 'Belbek Airport', city: 'Sevastopol', country: 'Ukraine', timezone: 'Europe/Simferopol', offset: 3 },
  UKT: { name: 'Quakertown Airport', city: 'Quakertown', country: 'United States', timezone: null, offset: -5 },
  UKX: { name: 'Ust-Kut Airport', city: 'Ust-Kut', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  ULA: { name: 'Capitan D Daniel Vazquez Airport', city: 'San Julian', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: -3 },
  ULB: { name: 'Uléi Airport', city: 'Ambryn Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  ULD: { name: 'Prince Mangosuthu Buthelezi Airport', city: 'Ulundi', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  ULG: { name: 'Ulgii Mongolei Airport', city: 'Olgii', country: 'Mongolia', timezone: 'Asia/Hovd', offset: 7 },
  ULK: { name: 'Lensk Airport', city: 'Lensk', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  ULN: { name: 'Chinggis Khaan International Airport', city: 'Ulan Bator', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 8 },
  ULO: { name: 'Ulaangom Airport', city: 'Ulaangom', country: 'Mongolia', timezone: 'Asia/Hovd', offset: 7 },
  ULP: { name: 'Quilpie Airport', city: 'Quilpie', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  ULQ: { name: 'Heriberto Gíl Martínez Airport', city: 'Tulua', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  ULS: { name: 'Ulysses Airport', city: 'Ulysses', country: 'United States', timezone: null, offset: -5 },
  ULU: { name: 'Gulu Airport', city: 'Gulu', country: 'Uganda', timezone: 'Africa/Kampala', offset: 3 },
  ULV: { name: 'Ulyanovsk Baratayevka Airport', city: 'Ulyanovsk', country: 'Russia', timezone: 'Europe/Samara', offset: 4 },
  ULY: { name: 'Ulyanovsk East Airport', city: 'Ulyanovsk', country: 'Russia', timezone: 'Europe/Samara', offset: 4 },
  UMD: { name: 'Uummannaq Heliport', city: 'Uummannaq', country: 'Greenland', timezone: 'America/Godthab', offset: -3 },
  UME: { name: 'Umeå Airport', city: 'Umea', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  UMP: { name: 'Indianapolis Metropolitan Airport', city: 'Indianapolis', country: 'United States', timezone: 'America/New_York', offset: -5 },
  UMR: { name: 'Woomera Airfield', city: 'Woomera', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  UMS: { name: 'Ust-Maya Airport', city: 'Ust-Maya', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  UMU: { name: 'Umuarama Airport', city: 'Umuarama', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  UNA: { name: 'Hotel Transamérica Airport', city: 'Una', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  UND: { name: 'Konduz Airport', city: 'Kunduz', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  UNG: { name: 'Kiunga Airport', city: 'Kiunga', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  UNI: { name: 'Union Island International Airport', city: 'Union Island', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: -4 },
  UNK: { name: 'Unalakleet Airport', city: 'Unalakleet', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  UNN: { name: 'Ranong Airport', city: 'Ranong', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  UNT: { name: 'Unst Airport', city: 'Unst', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  UOX: { name: 'University Oxford Airport', city: 'Oxford', country: 'United States', timezone: null, offset: 0 },
  UPB: { name: 'Playa Baracoa Airport', city: 'Baracoa Playa', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  UPG: { name: 'Hasanuddin International Airport', city: 'Ujung Pandang', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  UPL: { name: 'Upala Airport', city: 'Upala', country: 'Costa Rica', timezone: null, offset: 0 },
  UPN: { name: 'Licenciado y General Ignacio Lopez Rayon Airport', city: 'Uruapan', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  UPP: { name: 'Upolu Airport', city: 'Opolu', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  URA: { name: 'Uralsk Airport', city: 'Uralsk', country: 'Kazakhstan', timezone: 'Asia/Oral', offset: 5 },
  URC: { name: 'Ürümqi Diwopu International Airport', city: 'Urumqi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  URE: { name: 'Kuressaare Airport', city: 'Kuressaare', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 2 },
  URG: { name: 'Rubem Berta Airport', city: 'Uruguaiana', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  URJ: { name: 'Uray Airport', city: 'Uraj', country: 'Russia', timezone: 'Asia/Yekaterinburg', offset: 5 },
  URO: { name: 'Rouen Airport', city: 'Rouen', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  URS: { name: 'Kursk East Airport', city: 'Kursk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  URT: { name: 'Surat Thani Airport', city: 'Surat Thani', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  URY: { name: 'Gurayat Domestic Airport', city: 'Guriat', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  USH: { name: 'Malvinas Argentinas Airport', city: 'Ushuaia', country: 'Argentina', timezone: 'America/Argentina/Ushuaia', offset: -3 },
  USI: { name: 'Mabaruma Airport', city: 'Mabaruma', country: 'Guyana', timezone: 'America/Guyana', offset: -4 },
  USK: { name: 'Usinsk Airport', city: 'Usinsk', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  USM: { name: 'Samui Airport', city: 'Ko Samui', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  USN: { name: 'Ulsan Airport', city: 'Ulsan', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  USQ: { name: 'Uşak Airport', city: 'Usak', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  UST: { name: 'Northeast Florida Regional Airport', city: 'St. Augustine Airport', country: 'United States', timezone: 'America/New_York', offset: -5 },
  USU: { name: 'Francisco B. Reyes Airport', city: 'Busuanga', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  UTC: { name: 'Soesterberg Air Base', city: 'Soesterberg', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  UTH: { name: 'Udon Thani Airport', city: 'Udon Thani', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  UTK: { name: 'Utirik Airport', city: 'Utirik Island', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 12 },
  UTM: { name: 'Tunica Municipal Airport', city: 'Tunica', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  UTN: { name: 'Pierre Van Ryneveld Airport', city: 'Upington', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  UTO: { name: 'Indian Mountain LRRS Airport', city: 'Indian Mountains', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  UTP: { name: 'U-Tapao International Airport', city: 'Pattaya', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 7 },
  UTT: { name: 'K. D. Matanzima Airport', city: 'Umtata', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  UTW: { name: 'Queenstown Airport', city: 'Queenstown', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  UUA: { name: 'Bugulma Airport', city: 'Bugulma', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  UUD: { name: 'Ulan-Ude Airport (Mukhino)', city: 'Ulan-ude', country: 'Russia', timezone: 'Asia/Irkutsk', offset: 8 },
  UUK: { name: 'Ugnu-Kuparuk Airport', city: 'Kuparuk', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  UUN: { name: 'Baruun Urt Airport', city: null, country: 'Mongolia', timezone: null, offset: 0 },
  UUS: { name: 'Yuzhno-Sakhalinsk Airport', city: 'Yuzhno-sakhalinsk', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  UVA: { name: 'Garner Field', city: 'Uvalde', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  UVE: { name: 'Ouvéa Airport', city: 'Ouvea', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 11 },
  UVF: { name: 'Hewanorra International Airport', city: 'Hewandorra', country: 'Saint Lucia', timezone: 'America/St_Lucia', offset: -4 },
  UWL: { name: 'New Castle Henry Co. Municipal Airport', city: 'New Castle', country: 'United States', timezone: null, offset: -4 },
  UYF: { name: 'Madison County Airport', city: 'London', country: 'United States', timezone: null, offset: -5 },
  UYL: { name: 'Nyala Airport', city: 'Nyala', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  UYN: { name: 'Yulin Yuyang Airport', city: 'Yulin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  UYU: { name: 'Uyuni Airport', city: 'Uyuni', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  VAA: { name: 'Vaasa Airport', city: 'Vaasa', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  VAD: { name: 'Moody Air Force Base', city: 'Valdosta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VAF: { name: 'Valence-Chabeuil Airport', city: 'Valence', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  VAG: { name: 'Major Brigadeiro Trompowsky Airport', city: 'Varginha', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  VAI: { name: 'Vanimo Airport', city: 'Vanimo', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  VAK: { name: 'Chevak Airport', city: 'Chevak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  VAL: { name: 'Valença Airport', city: 'Valenca', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  VAM: { name: 'Villa Airport', city: 'Maamigili', country: 'Maldives', timezone: 'Indian/Maldives', offset: 5 },
  VAN: { name: 'Van Ferit Melen Airport', city: 'Van', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  VAO: { name: 'Suavanao Airport', city: 'Suavanao', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  VAR: { name: 'Varna Airport', city: 'Varna', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 2 },
  VAS: { name: 'Sivas Nuri Demirağ Airport', city: 'Sivas', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  VAV: { name: 'Vava\'u International Airport', city: 'Vava\'u', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 13 },
  VAW: { name: 'Vardø Airport, Svartnes', city: 'Vardø', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  VAY: { name: 'South Jersey Regional Airport', city: 'Mount Holly', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VBG: { name: 'Vandenberg Air Force Base', city: 'Lompoc', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  VBS: { name: 'Brescia Airport', city: 'Brescia', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  VBV: { name: 'Vanua Balavu Airport', city: 'Vanua Balavu', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 12 },
  VBY: { name: 'Visby Airport', city: 'Visby', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  VCA: { name: 'Can Tho International Airport', city: 'Can Tho', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  VCD: { name: 'Victoria River Downs Airport', city: 'Victoria River Downs', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  VCE: { name: 'Venice Marco Polo Airport', city: 'Venice', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  VCL: { name: 'Chu Lai International Airport', city: 'Chu Lai', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  VCP: { name: 'Viracopos International Airport', city: 'Campinas', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  VCS: { name: 'Co Ong Airport', city: 'Conson', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  VCT: { name: 'Victoria Regional Airport', city: 'Victoria', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  VCV: { name: 'Southern California Logistics Airport', city: 'Victorville', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  VDA: { name: 'Ovda International Airport', city: 'Ovda', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 2 },
  VDB: { name: 'Leirin Airport', city: 'Fagernes', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  VDC: { name: 'Vitória da Conquista Airport', city: 'Vitória Da Conquista', country: 'Brazil', timezone: 'America/Fortaleza', offset: -3 },
  VDE: { name: 'Hierro Airport', city: 'Hierro', country: 'Spain', timezone: 'Atlantic/Canary', offset: 0 },
  VDM: { name: 'Gobernador Castello Airport', city: 'Viedma', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: -3 },
  VDP: { name: 'Valle de La Pascua Airport', city: 'Valle De La Pascua', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  VDR: { name: 'Villa Dolores Airport', city: 'Villa Dolores', country: 'Argentina', timezone: 'America/Cordoba', offset: -3 },
  VDS: { name: 'Vadsø Airport', city: 'Vadsø', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  VDZ: { name: 'Valdez Pioneer Field', city: 'Valdez', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  VEE: { name: 'Venetie Airport', city: 'Venetie', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  VEL: { name: 'Vernal Regional Airport', city: 'Vernal', country: 'United States', timezone: 'America/Denver', offset: -7 },
  VER: { name: 'General Heriberto Jara International Airport', city: 'Vera Cruz', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  VEY: { name: 'Vestmannaeyjar Airport', city: 'Vestmannaeyjar', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  VFA: { name: 'Victoria Falls International Airport', city: 'Victoria Falls', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  VGA: { name: 'Vijayawada Airport', city: 'Vijayawada', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  VGD: { name: 'Vologda Airport', city: 'Vologda', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VGO: { name: 'Vigo Airport', city: 'Vigo', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  VGT: { name: 'North Las Vegas Airport', city: 'Las Vegas', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  VGZ: { name: 'Villa Garzón Airport', city: 'Villa Garzon', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  VHC: { name: 'Saurimo Airport', city: 'Saurimo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  VHM: { name: 'Vilhelmina Airport', city: 'Vilhelmina', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  VHY: { name: 'Vichy-Charmeil Airport', city: 'Vichy', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  VIC: { name: 'Vicenza Airport', city: 'Vicenza', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  VIE: { name: 'Vienna International Airport', city: 'Vienna', country: 'Austria', timezone: 'Europe/Vienna', offset: 1 },
  VIG: { name: 'Juan Pablo Pérez Alfonso Airport', city: 'El Vigía', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  VII: { name: 'Vinh Airport', city: 'Vinh', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  VIJ: { name: 'Virgin Gorda Airport', city: 'Spanish Town', country: 'British Virgin Islands', timezone: 'America/Tortola', offset: -4 },
  VIL: { name: 'Dakhla Airport', city: 'Dakhla', country: 'Western Sahara', timezone: 'Africa/El_Aaiun', offset: 0 },
  VIN: { name: 'Vinnytsia/Gavyryshivka Airport', city: 'Vinnitsa', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  VIR: { name: 'Virginia Airport', city: 'Durban', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  VIS: { name: 'Visalia Municipal Airport', city: 'Visalia', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  VIT: { name: 'Vitoria/Foronda Airport', city: 'Vitoria', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  VIX: { name: 'Eurico de Aguiar Salles Airport', city: 'Vitoria', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  VKG: { name: 'Rach Gia Airport', city: 'Rach Gia', country: 'Vietnam', timezone: 'Asia/Saigon', offset: 7 },
  VKO: { name: 'Vnukovo International Airport', city: 'Moscow', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VKT: { name: 'Vorkuta Airport', city: 'Vorkuta', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VLC: { name: 'Valencia Airport', city: 'Valencia', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  VLD: { name: 'Valdosta Regional Airport', city: 'Valdosta', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VLG: { name: 'Villa Gesell Airport', city: 'Villa Gesell', country: 'Argentina', timezone: 'America/Buenos_Aires', offset: -3 },
  VLI: { name: 'Bauerfield International Airport', city: 'Port-vila', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  VLK: { name: 'Volgodonsk Airport', city: null, country: 'Russia', timezone: null, offset: 0 },
  VLL: { name: 'Valladolid Airport', city: 'Valladolid', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  VLN: { name: 'Arturo Michelena International Airport', city: 'Valencia', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  VLP: { name: 'Vila Rica Airport', city: 'Vila Rica', country: 'Brazil', timezone: null, offset: -4 },
  VLS: { name: 'Valesdir Airport', city: 'Valesdir', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  VLU: { name: 'Velikiye Luki Airport', city: 'Velikiye Luki', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VLV: { name: 'Dr. Antonio Nicolás Briceño Airport', city: 'Valera', country: 'Venezuela', timezone: 'America/Caracas', offset: -4 },
  VLY: { name: 'Anglesey Airport', city: 'Angelsey', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  VMR: { name: 'Presidente Néstor Kirchner Regionsl Airport', city: 'Villa Maria', country: 'Argentina', timezone: null, offset: 0 },
  VNA: { name: 'Saravane Airport', city: 'Saravane', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  VNC: { name: 'Venice Municipal Airport', city: 'Venice', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VNE: { name: 'Vannes-Meucon Airport', city: 'Vannes', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  VNO: { name: 'Vilnius International Airport', city: 'Vilnius', country: 'Lithuania', timezone: 'Europe/Vilnius', offset: 2 },
  VNS: { name: 'Lal Bahadur Shastri Airport', city: 'Varanasi', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  VNW: { name: 'Van Wert County Airport', city: 'Van Wert', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VNX: { name: 'Vilankulo Airport', city: 'Vilankulu', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  VNY: { name: 'Van Nuys Airport', city: 'Van Nuys', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  VOG: { name: 'Volgograd International Airport', city: 'Volgograd', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VOH: { name: 'Vohimarina Airport', city: 'Vohemar', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  VOK: { name: 'Volk Field', city: 'Camp Douglas', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  VOL: { name: 'Nea Anchialos Airport', city: 'Nea Anghialos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  VOZ: { name: 'Voronezh International Airport', city: 'Voronezh', country: 'Russia', timezone: 'Europe/Moscow', offset: 3 },
  VPC: { name: 'Cartersville Airport', city: 'Cartersville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VPE: { name: 'Ngjiva Pereira Airport', city: 'Ondjiva', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  VPN: { name: 'Vopnafjörður Airport', city: 'Vopnafjörður', country: 'Iceland', timezone: 'Atlantic/Reykjavik', offset: 0 },
  VPS: { name: 'Destin-Ft Walton Beach Airport', city: 'Valparaiso', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  VPY: { name: 'Chimoio Airport', city: 'Chimoio', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  VPZ: { name: 'Porter County Municipal Airport', city: 'Valparaiso IN', country: 'United States', timezone: null, offset: -5 },
  VQS: { name: 'Vieques Airport', city: 'Vieques Island', country: 'Puerto Rico', timezone: 'America/Puerto_Rico', offset: -4 },
  VRA: { name: 'Juan Gualberto Gomez International Airport', city: 'Varadero', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  VRB: { name: 'Vero Beach Municipal Airport', city: 'Vero Beach', country: 'United States', timezone: 'America/New_York', offset: -5 },
  VRC: { name: 'Virac Airport', city: 'Virac', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  VRK: { name: 'Varkaus Airport', city: 'Varkaus', country: 'Finland', timezone: 'Europe/Helsinki', offset: 2 },
  VRL: { name: 'Vila Real Airport', city: 'Vila Real', country: 'Portugal', timezone: 'Europe/Lisbon', offset: 0 },
  VRN: { name: 'Verona Villafranca Airport', city: 'Villafranca', country: 'Italy', timezone: 'Europe/Rome', offset: 1 },
  VRU: { name: 'Vryburg Airport', city: 'Vryburg', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  VRY: { name: 'Værøy Heliport', city: 'Værøy', country: 'Norway', timezone: 'Europe/Oslo', offset: 1 },
  VSA: { name: 'Carlos Rovirosa Pérez International Airport', city: 'Villahermosa', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  VSG: { name: 'Luhansk International Airport', city: 'Lugansk', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  VST: { name: 'Stockholm Västerås Airport', city: 'Vasteras', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  VTB: { name: 'Vitebsk Vostochny Airport', city: 'Vitebsk', country: 'Belarus', timezone: 'Europe/Minsk', offset: 3 },
  VTE: { name: 'Wattay International Airport', city: 'Vientiane', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  VTN: { name: 'Miller Field', city: 'Valentine', country: 'United States', timezone: null, offset: 0 },
  VTS: { name: 'Ventspils International Airport', city: 'Ventspils', country: 'Latvia', timezone: 'Europe/Riga', offset: 2 },
  VTU: { name: 'Hermanos Ameijeiras Airport', city: 'Las Tunas', country: 'Cuba', timezone: 'America/Havana', offset: -5 },
  VTZ: { name: 'Vishakhapatnam Airport', city: 'Vishakhapatnam', country: 'India', timezone: 'Asia/Calcutta', offset: 5 },
  VUP: { name: 'Alfonso López Pumarejo Airport', city: 'Valledupar', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  VVC: { name: 'Vanguardia Airport', city: 'Villavicencio', country: 'Colombia', timezone: 'America/Bogota', offset: -5 },
  VVI: { name: 'Viru Viru International Airport', city: 'Santa Cruz', country: 'Bolivia', timezone: 'America/La_Paz', offset: -4 },
  VVO: { name: 'Vladivostok International Airport', city: 'Vladivostok', country: 'Russia', timezone: 'Asia/Vladivostok', offset: 10 },
  VVZ: { name: 'Illizi Takhamalt Airport', city: 'Illizi', country: 'Algeria', timezone: 'Africa/Algiers', offset: 1 },
  VXC: { name: 'Lichinga Airport', city: 'Lichinga', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 2 },
  VXE: { name: 'São Pedro Airport', city: 'Sao Vicente Island', country: 'Cape Verde', timezone: 'Atlantic/Cape_Verde', offset: -1 },
  VXO: { name: 'Växjö Kronoberg Airport', city: 'Vaxjo', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 1 },
  VYI: { name: 'Vilyuisk Airport', city: 'Vilyuisk', country: 'Russia', timezone: null, offset: 0 },
  VYS: { name: 'Illinois Valley Regional Airport-Walter A Duncan Field', city: 'Peru', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  W63: { name: 'Lake Country Regional Airport', city: 'Clarksville', country: 'United Arab Emirates', timezone: 'America/New_York', offset: -5 },
  WAA: { name: 'Wales Airport', city: 'Wales', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WAG: { name: 'Wanganui Airport', city: 'Wanganui', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WAI: { name: 'Ambalabe Airport', city: 'Antsohihy', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WAL: { name: 'Wallops Flight Facility Airport', city: 'Wallops Island', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WAM: { name: 'Ambatondrazaka Airport', city: 'Ambatondrazaka', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WAQ: { name: 'Antsalova Airport', city: 'Antsalova', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WAR: { name: 'Waris Airport', city: 'Waris-Papua Island', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  WAT: { name: 'Waterford Airport', city: 'Waterford', country: 'Ireland', timezone: 'Europe/Dublin', offset: 0 },
  WAW: { name: 'Warsaw Chopin Airport', city: 'Warsaw', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  WBM: { name: 'Wapenamanda Airport', city: 'Wapenamanda', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  WBQ: { name: 'Beaver Airport', city: 'Beaver', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WBU: { name: 'Boulder Municipal Airport', city: 'Boulder', country: 'United States', timezone: 'America/Denver', offset: -7 },
  WBW: { name: 'Wilkes Barre Wyoming Valley Airport', city: 'Wilkes-Barre', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WCH: { name: 'Chaitén Airport', city: 'Chaiten', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  WDH: { name: 'Hosea Kutako International Airport', city: 'Windhoek', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  WDR: { name: 'Barrow County Airport', city: 'Winder', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WDS: { name: 'Shiyan Wudangshan Airport', city: 'Shiyan', country: 'China', timezone: null, offset: 0 },
  WEF: { name: 'Weifang Airport', city: 'Weifang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WEH: { name: 'Weihai Airport', city: 'Weihai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WEI: { name: 'Weipa Airport', city: 'Weipa', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  WEL: { name: 'Welkom Airport', city: 'Welkom', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 2 },
  WFI: { name: 'Fianarantsoa Airport', city: 'Fianarantsoa', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WFK: { name: 'Northern Aroostook Regional Airport', city: 'Frenchville', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WGA: { name: 'Wagga Wagga City Airport', city: 'Wagga Wagga', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  WGE: { name: 'Walgett Airport', city: 'Walgett', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  WGP: { name: 'Waingapu Airport', city: 'Waingapu', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 8 },
  WGT: { name: 'Wangaratta Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  WHF: { name: 'Wadi Halfa Airport', city: 'Wadi Halfa', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 3 },
  WHK: { name: 'Whakatane Airport', city: 'Whakatane', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WHP: { name: 'Whiteman Airport', city: 'Los Angeles', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  WHU: { name: 'Wuhu Air Base', city: 'Wuhu', country: 'China', timezone: null, offset: 0 },
  WIC: { name: 'Wick Airport', city: 'Wick', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  WIL: { name: 'Nairobi Wilson Airport', city: 'Nairobi', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  WIN: { name: 'Winton Airport', city: 'Winton', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  WIO: { name: 'Wilcannia Airport', city: 'Wilcannia', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  WJF: { name: 'General WM J Fox Airfield', city: 'Lancaster', country: 'United States', timezone: null, offset: -8 },
  WJR: { name: 'Wajir Airport', city: 'Wajir', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 3 },
  WJU: { name: 'Wonju Airport', city: 'Wonju', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  WKA: { name: 'Wanaka Airport', city: 'Wanaka', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WKB: { name: 'Warracknabeal Airport', city: null, country: 'Australia', timezone: null, offset: 0 },
  WKJ: { name: 'Wakkanai Airport', city: 'Wakkanai', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  WKK: { name: 'Aleknagik / New Airport', city: 'Aleknagik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WKL: { name: 'Waikoloa Heliport', city: 'Waikoloa Village', country: 'United States', timezone: 'Pacific/Honolulu', offset: -10 },
  WKM: { name: 'Hwange National Park Airport', city: 'Hwange National Park', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 2 },
  WKR: { name: 'Abaco I Walker C Airport', city: 'Walker\'s Cay', country: 'Bahamas', timezone: null, offset: -5 },
  WLG: { name: 'Wellington International Airport', city: 'Wellington', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WLH: { name: 'Walaha Airport', city: 'Walaha', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  WLK: { name: 'Selawik Airport', city: 'Selawik', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WLS: { name: 'Hihifo Airport', city: 'Wallis', country: 'Wallis and Futuna', timezone: 'Pacific/Wallis', offset: 12 },
  WMA: { name: 'Mandritsara Airport', city: 'Mandritsara', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WMB: { name: 'Warrnambool Airport', city: 'Warrnambool', country: 'Australia', timezone: null, offset: 10 },
  WMC: { name: 'Winnemucca Municipal Airport', city: 'Winnemucca', country: 'United States', timezone: null, offset: 0 },
  WME: { name: 'Mount Keith Airport', city: 'Mount Keith', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  WMH: { name: 'Ozark Regional Airport', city: 'Mountain Home', country: 'United States', timezone: null, offset: 0 },
  WMI: { name: 'Modlin Airport', city: 'Warsaw', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  WMN: { name: 'Maroantsetra Airport', city: 'Maroantsetra', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WMO: { name: 'White Mountain Airport', city: 'White Mountain', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WMP: { name: 'Mampikony Airport', city: 'Mampikony', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WMR: { name: 'Mananara Nord Airport', city: 'Mananara', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WMX: { name: 'Wamena Airport', city: 'Wamena', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  WNA: { name: 'Napakiak Airport', city: 'Napakiak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WNN: { name: 'Wunnumin Lake Airport', city: 'Wunnumin Lake', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  WNP: { name: 'Naga Airport', city: 'Naga', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  WNR: { name: 'Windorah Airport', city: 'Windorah', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  WNS: { name: 'Shaheed Benazirabad Airport', city: 'Nawabshah', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 5 },
  WNZ: { name: 'Wenzhou Yongqiang Airport', city: 'Wenzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WOE: { name: 'Woensdrecht Air Base', city: 'Woensdrecht', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 1 },
  WOL: { name: 'Wollongong Airport', city: 'Wollongong', country: 'Australia', timezone: 'Australia/Sydney', offset: 10 },
  WOS: { name: 'Wonsan Kalma International Airport', city: 'Wonsan', country: 'North Korea', timezone: null, offset: 8 },
  WOT: { name: 'Wang-an Airport', city: 'Wang An', country: 'Taiwan', timezone: 'Asia/Taipei', offset: 8 },
  WPB: { name: 'Port Bergé Airport', city: 'Port Bergé', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WPC: { name: 'Pincher Creek Airport', city: 'Pincher Creek', country: 'Canada', timezone: null, offset: 0 },
  WPR: { name: 'Capitan Fuentes Martinez Airport Airport', city: 'Porvenir', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  WPU: { name: 'Guardiamarina Zañartu Airport', city: 'Puerto Williams', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  WRB: { name: 'Robins Air Force Base', city: 'Macon', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WRE: { name: 'Whangarei Airport', city: 'Whangarei', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WRG: { name: 'Wrangell Airport', city: 'Wrangell', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WRI: { name: 'Mc Guire Air Force Base', city: 'Wrightstown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WRL: { name: 'Worland Municipal Airport', city: 'Worland', country: 'United States', timezone: 'America/Denver', offset: -7 },
  WRO: { name: 'Copernicus Wrocław Airport', city: 'Wroclaw', country: 'Poland', timezone: 'Europe/Warsaw', offset: 1 },
  WRY: { name: 'Westray Airport', city: 'Westray', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  WRZ: { name: 'Weerawila Airport', city: 'Wirawila', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 5 },
  WSD: { name: 'Condron Army Air Field', city: 'White Sands', country: 'United States', timezone: 'America/Denver', offset: -7 },
  WSN: { name: 'South Naknek Nr 2 Airport', city: 'South Naknek', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WSP: { name: 'Waspam Airport', city: 'Waspam', country: 'Nicaragua', timezone: 'America/Managua', offset: -6 },
  WSR: { name: 'Wasior Airport', city: 'Wasior', country: 'Indonesia', timezone: 'Asia/Jayapura', offset: 9 },
  WST: { name: 'Westerly State Airport', city: 'Washington County', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WSY: { name: 'Whitsunday Island Airport', city: 'Airlie Beach', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  WSZ: { name: 'Westport Airport', city: 'Westport', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WTA: { name: 'Tambohorano Airport', city: 'Tambohorano', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WTB: { name: 'Brisbane West Wellcamp Airport', city: 'Toowoomba', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  WTK: { name: 'Noatak Airport', city: 'Noatak', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  WTN: { name: 'RAF Waddington', city: 'Waddington', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  WTS: { name: 'Tsiroanomandidy Airport', city: 'Tsiroanomandidy', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WTZ: { name: 'Whitianga Airport', city: 'Whitianga', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  WUA: { name: 'Wuhai Airport', city: 'Wuhai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WUH: { name: 'Wuhan Tianhe International Airport', city: 'Wuhan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WUN: { name: 'Wiluna Airport', city: 'Wiluna', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  WUS: { name: 'Nanping Wuyishan Airport', city: 'Wuyishan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WUU: { name: 'Wau Airport', city: 'Wau', country: 'Sudan', timezone: 'Africa/Juba', offset: 3 },
  WUX: { name: 'Sunan Shuofang International Airport', city: 'Wuxi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WUZ: { name: 'Wuzhou Changzhoudao Airport', city: 'Wuzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WVB: { name: 'Walvis Bay Airport', city: 'Walvis Bay', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 1 },
  WVI: { name: 'Watsonville Municipal Airport', city: 'Watsonville', country: 'United States', timezone: null, offset: -8 },
  WVK: { name: 'Manakara Airport', city: 'Manakara', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  WVN: { name: 'Wilhelmshaven-Mariensiel Airport', city: 'Wilhelmshaven', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  WWA: { name: 'Wasilla Airport', city: 'Wasilla', country: 'United States', timezone: null, offset: 0 },
  WWD: { name: 'Cape May County Airport', city: 'Wildwood', country: 'United States', timezone: 'America/New_York', offset: -5 },
  WWK: { name: 'Wewak International Airport', city: 'Wewak', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 10 },
  WWR: { name: 'West Woodward Airport', city: 'Woodward', country: 'United States', timezone: null, offset: 0 },
  WWY: { name: 'West Wyalong Airport', city: 'West Wyalong', country: 'Australia', timezone: null, offset: 0 },
  WXN: { name: 'Wanxian Airport', city: 'Wanxian', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  WYA: { name: 'Whyalla Airport', city: 'Whyalla', country: 'Australia', timezone: 'Australia/Adelaide', offset: 9 },
  WYE: { name: 'Yengema Airport', city: 'Yengema', country: 'Sierra Leone', timezone: 'Africa/Freetown', offset: 0 },
  WYS: { name: 'Yellowstone Airport', city: 'West Yellowstone', country: 'United States', timezone: 'America/Denver', offset: -7 },
  XAC: { name: 'Arcachon-La Teste-de-Buch Airport', city: 'Arcachon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XAP: { name: 'Serafin Enoss Bertaso Airport', city: 'Chapeco', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: -3 },
  XAU: { name: 'Saúl Airport', city: 'Saul', country: 'French Guiana', timezone: 'America/Cayenne', offset: -3 },
  XBE: { name: 'Bearskin Lake Airport', city: 'Bearskin Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  XBJ: { name: 'Birjand Airport', city: 'Birjand', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  XBK: { name: 'Bourg-Ceyzériat Airport', city: 'Bourg', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XCD: { name: 'Chalon-Champforgeuil Airport', city: 'Chalon', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XCH: { name: 'Christmas Island Airport', city: 'Christmas Island', country: 'Christmas Island', timezone: 'Indian/Christmas', offset: 7 },
  XCR: { name: 'Châlons-Vatry Air Base', city: 'Chalons', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XFL: { name: 'Flagler County Airport', city: 'Flagler', country: 'United States', timezone: 'America/New_York', offset: -5 },
  XFN: { name: 'Xiangyang Liuji Airport', city: 'Xiangfan', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XFW: { name: 'Hamburg-Finkenwerder Airport', city: 'Hamburg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  XGN: { name: 'Xangongo Airport', city: 'Xangongo', country: 'Angola', timezone: 'Africa/Luanda', offset: 1 },
  XGR: { name: 'Kangiqsualujjuaq (Georges River) Airport', city: 'Kangiqsualujjuaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  XIC: { name: 'Xichang Qingshan Airport', city: 'Xichang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XIJ: { name: 'Ahmed Al Jaber Air Base', city: 'Ahmed Al Jaber AB', country: 'Kuwait', timezone: null, offset: 0 },
  XIL: { name: 'Xilinhot Airport', city: 'Xilinhot', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XIY: { name: 'Xi\'an Xianyang International Airport', city: 'Xi\'an', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XKH: { name: 'Xieng Khouang Airport', city: 'Phon Savan', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  XKS: { name: 'Kasabonika Airport', city: 'Kasabonika', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  XLB: { name: 'Lac Brochet Airport', city: 'Lac Brochet', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  XLS: { name: 'Saint Louis Airport', city: 'St. Louis', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  XMC: { name: 'Mallacoota Airport', city: 'Mallacoota', country: 'Australia', timezone: 'Australia/Hobart', offset: 10 },
  XMH: { name: 'Manihi Airport', city: 'Manihi', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: -10 },
  XMN: { name: 'Xiamen Gaoqi International Airport', city: 'Xiamen', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XMR: { name: 'Cape Canaveral AFS Skid Strip', city: 'Cocoa Beach', country: 'United States', timezone: null, offset: 0 },
  XMS: { name: 'Coronel E Carvajal Airport', city: 'Macas', country: 'Ecuador', timezone: 'America/Guayaquil', offset: -5 },
  XMU: { name: 'Moulins-Montbeugny Airport', city: 'Moulins', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XMY: { name: 'Yam Island Airport', city: 'Yam Island', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  XNA: { name: 'Northwest Arkansas Regional Airport', city: 'Bentonville', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  XNN: { name: 'Xining Caojiabu Airport', city: 'Xining', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XPK: { name: 'Pukatawagan Airport', city: 'Pukatawagan', country: 'Canada', timezone: null, offset: 0 },
  XPL: { name: 'Coronel Enrique Soto Cano Air Base', city: 'Comayagua', country: 'Honduras', timezone: null, offset: 0 },
  XPP: { name: 'Poplar River Airport', city: 'Poplar River', country: 'Canada', timezone: null, offset: 0 },
  XQP: { name: 'Quepos Managua Airport', city: 'Quepos', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: -6 },
  XRR: { name: 'Ross River Airport', city: 'Ross River', country: 'Canada', timezone: null, offset: 0 },
  XRY: { name: 'Jerez Airport', city: 'Jerez', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  XSB: { name: 'Sir Bani Yas Airport', city: 'Sir Bani Yas Island', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 4 },
  XSC: { name: 'South Caicos Airport', city: 'South Caicos', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: -4 },
  XSI: { name: 'South Indian Lake Airport', city: 'South Indian Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  XSP: { name: 'Seletar Airport', city: 'Singapore', country: 'Singapore', timezone: 'Asia/Singapore', offset: 8 },
  XTG: { name: 'Thargomindah Airport', city: 'Thargomindah', country: 'Australia', timezone: 'Australia/Brisbane', offset: 10 },
  XTL: { name: 'Tadoule Lake Airport', city: 'Tadoule Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  XTO: { name: 'Taroom Airport', city: 'Taroom', country: 'Australia', timezone: null, offset: 10 },
  XUZ: { name: 'Xuzhou Guanyin Airport', city: 'Xuzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  XVF: { name: 'Villefranche-Tarare Airport', city: 'Vilefrance', country: 'France', timezone: 'Europe/Paris', offset: 1 },
  XXN: { name: 'Riyadh Air Base', city: 'Riyadh', country: 'Saudi Arabia', timezone: null, offset: 0 },
  XYA: { name: 'Yandina Airport', city: 'Yandina', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 11 },
  XYE: { name: 'Ye Airport', city: 'Ye', country: 'Burma', timezone: 'Asia/Rangoon', offset: 6 },
  YAA: { name: 'Anahim Lake Airport', city: 'Anahim Lake', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YAB: { name: 'Old Arctic Bay Airport', city: 'Arctic Bay', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YAC: { name: 'Cat Lake Airport', city: 'Cat Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YAG: { name: 'Fort Frances Municipal Airport', city: 'Fort Frances', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YAH: { name: 'La Grande-4 Airport', city: 'La Grande-4', country: 'Canada', timezone: null, offset: 0 },
  YAK: { name: 'Yakutat Airport', city: 'Yakutat', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  YAL: { name: 'Alert Bay Airport', city: 'Alert Bay', country: 'Canada', timezone: null, offset: 0 },
  YAM: { name: 'Sault Ste Marie Airport', city: 'Sault Sainte Marie', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YAO: { name: 'Yaoundé Airport', city: 'Yaounde', country: 'Cameroon', timezone: 'Africa/Douala', offset: 1 },
  YAP: { name: 'Yap International Airport', city: 'Yap', country: 'Micronesia', timezone: 'Pacific/Truk', offset: 10 },
  YAT: { name: 'Attawapiskat Airport', city: 'Attawapiskat', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YAV: { name: 'Winnipeg / St. Andrews Airport', city: 'Winnipeg', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YAW: { name: 'Halifax / CFB Shearwater Heliport', city: 'Halifax', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YAX: { name: 'Wapekeka Airport', city: 'Angling Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YAY: { name: 'St. Anthony Airport', city: 'St. Anthony', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YAZ: { name: 'Tofino / Long Beach Airport', city: 'Tofino', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YBA: { name: 'Banff Airport', city: 'Banff', country: 'Canada', timezone: null, offset: -7 },
  YBB: { name: 'Kugaaruk Airport', city: 'Pelly Bay', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YBC: { name: 'Baie Comeau Airport', city: 'Baie Comeau', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YBE: { name: 'Uranium City Airport', city: 'Uranium City', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YBG: { name: 'CFB Bagotville', city: 'Bagotville', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YBI: { name: 'Black Tickle Airport', city: 'Black Tickle', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YBK: { name: 'Baker Lake Airport', city: 'Baker Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YBL: { name: 'Campbell River Airport', city: 'Campbell River', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YBO: { name: 'Bob Quinn Lake Airport', city: 'Bob Quinn Lake', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YBP: { name: 'Yibin Caiba Airport', city: 'Yibin', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YBR: { name: 'Brandon Municipal Airport', city: 'Brandon', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YBT: { name: 'Brochet Airport', city: 'Brochet', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YBU: { name: 'Nipawin Airport', city: 'Nipawin', country: 'Canada', timezone: null, offset: 0 },
  YBV: { name: 'Berens River Airport', city: 'Berens River', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YBW: { name: 'Bedwell Harbour Seaplane Base', city: 'Bedwell Harbour', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YBX: { name: 'Lourdes de Blanc Sablon Airport', city: 'Lourdes-De-Blanc-Sablon', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  YBY: { name: 'Bonnyville Airport', city: 'Bonnyville', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YCB: { name: 'Cambridge Bay Airport', city: 'Cambridge Bay', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YCC: { name: 'Cornwall Regional Airport', city: 'Cornwall', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YCD: { name: 'Nanaimo Airport', city: 'Nanaimo', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YCE: { name: 'James T. Field Memorial Aerodrome', city: 'Centralia', country: 'Canada', timezone: null, offset: 0 },
  YCG: { name: 'Castlegar/West Kootenay Regional Airport', city: 'Castlegar', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YCH: { name: 'Miramichi Airport', city: 'Chatham', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YCK: { name: 'Colville Lake Airport', city: 'Colville Lake', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YCL: { name: 'Charlo Airport', city: 'Charlo', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YCM: { name: 'Niagara District Airport', city: 'Saint Catherines', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YCN: { name: 'Cochrane Airport', city: 'Cochrane', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YCO: { name: 'Kugluktuk Airport', city: 'Coppermine', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YCP: { name: 'Blue River Airport', city: 'Blue River', country: 'Canada', timezone: null, offset: 0 },
  YCQ: { name: 'Chetwynd Airport', city: 'Chetwynd', country: 'Canada', timezone: null, offset: 0 },
  YCR: { name: 'Cross Lake (Charlie Sinclair Memorial) Airport', city: 'Cross Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YCS: { name: 'Chesterfield Inlet Airport', city: 'Chesterfield Inlet', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YCT: { name: 'Coronation Airport', city: 'Coronation', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YCU: { name: 'Yuncheng Guangong Airport', city: 'Yuncheng', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YCW: { name: 'Chilliwack Airport', city: 'Chilliwack', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YCY: { name: 'Clyde River Airport', city: 'Clyde River', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YDA: { name: 'Dawson City Airport', city: 'Dawson', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YDB: { name: 'Burwash Airport', city: 'Burwash', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YDC: { name: 'Princeton Airport', city: 'Princeton', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YDF: { name: 'Deer Lake Airport', city: 'Deer Lake', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YDG: { name: 'Digby / Annapolis Regional Airport', city: 'Digby', country: 'Canada', timezone: null, offset: 0 },
  YDL: { name: 'Dease Lake Airport', city: 'Dease Lake', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YDN: { name: 'Dauphin Barker Airport', city: 'Dauphin', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YDO: { name: 'Dolbeau St Felicien Airport', city: 'Dolbeau-St-Félicien', country: 'Canada', timezone: null, offset: 0 },
  YDP: { name: 'Nain Airport', city: 'Nain', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YDQ: { name: 'Dawson Creek Airport', city: 'Dawson Creek', country: 'Canada', timezone: 'America/Dawson_Creek', offset: -7 },
  YDT: { name: 'Boundary Bay Airport', city: 'Boundary Bay', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YEC: { name: 'Yecheon Airport', city: 'Yechon', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  YEE: { name: 'Huronia Airport', city: 'Midland', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YEG: { name: 'Edmonton International Airport', city: 'Edmonton', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YEI: { name: 'Bursa Yenişehir Airport', city: 'Yenisehir', country: 'Turkey', timezone: 'Europe/Istanbul', offset: 3 },
  YEK: { name: 'Arviat Airport', city: 'Eskimo Point', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YEL: { name: 'Elliot Lake Municipal Airport', city: 'ELLIOT LAKE', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YEM: { name: 'Manitoulin East Municipal Airport', city: 'Manitowaning', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YEN: { name: 'Estevan Airport', city: 'Estevan', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YEO: { name: 'RNAS Yeovilton', city: 'Yeovilton', country: 'United Kingdom', timezone: 'Europe/London', offset: 0 },
  YER: { name: 'Fort Severn Airport', city: 'Fort Severn', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YES: { name: 'Yasouj Airport', city: 'Yasuj', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  YET: { name: 'Edson Airport', city: 'Edson', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YEU: { name: 'Eureka Airport', city: 'Eureka', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YEV: { name: 'Inuvik Mike Zubko Airport', city: 'Inuvik', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YEY: { name: 'Amos Magny Airport', city: 'Amos', country: 'Canada', timezone: null, offset: 0 },
  YFA: { name: 'Fort Albany Airport', city: 'Fort Albany', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YFB: { name: 'Iqaluit Airport', city: 'Iqaluit', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YFC: { name: 'Fredericton Airport', city: 'Fredericton', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YFD: { name: 'Brantford Municipal Airport', city: 'Brantford', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YFH: { name: 'Fort Hope Airport', city: 'Fort Hope', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YFJ: { name: 'Wekweètì Airport', city: 'Wekweeti', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YFO: { name: 'Flin Flon Airport', city: 'Flin Flon', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YFR: { name: 'Fort Resolution Airport', city: 'Fort Resolution', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YFS: { name: 'Fort Simpson Airport', city: 'Fort Simpson', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YFX: { name: 'St. Lewis (Fox Harbour) Airport', city: 'St. Lewis', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YGB: { name: 'Texada Gillies Bay Airport', city: 'Texada', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YGD: { name: 'Goderich Airport', city: 'Goderich', country: 'Canada', timezone: null, offset: 0 },
  YGE: { name: 'Golden Airport', city: 'Golden', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YGG: { name: 'Ganges Seaplane Base', city: 'Ganges', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YGH: { name: 'Fort Good Hope Airport', city: 'Fort Good Hope', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YGJ: { name: 'Miho Yonago Airport', city: 'Miho', country: 'Japan', timezone: 'Asia/Tokyo', offset: 9 },
  YGK: { name: 'Kingston Norman Rogers Airport', city: 'Kingston', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGL: { name: 'La Grande Rivière Airport', city: 'La Grande Riviere', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGM: { name: 'Gimli Industrial Park Airport', city: 'Gimli', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YGO: { name: 'Gods Lake Narrows Airport', city: 'Gods Lake Narrows', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YGP: { name: 'Gaspé (Michel-Pouliot) Airport', city: 'Gaspe', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGQ: { name: 'Geraldton Greenstone Regional Airport', city: 'Geraldton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGR: { name: 'Îles-de-la-Madeleine Airport', city: 'Iles De La Madeleine', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGT: { name: 'Igloolik Airport', city: 'Igloolik', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGV: { name: 'Havre St Pierre Airport', city: 'Havre-Saint-Pierre', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGW: { name: 'Kuujjuarapik Airport', city: 'Kuujjuarapik', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YGX: { name: 'Gillam Airport', city: 'Gillam', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YGZ: { name: 'Grise Fiord Airport', city: 'Grise Fiord', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YHA: { name: 'Port Hope Simpson Airport', city: 'Port Hope Simpson', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YHB: { name: 'Hudson Bay Airport', city: 'Hudson Bay', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YHD: { name: 'Dryden Regional Airport', city: 'Dryden', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YHE: { name: 'Hope Airport', city: 'Hope', country: 'Canada', timezone: null, offset: 0 },
  YHF: { name: 'Hearst René Fontaine Municipal Airport', city: 'Hearst', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YHI: { name: 'Ulukhaktok Holman Airport', city: 'Holman Island', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YHK: { name: 'Gjoa Haven Airport', city: 'Gjoa Haven', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YHM: { name: 'John C. Munro Hamilton International Airport', city: 'Hamilton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YHN: { name: 'Hornepayne Municipal Airport', city: 'Hornepayne', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YHO: { name: 'Hopedale Airport', city: 'Hopedale', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YHP: { name: 'Poplar Hill Airport', city: 'Poplar Hill', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YHR: { name: 'Chevery Airport', city: 'Chevery', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  YHT: { name: 'Haines Junction Airport', city: 'Haines Junction', country: 'Canada', timezone: null, offset: 0 },
  YHU: { name: 'Montréal / Saint-Hubert Airport', city: 'Montreal', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YHY: { name: 'Hay River / Merlyn Carter Airport', city: 'Hay River', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YHZ: { name: 'Halifax / Stanfield International Airport', city: 'Halifax', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YIB: { name: 'Atikokan Municipal Airport', city: 'Atikokan', country: 'Canada', timezone: 'America/Coral_Harbour', offset: -5 },
  YIC: { name: 'Yichun Mingyueshan Airport', city: 'Yichun', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YIE: { name: 'Arxan Yi\'ershi Airport', city: 'Arxan', country: 'China', timezone: null, offset: 8 },
  YIF: { name: 'St Augustin Airport', city: 'St-Augustin', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  YIH: { name: 'Yichang Sanxia Airport', city: 'Yichang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YIK: { name: 'Ivujivik Airport', city: 'Ivujivik', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YIN: { name: 'Yining Airport', city: 'Yining', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YIO: { name: 'Pond Inlet Airport', city: 'Pond Inlet', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YIP: { name: 'Willow Run Airport', city: 'Detroit', country: 'United States', timezone: 'America/New_York', offset: -5 },
  YIV: { name: 'Island Lake Airport', city: 'Island Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YIW: { name: 'Yiwu Airport', city: 'Yiwu', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YJF: { name: 'Fort Liard Airport', city: 'Fort Liard', country: 'Canada', timezone: null, offset: 0 },
  YJM: { name: 'Fort St James Airport', city: 'Fort St. James', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YJN: { name: 'St Jean Airport', city: 'St. Jean', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YJT: { name: 'Stephenville Airport', city: 'Stephenville', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YKA: { name: 'Kamloops Airport', city: 'Kamloops', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YKF: { name: 'Waterloo Airport', city: 'Waterloo', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKG: { name: 'Kangirsuk Airport', city: 'Kangirsuk', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKH: { name: 'Yingkou Lanqi Airport', city: 'Yingkou', country: 'China', timezone: null, offset: 0 },
  YKJ: { name: 'Key Lake Airport', city: 'Key Lake', country: 'Canada', timezone: null, offset: 0 },
  YKL: { name: 'Schefferville Airport', city: 'Schefferville', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKM: { name: 'Yakima Air Terminal McAllister Field', city: 'Yakima', country: 'United States', timezone: 'America/Los_Angeles', offset: -8 },
  YKN: { name: 'Chan Gurney Municipal Airport', city: 'Yankton', country: 'United States', timezone: 'America/Chicago', offset: -6 },
  YKO: { name: 'Hakkari Yüksekova Airport', city: 'Hakkari', country: 'Turkey', timezone: null, offset: 2 },
  YKQ: { name: 'Waskaganish Airport', city: 'Waskaganish', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKS: { name: 'Yakutsk Airport', city: 'Yakutsk', country: 'Russia', timezone: 'Asia/Yakutsk', offset: 9 },
  YKU: { name: 'Chisasibi Airport', city: 'Chisasibi', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKX: { name: 'Kirkland Lake Airport', city: 'Kirkland Lake', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YKY: { name: 'Kindersley Airport', city: 'Kindersley', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YKZ: { name: 'Buttonville Municipal Airport', city: 'Toronto', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLC: { name: 'Kimmirut Airport', city: 'Kimmirut', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLD: { name: 'Chapleau Airport', city: 'Chapleau', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLE: { name: 'Whatì Airport', city: 'Whatì', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YLH: { name: 'Lansdowne House Airport', city: 'Lansdowne House', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLJ: { name: 'Meadow Lake Airport', city: 'Meadow Lake', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YLK: { name: 'Barrie-Orillia (Lake Simcoe Regional Airport)', city: 'Barrie-Orillia', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLL: { name: 'Lloydminster Airport', city: 'Lloydminster', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YLR: { name: 'Leaf Rapids Airport', city: 'Leaf Rapids', country: 'Canada', timezone: null, offset: 0 },
  YLT: { name: 'Alert Airport', city: 'Alert', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YLW: { name: 'Kelowna International Airport', city: 'Kelowna', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YMA: { name: 'Mayo Airport', city: 'Mayo', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YME: { name: 'Matane Airport', city: 'Matane', country: 'Canada', timezone: null, offset: 0 },
  YMG: { name: 'Manitouwadge Airport', city: 'Manitouwadge', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YMH: { name: 'Mary\'s Harbour Airport', city: 'Mary\'s Harbour', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YMJ: { name: 'Moose Jaw Air Vice Marshal C. M. McEwen Airport', city: 'Moose Jaw', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YML: { name: 'Charlevoix Airport', city: 'Charlevoix', country: 'Canada', timezone: null, offset: 0 },
  YMM: { name: 'Fort McMurray Airport', city: 'Fort Mcmurray', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YMN: { name: 'Makkovik Airport', city: 'Makkovik', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YMO: { name: 'Moosonee Airport', city: 'Moosonee', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YMS: { name: 'Moises Benzaquen Rengifo Airport', city: 'Yurimaguas', country: 'Peru', timezone: 'America/Lima', offset: -5 },
  YMT: { name: 'Chapais Airport', city: 'Chibougamau', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YMW: { name: 'Maniwaki Airport', city: 'Maniwaki', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YMX: { name: 'Montreal International (Mirabel) Airport', city: 'Montreal', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YNA: { name: 'Natashquan Airport', city: 'Natashquan', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YNB: { name: 'Prince Abdulmohsin Bin Abdulaziz Airport', city: 'Yenbo', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', offset: 3 },
  YNC: { name: 'Wemindji Airport', city: 'Wemindji', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YND: { name: 'Ottawa / Gatineau Airport', city: 'Gatineau', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YNE: { name: 'Norway House Airport', city: 'Norway House', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YNG: { name: 'Youngstown Warren Regional Airport', city: 'Youngstown', country: 'United States', timezone: 'America/New_York', offset: -5 },
  YNJ: { name: 'Yanji Chaoyangchuan Airport', city: 'Yanji', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YNL: { name: 'Points North Landing Airport', city: 'Points North Landing', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YNM: { name: 'Matagami Airport', city: 'Matagami', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YNO: { name: 'North Spirit Lake Airport', city: 'North Spirit Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YNS: { name: 'Nemiscau Airport', city: 'Nemiscau', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YNT: { name: 'Yantai Laishan Airport', city: 'Yantai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YNY: { name: 'Yangyang International Airport', city: 'Sokcho / Gangneung', country: 'South Korea', timezone: 'Asia/Seoul', offset: 9 },
  YNZ: { name: 'Yancheng Airport', city: 'Yancheng', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YOA: { name: 'Ekati Airport', city: 'Ekati', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YOC: { name: 'Old Crow Airport', city: 'Old Crow', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YOD: { name: 'CFB Cold Lake', city: 'Cold Lake', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YOG: { name: 'Ogoki Post Airport', city: 'Ogoki Post', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YOH: { name: 'Oxford House Airport', city: 'Oxford House', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YOJ: { name: 'High Level Airport', city: 'High Level', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YOL: { name: 'Yola Airport', city: 'Yola', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  YOO: { name: 'Oshawa Airport', city: 'Oshawa', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YOP: { name: 'Rainbow Lake Airport', city: 'Rainbow Lake', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YOS: { name: 'Owen Sound / Billy Bishop Regional Airport', city: 'Owen Sound', country: 'Canada', timezone: null, offset: 0 },
  YOW: { name: 'Ottawa Macdonald-Cartier International Airport', city: 'Ottawa', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPA: { name: 'Prince Albert Glass Field', city: 'Prince Albert', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YPC: { name: 'Paulatuk (Nora Aliqatchialuk Ruben) Airport', city: 'Paulatuk', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YPD: { name: 'Parry Sound Area Municipal Airport', city: 'Parry Sound', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPE: { name: 'Peace River Airport', city: 'Peace River', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YPG: { name: 'Southport Airport', city: 'Portage-la-prairie', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YPH: { name: 'Inukjuak Airport', city: 'Inukjuak', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPJ: { name: 'Aupaluk Airport', city: 'Aupaluk', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPL: { name: 'Pickle Lake Airport', city: 'Pickle Lake', country: 'Canada', timezone: 'America/Coral_Harbour', offset: -5 },
  YPM: { name: 'Pikangikum Airport', city: 'Pikangikum', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YPN: { name: 'Port Menier Airport', city: 'Port Menier', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPO: { name: 'Peawanuck Airport', city: 'Peawanuck', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPQ: { name: 'Peterborough Airport', city: 'Peterborough', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPR: { name: 'Prince Rupert Airport', city: 'Prince Pupert', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YPS: { name: 'Port Hawkesbury Airport', city: 'Port Hawkesbury', country: 'Canada', timezone: null, offset: 0 },
  YPW: { name: 'Powell River Airport', city: 'Powell River', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YPX: { name: 'Puvirnituq Airport', city: 'Puvirnituq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YPY: { name: 'Fort Chipewyan Airport', city: 'Fort Chipewyan', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YQA: { name: 'Muskoka Airport', city: 'Muskoka', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQB: { name: 'Quebec Jean Lesage International Airport', city: 'Quebec', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQC: { name: 'Quaqtaq Airport', city: 'Quaqtaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQD: { name: 'The Pas Airport', city: 'The Pas', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YQF: { name: 'Red Deer Regional Airport', city: 'Red Deer Industrial', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YQG: { name: 'Windsor Airport', city: 'Windsor', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQH: { name: 'Watson Lake Airport', city: 'Watson Lake', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YQI: { name: 'Yarmouth Airport', city: 'Yarmouth', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YQK: { name: 'Kenora Airport', city: 'Kenora', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YQL: { name: 'Lethbridge County Airport', city: 'Lethbridge', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YQM: { name: 'Greater Moncton International Airport', city: 'Moncton', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YQN: { name: 'Nakina Airport', city: 'Nakina', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQQ: { name: 'Comox Airport', city: 'Comox', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YQR: { name: 'Regina International Airport', city: 'Regina', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YQS: { name: 'St Thomas Municipal Airport', city: 'St Thomas', country: 'Canada', timezone: null, offset: 0 },
  YQT: { name: 'Thunder Bay Airport', city: 'Thunder Bay', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YQU: { name: 'Grande Prairie Airport', city: 'Grande Prairie', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YQV: { name: 'Yorkton Municipal Airport', city: 'Yorkton', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YQW: { name: 'North Battleford Airport', city: 'North Battleford', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YQX: { name: 'Gander International Airport', city: 'Gander', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YQY: { name: 'Sydney / J.A. Douglas McCurdy Airport', city: 'Sydney', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YQZ: { name: 'Quesnel Airport', city: 'Quesnel', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YRA: { name: 'Rae Lakes Airport', city: 'Gamètì', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YRB: { name: 'Resolute Bay Airport', city: 'Resolute', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YRF: { name: 'Cartwright Airport', city: 'Cartwright', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YRG: { name: 'Rigolet Airport', city: 'Rigolet', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YRI: { name: 'Rivière-du-Loup Airport', city: 'Riviere Du Loup', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YRJ: { name: 'Roberval Airport', city: 'Roberval', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YRL: { name: 'Red Lake Airport', city: 'Red Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YRM: { name: 'Rocky Mountain House Airport', city: 'Rocky Mountain House', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YRO: { name: 'Ottawa / Rockcliffe Airport', city: 'Ottawa', country: 'Canada', timezone: null, offset: 0 },
  YRP: { name: 'Ottawa / Carp Airport', city: 'Ottawa', country: 'Canada', timezone: null, offset: 0 },
  YRQ: { name: 'Trois-Rivières Airport', city: 'Trois Rivieres', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YRS: { name: 'Red Sucker Lake Airport', city: 'Red Sucker Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YRT: { name: 'Rankin Inlet Airport', city: 'Rankin Inlet', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YRV: { name: 'Revelstoke Airport', city: 'Revelstoke', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YSB: { name: 'Sudbury Airport', city: 'Sudbury', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YSC: { name: 'Sherbrooke Airport', city: 'Sherbrooke', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YSD: { name: 'Suffield Heliport', city: 'Suffield', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YSE: { name: 'Squamish Airport', city: 'Squamish', country: 'Canada', timezone: null, offset: -8 },
  YSF: { name: 'Stony Rapids Airport', city: 'Stony Rapids', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YSG: { name: 'St Georges Airport', city: 'Lutselk\'e', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YSH: { name: 'Smiths Falls-Montague (Russ Beach) Airport', city: 'Smiths Falls', country: 'Canada', timezone: null, offset: 0 },
  YSJ: { name: 'Saint John Airport', city: 'St. John', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YSK: { name: 'Sanikiluaq Airport', city: 'Sanikiluaq', country: 'Canada', timezone: null, offset: -5 },
  YSL: { name: 'St Leonard Airport', city: 'St Leonard', country: 'Canada', timezone: null, offset: 0 },
  YSM: { name: 'Fort Smith Airport', city: 'Fort Smith', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YSN: { name: 'Salmon Arm Airport', city: 'Salmon Arm', country: 'Canada', timezone: null, offset: 0 },
  YSO: { name: 'Postville Airport', city: 'Postville', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YSP: { name: 'Marathon Airport', city: 'Marathon', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YSR: { name: 'Nanisivik Airport', city: 'Nanisivik', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YST: { name: 'St. Theresa Point Airport', city: 'St. Theresa Point', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YSU: { name: 'Summerside Airport', city: 'Summerside', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YSY: { name: 'Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport', city: 'Sachs Harbour', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YTA: { name: 'Pembroke Airport', city: 'Pembroke', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTD: { name: 'Thicket Portage Airport', city: 'Thicket Portage', country: 'Canada', timezone: null, offset: 0 },
  YTE: { name: 'Cape Dorset Airport', city: 'Cape Dorset', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTF: { name: 'Alma Airport', city: 'Alma', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTH: { name: 'Thompson Airport', city: 'Thompson', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YTL: { name: 'Big Trout Lake Airport', city: 'Big Trout Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YTM: { name: 'La Macaza / Mont-Tremblant International Inc Airport', city: 'Mont-Tremblant', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTQ: { name: 'Tasiujaq Airport', city: 'Tasiujaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTR: { name: 'CFB Trenton', city: 'Trenton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTS: { name: 'Timmins/Victor M. Power', city: 'Timmins', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YTY: { name: 'Yangzhou Taizhou Airport', city: 'Yangzhou', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YTZ: { name: 'Billy Bishop Toronto City Centre Airport', city: 'Toronto', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YUB: { name: 'Tuktoyaktuk Airport', city: 'Tuktoyaktuk', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YUD: { name: 'Umiujaq Airport', city: 'Umiujaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YUE: { name: 'Yuendumu Airport', city: 'Yuendumu ', country: 'Australia', timezone: 'Australia/Darwin', offset: 9 },
  YUL: { name: 'Montreal / Pierre Elliott Trudeau International Airport', city: 'Montreal', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YUM: { name: 'Yuma MCAS/Yuma International Airport', city: 'Yuma', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  YUS: { name: 'Yushu Batang Airport', city: 'Yushu', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  YUT: { name: 'Repulse Bay Airport', city: 'Repulse Bay', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YUX: { name: 'Hall Beach Airport', city: 'Hall Beach', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YUY: { name: 'Rouyn Noranda Airport', city: 'Rouyn', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVA: { name: 'Iconi Airport', city: 'Moroni', country: 'Comoros', timezone: 'Indian/Comoro', offset: 3 },
  YVB: { name: 'Bonaventure Airport', city: 'Bonaventure', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVC: { name: 'La Ronge Airport', city: 'La Ronge', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YVD: { name: 'Virden/R.J. (Bob) Andrew Field Regional Aerodrome', city: 'Virden', country: 'Canada', timezone: null, offset: 0 },
  YVE: { name: 'Vernon Airport', city: 'Vernon', country: 'Canada', timezone: null, offset: 0 },
  YVG: { name: 'Vermilion Airport', city: 'Vermillion', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YVM: { name: 'Qikiqtarjuaq Airport', city: 'Broughton Island', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVO: { name: 'Val-d\'Or Airport', city: 'Val D\'or', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVP: { name: 'Kuujjuaq Airport', city: 'Quujjuaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVQ: { name: 'Norman Wells Airport', city: 'Norman Wells', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YVR: { name: 'Vancouver International Airport', city: 'Vancouver', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YVT: { name: 'Buffalo Narrows Airport', city: 'Buffalo Narrows', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YVV: { name: 'Wiarton Airport', city: 'Wiarton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YVZ: { name: 'Deer Lake Airport', city: 'Deer Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YWA: { name: 'Petawawa Airport', city: 'Petawawa', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YWB: { name: 'Kangiqsujuaq (Wakeham Bay) Airport', city: 'Kangiqsujuaq', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YWG: { name: 'Winnipeg / James Armstrong Richardson International Airport', city: 'Winnipeg', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YWH: { name: 'Victoria Harbour Seaplane Base', city: 'Victoria', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YWJ: { name: 'Déline Airport', city: 'Deline', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YWK: { name: 'Wabush Airport', city: 'Wabush', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YWL: { name: 'Williams Lake Airport', city: 'Williams Lake', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YWM: { name: 'Williams Harbour Airport', city: 'Williams Harbour', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YWP: { name: 'Webequie Airport', city: 'Webequie', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YWS: { name: 'Whistler/Green Lake Water Aerodrome', city: 'Whistler', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YWY: { name: 'Wrigley Airport', city: 'Wrigley', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YXC: { name: 'Cranbrook/Canadian Rockies International Airport', city: 'Cranbrook', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YXD: { name: 'Edmonton City Centre (Blatchford Field) Airport', city: 'Edmonton', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YXE: { name: 'Saskatoon John G. Diefenbaker International Airport', city: 'Saskatoon', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YXH: { name: 'Medicine Hat Airport', city: 'Medicine Hat', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YXJ: { name: 'Fort St John Airport', city: 'Fort Saint John', country: 'Canada', timezone: 'America/Dawson_Creek', offset: -7 },
  YXK: { name: 'Rimouski Airport', city: 'Rimouski', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YXL: { name: 'Sioux Lookout Airport', city: 'Sioux Lookout', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YXN: { name: 'Whale Cove Airport', city: 'Whale Cove', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YXP: { name: 'Pangnirtung Airport', city: 'Pangnirtung', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YXQ: { name: 'Beaver Creek Airport', city: 'Beaver Creek', country: 'Canada', timezone: null, offset: 0 },
  YXR: { name: 'Earlton (Timiskaming Regional) Airport', city: 'Earlton', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YXS: { name: 'Prince George Airport', city: 'Prince George', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YXT: { name: 'Northwest Regional Airport Terrace-Kitimat', city: 'Terrace', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YXU: { name: 'London Airport', city: 'London', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YXX: { name: 'Abbotsford Airport', city: 'Abbotsford', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YXY: { name: 'Whitehorse / Erik Nielsen International Airport', city: 'Whitehorse', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YXZ: { name: 'Wawa Airport', city: 'Wawa', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YYB: { name: 'North Bay Airport', city: 'North Bay', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YYC: { name: 'Calgary International Airport', city: 'Calgary', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YYD: { name: 'Smithers Airport', city: 'Smithers', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YYE: { name: 'Fort Nelson Airport', city: 'Fort Nelson', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YYF: { name: 'Penticton Airport', city: 'Penticton', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YYG: { name: 'Charlottetown Airport', city: 'Charlottetown', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YYH: { name: 'Taloyoak Airport', city: 'Spence Bay', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YYJ: { name: 'Victoria International Airport', city: 'Victoria', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YYL: { name: 'Lynn Lake Airport', city: 'Lynn Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YYN: { name: 'Swift Current Airport', city: 'Swift Current', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  YYQ: { name: 'Churchill Airport', city: 'Churchill', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  YYR: { name: 'Goose Bay Airport', city: 'Goose Bay', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YYT: { name: 'St. John\'s International Airport', city: 'St. John\'s', country: 'Canada', timezone: 'America/St_Johns', offset: -3 },
  YYU: { name: 'Kapuskasing Airport', city: 'Kapuskasing', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YYW: { name: 'Armstrong Airport', city: 'Armstrong', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YYY: { name: 'Mont Joli Airport', city: 'Mont Joli', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YYZ: { name: 'Lester B. Pearson International Airport', city: 'Toronto', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZD: { name: 'Downsview Airport', city: 'Toronto', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZE: { name: 'Gore Bay Manitoulin Airport', city: 'Gore Bay', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZF: { name: 'Yellowknife Airport', city: 'Yellowknife', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YZG: { name: 'Salluit Airport', city: 'Salluit', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZH: { name: 'Slave Lake Airport', city: 'Slave Lake', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YZP: { name: 'Sandspit Airport', city: 'Sandspit', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YZR: { name: 'Chris Hadfield Airport', city: 'Sarnia', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZS: { name: 'Coral Harbour Airport', city: 'Coral Harbour', country: 'Canada', timezone: 'America/Coral_Harbour', offset: -5 },
  YZT: { name: 'Port Hardy Airport', city: 'Port Hardy', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YZU: { name: 'Whitecourt Airport', city: 'Whitecourt', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  YZV: { name: 'Sept-Îles Airport', city: 'Sept-iles', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  YZW: { name: 'Teslin Airport', city: 'Teslin', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YZX: { name: 'CFB Greenwood', city: 'Greenwood', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  YZY: { name: 'Mackenzie Airport', city: 'Mackenzie British Columbia', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  YZZ: { name: 'Trail Airport', city: 'Trail', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  Z84: { name: 'Clear Airport', city: 'Clear Mews', country: 'United States', timezone: 'America/Anchorage', offset: -9 },
  ZAC: { name: 'York Landing Airport', city: 'York Landing', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZAD: { name: 'Zemunik Airport', city: 'Zadar', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  ZAG: { name: 'Zagreb Airport', city: 'Zagreb', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 1 },
  ZAH: { name: 'Zahedan International Airport', city: 'Zahedan', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ZAJ: { name: 'Zaranj Airport', city: 'Zaranj', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 4 },
  ZAL: { name: 'Pichoy Airport', city: 'Valdivia', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ZAM: { name: 'Zamboanga International Airport', city: 'Zamboanga', country: 'Philippines', timezone: 'Asia/Manila', offset: 8 },
  ZAR: { name: 'Zaria Airport', city: 'Zaria', country: 'Nigeria', timezone: 'Africa/Lagos', offset: 1 },
  ZAT: { name: 'Zhaotong Airport', city: 'Zhaotong', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ZAZ: { name: 'Zaragoza Air Base', city: 'Zaragoza', country: 'Spain', timezone: 'Europe/Madrid', offset: 1 },
  ZBF: { name: 'Bathurst Airport', city: 'Bathurst', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  ZBM: { name: 'Bromont (Roland Desourdy) Airport', city: 'Bromont', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  ZBR: { name: 'Konarak Airport', city: 'Chah Bahar', country: 'Iran', timezone: 'Asia/Tehran', offset: 3 },
  ZCA: { name: 'Arnsberg-Menden Airport', city: 'Arnsberg', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZCL: { name: 'General Leobardo C. Ruiz International Airport', city: 'Zacatecas', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  ZCN: { name: 'Celle Airport', city: 'Celle', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZCO: { name: 'Maquehue Airport', city: 'Temuco', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ZEL: { name: 'Denny Island Airport', city: 'Bella Bella', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZEM: { name: 'Eastmain River Airport', city: 'Eastmain River', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  ZFA: { name: 'Faro Airport', city: 'Faro', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZFD: { name: 'Fond-Du-Lac Airport', city: 'Fond-Du-Lac', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  ZFM: { name: 'Fort Mcpherson Airport', city: 'Fort Mcpherson', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  ZFN: { name: 'Tulita Airport', city: 'Tulita', country: 'Canada', timezone: 'America/Edmonton', offset: -7 },
  ZGF: { name: 'Grand Forks Airport', city: 'Grand Forks', country: 'Canada', timezone: null, offset: 0 },
  ZGI: { name: 'Gods River Airport', city: 'Gods River', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZGR: { name: 'Little Grand Rapids Airport', city: 'Little Grand Rapids', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZGU: { name: 'Gaua Island Airport', city: 'Gaua Island', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 11 },
  ZHA: { name: 'Zhanjiang Airport', city: 'Zhanjiang', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ZHY: { name: 'Zhongwei Shapotou Airport', city: 'Zhongwei', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ZIC: { name: 'Victoria Airport', city: 'Victoria', country: 'Chile', timezone: null, offset: 0 },
  ZIG: { name: 'Ziguinchor Airport', city: 'Ziguinchor', country: 'Senegal', timezone: 'Africa/Dakar', offset: 0 },
  ZIH: { name: 'Ixtapa Zihuatanejo International Airport', city: 'Zihuatanejo', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  ZIN: { name: 'Interlaken Air Base', city: 'Interlaken', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  ZIX: { name: 'Zhigansk Airport', city: 'Zhigansk', country: 'Russia', timezone: null, offset: 0 },
  ZJG: { name: 'Jenpeg Airport', city: 'Jenpeg', country: 'Canada', timezone: null, offset: 0 },
  ZJI: { name: 'Locarno Airport', city: 'Locarno', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  ZJN: { name: 'Swan River Airport', city: 'Swan River', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZKB: { name: 'Kasaba Bay Airport', city: 'Kasaba Bay', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 2 },
  ZKE: { name: 'Kashechewan Airport', city: 'Kashechewan', country: 'Canada', timezone: 'America/Toronto', offset: -5 },
  ZKG: { name: 'Kegaska Airport', city: 'Kegaska', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  ZKP: { name: 'Zyryanka Airport', city: 'Zyryanka', country: 'Russia', timezone: 'Asia/Srednekolymsk', offset: 11 },
  ZLO: { name: 'Playa De Oro International Airport', city: 'Manzanillo', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  ZLT: { name: 'La Tabatière Airport', city: 'La Tabatière', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  ZML: { name: 'South Cariboo Region / 108 Mile Airport', city: '108 Mile Ranch', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZMM: { name: 'Zamora Airport', city: 'Zamora', country: 'Mexico', timezone: 'America/Mexico_City', offset: -6 },
  ZMT: { name: 'Masset Airport', city: 'Masset', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZNA: { name: 'Nanaimo Harbour Water Airport', city: 'Nanaimo', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZND: { name: 'Zinder Airport', city: 'Zinder', country: 'Niger', timezone: 'Africa/Niamey', offset: 1 },
  ZNE: { name: 'Newman Airport', city: 'Newman', country: 'Australia', timezone: 'Australia/Perth', offset: 8 },
  ZNF: { name: 'Hanau Army Air Field', city: 'Hanau', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZNV: { name: 'Koblenz-Winningen Airport', city: 'Koblenz', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZNZ: { name: 'Abeid Amani Karume International Airport', city: 'Zanzibar', country: 'Tanzania', timezone: 'Africa/Dar_es_Salaam', offset: 3 },
  ZOS: { name: 'Cañal Bajo Carlos - Hott Siebert Airport', city: 'Osorno', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ZPB: { name: 'Sachigo Lake Airport', city: 'Sachigo Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZPC: { name: 'Pucón Airport', city: 'Pucon', country: 'Chile', timezone: 'America/Santiago', offset: -4 },
  ZPH: { name: 'Zephyrhills Municipal Airport', city: 'Zephyrhills', country: 'United States', timezone: 'America/New_York', offset: -5 },
  ZQC: { name: 'Speyer Airport', city: 'Speyer', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZQF: { name: 'Trier-Föhren Airport', city: 'Trier', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZQL: { name: 'Donaueschingen-Villingen Airport', city: 'Donaueschingen', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZQN: { name: 'Queenstown International Airport', city: 'Queenstown International', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 12 },
  ZQW: { name: 'Zweibrücken Airport', city: 'Zweibruecken', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZRH: { name: 'Zürich Airport', city: 'Zurich', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 1 },
  ZRJ: { name: 'Round Lake (Weagamow Lake) Airport', city: 'Round Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZSA: { name: 'San Salvador Airport', city: 'Cockburn Town', country: 'Bahamas', timezone: 'America/Nassau', offset: -5 },
  ZSE: { name: 'Pierrefonds Airport', city: 'St.-pierre', country: 'Reunion', timezone: 'Indian/Reunion', offset: 4 },
  ZSJ: { name: 'Sandy Lake Airport', city: 'Sandy Lake', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZSN: { name: 'Stendal-Borstel Airport', city: 'Stendal', country: 'Germany', timezone: 'Europe/Berlin', offset: 1 },
  ZST: { name: 'Stewart Airport', city: 'Stewart', country: 'Canada', timezone: null, offset: 0 },
  ZSW: { name: 'Prince Rupert/Seal Cove Seaplane Base', city: 'Prince Rupert', country: 'Canada', timezone: 'America/Vancouver', offset: -8 },
  ZSY: { name: 'Scottsdale Airport', city: 'Scottsdale', country: 'United States', timezone: 'America/Phoenix', offset: -7 },
  ZTA: { name: 'Tureia Airport', city: 'Tureia', country: 'French Polynesia', timezone: null, offset: -10 },
  ZTB: { name: 'Tête-à-la-Baleine Airport', city: 'Tête-à-la-Baleine', country: 'Canada', timezone: 'America/Blanc-Sablon', offset: -4 },
  ZTH: { name: 'Dionysios Solomos Airport', city: 'Zakynthos', country: 'Greece', timezone: 'Europe/Athens', offset: 2 },
  ZTM: { name: 'Shamattawa Airport', city: 'Shamattawa', country: 'Canada', timezone: 'America/Winnipeg', offset: -6 },
  ZTR: { name: 'Zhytomyr Airport', city: 'Zhytomyr', country: 'Ukraine', timezone: 'Europe/Kiev', offset: 2 },
  ZTU: { name: 'Zaqatala International Airport', city: 'Zaqatala', country: 'Azerbaijan', timezone: 'Asia/Baku', offset: 4 },
  ZUC: { name: 'Ignace Municipal Airport', city: 'Ignace', country: 'Canada', timezone: null, offset: 0 },
  ZUH: { name: 'Zhuhai Jinwan Airport', city: 'Zhuhai', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ZUM: { name: 'Churchill Falls Airport', city: 'Churchill Falls', country: 'Canada', timezone: 'America/Halifax', offset: -4 },
  ZUN: { name: 'Black Rock Airport', city: 'Zuni Pueblo', country: 'United States', timezone: 'America/Denver', offset: -7 },
  ZVA: { name: 'Miandrivazo Airport', city: 'Miandrivazo', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  ZVK: { name: 'Savannakhet Airport', city: 'Savannakhet', country: 'Laos', timezone: 'Asia/Vientiane', offset: 7 },
  ZWA: { name: 'Andapa Airport', city: 'Andapa', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 3 },
  ZWL: { name: 'Wollaston Lake Airport', city: 'Wollaston Lake', country: 'Canada', timezone: 'America/Regina', offset: -6 },
  ZXB: { name: 'Jan Mayensfield', city: 'Jan Mayen', country: 'Norway', timezone: 'Arctic/Longyearbyen', offset: 1 },
  ZYI: { name: 'Zunyi Xinzhou Airport', city: 'Zunyi', country: 'China', timezone: 'Asia/Shanghai', offset: 8 },
  ZYL: { name: 'Osmany International Airport', city: 'Sylhet Osmani', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 6 },
  ZZU: { name: 'Mzuzu Airport', city: 'Mzuzu', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 2 },
  ZZV: { name: 'Zanesville Municipal Airport', city: 'Zanesville', country: 'United States', timezone: null, offset: 0 }
};
