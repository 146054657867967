import { ConfigService } from './../core/config.service';
import { SearchResult } from './../models/search-result';
import { Search } from './../models/search';
import { Flight } from './../models/flight';
import { DTOFlights } from './../models/dto-flights';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { Observable, Subscriber } from 'rxjs';
import { Route } from './../models/route';
import { DTORoute } from './../models/dto-route';
import { DTOSearch } from './../models/dto-search';
import { AutoUnsubscribe } from './../common/auto-unsubscribe.base';
import { HttpClient } from '@angular/common/http';
import { DTODates } from './../models/dto-dates';

@Injectable({
  providedIn: 'root'
})
export class RouteService extends AutoUnsubscribe {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private configService: ConfigService
  ) {
    super();
  }

  cleanupRoutes(): Observable<boolean> {
    return new Observable(observer => {
      this.subscriptions.push(this.http.get(this.configService.appConfig.cleanupURL)
        .subscribe((response: any) => {
          const result: boolean = response.data.result;
          if (result) {
            observer.next(true);
          } else {
            observer.error();
          }
        }, () => observer.error()));
    });
  }

  getAllRoutes(): Observable<Route[]> {
    return new Observable(observer => {
      this.subscriptions.push(this.firestore
        .collection('routes')
        .valueChanges()
        .subscribe(async (dtoRoutes: DTORoute[]) => {
          const routes: Route[] = [];

          for (const dtoRoute of dtoRoutes) {
            const routeName = `${dtoRoute.fromCity}-${dtoRoute.toCity}`;
            routes.push({
              fromCity: dtoRoute.fromCity,
              toCity: dtoRoute.toCity,
              name: routeName,
              searchDate: dtoRoute.searchDate,
              flights: []
            });
          }

          observer.next(routes);
        }));
    });
  }

  async getFlightsForRoute(fromCity: string, toCity: string): Promise<Flight[]> {
    const dtoDates = {};
    const dates: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await this.firestore.doc(`routes/${fromCity}-${toCity}`).collection('dates').get().toPromise();
    dates.docs.forEach((date: firebase.firestore.DocumentData) => {
      dtoDates[date.id] = date.data();
    });

    return this.mapFlights(fromCity, toCity, dtoDates);
  }

  getRoute(routeName: string): Observable<Route> {
    return new Observable(observer => {
      this.subscriptions.push(this.firestore
        .doc(`routes/${routeName.toUpperCase()}`)
        .valueChanges()
        .subscribe(async (dtoRoute: DTORoute) => {
          let route: Route = null;

          if (dtoRoute) {
            route = {
              fromCity: dtoRoute.fromCity,
              toCity: dtoRoute.toCity,
              name: `${dtoRoute.fromCity}-${dtoRoute.toCity}`,
              searchDate: new Date(dtoRoute.searchDate.seconds * 1000),
              flights: []
            };

            route.flights = await this.getFlightsForRoute(dtoRoute.fromCity, dtoRoute.toCity);
          }

          observer.next(route);
        }));
    });
  }

  multiSearch(searches: Search[]): Observable<SearchResult> {
    return new Observable(observer => {
      // do searches recursively because other methods to do
      // sync subscriptions do not work
      const searchResult: SearchResult = {
        success: true,
        nextSearchDate: null,
        lastSearchDate: null,
        errors: [],
        warnings: []
      };
      this.search(searches, 0, observer, searchResult);
    });
  }

  private search(searches: Search[], index: number, observer: Subscriber<SearchResult>, searchResult: SearchResult) {
    const search: Search = searches[index];

    if (!search) {
      observer.next(searchResult);
    } else {
      this.subscriptions.push(this.http.post(this.configService.appConfig.searchURL, { data: search })
        .subscribe((response: any) => {
          const result: SearchResult = response.data.result;

          searchResult.success = searchResult.success && result.success;
          searchResult.nextSearchDate = result.nextSearchDate;
          searchResult.lastSearchDate = result.lastSearchDate;
          searchResult.errors.push(...result.errors);
          searchResult.warnings.push(...result.warnings);

          let nextSearchIndex: number = index + 1;
          if (searchResult.nextSearchDate) {
            search.start = searchResult.nextSearchDate;
            search.end = searchResult.lastSearchDate;

            nextSearchIndex = index;
          }

          this.search(searches, nextSearchIndex, observer, searchResult);
        }, (err) => {
          console.error(err);

          searchResult.success = false;
          searchResult.errors.push(err);

          this.search(searches, index + 1, observer, searchResult);
        }));
    }
  }

  private mapFlights(fromCity: string, toCity: string, dtoDates: DTODates): Flight[] {
    const mappedFlights: Flight[] = [];

    for (const dateKey in dtoDates) {
      if (dtoDates.hasOwnProperty(dateKey)) {
        const flights: DTOFlights = dtoDates[dateKey];

        for (const flightKey in flights) {
          if (flights.hasOwnProperty(flightKey)) {
            const search: DTOSearch = flights[flightKey];
            const dateKeySplit: string[] = dateKey.split('-');
            const date: Date = new Date(parseInt(dateKeySplit[0], 10), parseInt(dateKeySplit[1], 10) - 1, parseInt(dateKeySplit[2], 10));
            const searchDate: Date = new Date(search.searchDate.seconds * 1000);
            const daysSinceSearch: number = ((new Date()).getTime() - searchDate.getTime()) / (24 * 3600 * 1000);

            if (daysSinceSearch <= this.configService.appConfig.awardsExpiryDays) {
              const flight: Flight = {
                from: fromCity,
                to: toCity,
                date,
                searchDate,
                awards: search.awards,
                segments: search.segments
              };

              mappedFlights.push(flight);
            }
          }
        }
      }
    }

    return mappedFlights;
  }

}
