import { HeaderTitle } from './models/header-title';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private headerTitleSubject: BehaviorSubject<HeaderTitle>;

  constructor() {
    this.headerTitleSubject = new BehaviorSubject(null);
  }

  getHeaderTitle(): Observable<HeaderTitle> {
    return this.headerTitleSubject.asObservable();
  }

  setHeaderTitle(title: HeaderTitle) {
    this.headerTitleSubject.next(title);
  }

}
