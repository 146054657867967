import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaitComponent } from './wait/wait.component';
import { PromptComponent } from './prompt/prompt.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    WaitComponent,
    PromptComponent
  ],
  entryComponents: [
    WaitComponent,
    PromptComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class DialogModule { }
