import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { AirportData } from '../../../data/airport-data';
import { Engine } from '../../../models/engine';
import { AutoUnsubscribe } from './../../../common/auto-unsubscribe.base';
import { Airport } from './../../../models/airport';
import { Cabin } from './../../../models/cabin';
import { Search } from './../../../models/search';
import { SearchResult } from './../../../models/search-result';
import { ActivityIndicatorService } from './../../../shared/dialog/activity-indicator.service';
import { RouteService } from './../../../shared/route.service';
import { ConfigService } from './../../config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends AutoUnsubscribe implements OnInit {

  public toAirports: Observable<Airport[]>;

  public fromAirports: Observable<Airport[]>;

  public cabins: any[];

  public searchForm: FormGroup;

  public searchFormEngines: FormGroup;

  public engines: Engine[];

  private airportDetails: Airport[];

  @ViewChild('container', { read: ViewContainerRef })
  public container: ViewContainerRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private configService: ConfigService,
    private activityIndicatorService: ActivityIndicatorService,
    private routeService: RouteService,
    @Inject(LOCALE_ID) private locale: string,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) {
    super();

    this.airportDetails = [];
    for (const airportCode in AirportData) {
      if (AirportData.hasOwnProperty(airportCode)) {
        this.airportDetails.push({
          code: airportCode,
          name: AirportData[airportCode].name,
          country: AirportData[airportCode].country,
          city: AirportData[airportCode].city
        });
      }
    }

    this.cabins = [
      { name: 'Economy', value: Cabin.Economy },
      { name: 'Premium Economy', value: Cabin.PremiumEconomy },
      { name: 'Business', value: Cabin.Business },
      { name: 'First', value: Cabin.First }
    ];
    this.engines = [];
    this.searchForm = new FormGroup({
      from: new FormControl(),
      to: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      cabin: new FormControl('economy'),
      quantity: new FormControl(1),
      engines: new FormArray([]),
      partners: new FormControl(true),
      roundtrip: new FormControl()
    });
    this.searchFormEngines = this.searchForm.get('engines') as FormGroup;
  }

  ngOnInit() {
    const engines: Engine[] = this.configService.engines.filter(config => config.allowSearchInApp);
    const engineFormControls: FormControl[] = [];

    engines.forEach(() => engineFormControls.push(new FormControl()));

    this.searchForm.setControl('engines', new FormArray(engineFormControls, this.atLeastOneFieldValidator));
    this.engines = engines;

    this.changeDetectorRef.detectChanges();

    this.subscriptions.push(this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof RoutesRecognized),
        filter((routerEvent: RoutesRecognized) => routerEvent.state.root.firstChild !== null),
        map((routerEvent: RoutesRecognized) => routerEvent.state.root.firstChild.params)
      )
      .subscribe((routeParams: Params) => {
        const routeNameArr: string[] = routeParams.routeName ? routeParams.routeName.toUpperCase().split('-') : [];

        if (routeNameArr.length > 0) {
          this.searchForm.get('from').setValue(routeNameArr[0]);
          this.searchForm.get('to').setValue(routeNameArr[1]);
        }
      }));

    this.fromAirports = this.searchForm.get('from').valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map((search: string) => this.filterAirports(search, this.searchForm.get('to').value))
      );

    this.toAirports = this.searchForm.get('to').valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map((search: string) => this.filterAirports(search, this.searchForm.get('from').value))
      );
  }

  search() {
    const searches: Search[] = this.createSearches();
    const startTime: Date = new Date();

    const indicatorKey: string = this.activityIndicatorService.showIndicator(this.container);
    this.subscriptions.push(this.routeService.multiSearch(searches)
      .subscribe((result: SearchResult) => {
        if (result.errors.length > 0) {
          this.matSnackBar.open('There were errors while searching', 'Close');

          result.errors.forEach((error: string) => console.error(error));
        } else if (result.warnings.length > 0) {
          this.matSnackBar.open('There were warnings searching', 'Close');

          result.warnings.forEach((warning: string) => console.warn(warning));
        } else if (result.success) {
          const elapsedTime: string = this.getTimeBetweenDates(new Date(), startTime);
          this.matSnackBar.open(`Search successful!. Total time: ${elapsedTime}`, 'Close');
        }

        this.activityIndicatorService.hideIndicator(indicatorKey);
      }, () => this.activityIndicatorService.hideIndicator(indicatorKey)));

    this.showTimer(indicatorKey, startTime);
  }

  private atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (group.controls.hasOwnProperty(control) && group.controls[control].valid && group.controls[control].value) {
          isAtLeastOne = true;
          break;
        }
      }
    }

    return isAtLeastOne ? null : { required: true };
  }

  private createSearches(): Search[] {
    const searches: Search[] = [];

    const from: string = this.searchForm.get('from').value;
    const to: string = this.searchForm.get('to').value;
    const startDate: Date = this.searchForm.get('startDate').value;
    const endDate: Date = this.searchForm.get('endDate').value;
    const cabin: string = this.searchForm.get('cabin').value;
    const quantity: number = this.searchForm.get('quantity').value;
    const websites: string[] = this.engines
      .map((engine: Engine) => engine.airlineCode)
      .filter((_: string, index: number) => this.searchForm.get('engines').value[index]);
    const partners: boolean = this.searchForm.get('partners').value;
    const oneway: boolean = !this.searchForm.get('roundtrip').value;

    websites.forEach((website: string) => {
      searches.push({
        website,
        from,
        to,
        start: formatDate(startDate, 'yyyy-MM-dd', this.locale),
        end: endDate ? formatDate(endDate, 'yyyy-MM-dd', this.locale) : null,
        cabin,
        quantity,
        oneway,
        partners
      });
    });

    return searches;
  }

  private filterAirports(search: string, airportCodeToIgnore: string): Airport[] {
    const searchTerm = search ? search.toLowerCase() : '';
    return this.airportDetails.filter(airportDetail => {
      return (!airportCodeToIgnore || airportDetail.code.toLowerCase() !== airportCodeToIgnore.toLowerCase()) &&
        (!searchTerm ||
          airportDetail.code.toLowerCase().includes(searchTerm) ||
          (airportDetail.name && airportDetail.name.toLowerCase().includes(searchTerm)) ||
          (airportDetail.city && airportDetail.city.toLowerCase().includes(searchTerm)) ||
          (airportDetail.country && airportDetail.country.toLowerCase().includes(searchTerm)));
    });
  }

  private getTimeBetweenDates(endTime: Date, startTime: Date): string {
    const secondsPast: number = (endTime.getTime() - startTime.getTime()) / 1000;
    const minutes: number = Math.floor(secondsPast / 60);
    const seconds: number = Math.floor(secondsPast % 60);
    const secondsStr: string = seconds < 10 ? `0${seconds}` : seconds.toString();

    return `${minutes}:${secondsStr}`;
  }

  private showTimer(indicatorKey: string, timer: Date) {
    if (this.activityIndicatorService.updateIndicatorText(indicatorKey, this.getTimeBetweenDates(new Date(), timer))) {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => this.showTimer(indicatorKey, timer), 1000);
    }
  }

}
