<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="width-100">
      <div>
        <button mat-icon-button (click)="toggleLeftNav.emit()">
          <mat-icon>menu</mat-icon>
        </button>
        <span class="title cursor-pointer outline-none" [routerLink]="['']">{{appTitle}}</span>
      </div>
      <div fxHide.lt-sm fxFlex="aut0" fxLayoutAlign="center">
        <app-header-title [headerTitle]="headerTitle | async"></app-header-title>
      </div>
    </div>
    <div *ngIf="isLoggedIn | async" class="menu">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="cleanup()">Cleanup Routes</button>
        <button mat-menu-item [routerLink]="['admin/engines']">Manage Engines</button>
        <button mat-menu-item [routerLink]="['auth/logout']">Logout</button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="headerTitle | async" fxHide fxShow.lt-sm fxLayoutAlign="center">
    <app-header-title [headerTitle]="headerTitle | async" [small]="true"></app-header-title>
  </mat-toolbar-row>
</mat-toolbar>
