import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { auth } from 'firebase/app';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;

  constructor(private fireauth: AngularFireAuth) { }

  isLoggedIn(completeObservable?: boolean): Observable<boolean> {
    return new Observable(observer => {
      this.fireauth.authState.subscribe(user => {
        this.user = user;
        observer.next(!!this.user);

        if (completeObservable) {
          observer.complete();
        }
      }, () => observer.error())
    });
  }

  login(email: string, password: string): Observable<boolean> {
    return new Observable(observer => {
      from(this.fireauth.signInWithEmailAndPassword(email, password))
        .subscribe((userCredentials: auth.UserCredential) => {
          this.user = userCredentials.user;
          observer.next(!!this.user);
        }, () => observer.error());
    });
  }

  logout(): Observable<boolean> {
    return new Observable(observer => {
      from(this.fireauth.signOut())
        .subscribe(() => observer.next(true), () => observer.error());
    });
  }

}
