import { AuthGuard } from './auth/auth.guard';
import { ConfigService } from './core/config.service';
import { HeaderModule } from './core/header/header.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { DialogModule } from './shared/dialog/dialog.module';
import { Routes, RouterModule } from '@angular/router';
import { SidenavModule } from './core/sidenav/sidenav.module';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'route/:routeName', loadChildren: () => import('./route/route.module').then(m => m.RouteModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canLoad: [AuthGuard] }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    DialogModule,
    SidenavModule,
    HeaderModule,
    HttpClientModule,
    FlexLayoutModule,
    MatSidenavModule
  ],
  providers: [
    ConfigService,
    { provide: APP_INITIALIZER, deps: [ConfigService], useFactory: (configService: ConfigService) => async () => configService.loadConfig(), multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
