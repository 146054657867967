import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { WaitComponent } from './wait/wait.component';

@Injectable({
  providedIn: 'root'
})
export class ActivityIndicatorService {

  private componentRefs: { [indicatorKey: string]: ComponentRef<any> };

  private viewContainerRefs: { [indicatorKey: string]: ViewContainerRef };

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.componentRefs = {};
    this.viewContainerRefs = {};
  }

  hideIndicator(indicatorKey: string) {
    const componentRef = this.componentRefs[indicatorKey];
    const viewContainerRef = this.viewContainerRefs[indicatorKey];

    if (componentRef && viewContainerRef) {
      // set the position of the parent element back to the original value
      if (viewContainerRef.element.nativeElement.parentElement) {
        const position: string = viewContainerRef.element.nativeElement.parentElement.dataset.hmisOrgPos;
        viewContainerRef.element.nativeElement.parentElement.style.position = position;
      }

      componentRef.destroy();

      // remove the references from the objects
      delete this.componentRefs[indicatorKey];
      delete this.viewContainerRefs[indicatorKey];
    }
  }

  showIndicator(viewContainerRef: ViewContainerRef): string {
    // set the position of the parent element to relative so that the indicator
    // is positioned properly
    if (viewContainerRef.element.nativeElement.parentElement) {
      viewContainerRef.element.nativeElement.parentElement.dataset.hmisOrgPos =
        viewContainerRef.element.nativeElement.parentElement.style.position;
      viewContainerRef.element.nativeElement.parentElement.style.position = 'relative';
    }

    // dynamically inject the component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WaitComponent);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as WaitComponent).text = '';

    // store the component and view container references and return a random key
    // that will be used to close the indicator
    const indicatorKey = Math.random().toString(36).substring(4);
    this.componentRefs[indicatorKey] = componentRef;
    this.viewContainerRefs[indicatorKey] = viewContainerRef;
    return indicatorKey;
  }

  updateIndicatorText(indicatorKey: string, text: string): boolean {
    if (this.componentRefs[indicatorKey]) {
      (this.componentRefs[indicatorKey].instance as WaitComponent).text = text;

      return true;
    }

    return false;
  }

}

