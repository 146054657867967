<div>
    <div fxHide.gt-xs class="actions border-color-grey-light">
      <mat-icon class="action cursor-pointer" (click)="closeSidePanel()">close</mat-icon>
    </div>
    <div class="content">
      <div #contentContainer>
        <div #contentAnchor></div>
      </div>
    </div>
  </div>
