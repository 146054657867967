import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptComponent {

  public buttonTextCancel: string;

  public buttonTextConfirm = 'OK';

  public title: string;

  public message: string;

}
