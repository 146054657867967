import { AutoUnsubscribe } from './../../../common/auto-unsubscribe.base';
import { RouteService } from './../../../shared/route.service';
import { AuthService } from './../../../auth/auth.service';
import { HeaderTitle } from './../models/header-title';
import { Observable } from 'rxjs';
import { HeaderService } from './../header.service';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AutoUnsubscribe {

  public headerTitle: Observable<HeaderTitle>;

  public isLoggedIn: Observable<boolean>;

  @Input()
  public appTitle: string;

  @Output()
  public toggleLeftNav: EventEmitter<any> = new EventEmitter();

  constructor(
    private headerService: HeaderService,
    private authService: AuthService,
    private routeService: RouteService,
    private matSnackBar: MatSnackBar
  ) {
    super();

    this.headerTitle = this.headerService.getHeaderTitle();
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  cleanup() {
    this.subscriptions.push(this.routeService.cleanupRoutes()
      .subscribe((success: boolean) => {
        if (success) {
          this.matSnackBar.open('Cleanup successful!', 'Close');
        } else {
          this.matSnackBar.open('There were errors while cleaning routes', 'Close');
        }
      }, () => this.matSnackBar.open('There were errors while cleaning routes', 'Close')));
  }

}
