import { HeaderTitle } from './core/header/models/header-title';
import { HeaderService } from './core/header/header.service';
import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AutoUnsubscribe } from './common/auto-unsubscribe.base';
import { SidenavService } from './core/sidenav/sidenav.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AutoUnsubscribe implements AfterViewInit {

  public appTitle: string;

  public sidenavContainerClasses: string[];

  @ViewChild('leftNav')
  public leftNav: MatSidenav;

  @ViewChild('rightNav')
  public rightNav: MatSidenav;

  constructor(
    private sidenavService: SidenavService,
    private headerService: HeaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.appTitle = 'Award Searcher';
    this.sidenavContainerClasses = [];
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.sidenavService.getLeftNavOpen()
      .subscribe((open: boolean) => {
        if (open) {
          this.leftNav.open();
        } else {
          this.leftNav.close();
        }

        this.changeDetectorRef.detectChanges();
      }));

    this.subscriptions.push(this.sidenavService.getRightNavOpen()
      .subscribe((open: boolean) => {
        if (open) {
          this.rightNav.open();
        } else {
          this.rightNav.close();
        }

        this.changeDetectorRef.detectChanges();
      }));

    this.subscriptions.push(this.headerService.getHeaderTitle()
      .subscribe((title: HeaderTitle) => {
        if (title) {
          this.sidenavContainerClasses.push('has-header');
        } else {
          this.sidenavContainerClasses = this.sidenavContainerClasses.filter((c: string) => c !== 'has-header');
        }

        this.changeDetectorRef.detectChanges();
      }));
  }

  getSmallSidenavContainerClass(): string[] {
    return [...this.sidenavContainerClasses, 'small'];
  }

}
