import { AutoUnsubscribe } from './../common/auto-unsubscribe.base';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends AutoUnsubscribe implements CanLoad {

  constructor(private authService: AuthService) {
    super();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.authService.isLoggedIn(true);
  }

}
