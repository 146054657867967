<div class="container" [ngClass.lt-sm]="'width-100'" [ngClass.gt-xs]="'max-width'">
  <div #container>
    <form [formGroup]="searchForm" (ngSubmit)="search()">

      <mat-form-field class="width-100">
        <mat-label>From</mat-label>
        <input matInput formControlName="from" [matAutocomplete]="fromAutoComplete" [required]="true">
        <mat-autocomplete #fromAutoComplete>
          <mat-option *ngFor="let airport of fromAirports | async" [value]="airport.code">
            {{airport.code}} - {{airport.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-label>To</mat-label>
        <input matInput formControlName="to" [matAutocomplete]="toAutoComplete" [required]="true">
        <mat-autocomplete #toAutoComplete>
          <mat-option *ngFor="let airport of toAirports | async" [value]="airport.code">
            {{airport.code}} - {{airport.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startPicker" [required]="true" [max]="searchForm.get('endDate').value"
          formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endPicker" [min]="searchForm.get('startDate').value" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-select formControlName="cabin" [required]="true">
          <mat-option *ngFor="let cabin of cabins" [value]="cabin.value">
            {{cabin.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="width-100">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" placeholder="Quantity" min="1" formControlName="quantity">
      </mat-form-field>

      <ng-container formArrayName="engines">
        <mat-slide-toggle *ngFor="let engine of engines; let i = index" class="width-100" [formControlName]="i"
          [labelPosition]="'before'">{{engine.airlineName}}</mat-slide-toggle>
      </ng-container>

      <div class="divider">
        <mat-divider></mat-divider>
      </div>

      <mat-slide-toggle class="width-100" formControlName="partners" [labelPosition]="'before'">Include Partner Awards
      </mat-slide-toggle>

      <mat-slide-toggle class="width-100" formControlName="roundtrip" [labelPosition]="'before'">Roundtrip
      </mat-slide-toggle>

      <button mat-raised-button class="width-100 submit" color="primary" type="submit"
        [disabled]="!searchForm.valid">Search</button>

    </form>
  </div>
</div>
