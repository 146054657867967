<app-header [appTitle]="appTitle" (toggleLeftNav)="leftNav.toggle()"></app-header>
<mat-sidenav-container class="sidenav-container" [ngClass]="sidenavContainerClasses"
  [ngClass.lt-sm]="getSmallSidenavContainerClass()">
  <mat-sidenav #leftNav [mode]="'side'" [ngClass.lt-sm]="'width-100'">
    <app-left-nav></app-left-nav>
  </mat-sidenav>
  <mat-sidenav #rightNav position="end" [ngClass.lt-sm]="'width-100'">
    <app-right-nav></app-right-nav>
  </mat-sidenav>
  <div class="container" [ngClass.lt-sm]="'small'">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
