import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SidenavService } from '../sidenav.service';
import { AutoUnsubscribe } from '../../../common/auto-unsubscribe.base';
import { SidenavContent } from '../../../models/sidenav-content';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-right-nav',
  templateUrl: './right-nav.component.html',
  styleUrls: ['./right-nav.component.scss']
})
export class RightNavComponent extends AutoUnsubscribe {

  @ViewChild('contentAnchor', { read: ViewContainerRef })
  public contentAnchor: ViewContainerRef;

  @ViewChild('contentContainer', { read: ViewContainerRef })
  public contentContainer: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.subscriptions.push(this.sidenavService.getRightNavContent()
      .subscribe((content: SidenavContent) => {
        this.setContent(content);
      }));
  }

  closeSidePanel() {
    this.sidenavService.closeRightNav();
  }

  private setContent(content: SidenavContent) {
    if (this.contentAnchor) {
      this.contentAnchor.clear();

      if (content.componentType) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(content.componentType);
        const componentRef = this.contentAnchor.createComponent(componentFactory);

        if (content.componentInput) {
          for (const input in content.componentInput) {
            if (content.componentInput.hasOwnProperty(input)) {
              componentRef.instance[input] = content.componentInput[input];
            }
          }
        }

        this.changeDetectorRef.detectChanges();
      }
    }
  }

}
