import { HeaderTitle } from './../models/header-title';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {

  @Input()
  public headerTitle: HeaderTitle;

  @Input()
  public small: boolean;

}
