export enum Cabin {

  Economy = 'economy',

  PremiumEconomy = 'premium',

  Business = 'business',

  First = 'first'

}
