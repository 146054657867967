import { EngineCredentials } from './../models/engine-credentials';
import { AppConfig } from './../models/app-config';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Engine } from './../models/engine';
import { AirlineData } from './../data/airline-data';
import { Airline } from './../models/airline';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public appConfig: AppConfig;

  public engines: Engine[];

  constructor(private firestore: AngularFirestore) { }

  async deleteConfig(engineCode: string) {
    const configToDelete: any = {};
    configToDelete[engineCode] = firebase.firestore.FieldValue.delete();

    await this.firestore.doc('config/engines').update(configToDelete);
    await this.loadEngineConfig();
  }

  async loadConfig() {
    await this.loadAppConfig();
    await this.loadEngineConfig();
  }

  async updateConfig(engine: Engine) {
    const airlineCode: string = engine.airlineCode;
    const configToUpdate: any = {};
    configToUpdate[airlineCode] = engine;

    delete configToUpdate[airlineCode].airlineCode;

    await this.firestore.doc('config/engines').update(configToUpdate);
    await this.loadEngineConfig();
  }

  async updateCredentials(credentials: EngineCredentials) {
    const airlineCode: string = credentials.airlineCode;
    const credentialsToUpdate: any = {};
    credentialsToUpdate[airlineCode] = credentials;

    delete credentialsToUpdate[airlineCode].airlineCode;

    await this.firestore.doc('config/credentials').update(credentialsToUpdate);
  }

  private async loadAppConfig() {
    const appConfigData: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = await this.firestore.doc('config/app').get().toPromise();
    this.appConfig = appConfigData.data() as AppConfig;
  }

  private async loadEngineConfig() {
    const engineConfigData: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = await this.firestore.doc('config/engines').get().toPromise();
    const engineConfig: firebase.firestore.DocumentData = engineConfigData.data();

    const engines: Engine[] = [];

    for (const engineCode in engineConfig) {
      if (engineConfig.hasOwnProperty(engineCode)) {
        const engine: Engine = engineConfig[engineCode];
        engine.airlineCode = engineCode;
        engine.airlineName = AirlineData.find((airlineData: Airline) => airlineData.iata === engineCode)?.name ?? 'Unknown';
        engine.name = engine.name ?? 'Unknown';

        engines.push(engine);
      }
    }

    engines.sort((a: Engine, b: Engine) => a.airlineName.toLowerCase().localeCompare(b.airlineName.toLowerCase()));

    this.engines = engines;
  }

}
