<div>
  <mat-tab-group>
    <mat-tab label="Find Awards">
      <app-find></app-find>
    </mat-tab>
    <mat-tab label="Search for Awards">
      <app-search></app-search>
    </mat-tab>
  </mat-tab-group>
  <div class="content">
    <div #contentContainer>
      <div #contentAnchor></div>
    </div>
  </div>
</div>
