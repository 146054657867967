import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidenavContent } from '../../models/sidenav-content';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private leftNavContentSubject: BehaviorSubject<SidenavContent>;

  private rightNavContentSubject: BehaviorSubject<SidenavContent>;

  private leftNavOpenSubject: BehaviorSubject<boolean>;

  private rightNavOpenSubject: BehaviorSubject<boolean>;

  constructor() {
    this.leftNavContentSubject = new BehaviorSubject(null);
    this.rightNavContentSubject = new BehaviorSubject(null);
    this.leftNavOpenSubject = new BehaviorSubject(false);
    this.rightNavOpenSubject = new BehaviorSubject(false);
  }

  clearLeftNavContent() {
    this.leftNavContentSubject.next({ componentType: null, componentInput: null });
  }

  clearRightNavContent() {
    this.rightNavContentSubject.next({ componentType: null, componentInput: null });
  }

  closeLeftNav() {
    this.leftNavOpenSubject.next(false);
  }

  closeRightNav() {
    this.rightNavOpenSubject.next(false);
  }

  getLeftNavContent(): Observable<SidenavContent> {
    return this.leftNavContentSubject.asObservable();
  }

  getLeftNavOpen(): Observable<boolean> {
    return this.leftNavOpenSubject.asObservable();
  }

  getRightNavContent(): Observable<SidenavContent> {
    return this.rightNavContentSubject.asObservable();
  }

  getRightNavOpen(): Observable<boolean> {
    return this.rightNavOpenSubject.asObservable();
  }

  openLeftNav() {
    this.leftNavOpenSubject.next(true);
  }

  openRightNav() {
    this.rightNavOpenSubject.next(true);
  }

  setLeftNavContent(componentType: Type<any>, componentInput?: any) {
    this.leftNavContentSubject.next({ componentType, componentInput });
  }

  setRightNavContent(componentType: Type<any>, componentInput?: any) {
    this.rightNavContentSubject.next({ componentType, componentInput });
  }

}
