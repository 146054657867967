import { Airline } from '../models/airline';

export const AirlineData: Airline[] = [
  { iata: '&T', icao: 'T&O', name: 'Tom\'s & co airliners', callsign: 'T&', aliases: ['Tom\'s air'] },
  { iata: '0B', icao: 'BMS', name: 'Blue Air', callsign: 'BLUE MESSENGER' },
  { iata: '0D', icao: 'DWT', name: 'Darwin Airline', callsign: 'DARWIN' },
  { iata: '0P', icao: 'PYB', name: 'All America BOPY', callsign: 'BOPY', aliases: ['All America BOPY'] },
  { iata: '0X', icao: 'CX0', name: 'Copenhagen Express', callsign: 'Copex' },
  { iata: '12', icao: 'N12', name: '12 North', callsign: '12N' },
  { iata: '13', icao: 'EAV', name: 'Eastern Atlantic Virtual Airlines', callsign: 'EAVA' },
  { iata: '1I', icao: 'EJA', name: 'NetJets', callsign: 'EXECJET' },
  { iata: '1I', icao: 'NVR', name: 'Novair', callsign: 'NAVIGATOR' },
  { iata: '1T', icao: 'RNX', name: '1Time Airline', callsign: 'NEXTIME' },
  { iata: '20', icao: 'RNE', name: 'Air Salone', callsign: 'AIR SALONE' },
  { iata: '2B', icao: 'ARD', name: 'Aerocondor', callsign: 'AEROCONDOR' },
  { iata: '2C', icao: null, name: 'SNCF', callsign: null },
  { iata: '2F', icao: 'FTA', name: 'Frontier Flying Service', callsign: 'FRONTIER-AIR' },
  { iata: '2J', icao: 'VBW', name: 'Air Burkina', callsign: 'BURKINA' },
  { iata: '2K', icao: 'GLG', name: 'Aerolineas Galapagos (Aerogal)', callsign: 'AEROGAL' },
  { iata: '2L', icao: 'OAW', name: 'Helvetic Airways', callsign: 'HELVETIC' },
  { iata: '2M', icao: 'MDV', name: 'Moldavian Airlines', callsign: 'MOLDAVIAN' },
  { iata: '2N', icao: 'NTJ', name: 'NextJet', callsign: 'NEXTJET' },
  { iata: '2P', icao: 'GAP', name: 'Air Philippines', callsign: 'ORIENT PACIFIC' },
  { iata: '2Q', icao: 'SNC', name: 'Air Cargo Carriers', callsign: 'NIGHT CARGO' },
  { iata: '2U', icao: 'GIP', name: 'Air Guinee Express', callsign: 'FUTURE EXPRESS' },
  { iata: '2W', icao: 'WLC', name: 'Welcome Air', callsign: 'WELCOMEAIR' },
  { iata: '3G', icao: 'AYZ', name: 'Atlant-Soyuz Airlines', callsign: 'ATLANT-SOYUZ' },
  { iata: '3K', icao: 'JSA', name: 'Jetstar Asia Airways', callsign: 'JETSTAR ASIA' },
  { iata: '3L', icao: 'ISK', name: 'Intersky', callsign: 'INTERSKY' },
  { iata: '3P', icao: 'TNM', name: 'Tiara Air', callsign: 'TIARA' },
  { iata: '3Q', icao: 'CYH', name: 'Yunnan Airlines', callsign: 'YUNNAN' },
  { iata: '3R', icao: 'GAI', name: 'Moskovia Airlines', callsign: 'GROMOV AIRLINE' },
  { iata: '3S', icao: 'GUY', name: 'Air Antilles', callsign: 'GREEN BIRD' },
  { iata: '3T', icao: 'URN', name: 'Turan Air', callsign: 'TURAN' },
  { iata: '3U', icao: 'CSC', name: 'Sichuan Airlines', callsign: 'SI CHUAN' },
  { iata: '3X', icao: 'JAC', name: 'Japan Air Commuter', callsign: 'COMMUTER' },
  { iata: '4C', icao: 'ARE', name: 'Aires', callsign: ' Aerovias de Integracion Regional' },
  { iata: '4D', icao: 'ASD', name: 'Air Sinai', callsign: 'AIR SINAI' },
  { iata: '4G', icao: 'GZP', name: 'Gazpromavia', callsign: 'GAZPROMAVIA' },
  { iata: '4H', icao: 'UBD', name: 'United Airways', callsign: 'UNITED BANGLADESH' },
  { iata: '4K', icao: 'AAS', name: 'Askari Aviation', callsign: 'AL-AAS' },
  { iata: '4L', icao: 'MJX', name: 'Euroline', callsign: 'GEO-LINE' },
  { iata: '4M', icao: 'DSM', name: 'LAN Argentina', callsign: 'LAN AR' },
  { iata: '4N', icao: 'ANT', name: 'Air North Charter - Canada', callsign: 'AIR NORTH' },
  { iata: '4R', icao: 'HHI', name: 'Hamburg International', callsign: 'HAMBURG JET' },
  { iata: '4T', icao: 'BHP', name: 'Belair Airlines', callsign: 'BELAIR' },
  { iata: '4U', icao: 'GWI', name: 'Germanwings', callsign: 'GERMAN WINGS' },
  { iata: '5B', icao: 'BSX', name: 'Bassaka airlines', callsign: '5B' },
  { iata: '5C', icao: 'ICL', name: 'CAL Cargo Air Lines', callsign: 'CAL' },
  { iata: '5D', icao: 'SLI', name: 'Aerolitoral', callsign: 'COSTERA' },
  { iata: '5D', icao: 'UDC', name: 'DonbassAero', callsign: 'DONBASS AERO' },
  { iata: '5G', icao: 'SSV', name: 'Skyservice Airlines', callsign: 'SKYTOUR' },
  { iata: '5H', icao: 'FFV', name: 'Fly540', callsign: 'SWIFT TANGO' },
  { iata: '5J', icao: 'CEB', name: 'Cebu Pacific', callsign: 'CEBU AIR' },
  { iata: '5L', icao: 'RSU', name: 'Aerosur', callsign: 'AEROSUR' },
  { iata: '5M', icao: 'SIB', name: 'Sibaviatrans', callsign: 'SIBAVIA' },
  { iata: '5N', icao: 'AUL', name: 'Aeroflot-Nord', callsign: 'DVINA' },
  { iata: '5T', icao: 'MPE', name: 'Canadian North', callsign: 'EMPRESS' },
  { iata: '5W', icao: 'AEU', name: 'Astraeus', callsign: 'FLYSTAR' },
  { iata: '5Y', icao: 'GTI', name: 'Atlas Air', callsign: 'GIANT' },
  { iata: '6A', icao: 'CHP', name: 'Consorcio Aviaxsa', callsign: 'AVIACSA' },
  { iata: '6B', icao: 'BLX', name: 'TUIfly Nordic', callsign: 'BLUESCAN' },
  { iata: '6E', icao: 'IGO', name: 'IndiGo Airlines', callsign: 'IFLY' },
  { iata: '6G', icao: 'AWW', name: 'Air Wales', callsign: 'RED DRAGON' },
  { iata: '6H', icao: 'ISR', name: 'Israir', callsign: 'ISRAIR' },
  { iata: '6J', icao: 'SNJ', name: 'Skynet Asia Airways', callsign: 'NEWSKY' },
  { iata: '6K', icao: 'RIT', name: 'Asian Spirit', callsign: 'ASIAN SPIRIT' },
  { iata: '6R', icao: 'DRU', name: 'Alrosa Mirny Air Enterprise', callsign: 'MIRNY' },
  { iata: '6W', icao: 'SOV', name: 'Saratov Aviation Division', callsign: 'SARATOV AIR' },
  { iata: '7B', icao: 'KJC', name: 'Krasnojarsky Airlines', callsign: 'KRASNOJARSKY AIR' },
  { iata: '7C', icao: 'JJA', name: 'Jeju Air', callsign: 'JEJU AIR' },
  { iata: '7E', icao: 'AWU', name: 'Aeroline GmbH', callsign: 'SYLT-AIR' },
  { iata: '7G', icao: 'SFJ', name: 'Star Flyer', callsign: 'STARFLYER' },
  { iata: '7H', icao: 'ERR', name: 'Era Alaska', callsign: 'ERAH' },
  { iata: '7K', icao: 'KGL', name: 'Kogalymavia Air Company', callsign: 'KOGALYM' },
  { iata: '7L', icao: 'ERO', name: 'Sun D\'Or', callsign: 'ECHO ROMEO' },
  { iata: '7P', icao: 'BTV', name: 'Metro Batavia', callsign: 'BATAVIA' },
  { iata: '7R', icao: 'SJM', name: 'Svyaz Rossiya', callsign: 'RussianConnecty', aliases: ['Russian Commuter '] },
  { iata: '7T', icao: 'AGV', name: 'Air Glaciers', callsign: 'AIR GLACIERS' },
  { iata: '8A', icao: 'BMM', name: 'Atlas Blue', callsign: 'ATLAS BLUE' },
  { iata: '8E', icao: 'BRG', name: 'Bering Air', callsign: 'BERING AIR' },
  { iata: '8F', icao: 'STP', name: 'STP Airways', callsign: 'SAOTOME AIRWAYS' },
  { iata: '8H', icao: 'HFR', name: 'Heli France', callsign: 'HELIFRANCE' },
  { iata: '8J', icao: 'JFU', name: 'Jet4You', callsign: 'ARGAN' },
  { iata: '8M', icao: 'MMM', name: 'Myanmar Airways International', callsign: 'assignment postponed' },
  { iata: '8M', icao: 'MXL', name: 'Maxair', callsign: 'MAXAIR' },
  { iata: '8N', icao: 'NKF', name: 'Barents AirLink', callsign: 'NORDFLIGHT' },
  { iata: '8P', icao: 'PCO', name: 'Pacific Coastal Airline', callsign: 'PASCO' },
  { iata: '8Q', icao: 'OHY', name: 'Onur Air', callsign: 'ONUR AIR' },
  { iata: '8R', icao: 'TIB', name: 'TRIP Linhas A', callsign: 'TRIP' },
  { iata: '8U', icao: 'AAW', name: 'Afriqiyah Airways', callsign: 'AFRIQIYAH' },
  { iata: '8V', icao: 'ACP', name: 'Astral Aviation', callsign: 'ASTRAL CARGO' },
  { iata: '8Z', icao: 'WVL', name: 'Wizz Air Hungary', callsign: 'WIZZBUL' },
  { iata: '9E', icao: 'FLG', name: 'Pinnacle Airlines', callsign: 'FLAGSHIP' },
  { iata: '9K', icao: 'KAP', name: 'Cape Air', callsign: 'CAIR' },
  { iata: '9L', icao: 'CJC', name: 'Colgan Air', callsign: 'COLGAN' },
  { iata: '9Q', icao: 'PBA', name: 'PB Air', callsign: 'PEEBEE AIR' },
  { iata: '9R', icao: 'NSE', name: 'SATENA', callsign: 'SATENA' },
  { iata: '9S', icao: 'CQH', name: 'Spring Airlines', callsign: 'AIR SPRING' },
  { iata: '9T', icao: 'ABS', name: 'Transwest Air', callsign: 'ATHABASKA' },
  { iata: '9U', icao: 'MLD', name: 'Air Moldova', callsign: 'AIR MOLDOVA' },
  { iata: '9W', icao: 'JAI', name: 'Jet Airways', callsign: 'JET AIRWAYS' },
  { iata: '9Y', icao: 'KZK', name: 'Air Kazakhstan', callsign: 'Kazakh' },
  { iata: 'A1', icao: 'A1F', name: 'Atifly', callsign: 'atifly' },
  { iata: 'A3', icao: 'AEE', name: 'Aegean Airlines', callsign: 'AEGEAN', alliances: ['*A'] },
  { iata: 'A4', icao: 'SWD', name: 'Southern Winds Airlines', callsign: 'SOUTHERN WINDS' },
  { iata: 'A5', icao: 'HOP', name: 'Société HOP!', callsign: 'AIR HOP', aliases: ['HOP!'] },
  { iata: 'A7', icao: 'MPD', name: 'Air Plus Comet', callsign: 'RED COMET' },
  { iata: 'A9', icao: 'TGZ', name: 'Georgian Airways', callsign: 'TAMAZI', aliases: ['Airzena Georgian Airlines'] },
  { iata: 'AA', icao: 'AAL', name: 'American Airlines', callsign: 'AMERICAN', alliances: ['OW'] },
  { iata: 'AB', icao: 'BER', name: 'Air Berlin', callsign: 'AIR BERLIN' },
  { iata: 'AC', icao: 'ACA', name: 'Air Canada', callsign: 'AIR CANADA', alliances: ['*A'] },
  { iata: 'AE', icao: 'MDA', name: 'Mandarin Airlines', callsign: 'Mandarin' },
  { iata: 'AF', icao: 'AFR', name: 'Air France', callsign: 'AIRFRANS', alliances: ['ST'] },
  { iata: 'AH', icao: 'DAH', name: 'Air Algerie', callsign: 'AIR ALGERIE' },
  { iata: 'AI', icao: 'AIC', name: 'Air India Limited', callsign: 'AIRINDIA', aliases: ['Air India'], alliances: ['*A'] },
  { iata: 'AJ', icao: 'NIG', name: 'Aero Contractors', callsign: 'AEROLINE' },
  { iata: 'AK', icao: 'AXM', name: 'AirAsia', callsign: 'ASIAN EXPRESS', aliases: ['Air Asia'] },
  { iata: 'AL', icao: 'SYX', name: 'Skywalk Airlines', callsign: 'SKYWAY-EX' },
  { iata: 'AM', icao: 'AMX', name: 'AeroMéxico', callsign: 'AEROMEXICO', aliases: ['Aeromexico'], alliances: ['ST'] },
  { iata: 'AN', icao: 'AAA', name: 'Ansett Australia', callsign: 'ANSETT' },
  { iata: 'AP', icao: 'ADH', name: 'Air One', callsign: 'HERON' },
  { iata: 'AQ', icao: 'AAH', name: 'Aloha Airlines', callsign: 'ALOHA' },
  { iata: 'AR', icao: 'ARG', name: 'Aerolineas Argentinas', callsign: 'ARGENTINA', alliances: ['ST'] },
  { iata: 'AS', icao: 'ASA', name: 'Alaska Airlines', callsign: 'ALASKA' },
  { iata: 'AT', icao: 'RAM', name: 'Royal Air Maroc', callsign: 'ROYALAIR MAROC' },
  { iata: 'AU', icao: 'AUT', name: 'Austral Lineas Aereas', callsign: 'AUSTRAL' },
  { iata: 'AV', icao: 'AVA', name: 'Avianca', callsign: 'AVIANCA', aliases: ['Avianca - Aerovias Nacionales de Colombia S.A.'], alliances: ['*A'] },
  { iata: 'AW', icao: 'AWM', name: 'Asian Wings Airways', callsign: 'Asian Star' },
  { iata: 'AX', icao: 'LOF', name: 'Trans States Airlines', callsign: 'WATERSKI' },
  { iata: 'AY', icao: 'FIN', name: 'Finnair', callsign: 'FINNAIR', aliases: ['Finnair Oyj'], alliances: ['OW'] },
  { iata: 'AZ', icao: 'AZA', name: 'Alitalia', callsign: 'ALITALIA', alliances: ['ST'] },
  { iata: 'B0', icao: '666', name: 'Aws express', callsign: 'aws' },
  { iata: 'B1', icao: 'BA1', name: 'Baltic Air lines', callsign: 'Baltic' },
  { iata: 'B2', icao: 'BRU', name: 'Belavia', callsign: 'BELARUS AVIA', aliases: ['Belavia Belarusian Airlines'] },
  { iata: 'B3', icao: 'BLV', name: 'Bellview Airlines', callsign: 'BELLVIEW AIRLINES' },
  { iata: 'B4', icao: 'GSM', name: 'Flyglobespan', callsign: 'GLOBESPAN' },
  { iata: 'B5', icao: 'FLT', name: 'Flightline', callsign: 'FLIGHTLINE' },
  { iata: 'B6', icao: 'JBU', name: 'JetBlue Airways', callsign: 'JETBLUE' },
  { iata: 'B7', icao: 'UIA', name: 'Uni Air', callsign: 'Glory' },
  { iata: 'B8', icao: 'ERT', name: 'Eritrean Airlines', callsign: 'ERITREAN' },
  { iata: 'B9', icao: 'BGD', name: 'Air Bangladesh', callsign: 'AIR BANGLA' },
  { iata: 'BA', icao: 'BAW', name: 'British Airways', callsign: 'SPEEDBIRD', alliances: ['OW'] },
  { iata: 'BB', icao: 'SBS', name: 'Seaborne Airlines', callsign: 'SEABORNE' },
  { iata: 'BC', icao: 'SKY', name: 'Skymark Airlines', callsign: 'SKYMARK' },
  { iata: 'BE', icao: 'BEE', name: 'Flybe', callsign: 'JERSEY' },
  { iata: 'BF', icao: 'RSR', name: 'Aero-Service', callsign: 'CONGOSERV' },
  { iata: 'BG', icao: 'BBC', name: 'Biman Bangladesh Airlines', callsign: 'BANGLADESH' },
  { iata: 'BI', icao: 'RBA', name: 'Royal Brunei Airlines', callsign: 'BRUNEI', aliases: ['Royal Brunei Airlines Sdn. Bhd.'] },
  { iata: 'BJ', icao: 'LBT', name: 'Nouvel Air Tunisie', callsign: 'NOUVELAIR' },
  { iata: 'BK', icao: 'PDC', name: 'Potomac Air', callsign: 'DISTRICT' },
  { iata: 'BL', icao: 'PIC', name: 'Jetstar Pacific', callsign: 'PACIFIC AIRLINES', aliases: ['Pacific Airlines', 'Pacific Airline Co.'] },
  { iata: 'BM', icao: 'BMR', name: 'Flybmi', callsign: 'MIDLAND', aliases: ['bmi', 'bmi British Midland', 'bmi regional'] },
  { iata: 'BP', icao: 'BOT', name: 'Air Botswana', callsign: 'BOTSWANA' },
  { iata: 'BR', icao: 'EVA', name: 'EVA Air', callsign: 'EVA', alliances: ['*A'] },
  { iata: 'BS', icao: 'BIH', name: 'British International Helicopters', callsign: 'BRINTEL' },
  { iata: 'BT', icao: 'BTI', name: 'Air Baltic', callsign: 'AIRBALTIC', aliases: ['Air Baltic Corporation S.A.'] },
  { iata: 'BV', icao: 'BPA', name: 'Blue Panorama Airlines', callsign: 'BLUE PANOROMA' },
  { iata: 'BW', icao: 'BWA', name: 'Caribbean Airlines', callsign: 'CARIBBEAN AIRLINES' },
  { iata: 'BX', icao: 'ABL', name: 'Air Busan', callsign: 'Air Busan' },
  { iata: 'BY', icao: 'TOM', name: 'Thomsonfly', callsign: 'TOMSON' },
  { iata: 'BZ', icao: 'BSA', name: 'Black Stallion Airways', callsign: 'Stallion' },
  { iata: 'C0', icao: 'CLW', name: 'Centralwings', callsign: 'CENTRALWINGS' },
  { iata: 'C1', icao: 'CA1', name: 'CanXpress', callsign: 'CAX' },
  { iata: 'C3', icao: 'KIS', name: 'Contact Air', callsign: 'CONTACTAIR', aliases: ['Contactair'] },
  { iata: 'C4', icao: 'LIX', name: 'LionXpress', callsign: 'LIX', aliases: ['lionXpress'] },
  { iata: 'C5', icao: 'UCA', name: 'CommutAir', callsign: 'COMMUTAIR' },
  { iata: 'C9', icao: 'RUS', name: 'Cirrus Airlines', callsign: 'CIRRUS AIR' },
  { iata: 'CA', icao: 'CCA', name: 'Air China Limited', callsign: 'AIR CHINA', aliases: ['Air China'], alliances: ['*A'] },
  { iata: 'CC', icao: 'ABD', name: 'Air Atlanta Icelandic', callsign: 'ATLANTA' },
  { iata: 'CE', icao: 'NTW', name: 'Nationwide Airlines', callsign: 'NATIONWIDE' },
  { iata: 'CF', icao: 'SDR', name: 'City Airline', callsign: 'SWEDESTAR' },
  { iata: 'CG', icao: 'TOK', name: 'Airlines PNG', callsign: 'BALUS' },
  { iata: 'CH', icao: 'BMJ', name: 'Bemidji Airlines', callsign: 'BEMIDJI' },
  { iata: 'CI', icao: 'CAL', name: 'China Airlines', callsign: 'DYNASTY', alliances: ['ST'] },
  { iata: 'CJ', icao: 'CFE', name: 'BA CityFlyer', callsign: 'FLYER' },
  { iata: 'CL', icao: 'CLH', name: 'Lufthansa CityLine', callsign: 'HANSALINE' },
  { iata: 'CM', icao: 'CMP', name: 'Copa Airlines', callsign: 'COPA', aliases: ['COPA'], alliances: ['*A'] },
  { iata: 'CN', icao: 'YCP', name: 'Canadian National Airways', callsign: 'CaNational' },
  { iata: 'CO', icao: 'COA', name: 'Continental Airlines', callsign: 'CONTINENTAL' },
  { iata: 'CP', icao: 'CDN', name: 'Canadian Airlines', callsign: 'CANADIAN' },
  { iata: 'CP', icao: 'CPZ', name: 'Compass Airlines', callsign: 'Compass Rose' },
  { iata: 'CQ', icao: 'KOL', name: 'SOCHI AIR', callsign: 'SLOW FROG', aliases: ['SOCHI'] },
  { iata: 'CS', icao: 'CMI', name: 'Continental Micronesia', callsign: 'AIR MIKE' },
  { iata: 'CU', icao: 'CUB', name: 'Cubana de Aviación', callsign: 'CUBANA' },
  { iata: 'CV', icao: 'CVA', name: 'Air Chathams', callsign: 'CHATHAM' },
  { iata: 'CW', icao: 'CWM', name: 'Air Marshall Islands', callsign: 'AIR MARSHALLS' },
  { iata: 'CX', icao: 'CPA', name: 'Cathay Pacific', callsign: 'CATHAY', aliases: ['Cathay Pacific Airways', 'Cathay Pacific Airways Ltd.'], alliances: ['OW'] },
  { iata: 'CY', icao: 'CYP', name: 'Cyprus Airways', callsign: 'CYPRUS' },
  { iata: 'CZ', icao: 'CSN', name: 'China Southern Airlines', callsign: 'CHINA SOUTHERN', alliances: ['ST'] },
  { iata: 'D1', icao: 'MDO', name: 'Domenican Airlines', callsign: 'Domenican', aliases: ['Domenican'] },
  { iata: 'D3', icao: 'DAO', name: 'Daallo Airlines', callsign: 'DALO AIRLINES' },
  { iata: 'D6', icao: 'ILN', name: 'Interair South Africa', callsign: 'INLINE' },
  { iata: 'D7', icao: 'XAX', name: 'AirAsia X', callsign: 'XANADU', aliases: ['FlyAsianXpress'] },
  { iata: 'D8', icao: 'DJB', name: 'Djibouti Airlines', callsign: 'DJIBOUTI AIR' },
  { iata: 'D9', icao: 'DNV', name: 'Aeroflot-Don', callsign: 'DONAVIA' },
  { iata: 'DB', icao: 'BZH', name: 'Brit Air', callsign: 'BRITAIR' },
  { iata: 'DC', icao: 'GAO', name: 'Golden Air', callsign: 'GOLDEN' },
  { iata: 'DD', icao: 'NOK', name: 'Nok Air', callsign: 'NOK AIR' },
  { iata: 'DE', icao: 'CFG', name: 'Condor Flugdienst', callsign: 'CONDOR' },
  { iata: 'DF', icao: 'MJG', name: 'Michael Airlines', callsign: 'MJG', aliases: ['Javi'] },
  { iata: 'DG', icao: 'SRQ', name: 'South East Asian Airlines', callsign: 'SEAIR' },
  { iata: 'DH', icao: 'DSY', name: 'Dennis Sky', callsign: 'DSY', aliases: ['Dennis Sky Holding'] },
  { iata: 'DI', icao: 'BAG', name: 'dba', callsign: 'SPEEDWAY' },
  { iata: 'DL', icao: 'DAL', name: 'Delta Air Lines', callsign: 'DELTA', alliances: ['ST'] },
  { iata: 'DO', icao: 'DOA', name: 'Dominicana de Aviaci', callsign: 'DOMINICANA' },
  { iata: 'DP', icao: 'FCA', name: 'First Choice Airways', callsign: 'JETSET' },
  { iata: 'DR', icao: 'BIE', name: 'Air Mediterranee', callsign: 'MEDITERRANEE' },
  { iata: 'DT', icao: 'DTA', name: 'TAAG Angola Airlines', callsign: 'DTA' },
  { iata: 'DU', icao: 'NLH', name: 'Norwegian Long Haul AS', callsign: 'NORSTAR' },
  { iata: 'DV', icao: 'VSV', name: 'Scat Air', callsign: 'VLASTA' },
  { iata: 'DX', icao: 'DTR', name: 'DAT Danish Air Transport', callsign: 'DANISH' },
  { iata: 'DY', icao: 'NAX', name: 'Norwegian Air Shuttle', callsign: 'NOR SHUTTLE' },
  { iata: 'E1', icao: 'ES2', name: 'Usa Sky Cargo', callsign: 'USKY', aliases: ['USky'] },
  { iata: 'E3', icao: 'DMO', name: 'Domodedovo Airlines', callsign: 'DOMODEDOVO' },
  { iata: 'E8', icao: 'GTA', name: 'City Airways', callsign: 'CITY AIR' },
  { iata: 'EA', icao: 'EAL', name: 'European Air Express', callsign: 'STAR WING' },
  { iata: 'EC', icao: 'TWN', name: 'Avialeasing Aviation Company', callsign: 'TWINARROW' },
  { iata: 'ED', icao: 'ABQ', name: 'Airblue', callsign: 'PAKBLUE' },
  { iata: 'EF', icao: 'EFA', name: 'Far Eastern Air Transport', callsign: 'Far Eastern' },
  { iata: 'EG', icao: 'JAA', name: 'Japan Asia Airways', callsign: 'ASIA' },
  { iata: 'EI', icao: 'EIN', name: 'Aer Lingus', callsign: 'SHAMROCK' },
  { iata: 'EJ', icao: 'NEA', name: 'New England Airlines', callsign: 'NEW ENGLAND' },
  { iata: 'EK', icao: 'UAE', name: 'Emirates', callsign: 'EMIRATES', aliases: ['Emirates Airlines'] },
  { iata: 'EL', icao: 'ANK', name: 'Air Nippon', callsign: 'ANK AIR' },
  { iata: 'EM', icao: 'AEB', name: 'Aero Benin', callsign: 'AEROBEN' },
  { iata: 'EN', icao: 'DLA', name: 'Air Dolomiti', callsign: 'DOLOMOTI' },
  { iata: 'EO', icao: 'LHN', name: 'Express One International', callsign: 'LONGHORN' },
  { iata: 'EQ', icao: 'TAE', name: 'TAME', callsign: 'TAME' },
  { iata: 'ES', icao: 'EUV', name: 'EuropeSky', callsign: 'EuropeSky' },
  { iata: 'ET', icao: 'ETH', name: 'Ethiopian Airlines', callsign: 'ETHIOPIAN', aliases: ['Ethiopian Airlines Enterprise'], alliances: ['*A'] },
  { iata: 'EU', icao: 'EEA', name: 'Empresa Ecuatoriana De Aviacion', callsign: 'ECUATORIANA' },
  { iata: 'EV', icao: 'ASQ', name: 'Atlantic Southeast Airlines', callsign: 'ACEY' },
  { iata: 'EW', icao: 'EWG', name: 'Eurowings', callsign: 'EUROWINGS' },
  { iata: 'EY', icao: 'ETD', name: 'Etihad Airways', callsign: 'ETIHAD' },
  { iata: 'EZ', icao: 'EIA', name: 'Evergreen International Airlines', callsign: 'EVERGREEN' },
  { iata: 'F1', icao: 'FBL', name: 'Fly Brasil', callsign: 'FBL', aliases: ['Fly Brasil'] },
  { iata: 'F7', icao: 'BBO', name: 'Flybaboo', callsign: 'BABOO' },
  { iata: 'F9', icao: 'FFT', name: 'Frontier Airlines', callsign: 'FRONTIER FLIGHT' },
  { iata: 'FA', icao: '4AA', name: 'Epic Holiday', callsign: 'Epic', aliases: ['Epic Holidays'] },
  { iata: 'FB', icao: 'LZB', name: 'Bulgaria Air', callsign: 'FLYING BULGARIA' },
  { iata: 'FC', icao: 'WBA', name: 'Finncomm Airlines', callsign: 'WESTBIRD' },
  { iata: 'FD', icao: 'AIQ', name: 'Thai AirAsia', callsign: 'THAI ASIA', aliases: ['Thai Air Asia'] },
  { iata: 'FG', icao: 'AFG', name: 'Ariana Afghan Airlines', callsign: 'ARIANA' },
  { iata: 'FH', icao: 'FHI', name: 'FlyHigh Airlines Ireland (FH)', callsign: 'FLYHIRELAND' },
  { iata: 'FI', icao: 'ICE', name: 'Icelandair', callsign: 'ICEAIR' },
  { iata: 'FJ', icao: 'FJI', name: 'Air Pacific', callsign: 'PACIFIC' },
  { iata: 'FK', icao: 'WTA', name: 'Africa West', callsign: 'WEST TOGO' },
  { iata: 'FL', icao: 'TRS', name: 'AirTran Airways', callsign: 'CITRUS' },
  { iata: 'FM', icao: 'CSH', name: 'Shanghai Airlines', callsign: 'SHANGHAI AIR', aliases: ['Shanghai Airlines Co. Ltd.'] },
  { iata: 'FO', icao: 'ATM', name: 'Airlines Of Tasmania', callsign: 'AIRTAS' },
  { iata: 'FP', icao: 'FRE', name: 'Freedom Air', callsign: 'FREEDOM' },
  { iata: 'FQ', icao: 'TCW', name: 'Thomas Cook Airlines', callsign: 'THOMAS COOK' },
  { iata: 'FR', icao: 'RYR', name: 'Ryanair', callsign: 'RYANAIR' },
  { iata: 'FS', icao: 'STU', name: 'Servicios de Transportes A', callsign: 'FUEGUINO' },
  { iata: 'FT', icao: 'SRH', name: 'Siem Reap Airways', callsign: 'SIEMREAP AIR' },
  { iata: 'FV', icao: 'SDM', name: 'Rossiya-Russian Airlines', callsign: 'PULKOVO', aliases: ['Pulkovo Aviation Enterprise'] },
  { iata: 'FW', icao: 'IBX', name: 'Ibex Airlines', callsign: 'IBEX' },
  { iata: 'FY', icao: 'FFM', name: 'Firefly', callsign: 'FIREFLY' },
  { iata: 'G0', icao: 'GHB', name: 'Ghana International Airlines', callsign: 'GHANA AIRLINES' },
  { iata: 'G1', icao: 'IG1', name: 'Indya Airline Group', callsign: 'Indya1', aliases: ['Indya1'] },
  { iata: 'G3', icao: 'CIX', name: 'City Connexion Airlines', callsign: 'CONNEXION' },
  { iata: 'G3', icao: 'GLO', name: 'Gol Transportes Aéreos', callsign: 'GOL TRANSPORTE' },
  { iata: 'G3', icao: 'SEH', name: 'Sky Express', callsign: 'AIR CRETE' },
  { iata: 'G4', icao: 'AAY', name: 'Allegiant Air', callsign: 'ALLEGIANT' },
  { iata: 'G7', icao: 'GJS', name: 'GoJet Airlines', callsign: 'GATEWAY' },
  { iata: 'G8', icao: 'GOW', name: 'Go Air', callsign: 'GOAIR' },
  { iata: 'G9', icao: 'ABY', name: 'Air Arabia', callsign: 'ARABIA' },
  { iata: 'GA', icao: 'GIA', name: 'Garuda Indonesia', callsign: 'INDONESIA', aliases: ['Garuda'], alliances: ['ST'] },
  { iata: 'GB', icao: 'BZE', name: 'BRAZIL AIR', callsign: 'BRAZIL AIR', aliases: ['BRAZIL AIR'] },
  { iata: 'GE', icao: 'TNA', name: 'TransAsia Airways', callsign: 'TransAsia' },
  { iata: 'GF', icao: 'GBA', name: 'Gulf Air Bahrain', callsign: 'GULF BAHRAIN' },
  { iata: 'GG', icao: 'GUY', name: 'Air Guyane', callsign: 'GREEN BIRD' },
  { iata: 'GI', icao: 'IKA', name: 'Itek Air', callsign: 'ITEK-AIR' },
  { iata: 'GJ', icao: 'EEU', name: 'Eurofly Service', callsign: 'EUROFLY' },
  { iata: 'GL', icao: 'GRL', name: 'Air Greenland', callsign: 'GREENLAND' },
  { iata: 'GO', icao: 'KZU', name: 'Kuzu Airlines Cargo', callsign: 'KUZU CARGO' },
  { iata: 'GP', icao: 'GDR', name: 'Gadair European Airlines', callsign: 'GADAIR' },
  { iata: 'GR', icao: 'AUR', name: 'Aurigny Air Services', callsign: 'AYLINE' },
  { iata: 'GS', icao: 'UPA', name: 'Air Foyle', callsign: 'FOYLE' },
  { iata: 'GT', icao: 'GBL', name: 'GB Airways', callsign: 'GEEBEE AIRWAYS' },
  { iata: 'GV', icao: 'ARF', name: 'Aero Flight', callsign: 'Aero Fox' },
  { iata: 'GW', icao: 'KIL', name: 'Kuban Airlines', callsign: 'AIR KUBAN' },
  { iata: 'GY', icao: 'GBK', name: 'Gabon Airlines', callsign: 'GABON AIRLINES' },
  { iata: 'H2', icao: 'SKU', name: 'Sky Airline', callsign: 'AEROSKY' },
  { iata: 'H5', icao: 'RSY', name: 'I-Fly', callsign: 'RUSSIAN SKY' },
  { iata: 'H6', icao: 'HAG', name: 'Hageland Aviation Services', callsign: 'HAGELAND' },
  { iata: 'H8', icao: 'KHB', name: 'Dalavia', callsign: 'DALAVIA' },
  { iata: 'HA', icao: 'HAL', name: 'Hawaiian Airlines', callsign: 'HAWAIIAN' },
  { iata: 'HC', icao: 'HYM', name: 'Himalayan Airlines', callsign: 'Himalayan', aliases: ['Himalaya'] },
  { iata: 'HD', icao: 'ADO', name: 'Hokkaido International Airlines', callsign: 'AIR DO' },
  { iata: 'HE', icao: 'LGW', name: 'Luftfahrtgesellschaft Walter', callsign: 'WALTER' },
  { iata: 'HF', icao: 'HLF', name: 'Hapagfly', callsign: 'HAPAG LLOYD' },
  { iata: 'HG', icao: 'NLY', name: 'Niki', callsign: 'FLYNIKI' },
  { iata: 'HH', icao: 'AHO', name: 'Air Hamburg (AHO)', callsign: 'Air Hamburg' },
  { iata: 'HM', icao: 'SEY', name: 'Air Seychelles', callsign: 'SEYCHELLES', aliases: ['Air Seychelles Limited'] },
  { iata: 'HN', icao: 'HNX', name: 'Hankook Airline', callsign: 'HNX' },
  { iata: 'HO', icao: 'DKH', name: 'Juneyao Airlines', callsign: 'JUNEYAO AIRLINES' },
  { iata: 'HP', icao: 'AWE', name: 'America West Airlines', callsign: 'CACTUS' },
  { iata: 'HR', icao: 'CUA', name: 'China United Airlines', callsign: 'LIANHANG' },
  { iata: 'HT', icao: 'IMP', name: 'Hellenic Imperial Airways', callsign: 'IMPERIAL' },
  { iata: 'HU', icao: 'CHH', name: 'Hainan Airlines', callsign: 'HAINAN' },
  { iata: 'HV', icao: 'TRA', name: 'Transavia Holland', callsign: 'TRANSAVIA', aliases: ['Transavia'] },
  { iata: 'HW', icao: 'FHE', name: 'Hello', callsign: 'FLYHELLO' },
  { iata: 'HX', icao: 'CRK', name: 'Hong Kong Airlines', callsign: 'BAUHINIA' },
  { iata: 'HY', icao: 'UZB', name: 'Uzbekistan Airways', callsign: 'UZBEK' },
  { iata: 'HZ', icao: 'SHU', name: 'Aurora', callsign: 'AURORA', aliases: ['Aurora Airlines'] },
  { iata: 'I6', icao: 'MXI', name: 'MexicanaLink', callsign: 'LINK' },
  { iata: 'I7', icao: 'PMW', name: 'Paramount Airways', callsign: 'PARAWAY' },
  { iata: 'I9', icao: 'AEY', name: 'Air Italy', callsign: 'AIR ITALY' },
  { iata: 'IA', icao: 'IAW', name: 'Iraqi Airways', callsign: 'IRAQI' },
  { iata: 'IB', icao: 'IBE', name: 'Iberia Airlines', callsign: 'IBERIA', aliases: ['Iberia'], alliances: ['OW'] },
  { iata: 'IC', icao: 'IAC', name: 'Indian Airlines', callsign: 'INDAIR' },
  { iata: 'ID', icao: 'ITK', name: 'Interlink Airlines', callsign: 'INTERLINK' },
  { iata: 'IE', icao: 'SOL', name: 'Solomon Airlines', callsign: 'SOLOMON' },
  { iata: 'IF', icao: 'ISW', name: 'Islas Airways', callsign: 'PINTADERA' },
  { iata: 'IG', icao: 'ISS', name: 'Meridiana', callsign: 'MERAIR' },
  { iata: 'II', icao: 'UWW', name: 'LSM International ', callsign: 'moose', aliases: ['Moskva-air'] },
  { iata: 'IK', icao: 'ITX', name: 'Imair Airlines', callsign: 'IMPROTEX' },
  { iata: 'IN', icao: 'MAK', name: 'MAT Macedonian Airlines', callsign: 'MAKAVIO' },
  { iata: 'IO', icao: 'IAA', name: 'Indonesian Airlines', callsign: 'INDO LINES' },
  { iata: 'IQ', icao: 'AUB', name: 'Augsburg Airways', callsign: 'AUGSBURG-AIR' },
  { iata: 'IR', icao: 'IRA', name: 'Iran Air', callsign: 'IRANAIR' },
  { iata: 'IT', icao: 'KFR', name: 'Kingfisher Airlines', callsign: 'KINGFISHER' },
  { iata: 'IV', icao: 'JET', name: 'Wind Jet', callsign: 'GHIBLI' },
  { iata: 'IW', icao: 'WON', name: 'Wings Air', callsign: 'WINGS ABADI' },
  { iata: 'IX', icao: 'AXB', name: 'Air India Express', callsign: 'EXPRESS INDIA' },
  { iata: 'IY', icao: 'IYE', name: 'Yemenia', callsign: 'YEMENI' },
  { iata: 'IZ', icao: 'AIZ', name: 'Arkia Israel Airlines', callsign: 'ARKIA' },
  { iata: 'J2', icao: 'AHY', name: 'Azerbaijan Airlines', callsign: 'AZAL' },
  { iata: 'J3', icao: 'PLR', name: 'Northwestern Air', callsign: 'POLARIS' },
  { iata: 'J8', icao: 'BVT', name: 'Berjaya Air', callsign: 'BERJAYA' },
  { iata: 'J9', icao: 'JZR', name: 'Jazeera Airways', callsign: 'JAZEERA' },
  { iata: 'JA', icao: 'BON', name: 'Air Bosna', callsign: 'AIR BOSNA' },
  { iata: 'JB', icao: 'JBA', name: 'Helijet', callsign: 'HELIJET' },
  { iata: 'JC', icao: 'JEX', name: 'JAL Express', callsign: 'JANEX' },
  { iata: 'JD', icao: 'JAS', name: 'Japan Air System', callsign: 'Air System' },
  { iata: 'JE', icao: 'MNO', name: 'Mango', callsign: 'TULCA' },
  { iata: 'JF', icao: 'JAF', name: 'Jetairfly', callsign: 'BEAUTY' },
  { iata: 'JI', icao: 'MDW', name: 'Midway Airlines', callsign: 'MIDWAY' },
  { iata: 'JJ', icao: 'TAM', name: 'LATAM Brasil', callsign: 'TAM', aliases: ['TAM Brazilian Airlines', 'LATAM Airlines Brasil'], alliances: ['OW'] },
  { iata: 'JK', icao: 'JKK', name: 'Spanair', callsign: 'SPANAIR' },
  { iata: 'JL', icao: 'JAL', name: 'Japan Airlines', callsign: 'JAPANAIR', aliases: ['JAL', 'JAL Japan Airlines'], alliances: ['OW'] },
  { iata: 'JM', icao: 'AJM', name: 'Air Jamaica', callsign: 'JAMAICA' },
  { iata: 'JN', icao: 'XLA', name: 'Excel Airways', callsign: 'EXPO' },
  { iata: 'JO', icao: 'JAZ', name: 'JALways', callsign: 'JALWAYS' },
  { iata: 'JP', icao: 'ADR', name: 'Adria Airways', callsign: 'ADRIA', aliases: ['Adria - The Airline of Slovenia'], alliances: ['*A'] },
  { iata: 'JQ', icao: 'JST', name: 'Jetstar Airways', callsign: 'JETSTAR' },
  { iata: 'JR', icao: 'JOY', name: 'Joy Air', callsign: 'JOY AIR' },
  { iata: 'JS', icao: 'KOR', name: 'Air Koryo', callsign: 'AIR KORYO' },
  { iata: 'JT', icao: 'LNI', name: 'Lion Mentari Airlines', callsign: 'LION INTER' },
  { iata: 'JU', icao: 'JAT', name: 'Jat Airways', callsign: 'AIR SERBIA', aliases: ['Air Serbia'] },
  { iata: 'JV', icao: 'BLS', name: 'Bearskin Lake Air Service', callsign: 'BEARSKIN' },
  { iata: 'JW', icao: 'APW', name: 'Arrow Air', callsign: 'BIG A' },
  { iata: 'JZ', icao: 'SKX', name: 'Skyways Express', callsign: 'SKY EXPRESS' },
  { iata: 'K2', icao: 'ELO', name: 'Eurolot', callsign: 'EUROLOT' },
  { iata: 'K5', icao: 'SQH', name: 'SeaPort Airlines', callsign: 'SASQUATCH' },
  { iata: 'K6', icao: 'KZW', name: 'Khalifa Airways', callsign: 'KHALIFA AIR' },
  { iata: 'K7', icao: 'KBR', name: 'KoralBlue Airlines', callsign: 'KORAL BLUE' },
  { iata: 'KA', icao: 'HDA', name: 'Cathay Dragon', callsign: 'Hong Kong Dragon Airlines', aliases: ['Cathay Dragon'] },
  { iata: 'KB', icao: 'DRK', name: 'Druk Air', callsign: 'ROYAL BHUTAN' },
  { iata: 'KC', icao: 'KZR', name: 'Air Astana', callsign: 'ASTANALINE' },
  { iata: 'KD', icao: 'KNI', name: 'KD Avia', callsign: 'KALININGRAD AIR' },
  { iata: 'KE', icao: 'KAL', name: 'Korean Air', callsign: 'KOREANAIR', alliances: ['ST'] },
  { iata: 'KF', icao: 'BLF', name: 'Blue1', callsign: 'BLUEFIN' },
  { iata: 'KG', icao: 'RAW', name: 'Royal Airways', callsign: 'RAW', aliases: ['Royal Inc.'] },
  { iata: 'KI', icao: 'DHI', name: 'Adam Air', callsign: 'ADAM SKY' },
  { iata: 'KJ', icao: 'LAJ', name: 'British Mediterranean Airways', callsign: 'BEE MED' },
  { iata: 'KK', icao: 'KKK', name: 'AtlasGlobal', callsign: 'ATLASGLOBAL' },
  { iata: 'KL', icao: 'KLM', name: 'KLM Royal Dutch Airlines', callsign: 'KLM', alliances: ['ST'] },
  { iata: 'KM', icao: 'AMC', name: 'Air Malta', callsign: 'AIR MALTA', aliases: ['Air Malta Co. Ltd.'] },
  { iata: 'KN', icao: 'CUA', name: 'China United Airlines', callsign: 'LIANHANG' },
  { iata: 'KO', icao: 'AER', name: 'Alaska Central Express', callsign: 'ACE AIR' },
  { iata: 'KP', icao: 'DWA', name: 'Dense Airways', callsign: 'DENSE' },
  { iata: 'KQ', icao: 'KQA', name: 'Kenya Airways', callsign: 'KENYA', alliances: ['ST'] },
  { iata: 'KR', icao: 'CWK', name: 'Comores Airlines', callsign: 'CONTICOM' },
  { iata: 'KS', icao: 'PEN', name: 'Peninsula Airways', callsign: 'PENINSULA' },
  { iata: 'KT', icao: 'VKJ', name: 'VickJet', callsign: 'Vickjet' },
  { iata: 'KU', icao: 'KAC', name: 'Kuwait Airways', callsign: 'KUWAITI' },
  { iata: 'KV', icao: 'MVD', name: 'Kavminvodyavia', callsign: 'AIR MINVODY' },
  { iata: 'KX', icao: 'CAY', name: 'Cayman Airways', callsign: 'CAYMAN' },
  { iata: 'KY', icao: 'KSY', name: 'KSY', callsign: 'KSY', aliases: ['Kreta Sky'] },
  { iata: 'KZ', icao: 'DC2', name: 'Dense Connection', callsign: 'DC2' },
  { iata: 'L3', icao: 'LTO', name: 'LTU Austria', callsign: 'BILLA TRANSPORT' },
  { iata: 'L4', icao: 'LJJ', name: 'Luchsh Airlines ', callsign: 'russian sky', aliases: ['Air luch'] },
  { iata: 'L5', icao: 'LTR', name: 'Lufttransport', callsign: 'LUFT TRANSPORT' },
  { iata: 'L8', icao: 'LBL', name: 'Line Blue', callsign: 'Bluebird' },
  { iata: 'LA', icao: 'LAN', name: 'LAN Airlines', callsign: 'LAN' },
  { iata: 'LC', icao: 'VLO', name: 'Varig Log', callsign: 'VELOG' },
  { iata: 'LF', icao: 'NDC', name: 'FlyNordic', callsign: 'NORDIC' },
  { iata: 'LG', icao: 'LGL', name: 'Luxair', callsign: 'LUXAIR' },
  { iata: 'LH', icao: 'DLH', name: 'Lufthansa', callsign: 'LUFTHANSA', alliances: ['*A'] },
  { iata: 'LI', icao: 'LIA', name: 'Leeward Islands Air Transport', callsign: 'LIAT' },
  { iata: 'LJ', icao: 'JNA', name: 'Jin Air', callsign: 'Jin Air' },
  { iata: 'LK', icao: 'LXR', name: 'Air Luxor', callsign: 'AIRLUXOR' },
  { iata: 'LN', icao: 'LAA', name: 'Libyan Arab Airlines', callsign: 'LIBAIR' },
  { iata: 'LO', icao: 'LOT', name: 'LOT Polish Airlines', callsign: 'POLLOT', aliases: ['LOT-Polish Airlines'], alliances: ['*A'] },
  { iata: 'LP', icao: 'LPE', name: 'LAN Peru', callsign: 'LANPERU' },
  { iata: 'LR', icao: 'LRC', name: 'LACSA', callsign: 'LACSA' },
  { iata: 'LS', icao: 'EXS', name: 'Jet2.com', callsign: 'CHANNEX' },
  { iata: 'LT', icao: 'LTU', name: 'Air Lituanica', callsign: 'LITUANICA', aliases: ['Air Lituanica'] },
  { iata: 'LU', icao: 'LXP', name: 'LAN Express', callsign: 'LANEX' },
  { iata: 'LV', icao: 'LBC', name: 'Albanian Airlines', callsign: 'ALBANIAN' },
  { iata: 'LW', icao: 'NMI', name: 'Pacific Wings', callsign: 'TSUNAMI' },
  { iata: 'LX', icao: 'SWR', name: 'Swiss International Air Lines', callsign: 'SWISS', aliases: ['SWISS', 'Swiss Airlines'], alliances: ['*A'] },
  { iata: 'LY', icao: 'ELY', name: 'El Al Israel Airlines', callsign: 'ELAL' },
  { iata: 'M1', icao: 'M1F', name: 'Maryland Air', callsign: 'Maryland Flight', aliases: ['Maryland'] },
  { iata: 'M3', icao: 'TUS', name: 'ABSA - Aerolinhas Brasileiras', callsign: 'ABSA Cargo' },
  { iata: 'M4', icao: '1QA', name: 'Marysya Airlines', callsign: 'MARSHAK AIR', aliases: ['MARYSYA AIRLINES'] },
  { iata: 'M5', icao: 'KEN', name: 'Kenmore Air', callsign: 'KENMORE' },
  { iata: 'M7', icao: 'MAA', name: 'MasAir', callsign: 'MAS CARGA' },
  { iata: 'M8', icao: 'TNU', name: 'TransNusa Air', callsign: 'TRANSNUSA' },
  { iata: 'M9', icao: 'MSI', name: 'Motor Sich', callsign: 'MOTOR SICH' },
  { iata: 'MA', icao: 'MAH', name: 'Malév', callsign: 'MALEV' },
  { iata: 'MB', icao: 'MNB', name: 'MNG Airlines', callsign: 'BLACK SEA' },
  { iata: 'MD', icao: 'MDG', name: 'Air Madagascar', callsign: 'AIR MADAGASCAR' },
  { iata: 'ME', icao: 'MEA', name: 'Middle East Airlines', callsign: 'CEDAR JET', aliases: ['Middle East Airlines AirLiban'], alliances: ['ST'] },
  { iata: 'MF', icao: 'CXA', name: 'Xiamen Airlines', callsign: 'XIAMEN AIR', alliances: ['ST'] },
  { iata: 'MH', icao: 'MAS', name: 'Malaysia Airlines', callsign: 'MALAYSIAN', alliances: ['OW'] },
  { iata: 'MI', icao: 'SLK', name: 'SilkAir', callsign: 'SILKAIR', aliases: ['Silk Air'], alliances: ['KF'] },
  { iata: 'MJ', icao: 'LPR', name: 'L', callsign: 'LAPA' },
  { iata: 'MK', icao: 'MAU', name: 'Air Mauritius', callsign: 'AIRMAURITIUS' },
  { iata: 'ML', icao: 'MAV', name: 'Maldivo Airlines', callsign: 'Maldivo' },
  { iata: 'MN', icao: 'CAW', name: 'Comair', callsign: 'COMMERCIAL', aliases: ['Comair (BA franchise)'] },
  { iata: 'MO', icao: 'AUH', name: 'Abu Dhabi Amiri Flight', callsign: 'SULTAN' },
  { iata: 'MP', icao: 'MPH', name: 'Martinair', callsign: 'MARTINAIR' },
  { iata: 'MQ', icao: 'EGF', name: 'American Eagle Airlines', callsign: 'EAGLE FLIGHT' },
  { iata: 'MS', icao: 'MSR', name: 'Egyptair', callsign: 'EGYPTAIR', alliances: ['*A'] },
  { iata: 'MT', icao: 'TCX', name: 'Thomas Cook Airlines', callsign: 'KESTREL' },
  { iata: 'MU', icao: 'CES', name: 'China Eastern Airlines', callsign: 'CHINA EASTERN', alliances: ['ST'] },
  { iata: 'MW', icao: 'MYD', name: 'Maya Island Air', callsign: 'MYLAND' },
  { iata: 'MX', icao: 'MXA', name: 'Mexicana de Aviaci', callsign: 'MEXICANA' },
  { iata: 'MZ', icao: 'MNA', name: 'Merpati Nusantara Airlines', callsign: 'MERPATI' },
  { iata: 'N5', icao: 'SGY', name: 'Skagway Air Service', callsign: 'SKAGWAY AIR' },
  { iata: 'NB', icao: 'SNB', name: 'Sterling Airlines', callsign: 'STERLING' },
  { iata: 'NC', icao: 'NJS', name: 'National Jet Systems', callsign: 'NATIONAL JET' },
  { iata: 'NE', icao: 'ESK', name: 'SkyEurope', callsign: 'RELAX' },
  { iata: 'NF', icao: 'AVN', name: 'Air Vanuatu', callsign: 'AIR VAN' },
  { iata: 'NG', icao: 'LDA', name: 'Lauda Air', callsign: 'LAUDA AIR' },
  { iata: 'NH', icao: 'ANA', name: 'All Nippon Airways', callsign: 'ALL NIPPON', aliases: ['ANA', 'ANA All Nippon Airways', 'All Nippon Airways Co. Ltd.'], alliances: ['*A'] },
  { iata: 'NI', icao: 'PGA', name: 'Portugalia', callsign: 'PORTUGALIA' },
  { iata: 'NJ', icao: 'NGB', name: 'Nordic Global Airlines', callsign: 'Nordic Global' },
  { iata: 'NK', icao: 'NKS', name: 'Spirit Airlines', callsign: 'SPIRIT WINGS' },
  { iata: 'NL', icao: 'SAI', name: 'Shaheen Air International', callsign: 'SHAHEEN AIR' },
  { iata: 'NM', icao: 'DRD', name: 'Air Madrid', callsign: 'ALADA AIR' },
  { iata: 'NN', icao: 'MOV', name: 'VIM Airlines', callsign: 'MOV AIR' },
  { iata: 'NP', icao: 'NIA', name: 'Nile Air', callsign: 'NILEBIRD' },
  { iata: 'NQ', icao: 'AJX', name: 'Air Japan', callsign: 'AIR JAPAN' },
  { iata: 'NR', icao: 'JTO', name: 'Jettor Airlines', callsign: 'JETHAPPY', aliases: ['Jettor'] },
  { iata: 'NS', icao: 'HBH', name: 'Hebei Airlines', callsign: 'HEBEI AIR' },
  { iata: 'NU', icao: 'JTA', name: 'Japan Transocean Air', callsign: 'JAI OCEAN' },
  { iata: 'NW', icao: 'NWA', name: 'Northwest Airlines', callsign: 'NORTHWEST' },
  { iata: 'NX', icao: 'AMU', name: 'Air Macau', callsign: 'AIR MACAO' },
  { iata: 'NY', icao: 'FXI', name: 'Air Iceland', callsign: 'FAXI' },
  { iata: 'NZ', icao: 'ANZ', name: 'Air New Zealand', callsign: 'NEW ZEALAND', alliances: ['*A'] },
  { iata: 'O1', icao: 'OAB', name: 'Orbit Airlines Azerbaijan', callsign: 'Orbitaz', aliases: ['Orbit Azerbaijan'] },
  { iata: 'O6', icao: 'ONE', name: 'Avianca Brazil', callsign: 'OCEAN AIR', aliases: ['Oceanair Linhas Aéreas S/A'], alliances: ['*A'] },
  { iata: 'O7', icao: 'OZJ', name: 'Ozjet Airlines', callsign: 'AUSJET' },
  { iata: 'O8', icao: 'OHK', name: 'Oasis Hong Kong Airlines', callsign: 'OASIS' },
  { iata: 'OA', icao: 'OAL', name: 'Olympic Airlines', callsign: 'OLYMPIC' },
  { iata: 'OB', icao: 'ASZ', name: 'Astrakhan Airlines', callsign: 'AIR ASTRAKHAN' },
  { iata: 'OD', icao: 'MXD', name: 'Malindo Air', callsign: 'Malindo' },
  { iata: 'OF', icao: 'FIF', name: 'Air Finland', callsign: 'AIR FINLAND' },
  { iata: 'OH', icao: 'COM', name: 'Comair', callsign: 'COMAIR' },
  { iata: 'OJ', icao: 'OLA', name: 'Overland Airways', callsign: 'OVERLAND' },
  { iata: 'OK', icao: 'CSA', name: 'Czech Airlines', callsign: 'CSA-LINES', aliases: ['CSA Czech Airlines'], alliances: ['ST'] },
  { iata: 'OL', icao: 'OLT', name: 'Ostfriesische Lufttransport', callsign: 'OLTRA' },
  { iata: 'OM', icao: 'MGL', name: 'MIAT Mongolian Airlines', callsign: 'MONGOL AIR' },
  { iata: 'ON', icao: 'RON', name: 'Nauru Air Corporation', callsign: 'AIR NAURU' },
  { iata: 'OO', icao: 'SKW', name: 'SkyWest', callsign: 'SKYWEST' },
  { iata: 'OQ', icao: 'CQN', name: 'Chongqing Airlines', callsign: 'CHONG QING' },
  { iata: 'OR', icao: 'TFL', name: 'Arkefly', callsign: 'ARKEFLY' },
  { iata: 'OS', icao: 'AUA', name: 'Austrian Airlines', callsign: 'AUSTRIAN', aliases: ['Austrian'], alliances: ['*A'] },
  { iata: 'OT', icao: 'PEL', name: 'Aeropelican Air Services', callsign: 'PELICAN' },
  { iata: 'OU', icao: 'CTN', name: 'Croatia Airlines', callsign: 'CROATIA', aliases: ['Croatie Airlines'], alliances: ['*A'] },
  { iata: 'OV', icao: 'ELL', name: 'Estonian Air', callsign: 'ESTONIAN' },
  { iata: 'OX', icao: 'OEA', name: 'Orient Thai Airlines', callsign: 'ORIENT THAI' },
  { iata: 'OY', icao: 'OAE', name: 'Omni Air International', callsign: 'OMNI-EXPRESS' },
  { iata: 'OZ', icao: 'AAR', name: 'Asiana Airlines', callsign: 'ASIANA', alliances: ['*A'] },
  { iata: 'P5', icao: 'RPB', name: 'AeroRep', callsign: 'AEROREPUBLICA' },
  { iata: 'P7', icao: 'REP', name: 'Regional Paraguaya', callsign: 'REGIOPAR' },
  { iata: 'P8', icao: 'MKG', name: 'Air Mekong', callsign: 'Air Mekong' },
  { iata: 'PA', icao: 'IPV', name: 'Parmiss Airlines (IPV)', callsign: 'IPV' },
  { iata: 'PC', icao: 'PGT', name: 'Pegasus Airlines', callsign: 'SUNTURK' },
  { iata: 'PD', icao: 'POE', name: 'Porter Airlines', callsign: 'PORTER AIR' },
  { iata: 'PE', icao: 'AEL', name: 'Air Europe', callsign: 'AIR EUROPE' },
  { iata: 'PG', icao: 'BKP', name: 'Bangkok Airways', callsign: 'BANGKOK AIR' },
  { iata: 'PH', icao: 'PAO', name: 'Polynesian Airlines', callsign: 'POLYNESIAN' },
  { iata: 'PI', icao: 'PDT', name: 'Piedmont Airlines (1948-1989)', callsign: 'PIEDMONT' },
  { iata: 'PK', icao: 'PIA', name: 'Pakistan International Airlines', callsign: 'PAKISTAN', aliases: ['PIA Pakistan International'] },
  { iata: 'PL', icao: 'PLI', name: 'Aeroper', callsign: 'Aeroperu' },
  { iata: 'PM', icao: 'TOS', name: 'Tropic Air', callsign: 'TROPISER' },
  { iata: 'PN', icao: 'CHB', name: 'West Air China', callsign: 'WEST CHINA' },
  { iata: 'PO', icao: 'FPT', name: 'FlyPortugal', callsign: 'FlyPortugal' },
  { iata: 'PP', icao: 'AI0', name: 'Air Indus', callsign: 'AIPL', aliases: ['Indus Airlines Pak'] },
  { iata: 'PQ', icao: 'LOO', name: 'LSM Airlines', callsign: 'slowbird', aliases: ['slowbird'] },
  { iata: 'PR', icao: 'PAL', name: 'Philippine Airlines', callsign: 'PHILIPPINE' },
  { iata: 'PS', icao: 'AUI', name: 'Ukraine International Airlines', callsign: 'UKRAINE INTERNATIONAL' },
  { iata: 'PU', icao: 'PUA', name: 'PLUNA', callsign: 'PLUNA' },
  { iata: 'PV', icao: 'PNR', name: 'PAN Air', callsign: 'SKYJET' },
  { iata: 'PW', icao: 'PRF', name: 'Precision Air', callsign: 'PRECISION AIR', aliases: ['Precisionair'] },
  { iata: 'PX', icao: 'ANG', name: 'Air Niugini', callsign: 'NUIGINI' },
  { iata: 'PY', icao: 'SLM', name: 'Surinam Airways', callsign: 'SURINAM' },
  { iata: 'PZ', icao: 'LAP', name: 'TAM Mercosur', callsign: 'PARAGUAYA' },
  { iata: 'Q3', icao: 'QER', name: 'SOCHI AIR CHATER', callsign: 'russian doll', aliases: ['Sochi Air'] },
  { iata: 'Q4', icao: 'SAE', name: 'SOCHI AIR EXPRESS', callsign: 'ADLER EXPRESS', aliases: ['ADLER EXPRESS'] },
  { iata: 'Q5', icao: 'MLA', name: '40-Mile Air', callsign: 'MILE-AIR' },
  { iata: 'Q6', icao: 'CDP', name: 'Aero Condor Peru', callsign: 'CONDOR-PERU' },
  { iata: 'QB', icao: 'GFG', name: 'Georgian National Airlines', callsign: 'NATIONAL' },
  { iata: 'QD', icao: 'DOB', name: 'Dobrolet', callsign: 'DOBROLET', aliases: ['Добролёт'] },
  { iata: 'QF', icao: 'QFA', name: 'Qantas', callsign: 'QANTAS', aliases: ['Qantas Airways', 'Qantas Airways Ltd'], alliances: ['OW'] },
  { iata: 'QH', icao: 'FLZ', name: 'Air Florida', callsign: 'AIR FLORIDA' },
  { iata: 'QI', icao: 'CIM', name: 'Cimber Air', callsign: 'CIMBER' },
  { iata: 'QK', icao: 'JZA', name: 'Air Canada Jazz', callsign: 'JAZZ' },
  { iata: 'QL', icao: 'RLN', name: 'Aero Lanka', callsign: 'AERO LANKA' },
  { iata: 'QM', icao: 'AML', name: 'Air Malawi', callsign: 'MALAWI' },
  { iata: 'QO', icao: 'OGN', name: 'Origin Pacific Airways', callsign: 'ORIGIN' },
  { iata: 'QQ', icao: 'UTY', name: 'Alliance Airlines', callsign: 'UNITY' },
  { iata: 'QR', icao: 'QTR', name: 'Qatar Airways', callsign: 'QATARI', aliases: ['Quatar Airways'], alliances: ['OW'] },
  { iata: 'QS', icao: 'TVS', name: 'Travel Service', callsign: 'SKYTRAVEL', aliases: ['Smart Wings', 'SmartWings'] },
  { iata: 'QT', icao: 'TPA', name: 'TAMPA', callsign: 'TAMPA' },
  { iata: 'QU', icao: 'UGX', name: 'East African', callsign: 'CRANE' },
  { iata: 'QV', icao: 'LAO', name: 'Lao Airlines', callsign: 'LAO' },
  { iata: 'QW', icao: 'BWG', name: 'Blue Wings', callsign: 'BLUE WINGS' },
  { iata: 'QX', icao: 'QXE', name: 'Horizon Air', callsign: 'HORIZON AIR', aliases: ['Horizon Airlines'] },
  { iata: 'QZ', icao: 'AWQ', name: 'Indonesia AirAsia', callsign: 'WAGON AIR' },
  { iata: 'R2', icao: 'ORB', name: 'Orenburg Airlines', callsign: 'ORENBURG' },
  { iata: 'R3', icao: 'SYL', name: 'Aircompany Yakutia', callsign: 'AIR YAKUTIA' },
  { iata: 'R5', icao: 'MAC', name: 'Malta Air Charter', callsign: 'MALTA CHARTER' },
  { iata: 'R7', icao: 'OCA', name: 'Aserca Airlines', callsign: 'AROSCA' },
  { iata: 'R8', icao: 'RRJ', name: 'AirRussia', callsign: 'russiancloud', aliases: ['RussianConector'] },
  { iata: 'RA', icao: 'RNA', name: 'Nepal Airlines', callsign: 'ROYAL NEPAL' },
  { iata: 'RB', icao: 'SYR', name: 'Syrian Arab Airlines', callsign: 'SYRIANAIR' },
  { iata: 'RC', icao: 'FLI', name: 'Atlantic Airways', callsign: 'FAROELINE' },
  { iata: 'RD', icao: 'RYN', name: 'Ryan International Airlines', callsign: 'RYAN INTERNATIONAL' },
  { iata: 'RE', icao: 'REA', name: 'Aer Arann', callsign: 'AER ARANN' },
  { iata: 'RF', icao: 'FWL', name: 'Florida West International Airways', callsign: 'FLO WEST' },
  { iata: 'RG', icao: 'VRN', name: 'VRG Linhas Aereas', callsign: 'VARIG', aliases: ['Varig'] },
  { iata: 'RH', icao: 'RPH', name: 'Republic Express Airlines', callsign: 'PUBLIC EXPRESS' },
  { iata: 'RI', icao: 'MDL', name: 'Mandala Airlines', callsign: 'MANDALA' },
  { iata: 'RJ', icao: 'RJA', name: 'Royal Jordanian', callsign: 'JORDANIAN', alliances: ['OW'] },
  { iata: 'RK', icao: 'RKA', name: 'Air Afrique', callsign: 'AIRAFRIC' },
  { iata: 'RM', icao: 'RNY', name: 'Rainbow Air US', callsign: 'Rainbow Air', aliases: ['Rainbow Air US'] },
  { iata: 'RN', icao: 'RAB', name: 'Rainbow Air (RAI)', callsign: 'Rainbow', aliases: ['Rainbow Air (RAI)'] },
  { iata: 'RO', icao: 'ROT', name: 'Tarom', callsign: 'TAROM', aliases: ['TAROM - Romenian Air Transport'], alliances: ['ST'] },
  { iata: 'RP', icao: 'CHQ', name: 'Chautauqua Airlines', callsign: 'CHAUTAUQUA' },
  { iata: 'RQ', icao: 'KMF', name: 'Kam Air', callsign: 'KAMGAR' },
  { iata: 'RR', icao: 'RXR', name: 'REXAIR VIRTUEL', callsign: 'Rexair' },
  { iata: 'RS', icao: 'SKV', name: 'Sky Regional', callsign: 'Sky Regional', aliases: ['Air Canada Express'] },
  { iata: 'RU', icao: 'RUE', name: 'Rainbow Air Euro', callsign: 'Rainbow Air', aliases: ['Rainbow Air EU'] },
  { iata: 'RV', icao: 'CPN', name: 'Caspian Airlines', callsign: 'CASPIAN' },
  { iata: 'RW', icao: 'RPA', name: 'Republic Airlines', callsign: 'BRICKYARD' },
  { iata: 'RX', icao: 'RPO', name: 'Rainbow Air Polynesia', callsign: 'Rainbow Air', aliases: ['Rainbow Air POL'] },
  { iata: 'RY', icao: 'RAY', name: 'Rainbow Air Canada', callsign: 'Rainbow CAN', aliases: ['Rainbow Air CAN'] },
  { iata: 'S0', icao: 'SAL', name: 'Spike Airlines', callsign: 'Spike Air', aliases: ['Aero Spike'] },
  { iata: 'S2', icao: 'RSH', name: 'Air Sahara', callsign: 'SAHARA' },
  { iata: 'S3', icao: 'BBR', name: 'Santa Barbara Airlines', callsign: 'SANTA BARBARA' },
  { iata: 'S4', icao: 'RZO', name: 'SATA International', callsign: 'AIR AZORES' },
  { iata: 'S5', icao: 'TCF', name: 'Shuttle America', callsign: 'MERCURY' },
  { iata: 'S6', icao: 'KSZ', name: 'Sunrise Airways', callsign: null, aliases: ['Sunrise Airlnes'] },
  { iata: 'S7', icao: 'SBI', name: 'S7 Airlines', callsign: 'SIBERIAN AIRLINES', aliases: ['Sibir Airlines', 'Siberia Airlines'], alliances: ['OW'] },
  { iata: 'SA', icao: 'SAA', name: 'South African Airways', callsign: 'SPRINGBOK', aliases: ['SAA South African Airways'], alliances: ['*A'] },
  { iata: 'SB', icao: 'ACI', name: 'Air Caledonie International', callsign: 'AIRCALIN' },
  { iata: 'SC', icao: 'CDG', name: 'Shandong Airlines', callsign: 'SHANDONG' },
  { iata: 'SD', icao: 'SUD', name: 'Sudan Airways', callsign: 'SUDANAIR' },
  { iata: 'SE', icao: 'SEU', name: 'XL Airways France', callsign: 'STARWAY' },
  { iata: 'SG', icao: 'SEJ', name: 'Spicejet', callsign: 'SPICEJET' },
  { iata: 'SH', icao: 'SHA', name: 'Sharp Airlines', callsign: 'SHARP' },
  { iata: 'SI', icao: 'SIH', name: 'Skynet Airlines', callsign: 'BLUEJET' },
  { iata: 'SJ', icao: 'SJY', name: 'Sriwijaya Air', callsign: 'SRIWIJAYA' },
  { iata: 'SK', icao: 'SAS', name: 'Scandinavian Airlines System', callsign: 'SCANDINAVIAN', aliases: ['SAS', 'SAS Scandinavian Airlines'], alliances: ['*A'] },
  { iata: 'SM', icao: 'MNP', name: 'Spirit of Manila Airlines', callsign: 'MANILA SKY' },
  { iata: 'SN', icao: 'DAT', name: 'Brussels Airlines', callsign: 'BEE-LINE', aliases: ['SN Brussels Airlines'], alliances: ['*A'] },
  { iata: 'SO', icao: 'SLC', name: 'Salsa d\'Haiti', callsign: 'SALSA' },
  { iata: 'SP', icao: 'SAT', name: 'SATA Air Acores', callsign: 'SATA' },
  { iata: 'SQ', icao: 'SIA', name: 'Singapore Airlines', callsign: 'SINGAPORE', aliases: ['Singapore Airlines Ltd'], alliances: ['KF', '*A'] },
  { iata: 'SR', icao: 'SWR', name: 'Swissair', callsign: 'Swissair' },
  { iata: 'SS', icao: 'CRL', name: 'Corsairfly', callsign: 'CORSAIR' },
  { iata: 'ST', icao: 'GMI', name: 'Germania', callsign: 'GERMANIA' },
  { iata: 'SU', icao: 'AFL', name: 'Aeroflot Russian Airlines', callsign: 'AEROFLOT', aliases: ['Aeroflot'], alliances: ['ST'] },
  { iata: 'SV', icao: 'SVA', name: 'Saudi Arabian Airlines', callsign: 'SAUDIA', alliances: ['ST'] },
  { iata: 'SW', icao: 'NMB', name: 'Air Namibia', callsign: 'NAMIBIA' },
  { iata: 'SY', icao: 'SCX', name: 'Sun Country Airlines', callsign: 'SUN COUNTRY' },
  { iata: 'T2', icao: 'TCG', name: 'Thai Air Cargo', callsign: 'THAI CARGO' },
  { iata: 'T3', icao: 'EZE', name: 'Eastern Airways', callsign: 'EASTFLIGHT' },
  { iata: 'T4', icao: 'HEJ', name: 'Hellas Jet', callsign: 'HELLAS JET' },
  { iata: 'T5', icao: 'TUA', name: 'Turkmenistan Airlines', callsign: 'TURKMENISTAN', aliases: ['Turkmenhovayollary'] },
  { iata: 'T7', icao: 'TJT', name: 'Twin Jet', callsign: 'TWINJET' },
  { iata: 'TA', icao: 'TAI', name: 'Avianca El Salvador', callsign: 'TACA', aliases: ['TACA', 'TACA Airlines'] },
  { iata: 'TC', icao: 'ATC', name: 'Air Tanzania', callsign: 'TANZANIA' },
  { iata: 'TE', icao: 'LIL', name: 'FlyLal', callsign: 'LITHUANIA AIR' },
  { iata: 'TF', icao: 'SCW', name: 'Malmö Aviation', callsign: 'Scanwings' },
  { iata: 'TG', icao: 'THA', name: 'Thai Airways International', callsign: 'THAI', alliances: ['*A'] },
  { iata: 'TJ', icao: 'TJA', name: 'T.J. Air', callsign: 'T.J. Air' },
  { iata: 'TK', icao: 'THY', name: 'Turkish Airlines', callsign: 'TURKAIR', aliases: ['Turkish Airlines Inc'], alliances: ['*A'] },
  { iata: 'TL', icao: 'ANO', name: 'Airnorth', callsign: 'TOPEND' },
  { iata: 'TL', icao: 'TMA', name: 'Trans Mediterranean Airlines', callsign: 'TANGO LIMA' },
  { iata: 'TM', icao: 'LAM', name: 'LAM Mozambique Airlines', callsign: 'MOZAMBIQUE', aliases: ['Lam Mozambique'] },
  { iata: 'TN', icao: 'THT', name: 'Air Tahiti Nui', callsign: 'TAHITI AIRLINES' },
  { iata: 'TO', icao: 'TVF', name: 'Transavia France', callsign: 'FRENCH SUN' },
  { iata: 'TP', icao: 'TAP', name: 'TAP Portugal', callsign: 'AIR PORTUGAL', aliases: ['TAP', 'TAP Air Portugal', 'TAP-Air Portugal'], alliances: ['*A'] },
  { iata: 'TQ', icao: 'TXW', name: 'Texas Wings', callsign: 'TXW' },
  { iata: 'TR', icao: 'TGW', name: 'Tiger Airways', callsign: 'GO CAT' },
  { iata: 'TS', icao: 'TSC', name: 'Air Transat', callsign: 'TRANSAT' },
  { iata: 'TT', icao: 'TGW', name: 'Tiger Airways Australia', callsign: 'GO CAT' },
  { iata: 'TU', icao: 'TAR', name: 'Tunisair', callsign: 'TUNAIR', aliases: ['Tunis Air'] },
  { iata: 'TV', icao: 'VEX', name: 'Virgin Express', callsign: 'VIRGIN EXPRESS' },
  { iata: 'TW', icao: 'TWB', name: 'Tway Airlines', callsign: 'TWAY AIR' },
  { iata: 'TX', icao: 'FWI', name: 'Air Caraïbes', callsign: 'FRENCH WEST' },
  { iata: 'U2', icao: 'EZY', name: 'easyJet', callsign: 'EASY', aliases: ['EasyJet Airline'] },
  { iata: 'U3', icao: 'AIA', name: 'Avies', callsign: 'AVIES' },
  { iata: 'U4', icao: 'PMT', name: 'PMTair', callsign: 'MULTITRADE' },
  { iata: 'U5', icao: 'GWY', name: 'USA3000 Airlines', callsign: 'GETAWAY' },
  { iata: 'U6', icao: 'SVR', name: 'Ural Airlines', callsign: 'SVERDLOVSK AIR', aliases: ['Ural Airlnes'] },
  { iata: 'U8', icao: 'RNV', name: 'Armavia', callsign: 'ARMAVIA' },
  { iata: 'U9', icao: 'TAK', name: 'Tatarstan Airlines', callsign: 'TATARSTAN' },
  { iata: 'UA', icao: 'UAL', name: 'United Airlines', callsign: 'UNITED', alliances: ['*A'] },
  { iata: 'UB', icao: 'UBA', name: 'Myanma Airways', callsign: 'UNIONAIR' },
  { iata: 'UD', icao: 'HER', name: 'Hex\'Air', callsign: 'HEX AIRLINE' },
  { iata: 'UE', icao: 'NAS', name: 'Nasair', callsign: 'NASAIRWAYS' },
  { iata: 'UF', icao: 'UKM', name: 'UM Airlines', callsign: 'UKRAINE MEDITERRANEE' },
  { iata: 'UI', icao: 'ECA', name: 'Eurocypria Airlines', callsign: 'EUROCYPRIA' },
  { iata: 'UJ', icao: 'LMU', name: 'AlMasria Universal Airlines', callsign: 'ALMASRIA' },
  { iata: 'UL', icao: 'ALK', name: 'SriLankan Airlines', callsign: 'SRILANKAN', alliances: ['OW'] },
  { iata: 'UM', icao: 'AZW', name: 'Air Zimbabwe', callsign: 'AIR ZIMBABWE' },
  { iata: 'UN', icao: 'TSO', name: 'Transaero Airlines', callsign: 'TRANSOVIET' },
  { iata: 'UO', icao: 'HKE', name: 'Hong Kong Express Airways', callsign: 'HONGKONG SHUTTLE' },
  { iata: 'UP', icao: 'BHS', name: 'Bahamasair', callsign: 'BAHAMAS' },
  { iata: 'UQ', icao: 'SJU', name: 'Skyjet Airlines', callsign: 'SKYJET' },
  { iata: 'US', icao: 'USA', name: 'US Airways', callsign: 'U S AIR' },
  { iata: 'UT', icao: 'UTA', name: 'UTair Aviation', callsign: 'UTAIR' },
  { iata: 'UU', icao: 'REU', name: 'Air Austral', callsign: 'REUNION' },
  { iata: 'UX', icao: 'AEA', name: 'Air Europa', callsign: 'EUROPA', alliances: ['ST'] },
  { iata: 'UZ', icao: 'BRQ', name: 'El-Buraq Air Transport', callsign: 'BURAQAIR' },
  { iata: 'V0', icao: 'VCV', name: 'Conviasa', callsign: 'CONVIASA' },
  { iata: 'V2', icao: 'RBY', name: 'Vision Airlines (V2)', callsign: 'RUBY' },
  { iata: 'V3', icao: 'KRP', name: 'Carpatair', callsign: 'CARPATAIR' },
  { iata: 'V8', icao: 'VAS', name: 'ATRAN Cargo Airlines', callsign: 'ATRAN' },
  { iata: 'VA', icao: 'VAU', name: 'V Australia', callsign: 'VEE-OZ', aliases: ['Virgin Australia'] },
  { iata: 'VE', icao: 'VLE', name: 'Volare Airlines', callsign: 'VOLA' },
  { iata: 'VF', icao: 'VLU', name: 'Valuair', callsign: 'VALUAIR' },
  { iata: 'VG', icao: 'VLM', name: 'VLM Airlines', callsign: 'RUBENS' },
  { iata: 'VI', icao: 'VDA', name: 'Volga-Dnepr Airlines', callsign: 'VOLGA-DNEPR' },
  { iata: 'VK', icao: 'VGN', name: 'Virgin Nigeria Airways', callsign: 'VIRGIN NIGERIA' },
  { iata: 'VN', icao: 'HVN', name: 'Vietnam Airlines', callsign: 'VIET NAM AIRLINES', aliases: ['Vietnam Airlines Corporation'], alliances: ['ST'] },
  { iata: 'VO', icao: 'TYR', name: 'Tyrolean Airways', callsign: 'TYROLEAN' },
  { iata: 'VP', icao: 'VSP', name: 'VASP', callsign: 'VASP' },
  { iata: 'VQ', icao: 'VKH', name: 'Viking Hellas', callsign: 'DELPHI' },
  { iata: 'VR', icao: 'TCV', name: 'TACV', callsign: 'CABOVERDE' },
  { iata: 'VS', icao: 'VIR', name: 'Virgin Atlantic Airways', callsign: 'VIRGIN', aliases: ['Virgin Atlantic'] },
  { iata: 'VT', icao: 'VTA', name: 'Air Tahiti', callsign: 'AIR TAHITI' },
  { iata: 'VU', icao: 'VUN', name: 'Air Ivoire', callsign: 'AIRIVOIRE' },
  { iata: 'VV', icao: 'AEW', name: 'Aerosvit Airlines', callsign: 'AEROSVIT' },
  { iata: 'VW', icao: 'TAO', name: 'Aeromar', callsign: 'TRANS-AEROMAR' },
  { iata: 'VX', icao: 'VRD', name: 'Virgin America', callsign: 'REDWOOD' },
  { iata: 'VY', icao: 'VLG', name: 'Vueling Airlines', callsign: 'VUELING' },
  { iata: 'VZ', icao: 'MYT', name: 'MyTravel Airways', callsign: 'KESTREL' },
  { iata: 'W1', icao: 'WE1', name: 'World Experience Airline', callsign: 'WEA', aliases: ['WEA'] },
  { iata: 'W4', icao: 'WER', name: 'AeroWorld ', callsign: 'sovet', aliases: ['Sovet Air'] },
  { iata: 'W5', icao: 'IRM', name: 'Mahan Air', callsign: 'MAHAN AIR' },
  { iata: 'W6', icao: 'WZZ', name: 'Wizz Air', callsign: 'WIZZ AIR' },
  { iata: 'W9', icao: 'JAB', name: 'Air Bagan', callsign: 'AIR BAGAN' },
  { iata: 'WA', icao: 'KLC', name: 'KLM Cityhopper', callsign: 'CITY', aliases: ['KLM Cityhopper B.V.'] },
  { iata: 'WB', icao: 'RWD', name: 'Rwandair Express', callsign: 'RWANDAIR' },
  { iata: 'WD', icao: 'AAN', name: 'Amsterdam Airlines', callsign: 'AMSTEL' },
  { iata: 'WF', icao: 'WIF', name: 'Widerøe', callsign: 'WIDEROE', aliases: ['Wideroe'] },
  { iata: 'WJ', icao: 'WEB', name: 'WebJet Linhas A', callsign: 'WEB-BRASIL' },
  { iata: 'WK', icao: 'EDW', name: 'Edelweiss Air', callsign: 'EDELWEISS' },
  { iata: 'WN', icao: 'SWA', name: 'Southwest Airlines', callsign: 'SOUTHWEST' },
  { iata: 'WO', icao: 'WOA', name: 'World Airways', callsign: 'WORLD' },
  { iata: 'WR', icao: 'WEN', name: 'WestJet Encore', callsign: 'Encore', aliases: ['Encore'] },
  { iata: 'WS', icao: 'WJA', name: 'WestJet', callsign: 'WESTJET' },
  { iata: 'WU', icao: 'WAU', name: 'Wizz Air Ukraine', callsign: 'WIZZAIR UKRAINE' },
  { iata: 'WV', icao: 'SWV', name: 'Swe Fly', callsign: 'FLYING SWEDE' },
  { iata: 'WW', icao: 'BMI', name: 'bmibaby', callsign: 'BABY' },
  { iata: 'WX', icao: 'BCY', name: 'CityJet', callsign: 'CITY-IRELAND', aliases: ['Cityjet dba Air France'] },
  { iata: 'WY', icao: 'OMA', name: 'Oman Air', callsign: 'OMAN AIR' },
  { iata: 'WZ', icao: 'RWZ', name: 'Red Wings', callsign: 'AIR RED', aliases: ['Avialinii 400'] },
  { iata: 'X3', icao: 'HLX', name: 'TUIfly', callsign: 'YELLOW CAB' },
  { iata: 'X5', icao: 'OTJ', name: 'Fly Romania', callsign: 'TENDER AIR' },
  { iata: 'XA', icao: 'XAU', name: 'XAIR USA', callsign: 'XAIR' },
  { iata: 'XB', icao: 'NXB', name: 'NEXT Brasil', callsign: 'XB', aliases: ['NEXT'] },
  { iata: 'XE', icao: 'BTA', name: 'ExpressJet', callsign: 'JET LINK' },
  { iata: 'XF', icao: 'VLK', name: 'Vladivostok Air', callsign: 'VLADAIR' },
  { iata: 'XG', icao: 'CLI', name: 'Calima Aviacion', callsign: 'CALIMA' },
  { iata: 'XJ', icao: 'MES', name: 'Mesaba Airlines', callsign: 'MESABA' },
  { iata: 'XK', icao: 'CCM', name: 'Corse-Mediterranee', callsign: 'CORSICA', aliases: ['Compagnie Aerienne Corse Mediterranee'] },
  { iata: 'XL', icao: 'LNE', name: 'LATAM Ecuador', callsign: 'LAN ECUADOR', aliases: ['Aerolane', 'LAN Ecuador'], alliances: ['OW'] },
  { iata: 'XM', icao: 'SMX', name: 'Alitalia Express', callsign: 'ALIEXPRESS' },
  { iata: 'XO', icao: 'LTE', name: 'LTE International Airways', callsign: 'FUN JET' },
  { iata: 'XP', icao: 'XPT', name: 'XPTO', callsign: 'XPTO', aliases: ['XPTO'] },
  { iata: 'XQ', icao: 'SXS', name: 'SunExpress', callsign: 'SUNEXPRESS' },
  { iata: 'XT', icao: 'AXL', name: 'Air Exel', callsign: 'EXEL COMMUTER' },
  { iata: 'XW', icao: 'SXR', name: 'Sky Express', callsign: 'SKYSTORM', aliases: ['SkyExpress'] },
  { iata: 'XY', icao: 'KNE', name: 'Nas Air', callsign: 'NAS EXPRESS' },
  { iata: 'Y4', icao: 'VOI', name: 'Volaris', callsign: 'VOLARIS' },
  { iata: 'Y8', icao: 'MRS', name: 'Marusya Airways', callsign: 'snowball', aliases: ['Marusya Air'] },
  { iata: 'Y9', icao: 'IRK', name: 'Kish Air', callsign: 'KISHAIR' },
  { iata: 'YC', icao: 'YCC', name: 'Ciel Canadien', callsign: 'Ciel' },
  { iata: 'YL', icao: 'LLM', name: 'Yamal Airlines', callsign: 'YAMAL' },
  { iata: 'YM', icao: 'MGX', name: 'Montenegro Airlines', callsign: 'MONTAIR' },
  { iata: 'YS', icao: 'RAE', name: 'Régional', callsign: 'REGIONAL EUROPE' },
  { iata: 'YV', icao: 'ASH', name: 'Mesa Airlines', callsign: 'AIR SHUTTLE' },
  { iata: 'YW', icao: 'ANE', name: 'Air Nostrum', callsign: 'AIR NOSTRUM' },
  { iata: 'YZ', icao: 'YZZ', name: 'LSM AIRLINES ', callsign: 'Moscow frog ', aliases: ['Russian. Yours Air Lines '] },
  { iata: 'Z3', icao: 'SMJ', name: 'Avient Aviation', callsign: 'AVAVIA' },
  { iata: 'Z4', icao: 'OOM', name: 'Zoom Airlines', callsign: 'ZOOM' },
  { iata: 'Z6', icao: 'ZTT', name: 'ZABAIKAL AIRLINES', callsign: 'BAIKAL ', aliases: ['ZABAIKAL '] },
  { iata: 'ZA', icao: 'CYD', name: 'Access Air', callsign: 'CYCLONE' },
  { iata: 'ZA', icao: 'SUW', name: 'Interavia Airlines', callsign: 'ASTAIR' },
  { iata: 'ZB', icao: 'BUB', name: 'Air Bourbon', callsign: 'BOURBON' },
  { iata: 'ZB', icao: 'MON', name: 'Monarch Airlines', callsign: 'MONARCH' },
  { iata: 'ZC', icao: 'KGO', name: 'Korongo Airlines', callsign: 'KORONGO' },
  { iata: 'ZE', icao: 'ESR', name: 'Eastar Jet', callsign: 'Eastar' },
  { iata: 'ZG', icao: 'VVM', name: 'Viva Macau', callsign: 'JACKPOT' },
  { iata: 'ZH', icao: 'CSZ', name: 'Shenzhen Airlines', callsign: 'SHENZHEN AIR', alliances: ['*A'] },
  { iata: 'ZI', icao: 'AAF', name: 'Aigle Azur', callsign: 'AIGLE AZUR' },
  { iata: 'ZK', icao: 'GLA', name: 'Great Lakes Airlines', callsign: 'LAKES AIR' },
  { iata: 'ZL', icao: 'RXA', name: 'Regional Express', callsign: 'REX' },
  { iata: 'ZM', icao: 'IWA', name: 'Apache Air', callsign: 'APACHE', aliases: ['Apache'] },
  { iata: 'ZN', icao: 'ZNA', name: 'Zenith International Airline', callsign: 'ZENITH', aliases: ['Zenith'] },
  { iata: 'ZP', icao: 'ZZZ', name: 'Zabaykalskii Airlines', callsign: 'Lakeair', aliases: ['Baikal Airlines'] },
  { iata: 'ZQ', icao: 'LOC', name: 'Locair', callsign: 'LOCAIR' },
  { iata: 'ZS', icao: 'SMY', name: 'Sama Airlines', callsign: 'NAJIM' },
  { iata: 'ZW', icao: 'AWI', name: 'Air Wisconsin', callsign: 'AIR WISCONSIN' },
  { iata: 'ZY', icao: 'ADE', name: 'Ada Air', callsign: 'ADA AIR' }
];
