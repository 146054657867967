import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  // tslint:disable-next-line: prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-wait',
  styleUrls: ['./wait.component.scss'],
  templateUrl: './wait.component.html'
})
export class WaitComponent {

  public color = 'primary';

  public diameter = 30;

  public mode = 'indeterminate';

  public strokeWidth = 3;

  public text = 'Please wait...';

  public useAsDialog = false;

}
